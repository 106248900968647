import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, ListItemText, ListItem, Tabs } from '@material-ui/core';
import { useHistory } from 'react-router';

import { MoreVert } from '@material-ui/icons';

import { isMobile } from "react-device-detect";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        // marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

export default function VerticalTabs() {

    const history = useHistory();
    const lastSegment = window.location.pathname;
    let segment = 0;
    if(lastSegment === '/summary'){
        segment = 1;
    }else if(lastSegment === '/account-statement'){
        segment = 2;
    }else if(lastSegment === '/current-bets'){
        segment = 3;
    }else if(lastSegment === '/activity-log'){
        segment = 4;
    }
    

    const openAccount = (URL,search='') => {
        if(URL==='current-bets'){
            history.push({
                pathname:'/'+URL,
                search:search,
            });
        }else{
            history.push(`/${URL}`);
        }
    }

    const classes = useStyles();
    const [value, setValue] = React.useState(segment);

    const handleChange = (event, newValue) => {
       setValue(newValue);
    };
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    const gridType = isMobile ? 2 : 3;

    return (
        <>
            <Grid item lg={gridType} xs={12} >
                <div className="account-tabs">
                    <ListItemLink href="#simple-list" className="first-item">
                        <ListItemText primary={<MoreVert />} />
                        <span className="first-list-icon">Sports</span>
                    </ListItemLink>
                    <Tabs orientation="vertical" value={value} onChange={handleChange} className={classes.tabs}>
                        <Tab onClick={()=>openAccount('account')} label="My Profile" {...a11yProps(0)} />
                        <Tab onClick={()=>openAccount('summary')} label="Balance Overview" {...a11yProps(1)} />
                        <Tab onClick={()=>openAccount('account-statement')} label="Account Statement" {...a11yProps(2)} />
                        <Tab onClick={()=>openAccount('current-bets','current')} label="My Bets" {...a11yProps(3)} />
                        <Tab onClick={()=>openAccount('activity-log')} label="Activity Log" {...a11yProps(4)} />
                    </Tabs>
                </div>
            </Grid>
        </>
    );
}
