import React, { useEffect, useContext } from "react";
import Header from "./Header";
// import { SocketContext } from "../../context/socket";
import LeftSideBar from '../includes/LeftSideBar';
import Loader from '../assets/images/loading40.gif';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { isMobile } from 'react-device-detect';
import Time from '../assets/images/clock-green.png';
import BtnDelete from '../assets/images/btn-delete.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import FlipClock from 'flipclock';

import {
    makeStyles,
    IconButton,
    Grid,
    Button,
    Modal,
    Backdrop,
    Fade,
    Link,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Appconfig from "../config/config";
import { toast } from "react-toastify";
import { postErrorToslack } from "../config/common";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: 0
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    modal: {
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",

        margin: 9,
        borderRadius: 5,
        height: 100

    },
    darktblhead: {
        td: {
            padding: 5
        }
    }
}));


function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}

const CasinoBets = () => {
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const history = useHistory();
    // const socket = useContext(SocketContext);
    const classes = useStyles();
    const params = useParams();
    const [chipList, setchipList] = React.useState([]);

    const [placing, setPlacing] = React.useState(false);
    const [isBetPlaced, setIsBetPlaced] = React.useState(false);


    const [resultModelOpen, setResultModelOpen] = React.useState(false);

    const [title, setTitle] = React.useState("");

    const [EventData, setEventData] = React.useState([]);

    const [BetPlaceData, setBetPlaceData] = React.useState({
        event_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
    });


    const [StakeValue, setStakeValue] = React.useState(0);
    const [ProfitValue, setProfitValue] = React.useState(0);
    const [lossValue, setLossValue] = React.useState(0);

    const [expanded, setExpanded] = React.useState({
        panel1: false,
        panel2: false
    });

    const [oddsData, setOddsData] = React.useState({
        is_fancy_off: "No",
        is_odds_off: "No"
    });

    const [userOddsData, setUserOddsData] = React.useState({

        is_match_odds_off: false,

    });

    const [tvUrl, setTvUrl] = React.useState("");
    const [count, setCount] = React.useState(30);




    const resultModelClose = () => {
        setResultModelOpen(false);
    }


    function handleChangeAccordian(newExpanded) {
        // alert(!expanded[newExpanded] );
        setExpanded({ ...expanded, [newExpanded]: !expanded[newExpanded] });
    }

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    // const eventId = params.event_id;
    var clock;

    useEffect(() => {
        clock = new FlipClock(document.querySelector(".clock"), count, {
            clockFace: "Counter", countdown: true,
        }).start();
        getEventData();
        getTvUrl(params.casino_type)
        getChips()
        getCasinoLastResult();
        if (params.casino_type == "7ud") {
            setTitle("7 Up Down  Result")
            // socket.off("casino_t20_market_update");
            // socket.off("casino_dt20_market_update");
            // socket.off("casino_32c_market_update");
            // socket.off("casino_aaa_market_update");
            // socket.off("casino_ltp_market_update");
            // socket.on("casino_7upDown_market_update", (data) => {
            //     t20Socket(data);
            // });
        }
        if (params.casino_type == "ltp") {
            setTitle("Teenpatti 1 Day  Result")
            // socket.off("casino_t20_market_update");
            // socket.off("casino_dt20_market_update");
            // socket.off("casino_32c_market_update");
            // socket.off("casino_aaa_market_update");
            // socket.off("casino_7upDown_market_update");
            // socket.on("casino_ltp_market_update", (data) => {
            //     t20Socket(data);
            // });
        }
        if (params.casino_type == "t20") {
            setTitle("Teenpatti T20  Result")
            // socket.off("casino_ltp_market_update");
            // socket.off("casino_dt20_market_update");
            // socket.off("casino_32c_market_update");
            // socket.off("casino_aaa_market_update");
            // socket.off("casino_7upDown_market_update");
            // socket.on("casino_t20_market_update", (data) => {
            //     t20Socket(data);
            // });
        }
        if (params.casino_type == "dt20") {
            setTitle("Dragon Tiger  Result")
            // socket.off("casino_ltp_market_update");
            // socket.off("casino_t20_market_update");
            // socket.off("casino_32c_market_update");
            // socket.off("casino_aaa_market_update");
            // socket.off("casino_7upDown_market_update");
            // socket.on("casino_dt20_market_update", (data) => {
            //     t20Socket(data);
            // });
        }
        if (params.casino_type == "32c") {
            setTitle("32 Cards  Result")
            // socket.off("casino_ltp_market_update");
            // socket.off("casino_t20_market_update");
            // socket.off("casino_dt20_market_update");
            // socket.off("casino_aaa_market_update");
            // socket.off("casino_7upDown_market_update");

            // socket.on("casino_32c_market_update", (data) => {
            //     t20Socket(data);
            // });
        }
        if (params.casino_type == "aaa") {
            setTitle("Amar Akbar Anthony  Result")
            // socket.off("casino_ltp_market_update");
            // socket.off("casino_t20_market_update");
            // socket.off("casino_dt20_market_update");
            // socket.off("casino_32c_market_update");
            // socket.off("casino_7upDown_market_update");

            // socket.on("casino_aaa_market_update", (data) => {
            //     t20Socket(data);
            // });
        }

        // socket.on("balance_is_updated", (data) => {
        //     setIsBetPlaced(!isBetPlaced);
        // });

    }, [params.casino_type]);

    function getTime() {
        let timer = 0;
        axios.get(`http://178.79.189.86:8001/getCasinoEvents/${Appconfig.casino_event_id[params.casino_type]}`).then(resp => {

            if (resp) {
                if (resp.data.data.hasOwnProperty("t1")) {
                    if (resp.data.data.t1) {
                        timer = resp.data.data.t1[0].autotime;
                        // handlecount(timer);
                    }
                } else {
                    if (resp.data.data.hasOwnProperty("bf")) {
                        if (resp.data.data.bf) {
                            timer = resp.data.data.bf[0].lasttime;
                            // handlecount(timer);
                        }

                    }

                }

            }
        });

    }

    let old_event_data = [];
    function getEventData() {
        const casino_type = params.casino_type;
        let user_id = "";
        let url = "getCasinoOddsWithoutUserId";
        if (isLoggedIn) {

            url = "getCasinoOdds";
            if (!params.user_id) {
                user_id = userInfo._id;
            }
            else {
                user_id = params.user_id;
            }
        }

        var data = JSON.stringify({
            user_id: user_id,
            casino_type: casino_type,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}casino/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    old_event_data = response.data.resultData;
                    setEventData(response.data.resultData);
                    let tempData = [];
                    let newrwo = response.data.resultData;
                    loader_remove();
                    getCasinoExposure();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }


    function getCasinoExposure() {
        var data = JSON.stringify({
            user_id: userInfo._id,
            casino_type: params.casino_type,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}casino/casinoExposure`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.resultData.length > 0) {

                        response.data.resultData.map((row, index) => {


                            if (document.getElementById(`${row.selection_id}_maxprofit_list_loss_runner_prev_${row.market_id}`)) {
                                document.getElementById(`${row.selection_id}_maxprofit_list_loss_runner_prev_${row.market_id}`).style.display = "block";
                                document.getElementById(`${row.selection_id}_maxprofit_list_loss_runner_prev_${row.market_id}`).setAttribute("data-value", row.exp);
                                document.getElementById(`${row.selection_id}_maxprofit_list_loss_runner_prev_${row.market_id}`).textContent = Math.abs(row.exp.toFixed(2));
                                // document.getElementById(`position_${row.market_id}_${row.selection_id}`).value = 20;

                            }


                        })
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });

    }
    function handlecount(timer) {
        if (document.querySelector(".clock")) {
            document.querySelector(".clock").innerHTML = "";
            clock = new FlipClock(document.querySelector(".clock"), timer, {
                clockFace: "Counter", countdown: true
            });
        }
    }

    let casino_market_id = "";

    function t20Socket(data) {

        if (document.querySelector("#prev-market-id")) {
            casino_market_id = document.querySelector("#prev-market-id").value;
            // console.log("casino_market_id",casino_market_id);
        }


        if (data.marketodds.length > 0) {

            // var market = data.marketodds[0];
            var market = data.marketodds.find(o => o.event_id == Appconfig.casino_event_id[params.casino_type]);

            if (market) {

                // console.log("Appconfig.casino_event_id[params.casino_type]",Appconfig.casino_event_id[params.casino_type]);
                if (market.hasOwnProperty("marketTypes")) {
                    // console.log("market.marketTypes.length ", market.marketTypes)
                    if (market.marketTypes.length > 0) {
                        market.marketTypes.map((market_type, index) => {

                            if (market_type.market_name == 'Match Odds') {
                                handlecount(market_type.timer)
                                // setCount(market_type.timer);
                                // console.log("in");
                                if (params.casino_type == "dt20") {
                                    dragonTigerCards(market_type.additional_info)
                                } else if (params.casino_type == "7ud") {
                                    sevenUpDownCards(market_type.additional_info)
                                }
                                else if (params.casino_type == "32c") {
                                    ttCards(market_type.additional_info)
                                }
                                else if (params.casino_type == "aaa") {
                                    amarAkbarAnthonyCards(market_type.additional_info)
                                }
                                else {
                                    showT20Cards(market_type.additional_info);
                                }
                                if (market_type.hasOwnProperty("marketRunners")) {
                                    if (market_type.marketRunners.length > 0) {
                                        market_type.marketRunners.map((runner, index) => {

                                            // console.log("TmpEventData", old_event_data);

                                            var casino_market_id_1;

                                            console.log("market");
                                            casino_market_id_1 = runner.market_id;


                                            if (casino_market_id_1 != casino_market_id) {
                                                setEventData([market]);
                                                getCasinoExposure();
                                                getCasinoLastResult();
                                                casino_market_id = casino_market_id_1;
                                            }



                                            var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id + '_' + runner.selection_id)[0];

                                            if (runner.status == 'SUSPENDED' || runner.status == 'CLOSED') {

                                                if (match_odd_row) {
                                                    match_odd_row.setAttribute('data-title', runner.status);
                                                    match_odd_row.classList.add("suspended");
                                                }

                                            } else {

                                                if (match_odd_row) {
                                                    match_odd_row.setAttribute('data-title', "");
                                                    match_odd_row.classList.remove("suspended");

                                                    if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {

                                                        document.getElementById('availableToBack1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price).toFixed(2);

                                                    } else {
                                                        document.getElementById('availableToBack1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price).toFixed(2);
                                                    }


                                                    if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                                                        document.getElementById('availableToLay1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price).toFixed(2);

                                                    } else {
                                                        document.getElementById('availableToLay1_price_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price).toFixed(2);
                                                    }
                                                }


                                            }


                                            if (document.getElementById('availableToBack1_size_' + runner.market_id + '_' + runner.selection_id)) {
                                                if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                                                    document.getElementById('availableToBack1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size).toFixed(2);


                                                } else {

                                                    document.getElementById('availableToBack1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size).toFixed(2);

                                                }


                                                if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                                                    document.getElementById('availableToLay1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size).toFixed(2);

                                                } else {

                                                    document.getElementById('availableToLay1_size_' + runner.market_id + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size).toFixed(2);

                                                }
                                            }



                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            }

        }

    }
    function showT20Cards(cardsInfo) {
        cardsInfo = cardsInfo[0];
        // console.log(cardsInfo);
        let html = `		<div data-v-91269fe6="" class="videoCards">
                        <div data-v-91269fe6="">
                            <h3 data-v-91269fe6="" class="text-white">PLAYER A</h3>
                            <div data-v-91269fe6="" id="player-a-cards">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C1}.png">
                                <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C2}.png">
                                <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C3}.png">
                            </div>
                        </div>
                        <div data-v-91269fe6="">
                            <h3 data-v-91269fe6="" class="text-white">PLAYER B</h3>
                            <div data-v-91269fe6="" id="player-b-cards">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C4}.png">
                                <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C5}.png">
                                <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C6}.png">
                            </div>
                        </div>
                    </div>`;
        if (document.querySelector(".video-overlay")) {
            document.querySelector(".video-overlay").innerHTML = html;
        }
    }

    function amarAkbarAnthonyCards(cardsInfo) {
        cardsInfo = cardsInfo[0];
        let html = `		<div data-v-91269fe6="" class="videoCards">
                        <div data-v-91269fe6="">
                            <h3 data-v-91269fe6="" class="text-white">CARD</h3>
                            <div data-v-91269fe6="" id="player-a-cards">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C1}.png">
                    
                            </div>
                        </div>
             
                    </div>`;

        if (document.querySelector(".video-overlay")) {
            document.querySelector(".video-overlay").innerHTML = html;
        }
    }

    function dragonTigerCards(cardsInfo) {
        cardsInfo = cardsInfo[0];
        let html = `		<div data-v-91269fe6="" class="videoCards">
                        <div data-v-91269fe6="">
                          
                            <div data-v-91269fe6="" id="player-a-cards">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C1}.png">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C2}.png">
                    
                            </div>
                        </div>
             
                    </div>`;

        if (document.querySelector(".video-overlay")) {
            document.querySelector(".video-overlay").innerHTML = html;
        }
    }
    function ttCards(cardsInfo) {
        if (document.querySelector("iframe")) {
            document.querySelector("iframe").setAttribute("style", "width: 100%;height: 332px;");
        }

        cardsInfo = cardsInfo[0];
        const myArrayname = cardsInfo.desc.split(",");
        const allEqual = myArrayname.every(v => v == 1);
        let html = `<div data-v-91269fe6="" class="videoCards">
                    
        <div data-v-3bb6c088="">
   <p data-v-3bb6c088="" class="m-b-0 text-white">
      <b data-v-3bb6c088="">
      <span data-v-3bb6c088="" class="">Player 8</span>
      : <span data-v-3bb6c088="" class="text-warning">${cardsInfo.C1}</span>
      </b>
   </p>
   <div data-v-3bb6c088="">
      <img data-v-3bb6c088="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${myArrayname[0]}.png">
   </div>
</div>


<div data-v-3bb6c088="">
   <p data-v-3bb6c088="" class="m-b-0 text-white">
      <b data-v-3bb6c088="">
      <span data-v-3bb6c088="" class="">Player 9</span>
      : <span data-v-3bb6c088="" class="text-warning">${cardsInfo.C2}</span>
      </b>
   </p>
   <div data-v-3bb6c088="">
      <img data-v-3bb6c088="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${myArrayname[1]}.png">
   </div>
</div>


<div data-v-3bb6c088="">
   <p data-v-3bb6c088="" class="m-b-0 text-white">
      <b data-v-3bb6c088="">
      <span data-v-3bb6c088="" class="">Player 10</span>
      : <span data-v-3bb6c088="" class="text-warning">${cardsInfo.C3}</span>
      </b>
   </p>
   <div data-v-3bb6c088="">
      <img data-v-3bb6c088="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${myArrayname[2]}.png">
   </div>
</div>


<div data-v-3bb6c088="">
   <p data-v-3bb6c088="" class="m-b-0 text-white">
      <b data-v-3bb6c088="">
      <span data-v-3bb6c088="" class="">Player 11</span>
      : <span data-v-3bb6c088="" class="text-warning">${cardsInfo.C4}</span>
      </b>
   </p>
   <div data-v-3bb6c088="">
      <img data-v-3bb6c088="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${myArrayname[3]}.png">
   </div>
</div>
             
                    </div>`;
        if (document.querySelector(".video-overlay")) {
            if (allEqual) {
                document.querySelector(".video-overlay").innerHTML = "";

            } else {
                document.querySelector(".video-overlay").innerHTML = html;
            }
        }
    }

    function sevenUpDownCards(cardsInfo) {
        cardsInfo = cardsInfo[0];
        let html = `		<div data-v-91269fe6="" class="videoCards">
                        <div data-v-91269fe6="">
                            <h3 data-v-91269fe6="" class="text-white">CARD</h3>
                            <div data-v-91269fe6="" id="player-a-cards">
                               <img data-v-91269fe6="" src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardsInfo.C1}.png">
                    
                            </div>
                        </div>
             
                    </div>`;
        if (document.querySelector(".video-overlay")) {
            document.querySelector(".video-overlay").innerHTML = html;
        }

    }

    function getCasinoLastResult() {

        var data = JSON.stringify({
            "event_type": Appconfig.casino_event_id[params.casino_type]
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}casino/getCasinoLastResult`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.resultData.name != "RequestError") {
                        createHtml(response.data.resultData);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const [tempitems, setTempItem] = React.useState([]);
    function createHtml(output) {

        let rList = "";
        let tempArr = [];
        let newit;


        output.map((val, index) => {

            if (params.casino_type == "7ud") {
                newit = <>
                    {
                        val.player == "LOW Card" ?
                            <span onClick={() => showCardOfResult(`24.${val.market_id}`, '', `${val.selection_id}`)} className="ball-runs playera last-result">L</span> :
                            <span onClick={() => showCardOfResult(`24.${val.market_id}`, '', `${val.selection_id}`)} className="ball-runs playerb last-result">H</span>
                    }
                </>
            } else if (params.casino_type == "dt20") {
                newit = <>
                    {val.player == "Tiger" ?
                        <span onClick={() => showCardOfResult(`25.${val.market_id}`, 'Tiger', `${val.selection_id}`)} className="ball-runs playera last-result">T</span> :
                        <span onClick={() => showCardOfResult(`25.${val.market_id}`, 'Dragon', `${val.selection_id}`)} className="ball-runs playerb last-result">D</span>
                    }
                </>

            } else if (params.casino_type == "32c") {
                newit = <>
                    {val.player == "Player 8" ?
                        <span onClick={() => showCardOfResult(`29.${val.market_id}`, 'Player 8', `${val.selection_id}`)} className="ball-runs playera last-result">8</span> :
                        ""
                    }
                    {val.player == "Player 9" ?
                        <span onClick={() => showCardOfResult(`29.${val.market_id}`, 'Player 9', `${val.selection_id}`)} className="ball-runs playera last-result">9</span> :
                        ""
                    }
                    {val.player == "Player 10" ?
                        <span onClick={() => showCardOfResult(`29.${val.market_id}`, 'Player 10', `${val.selection_id}`)} className="ball-runs playera last-result">10</span> :
                        ""
                    }
                    {val.player == "Player 11" ?
                        <span onClick={() => showCardOfResult(`29.${val.market_id}`, 'Player 11', `${val.selection_id}`)} className="ball-runs playera last-result">11</span> :
                        ""
                    }
                </>
            } else if (params.casino_type == "t20") {
                newit = <>
                    {val.player == "Player A" ?
                        <span onClick={() => showCardOfResult(`11.${val.market_id}`, 'Player A', `${val.selection_id}`)} className="ball-runs playera last-result">A</span> :
                        <span onClick={() => showCardOfResult(`11.${val.market_id}`, 'Player A', `${val.selection_id}`)} className="ball-runs playerb last-result">B</span>
                    }
                </>
            } else if (params.casino_type == "ltp") {
                newit = <>
                    {val.player == "Player A" ?
                        <span onClick={() => showCardOfResult(`9.${val.market_id}`, 'Player A', `${val.selection_id}`)} className="ball-runs playera last-result">A</span> :
                        <span onClick={() => showCardOfResult(`9.${val.market_id}`, 'Player A', `${val.selection_id}`)} className="ball-runs playerb last-result">B</span>
                    }
                </>
            }

            else if (params.casino_type == "aaa") {
                newit = <>
                    {val.player == "Amar" ?
                        <span onClick={() => showCardOfResult(`27.${val.market_id}`, 'Amar', `${val.selection_id}`)} className="ball-runs playera last-result">A</span> :
                        ""
                    }
                    {val.player == "Akbar" ?
                        <span onClick={() => showCardOfResult(`27.${val.market_id}`, 'Akbar', `${val.selection_id}`)} className="ball-runs playerb last-result">B</span> :
                        ""
                    }
                    {val.player == "Anthony" ?
                        <span onClick={() => showCardOfResult(`27.${val.market_id}`, 'Anthony', `${val.selection_id}`)} className="ball-runs playerc last-result">C</span> :
                        ""
                    }

                </>
            }
            // console.log("newit", newit);
            tempArr.push(newit);
        })

        setTempItem(tempArr);
        // document.querySelector("#last-result").innerHTML = rList;
    }
    function getChips() {


        var data = JSON.stringify({
            "user_id": userInfo._id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    loader_remove();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    const getOddsOnOff = () => {

        var data = JSON.stringify({
            site_code: userInfo.site_code,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}oddsOnOff/getOddsOnOff`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result && response.data.resultData.length > 0) {
                        oddsData["is_odds_off"] = response.data.resultData[0].is_odds_off;
                        oddsData["is_fancy_off"] = response.data.resultData[0].is_fancy_off

                    }
                    else {

                        oddsData["is_odds_off"] = "No"
                        oddsData["is_fancy_off"] = "No"

                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });

    };

    const handleBetInputChange = (e) => {
        setStakeValue(e.target.value);
        placeStakeValue(parseInt(e.target.value));
        calc(e.target.value, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(e.target.value, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    }



    function betPlace() {
        if (userInfo) {
            if (userInfo.user_type == "User") {

                setPlacing(true);
                loader_default();
                var data = JSON.stringify({
                    user_id: userInfo._id,
                    match_id: BetPlaceData.event_id,
                    selection_id: BetPlaceData.selection_id,
                    is_back: BetPlaceData.is_back,
                    stake: StakeValue,
                    price_val: BetPlaceData.price,
                    market_id: BetPlaceData.market_id,
                    is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
                    market_name: "Match odds",
                    profit: ProfitValue,
                    loss: lossValue,
                });
                // console.log("betting for fancy", data);

                var config = {
                    method: "post",
                    url: `${Appconfig.apiUrl}betting/addBetting`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                // alert("success");
                axios(config)
                    .then(function (response) {
                        try {
                            setBetPlaceData({
                                event_id: "",
                                market_id: "",
                                is_back: "",
                                price: "",
                                is_fancy: "",
                                selection_id: "",
                                runner_name: "",
                            });

                            setProfitValue(0);
                            setStakeValue(0);
                            hideAllBetBox();
                            getEventData();
                            getCasinoExposure();
                            if (response.data.result == 0) {
                                notify(response.data.resultMessage, "error");
                            } else {



                                if (response.data.resultData[0].is_back == 1) {

                                    document.getElementById("sideType").innerHTML = "Yes";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("blue-type");
                                }
                                if (response.data.resultData[0].is_back == 0) {

                                    document.getElementById("sideType").innerHTML = "No";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("pink-type");

                                }
                                loader_remove();
                                setPlacing(false);
                                document.getElementById("msgBox").style.display = "flex";
                                setTimeout(() => {
                                    document.getElementById("msgBox").style.display = "none";
                                }, 3000);

                            }
                            setPlacing(false);
                            // alert(response.data.result);
                            setIsBetPlaced(!isBetPlaced);

                        } catch (e) {
                            postErrorToslack(e.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        postErrorToslack(error.message);
                    });
            }
        }
        else {
            history.push("/login")
        }
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    const hideAllBetBox = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
        const boxes = document.getElementsByClassName("pbb");
        for (var box of boxes) {
            box.style.setProperty("display", "none", "important")
        }
    }


    const handleOpen = (
        event_id,
        market_id,
        is_back,
        price,
        is_fancy,
        selection_id,
        runner_name,
        htmlId
    ) => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));




        hideAllBetBox();


        // console.log('click',`availableToBack1_price_${market_id.replace('.', '')}_${selection_id}`);


        // console.log(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)
        // document.getElementsByClassName("pbb").style.setProperty("display", "none", "important");
        if (!is_fancy) {
            document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        } else {
            document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        }
        setProfitValue(0);
        setStakeValue(0)
        // console.log('click',`availableToBack1_price_${market_id.replace('.', '')}_${selection_id}`);
        var priceNew = document.getElementById(htmlId).innerHTML;

        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "_BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }

        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        // console.log(runners);

        if (!is_fancy) {
            if (EventData.length > 0) {
                EventData.map(async (event, index) =>
                    event.marketTypes.map((market, index) => {

                        market.marketRunners.map((runner, i) => {
                            // console.log(runner.selection_id);
                            if (market.market_id == market_id) {
                                // console.log(selection_id);
                                tempRunners += `<div class="parent-runner-on-bet"><div class="runner-on-bet">${runner.runner_name}</div><div class="runner-on-bet" id="${runner.selection_id + "_maxprofit_loss_runner_prev_" + market_id.replace('.', '')}">${0}</div><div class="runner-on-bet" id="${runner.selection_id + "_maxprofit_loss_runner_" + market_id.replace('.', '')}">${0}</div></div>`;

                            }
                        })

                    })
                )

            }
        }

        // alert(is_back);

        setBetPlaceData({
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
        });



        setTimeout(function () {
            SetPosition(0, priceNew, market_id, is_back, selection_id)
            calc(0, priceNew, selection_id)
        }, 800);

        // console.log("Bet Place Data", BetPlaceData);


    };


    const handleClose = () => {

        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
        setBetPlaceData({
            event_id: "",
            market_id: "",
            is_back: "",
            price: "",
            is_fancy: "",
            selection_id: "",
            runner_name: "",
        });
    };

    const placeStakeValue = (stake) => {
        setStakeValue(stake);
        // let profit;
        // if (BetPlaceData.is_fancy) {
        //   profit = stake;
        // }
        // else {
        //   profit = parseFloat(BetPlaceData.price) * parseFloat(stake) - stake;
        // }

        // setProfitValue(profit);
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };





    async function getTvUrl(casino_type) {
        setTvUrl(Appconfig.videoUrl[casino_type])
    }






    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;


            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);


                    } else {
                        curr = winloss + ((priceVal * stake) - stake);


                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));


                    } else {

                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));


                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = Math.round(curr);


            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)

                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
            }

            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).setAttribute('data-value', currV)
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML = Math.abs(currV);

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)

                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
            }
        }
    }



    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back

        if (!isfancy) {
            // if (gameType == 'market') {
            //    if (MatchMarketTypes == 'M') {
            // var pl = Math.round((priceVal * t_stake) / 100);

            //    } else {
            var pl = ((priceVal * t_stake) - t_stake);
            //    }
            // } else {
            // var pl = Math.round((priceVal * t_stake) / 100);
            // }
            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        } else {
            if (document.getElementById('fancy_lay_size_' + selection_id)) {
                var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
                var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
                var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
                var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);

            }
            pl = parseFloat(t_stake);
            if (inputno == inputyes) {

                if (isback) {
                    setLossValue(pl.toFixed(2))
                    setProfitValue((YesValume * pl / 100).toFixed(2))
                } else {
                    // console.log("inputno,inputyes,YesValume,NoValume", [inputno, inputyes, YesValume, NoValume])
                    setLossValue((NoValume * pl / 100).toFixed(2))
                    setProfitValue(pl.toFixed(2))
                }
            } else {
                setLossValue(pl.toFixed(2))
                setProfitValue(pl.toFixed(2))

            }
        }

    }
    const getUserGenSettings = (oddsinfo) => {
        setUserOddsData({
            ...userOddsData,
            is_match_odds_off: oddsinfo.is_odds_active == "Yes" ? false : true,
        })
    }
    function showCardOfResult(mid, pname, selection_id) {
        // console.log([mid, pname, selection_id])
        var data = JSON.stringify({
            market_id: mid
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}casino/showCardOfResult`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    cardResultHtml(response.data.resultData.data, mid, pname, selection_id);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    function cardResultHtml(data, mid, pname, selection_id) {
        let win = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge  css-c1sh5i" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="color: green;" data-testid="EmojiEventsIcon" aria-label="fontSize large"><path d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z"></path></svg>`;
        let html;
        let round_id = mid.split(".")[1];
        let cardArr = data[0].cards.split(",");
        let sid = data[0].sid.split(",");
        if (params.casino_type == 'ltp' || params.casino_type == 't20') {


            let onewinicon = "";
            let twowinicon = "";
            if (data[0].win == selection_id) {


                if (pname == "Player A") {
                    onewinicon = `<div class="winner-icon mt-3">${win}</div>`;
                }
                if (pname == "Player B") {
                    twowinicon = `<div class="winner-icon mt-3">${win}</div>`;
                }
            }
            html = `<div>
<h6 class="text-right round-id"><b>Round Id:</b> ${round_id}
</h6>
<div class="nrow">
  <div class="col-md-6 br1 text-center">
     <h4>Player A</h4>
     <div class="result-image">
  
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[0]}.png" class="mr-2"> 
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[2]}.png" class="mr-2">
      <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[4]}.png" class="mr-2">
      
      </div>
      ${onewinicon}
     
  </div>
  <div class="col-md-6 text-center">
     <h4>Player B</h4>
     <div class="result-image">

     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[1]}.png" class="mr-2"> 
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[3]}.png" class="mr-2">
    <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[5]}.png" class="mr-2">
      
      </div>
      ${twowinicon}
  </div>
</div>
</div>`;
        }

        // low high
        else if (params.casino_type == '7ud') {
            // $("#result-modal-header").text("7 Up Down Result");
            html = `<div>
<h6 class="text-right round-id">
<b>Round Id:</b> ${round_id}
            </h6> 
            <div class="row">
            <div class="col-12 text-center">
            <div class="result-image">
            <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${data[0].cards}.png" class="mr-2">
            </div>
            </div>
            </div> 
            <div class="row mt-3">
            <div class="col-12 text-center">
            <div class="winner-board">
            <div class="mb-1">
            <span class="text-success">Result:</span> <span>${data[0].desc}</span></div></div></div></div></div>`;
        }

        // dragon tiger
        else if (params.casino_type == 'dt20') {
            // $("#result-modal-header").text("Dragon Tiger T20 Result");

            let newdes = data[0].desc.split('*');
            html = `<div><h6 class="text-right round-id"><b>Round Id:</b> 210911172955
            </h6> <div class="row"><div class="col-12 text-center">
            <div class="result-image">
            <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[0]}.png" class="mr-2"> 
            <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[1]}.png" class="mr-2">
            </div>
            </div>
            </div> 
            <div class="row mt-3">
            <div class="col-12 text-center">
            <div class="winner-board">
            <div class="mb-1">
            <span class="text-success">Result: </span>
                          ${newdes[0]}
                        </div> 
                        <div class="mb-1">
                        <span class="text-success">Dragon: </span>
                        ${newdes[1]}
                        </div> <div class="mb-1"><span class="text-success">Tiger: </span>
                        ${newdes[2]}
                        </div></div></div></div></div>`;

        }
        // 32c
        else if (params.casino_type == '32c') {
            // $("#result-modal-header").text("32 Cards Result");
            document.querySelector("#transition-modal-title").innerText = "32 Cards Result";
            let p8 = "";
            let p9 = "";
            let p10 = "";
            let p11 = "";
            if (data[0].win == selection_id) {


                if (pname == "Player 8") {
                    p8 = `<div style="text-align: center;margin-top: -11%;margin-left: 68%;position: absolute;" class="winner-icon mt-3"><i class="fa fa-trophy mr-2"></i></div>`;
                }
                if (pname == "Player 9") {
                    p9 = `<div style="text-align: center;margin-top: -11%;margin-left: 68%;position: absolute;" class="winner-icon mt-3"><i class="fa fa-trophy mr-2"></i></div>`;
                }
                if (pname == "Player 10") {
                    p10 = `<div style="text-align: center;margin-top: -11%;margin-left: 68%;position: absolute;" class="winner-icon mt-3"><i class="fa fa-trophy mr-2"></i></div>`;
                }
                if (pname == "Player 11") {
                    p11 = `<div style="text-align: center;margin-top: -11%;margin-left: 68%;position: absolute;" class="winner-icon mt-3"><i class="fa fa-trophy mr-2"></i></div>`;
                }
            }
            html = `<div>
<h6 class="text-right round-id"><b>Round Id:</b> 210911173904
</h6>
<div class="row">
  <div class="col-12 text-center">
     <h4>Player 8</h4>
     <div class="result-image">
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[0]}.png" class="mr-2">
     </div>
     ${p8}
     <!---->
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 text-center">
     <h4>Player 9</h4>
     <div class="result-image">
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[1]}.png" class="mr-2">
     </div>
     ${p9}
     <!---->
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 text-center">
     <h4>Player 10</h4>
     <div class="result-image">
     <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[2]}.png" class="mr-2">
     </div>
     ${p10}
  </div>
</div>
<div class="row mt-3">
  <div class="col-12 text-center">
     <h4>Player 11</h4>
     <div class="result-image"><img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${cardArr[3]}.png" class="mr-2"></div>
     ${p11}
     <!---->
  </div>
</div>
</div>`;
        }
        // aaa
        else if (params.casino_type == 'aaa') {
            document.querySelector("#transition-modal-title").innerText = "Amar Akbar Anthony Result";
            html = `<div>
<h6 class="text-right round-id">
<b>Round Id:</b> ${round_id}
            </h6> 
            <div class="row">
            <div class="col-12 text-center">
            <div class="result-image">
            <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/${data[0].cards}.png" class="mr-2">
            </div>
            </div>
            </div> 
            <div class="row mt-3">
            <div class="col-12 text-center">
            <div class="winner-board">
            <div class="mb-1">
            <span class="text-success">Result:</span> <span>${data[0].desc}</span></div></div></div></div></div>`;
        }
        // console.log(cardArr);
        setResultModelOpen(true);
        if (document.querySelector(".card-result-body")) {
            document.querySelector(".card-result-body").innerHTML = html;
        }
    }

    return (
        <>
            <Header isBetPlaced={isBetPlaced} />
            <div className={classes.root}>
                <Grid container spacing={0} style={{ backgroundColor: '#F0ECE1' }}>
                    {isLoggedIn ? null :
                        <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                    }
                    {isMobile ?
                        <div id="msgBox" className="message-wrap-full success to-open_bets" style={{ display: 'none' }}>
                            <div className="message">
                                <h4 id="header">Bet Matched</h4>
                                <p id="info">
                                    <span id="sideType" className="back_notify">Yes</span>
                                    <strong id="selectionName">Match 1st Over</strong> <strong id="stake_notify"> 100.00</strong> at odds <strong id="price_notify_value">7/100</strong>
                                </p>
                            </div>
                            <Link id="close" className="close ui-link" to="#">Close</Link>
                        </div> : null
                    }
                    <Grid item lg={2} className="xs-none">
                        <LeftSideBar />
                    </Grid>


                    {/* match odds start */}
                    <Grid item lg={7} xs={12} className={`cricket-section ${classes.tableHeight}`}>
                        <Grid container id="ctitle" spacing={2} style={{ padding: '8px' }}>
                            <Grid item lg={12} xs={12} className="md-none xp-0">
                                <div className="match-block-header">
                                    <h5>{title}</h5>
                                    <span><img src={Time} /> In-Play</span>
                                </div>
                            </Grid>
                            <Grid item lg={12} xs={12} style={{ background: '#e0e6e6', position: "sticky" }} className="cricket-banner xp-0">
                                <iframe src={tvUrl} style={{ width: '100%', height: "205px" }} className="showtv" title="matchtv"></iframe>
                                <div className="clock">

                                </div>
                                <div className="video-overlay">

                                </div>


                            </Grid>
                            <Grid item lg={12} className="p-0" xs={12}>




                                {/* Match Odds Custom Section Start */}

                                <div id="naviMarket" className="market-type ps ps--theme_default" data-ps-id="1dabf2f1-7040-4af7-cf37-256f4f3ca887">
                                    <ul id="naviMarketList">
                                        <li id="naviMarketTemaplate" ><Link to="#"></Link></li>
                                        <li id="naviMarket_1_188841345" className="select"><Link to="#">Match Odds</Link></li>
                                    </ul>
                                    <div className="ps__scrollbar-x-rail" >
                                        <div className="ps__scrollbar-x" tabindex="0"></div>
                                    </div>
                                    <div className="ps__scrollbar-y-rail" >
                                        <div className="ps__scrollbar-y" tabindex="0"></div>
                                    </div>
                                </div>
                                <div id="marketBetsWrap" class="bets-wrap">
                                    <div id="minMaxBox" class="limit_info-popup" style={{ display: 'none' }}>
                                        <a class="close" onclick="javascript:$j('#minMaxBox').fadeOut();">Close</a>
                                        <dl>
                                            <dt id="minMaxDt" style={{ display: 'none' }}>Min / Max</dt>
                                            <dt id="maxDt">Max</dt>
                                            <dd id="minMaxInfo">1600</dd>
                                        </dl>
                                    </div>
                                    <Link id="minMaxButton" class="bet-limit"></Link>

                                    <dl id="betsHead" class="bets-selections-head">
                                        <dt>

                                            <a class="a-depth" href="#" id="marketDepthBtn">Markets Depth</a>
                                            <p>
                                                <span>Matched</span>
                                                <strong id="totalMatched">PBU  8,718,629</strong>
                                            </p>

                                        </dt>
                                        <dd class="mode-land"></dd>
                                        <dd class="mode-land"></dd>
                                        <dd>Back
                                        </dd>
                                        <dd>Lay
                                        </dd>
                                        <dd class="mode-land"></dd>
                                        <dd class="mode-land"></dd>
                                    </dl>
                                    {EventData != typeof undefined && EventData.length > 0 && EventData.map((event, index) =>
                                        event.hasOwnProperty("marketTypes") && event.marketTypes.map((market, index) =>
                                            market.hasOwnProperty("marketRunners") && market.marketRunners.length > 0 && market.market_name == "Match Odds" ?
                                                market.marketRunners.map((runner, i) => (
                                                    <>
                                                        {/* <input type="hidden" id={`position_${market.market_id.replace('.', '')}_${runner.selection_id}`} className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value="0" /> */}
                                                        <input type="hidden" id="prev-market-id" value={market.market_id} />
                                                        <dl key={i * 14} style={{ display: 'flex' }} className={`bets-selections odd_even_clr table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} data-title={runner.status === "suspended" ? "SUSPENDED" : ""} button>
                                                            <dt>

                                                                <d iv id="horseInfo" class="horse-info" style={{ display: 'none' }}>
                                                                    <ul id="horseBox" class="horse-box">
                                                                        <li id="clothNumberAlpha"></li>
                                                                        <li id="stallDraw"></li>
                                                                    </ul>
                                                                    <div id="uniform" class="uniform"><img src="" /></div>
                                                                    <ul id="horseName" class="horse-name">
                                                                        <li id="runnerName">{runner.runner_name}</li>
                                                                        <li id="jockyName"></li>
                                                                    </ul>
                                                                </d>

                                                                <h4 id="runnerName">{runner.runner_name}</h4>
                                                                <ul id="winLoss">
                                                                    <li id="withoutBet" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="lossWithoutBet" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="withBet" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="lossWithBet" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="zeroProfit" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="zeroLiability" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="zeroProfitWithBet" class="win" style={{ display: 'none' }}></li>
                                                                    <li id="zeroLiabilityWithBet" class="win" style={{ display: 'block' }}><p data-value="0" class="market-exposure" id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')}>0</p></li>
                                                                </ul>
                                                            </dt>

                                                            <dd id="back_1">
                                                                <Link onClick={() => handleOpen(
                                                                    event.event_id,
                                                                    market.market_id,
                                                                    true,
                                                                    runner.back_1_price,
                                                                    false,
                                                                    runner.selection_id,
                                                                    runner.runner_name,
                                                                    `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                )
                                                                } className={`back-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} to="#"><span className="backprice-match" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_price)}</span><span> {parseFloat(runner.back_1_size)}</span></Link>
                                                            </dd>
                                                            <dd id="lay_1">
                                                                <Link onClick={() => handleOpen(
                                                                    event.event_id,
                                                                    market.market_id,
                                                                    false,
                                                                    runner.lay_1_price,
                                                                    false,
                                                                    runner.selection_id,
                                                                    runner.runner_name,
                                                                    `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                )
                                                                } className={`lay-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} to="#" fullmarketodds={parseFloat(runner.lay_1_price)}><span className="layprice-match" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_price)}</span><span> {parseFloat(runner.lay_1_size)}</span></Link>
                                                            </dd>
                                                        </dl>

                                                        <div p={1} key={runner.selection_id} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                            <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                <div className="bet-list" >
                                                                    <div>
                                                                        <p>&nbsp;</p>
                                                                        <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                    </div>
                                                                    <div className="input-num">
                                                                        <p>Min Bet</p>
                                                                        <div>
                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                <RemoveIcon />
                                                                            </a>
                                                                            <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                <AddIcon />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="coin-list" >
                                                                    {chipList.slice(0, 6).map((row) => (
                                                                        <Link href="#" onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                    ))}
                                                                </div>
                                                                <div className="keyboard-wrap">
                                                                    <div className="btn-tel">
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                        <Link to="/#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                        <Link to="/#">.</Link>
                                                                    </div>
                                                                    <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                </div>
                                                                <div className="btn-list">
                                                                    <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                    <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => placing ? console.log('Placing....') : betPlace()}>{placing ? "Placing...." : "Place Bet"}</Link>
                                                                </div>
                                                                <div className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                    <label>
                                                                        <input type="checkbox" />
                                                                        <a>Accept Any Odds</a>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)) : ""))}
                                </div>


                                <div id="bookMakerMarket_30998640_130561" class="bets-wrap bets-bookmaker" style={{ display: 'block' }}>
                                    <h4>

                                        <span>Last Result</span>

                                        <a href="#" id="openBookMakerInfo" class="btn-fancy_info"></a>
                                        <div id="bookMakerMinMax" class="fancy_info-popup">
                                            <dl>
                                                <dt>Min / Max</dt>
                                                <dd id="minMax"> 1.00 /  800.00</dd>
                                            </dl>
                                            <dl>
                                                <dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                                <dd id="rebate" style={{ display: 'none' }}></dd>
                                            </dl>
                                            <a href="" id="closeBookMakerInfo" class="close">Close</a>
                                        </div>
                                    </h4>
                                    <p style={{ display: "flex" }} id="last-result" class="text-right">
                                        {tempitems}
                                    </p>

                                </div>



                                {/* Match Odds Custom Section End */}

                            </Grid>

                            {/* Child Grid item End */}
                        </Grid>
                        {/* Child Grid Container End */}

                    </Grid>
                    {/* match odds end */}


                </Grid>


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={resultModelOpen}
                    onClose={resultModelClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ height: "100%", padding: "7px" }}
                >

                    <Fade in={resultModelOpen}>
                        <div className="card-result" id="result-view">
                            <Grid className="card-result-title" container spacing={2}>
                                <Grid item lg={11} xs={10}>
                                    <h2 id="transition-modal-title">{title}</h2>
                                </Grid>
                                <Grid item lg={1} xs={1}>
                                    <IconButton aria-label="delete" onClick={resultModelClose} color="secondary" className={classes.margin}>
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="card-result-body">
                            </div>
                        </div>
                    </Fade>
                </Modal>
                {/* end fancy position */}
            </div >
        </>
    );
};

export default CasinoBets;

const CardsLayout = (props) => {
    // function cardsLayout(props) {
    const { children, value, index, casino_type } = props;
    return (
        <div class="video-overlay">
            <div class="videoCards">
                <div >
                    <h3 class="text-white">PLAYER A</h3>
                    <div id="player-a-cards">
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                    </div>
                </div>
                <div >
                    <h3 class="text-white">PLAYER B</h3>
                    <div id="player-b-cards">
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                        <img src="https://dzm0kbaskt4pv.cloudfront.net/v2/static/front/img/cards/1.png" />
                    </div>
                </div>
            </div>
        </div>
    );
}