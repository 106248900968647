import '../assets/css/style.css';
import React from 'react';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip';

import { Grid,makeStyles,Table,TableRow,TableCell,TableHead,TableBody } from '@material-ui/core';

//IMAGES
import HelpIcon from '@material-ui/icons/Help';
import transparent from '../assets/images/transparent.gif';
import ReplayIcon from '@material-ui/icons/Replay';

function createData(name1, value,name2,play,link,calories,fat,carbs,protein) {
    return { name1, value, name2, play,link,calories,fat,carbs,protein};
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    root:{
        height:'100%',
    },
    tableHeight:{
        height:'calc(100vh - 120px)',
    },
    table:{
        marginTop:"25px",
    },
}));
const FinancialMarket = () => {

    const classes = useStyles();
    const rows = [
        createData('King', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
        createData('Yong', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
        createData('Cyrax', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
        createData('Daring', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
        createData('Daring', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
        createData('Daring', '0 - 1 ', 'maxime cressy', 'In play','link', 237, "9.0 P.M.", "8.00", "8,000.00"),
    ];
    return (
    <>
      <Header/>
      <div className={classes.root}>
      <Grid container  style={{ backgroundColor: '#F0ECE1'}}>
        <Grid item lg={9} xs={6}  className={`ipl-section ${classes.tableHeight}`}>
            <Grid container spacing={2} style={{padding:'10px' }}>
                <Grid item lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow className="tbl_head">
                                <TableCell colSpan="1">
                                    <span style={{fontWeight:"bold",fontSize:"20px",margin:"10px"}}>Binary </span> 
                                    <span className="refresh-icon"><ReplayIcon/></span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className="financial-tbl-head">
                                <TableCell colSpan="1">
                                   <span className="binary-tag">Daily 
                                        <span className="binary-date">2021-08-02</span>
                                   </span>
                                </TableCell>
                                <TableCell align="left" colSpan="2">
                                </TableCell>
                                <TableCell align="right" colSpan="1"></TableCell>
                                <TableCell align="right" colSpan="1"></TableCell>
                                <TableCell align="right" colSpan="2">
                                    <span className="helpIcon"><HelpIcon/></span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="financial_tbl_body">
                            <TableRow className="">
                                <TableCell align="left" colSpan=""></TableCell>
                                <TableCell align="left" colSpan="2"></TableCell>
                                <TableCell align="center" colSpan=""><b>No</b></TableCell>
                                <TableCell align="center" colSpan=""><b>Yes</b></TableCell>
                                <TableCell align="left" colSpan="2"></TableCell>
                            </TableRow>
                            {rows.map((row) => (
                                <TableRowLink to={row.link} p={1} key={row.name1} className="odd_even_clr" button>
                                    <TableCell className="table_first_row" colSpan="1">
                                        <div className="text_left-in-ipl">
                                           {row.name1}
                                        </div>
                                    </TableCell>
                                    <TableCell align="left" colSpan="2">
                                        <div style={{fontSize:"11px"}}>
                                            {row.fat}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className="" style={{backgroundColor:'#FAA9BA'}}>
                                        <div>
                                            {row.calories}<br/>
                                            <span>72</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className="" style={{backgroundColor:'#72BBEF'}}>
                                        <div>
                                            {row.fat}<br/>
                                            <span>72</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left" colSpan="2" padding={0}>
                                        <div style={{display:'block'}}>
                                            <span style={{fontSize:"11px", color:'#9397a7'}}>Min/Max</span>
                                            <div  style={{fontSize:"11px"}}>{row.carbs}/{row.protein}</div>
                                        </div>
                                    </TableCell>
                                </TableRowLink>
                            ))}
                        </TableBody>    
                    </Table>
                </Grid>
            </Grid>
        </Grid>
        <Grid item lg={3} xs={12}  className="betSlipGrid">
           <BetSlip/>
        </Grid>
    </Grid>
    </div>
    </>
    
)}
export default FinancialMarket;

