import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItem, Tabs, Button } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ArrowForwardIos, MoreVert } from '@material-ui/icons';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { BrowserView, MobileView, isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import Appconfig from '../config/config';
import axios from 'axios';
import moment from "moment";
import { postErrorToslack } from '../config/common';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

function createData(name, value) {
    return { name, value };
}
function activity(time, status, ip, isp, city, type) {
    return { time, status, ip, isp, city, type };
}

const rows = [
    createData('First Name', 'Sumit'),
    createData('Last Name', '--'),
    createData('Birthday', '--'),
    createData('Email', 'test@gmail.com'),
    createData('Password', '***************************'),
];
const address = [
    createData('Address', '--'),
    createData('Town/City', '--'),
    createData('Country', '--'),
    createData('Country/State', '--'),
    createData('Postcode', '--'),
    createData('Timezone', 'IST'),
];
const activity_log = [
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),

];
function create_log(
    datetime,
    status,
    ip,
    isp,
    city_state_country,
    user_agent_type,
) {
    return { datetime, status, ip, isp, city_state_country, user_agent_type };
}

export default function VerticalTabs() {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/activity-log') {
        segment = 4;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(0);
    const [valuen, setnValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handlexChange = (event, newValue) => {
        setxValue(newValue);
    };
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
    };
    const [onlineUserList, SetOnlineUserList] = React.useState([]);
    React.useEffect(() => {
        getOnlineUser();
    }, []);

    function getOnlineUser() {

        var data = JSON.stringify({
            "user_id": userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
            "user_type": "User"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/usersOnline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    SetOnlineUserList(response.data.resultData.reverse());
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    const gridType = isMobile ? 10 : 8;

    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            <Grid className="account-stat" container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />
                <Grid item lg={gridType} xs={12} spacing={2}>
                    {/* Activity Log */}
                    <TabPanel value={value} index={4}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Activity Log</Typography>
                        </Box>
                        <Table className={`statement-table ${classes.table}`}>
                            <TableHead className="table_head">
                                <TableRow>
                                    <TableCell>Login Date &amp; Time</TableCell>
                                    <TableCell align="right">Login Status</TableCell>
                                    <TableCell align="right">IP Address</TableCell>
                                    <TableCell align="right">ISP</TableCell>
                                    <TableCell align="right">City/State/Country</TableCell>
                                    <TableCell align="right">User Agent Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                                {onlineUserList.length > 0 && onlineUserList.map((activityLog) => (
                                    <TableRow >
                                        {console.log("activityLog", activityLog)}
                                        <TableCell component="th" scope="row">{activityLog.createdAt}</TableCell>
                                        <TableCell align="right" className="green">{activityLog.status}</TableCell>
                                        <TableCell align="right">{activityLog.ip_address}</TableCell>
                                        <TableCell align="right">{activityLog.hasOwnProperty("all_info") ? activityLog.all_info.length > 0 ? activityLog.all_info[0].isp : "" : ""}</TableCell>
                                        <TableCell align="right">{activityLog.hasOwnProperty("all_info") ? activityLog.all_info.length > 0 ? activityLog.all_info[0].city + " / " + activityLog.all_info[0].regionName + " / " + activityLog.all_info[0].country : "" : ""}</TableCell>
                                        <TableCell align="right">{activityLog.hasOwnProperty("user_agent") ? activityLog.user_agent : ""}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TabPanel>
                </Grid>
            </Grid>
        </>
    );
}
