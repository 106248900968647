import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItem, Tabs, Button } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ArrowForwardIos, MoreVert } from '@material-ui/icons';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { BrowserView, MobileView, isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import Appconfig from "../config/config";
import axios from "axios";
import { postErrorToslack } from '../config/common';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

function createData(name, value) {
    return { name, value };
}
function activity(time, status, ip, isp, city, type) {
    return { time, status, ip, isp, city, type };
}

const rows = [
    createData('First Name', 'Sumit'),
    createData('Last Name', '--'),
    createData('Birthday', '--'),
    createData('Email', 'test@gmail.com'),
    createData('Password', '***************************'),
];
const address = [
    createData('Address', '--'),
    createData('Town/City', '--'),
    createData('Country', '--'),
    createData('Country/State', '--'),
    createData('Postcode', '--'),
    createData('Timezone', 'IST'),
];
const activity_log = [
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
];

export default function VerticalTabs() {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/summary') {
        segment = 1;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(0);
    const [valuen, setnValue] = React.useState(0);

    const handlexChange = (event, newValue) => {
        setxValue(newValue);
    };
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
    };

    const [MainBalance, setMainBalance] = React.useState(0);
    const [exposure, setExposure] = React.useState(0);
    React.useEffect(() => {
        getBalance();
    });

    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result == 0) {
                    } else {
                        console.log(response.data.resultData);
                        setExposure(response.data.resultData.exposure);
                        setMainBalance(response.data.resultData.balance);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
            });
    }
    const gridType = isMobile ? 10 : 8;
    let pthh = MainBalance - Math.abs(exposure);

    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            <Grid container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />
                <Grid item lg={gridType} xs={12} spacing={2}>
                    {/* Summary */}
                    <TabPanel value={value} index={1}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Summary</Typography>
                        </Box>
                        <Box className="balance-overview">
                            <Grid container>
                                <Grid item lg={4} xs={12} className="balance-section">
                                    <Typography className="balance">Your balance</Typography>
                                    <Typography className="balance-amount">
                                        {pthh.toFixed(2)}
                                        <Typography className="balance-currency">INR</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item lg={8} xs={12} className="welcome-section">
                                    <Typography className="welcome-text">Welcome,</Typography>
                                    <Typography className="welcome-content">View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container>
                            <Grid lg={12} xs={12}>
                                <Table className={`balance-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="right">Transaction No</TableCell>
                                            <TableCell align="right">Debits</TableCell>
                                            <TableCell align="right">Credits</TableCell>
                                            <TableCell align="right">Balance</TableCell>
                                            <TableCell align="right">Remarks</TableCell>
                                            <TableCell align="right">From/To</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        </>
    );
}
