import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar } from '@material-ui/core';
import Transparent from '../assets/images/transparent.gif';
import TimeField from 'react-simple-timefield';
import { makeStyles, List, ListItem, ListItemText, FormGroup, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';


import { isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import Appconfig from '../config/config';
import axios from 'axios';
import moment from 'moment';
import { postErrorToslack } from '../config/common';

const noPlReport = <div id="noReportMessage">
    <p>Betting Profit &amp; Loss enables you to review the bets you have placed.
        <br />Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
    <p>Betting Profit &amp; Loss is available online for the past 62 days.</p>
    <p>User can search up to 14 days records per query only .</p>
</div>
const noReport = <div id="noReportMessage" >
    <p>Betting History enables you to review the bets you have placed.
        <br />Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
    <p>Betting Profit &amp; Loss is available online for the past 62 days.</p>
    <p>User can search up to 14 days records per query only .</p>
</div>
function TabPanel(props) {

    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
// const [totalStakes, settotalStakes] = React.useState(0);
// function updateTotalStake(temparray) {
//   let totalll = 0;

//   temparray.length>0 && temparray.map((rowinner) => {
//         totalll += rowinner.stake?.toFixed(2);
//   });

//   return totalll;
// //   settotalStakes(totalll);
// }

function showCurrentBookmaker(click_id, rowarray) {
    var expandHtml = document.getElementById("expandCurrentBookmaker-" + click_id + "-toggle");
    var linkHtml = document.getElementById("expandCurrentBookmaker-" + click_id);
    // updateTotalStake(rowarray);

    if (linkHtml.getAttribute('data-id') == 0) {

        linkHtml.setAttribute('data-id', '1');
        expandHtml.style.display = "table-row";
        linkHtml.classList.add("expand-open");
        linkHtml.classList.remove("expand-close");

    } else {
        linkHtml.setAttribute('data-id', '0');
        expandHtml.style.display = "none";
        linkHtml.classList.add("expand-close");
        linkHtml.classList.remove("expand-open");

    }


}



// const handleSubmit = (event) => {
//     event.preventDefault();
//     setFormData({
//         ...formData,
//         ["type"]: type,
//       });
//     var config = {
//         method: 'post',
//         url: `${Appconfig.apiUrl}reports/livebetHistory`,
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         data: JSON.stringify(formData)
//     };
//     axios(config)
//         .then(function (response) {
//             console.log(response);
//             // storeUserinfo(response.data.resultData);
//             if (response.data.result) {
//                 // notify(response.data.resultMessage, 'success')
//                 storeUserinfo(response.data.resultData);
//                 props.setLoggedIn(true)
//             }
//             else {
//                 notify(response.data.resultMessage, 'error')
//             }
//         })
//         .catch(function (error) {
//             console.log(error);
//         });



// }



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));


export default function VerticalTabs(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [formData, setFormData] = React.useState({
        "event_id": "0",
        "user_id": userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        'event_type': "All",
        'status': "Open",
        "is_unmatch": "All",
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
    })
    let [profitAndLossCa, setProfitAndLossC] = React.useState({
        "user_id": userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
        'is_unmatch': "All",
        'report_type': "CurrentBets",
        'report_market_type': "Exchange",
        'status': "Open"
    })
    const [profitAndLossListt, setProfitAndLossfa] = React.useState([])

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleChangeProfit = (event) => {
        setProfitAndLossC({
            ...profitAndLossCa,
            [event.target.name]: event.target.value,
        });
    };

    const searchURL = props.location.search
    let tabIndex = 0;
    if (searchURL === '?history') {
        tabIndex = 1;
    } else if (searchURL === '?PandL') {
        tabIndex = 2;
    }
    const lastSegment = window.location.pathname;
    const [search, SetSearch] = React.useState(tabIndex);
    let segment = 0
    if (lastSegment === '/current-bets') {
        segment = 3;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(search);
    const [valuen, setnValue] = React.useState(0);

    const handlexChange = (event, newValue) => {
        setProfitAndLossfa([]);
        setxValue(newValue);
        // getProfitLossExp(newValue, valuen);
    };
    const handlenChange = (event, newValue) => {
        setProfitAndLossfa([]);
        setnValue(newValue);
        // getProfitLossExp(valuex, newValue);
    };

    const gridType = isMobile ? 10 : 8;
    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    const [profitLossList, SetProfitLossList] = React.useState([]);
    const [plfetchData, SetplFetchData] = React.useState({
        "user_id": userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        "from_date": moment().add(-365, 'days').format('YYYY-MM-DD'),
        "to_date": moment().format('YYYY-MM-DD'),
        "type": "All"
    });

    const [openFancyList, setFancyOpenList] = React.useState([]);
    React.useEffect(() => {
     
        if (valuex == 1) {
            formData.status = "All";
            profitAndLossCa.status = "All";
        }
        else  if (valuex == 2) {
            formData.status = "Settled";
            profitAndLossCa.status = "Settled";
        } else {
            formData.status = "Open";
            profitAndLossCa.status = "Open";
        }
        if (valuex == 0) {
            getProfitLossExp(valuex, valuen)
            // getProfitLoss();
            // getBetHistory(formData.event_type);
        }

        // getOpenBets();
    }, [valuex, valuen]);

    const handleFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }
    const handleFilterSubmit = () => {
        getBetHistory(formData.event_type);
    }
    const handleFilterSubmitProfit = () => {
        getProfitLossExp(valuex, valuen)
    }
    const handleFilterSubmitPL = () => {
        getProfitLoss();
    }
    function getProfitLoss() {
        var data = JSON.stringify(profitAndLossCa)
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossNew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    SetProfitLossList(response.data.resultData);
                    let tmpAccountList = response.data.resultData;
                    const newChipList = tmpAccountList.map((profitLossList, index) => {
                        tmpAccountList[index]["id"] = index + 1;
                    });
                } catch (e) {
                    postErrorToslack(e.message);
                }
                // SetProfitLossList(tmpAccountList);
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    function getProfitLossExp(reportType, reportMakretType) {
        let report_type = '';

        if (reportType == 0) {
            report_type = 'CurrentBets';
        }
        else if (reportType == 1) {
            report_type = 'BetHistory';
        }
        else if (reportType == 2) {
            report_type = 'ProfitLoss';
        }

        let report_market_type = '';
        if (reportMakretType == 0) {
            report_market_type = 'Exchange';
        }
        else if (reportMakretType == 1) {
            report_market_type = 'Fancy';
        }
        else if (reportMakretType == 2) {
            report_market_type = 'SportsBook';
        }
        else if (reportMakretType == 3) {
            report_market_type = 'BookMaker';
        }
        else if (reportMakretType == 4) {
            report_market_type = 'Binary';
        }
        else if (reportMakretType == 5) {
            report_market_type = 'Casino';
        }

        profitAndLossCa = { ...profitAndLossCa, report_type: report_type, report_market_type: report_market_type };
        var data = JSON.stringify(profitAndLossCa);
        profitAndLossCa.from_date = moment(profitAndLossCa.from_date).format('YYYY-MM-DD');
        profitAndLossCa.to_date = moment(profitAndLossCa.to_date).format('YYYY-MM-DD');
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/profitLossExplicitNew`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result) {
                        setProfitAndLossfa(response.data.resultData);
                        let tmpAccountList = response.data.resultData;
                        const newChipList = tmpAccountList.map((profitLossList, index) => {
                            tmpAccountList[index]["id"] = index + 1;
                        });
                        setProfitAndLossfa(tmpAccountList);
                        if (!response.data.resultData.length) {
                            document.getElementById("message").style.display = "block";
                            document.getElementById("msgtext").textContent = "you have no bets in this time period";
                            setTimeout(function () {
                                closeMessage('message');
                            }, 3000);
                        }
                    } else {
                        setProfitAndLossfa(response.data.resultData);
                        document.getElementById("errormessage").style.display = "block";
                        document.getElementById("errormsgtext").textContent = response.data.resultMessage;
                        setTimeout(function () {
                            closeMessage('errormessage');
                        }, 3000);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    function closeMessage(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).style.display = "none";
        }
    }
    function filterBackBets(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Fancy" && element.status == "Open");
        return backBets
    }
    function filterProfitFancy(bettings) {
        // let backBets = bettings.filter(element => element.market_name == "Fancy");
        return bettings
    }
    function filterProfitMatch(bettings) {
        // let backBets = bettings.filter(element => element.market_name == "Match Odds");
        return bettings
    }
    function filterProfitBookmaker(bettings) {
        // let backBets = bettings.filter(element => element.market_name == "Bookmaker");
        return []
    }
    function filterBookMaker(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Bookmaker" && element.status == "Open");
        return backBets
    }
    function filterUnMatchOdds(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Match Odds" && element.unmatch_bet == "No" && element.status == "Open");
        return backBets
    }
    function filterMatchOdds(bettings) {
        let backBets = bettings.filter(element => element.betting_type == "Match" && element.market_name == "Match Odds" && element.unmatch_bet != "No" && element.status == "Open");
        return backBets
    }
    function filterFancyHistory(bettings) {
        let backBets = bettings.filter(element => element.status == "Settled" && element.market_name == "Fancy");
        return backBets
    }
    function getBetHistory(event_type) {
        if (valuex != 0) {
            delete formData.from_date;
            delete formData.to_date;
        } else {
            formData.from_date = moment(formData.from_date).format('YYYY-MM-DD');
            formData.to_date = moment(formData.to_date).format('YYYY-MM-DD');
        }
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/betHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    SetBetHistoryList(response.data.resultData);
                    let tmpAccountList = response.data.resultData;
                    const newChipList = tmpAccountList.map((betHistoryList, index) => {
                        tmpAccountList[index]["id"] = index + 1;
                    });
                    SetBetHistoryList(tmpAccountList);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    function getTotalStakes(temparray) {
        let totalll = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalll += Number(rowinner.stake);
        });
        return totalll;
        //   settotalStakes(totalll);
    }
    function getYesTotalStakes(temparray) {
        let totalYes = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 1) {

                if (rowinner.bet_result == 'Plus') {
                    totalYes += parseFloat(rowinner.profit?.toFixed(2))

                }
                else if (rowinner.bet_result == 'Minus') {
                    totalYes -= parseFloat(rowinner.loss?.toFixed(2))

                }
            }
        });


        return totalYes.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getNoTotalStakes(temparray) {
        let totalNo = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.is_back == 0) {

                if (rowinner.bet_result == 'Plus') {
                    totalNo += parseFloat(rowinner.profit?.toFixed(2))

                }
                else if (rowinner.bet_result == 'Minus') {
                    totalNo -= parseFloat(rowinner.loss?.toFixed(2))

                }
            }
        });

        return totalNo.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getSubTotalStakes(temparray) {
        let totalSub = 0;

        temparray.length > 0 && temparray.map((rowinner) => {
            totalSub += Number(rowinner.stake)
        });

        return totalSub.toFixed(2);
        //   settotalStakes(totalll);
    }
    function getMarketTotalStakes(temparray) {
        let totalSub = 0;
        temparray.length > 0 && temparray.map((rowinner) => {
            if (rowinner.bet_result == 'Plus') {
                totalSub += parseFloat(rowinner.profit?.toFixed(2))

            }
            else if (rowinner.bet_result == 'Minus') {
                totalSub -= parseFloat(rowinner.loss?.toFixed(2))

            }
        });

        return totalSub.toFixed(2);
        //   settotalStakes(totalll);
    }
    // var olddate = moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[0] + 'T' + moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[1];
    //   var currentDate = moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[0] + 'T' + moment().subtract(7, 'days').format("MM/DD/YYYY").split(' ')[1];

    function justFor(days) {
        if (days == 1) {


            profitAndLossCa.from_date = moment().startOf('day').format("YYYY-MM-DD");
            profitAndLossCa.to_date = moment().endOf('day').format("YYYY-MM-DD");

        }
        if (days == 2) {
            profitAndLossCa.from_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
            profitAndLossCa.to_date = moment().subtract(0, 'days').format("YYYY-MM-DD");
        }
        getProfitLossExp(valuex, valuen);
    }
    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            <Grid className="current-bets-cont" container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />
                <Grid item lg={gridType} xs={12} spacing={2}>
                    <div id="message" className="message-wrap-new" style={{ background: "yellow", color: "#000" }}>
                        <Link to="#" onClick={() => closeMessage("message")} className="btn-close">Close</Link>
                        <p id="msgtext"> </p>
                    </div>
                    <div id="errormessage" className="message-wrap-new" style={{ background: "red", color: "#000" }}>
                        <Link to="#" onClick={() => closeMessage("errormessage")} className="btn-close">Close</Link>
                        <p id="errormsgtext"> </p>
                    </div>
                    {/* My Bets */}
                    <TabPanel value={value} index={3}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">My Bets</Typography>
                        </Box>
                        <Grid container spacing={1} className="my-bets">
                            <Grid item lg={12} xs={12} className="mini-games-grid">
                                <AppBar position="static" className="tab">
                                    <Tabs value={valuex} variant="fullWidth" onChange={handlexChange}>
                                        <Tab label="Current Bets" {...a11yProps(0)} />
                                        <Tab label="Bets History" {...a11yProps(1)} />
                                        <Tab label="Profit &amp; Loss" {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel className="current-bet" value={valuex} index={0} >
                                    <AppBar position="static" className="tab">
                                        <Tabs value={valuen} variant="fullWidth" onChange={handlenChange}>
                                            <Tab label="Exchange" {...a11yProps(0)} />
                                            <Tab label="FancyBet" {...a11yProps(1)} />
                                            <Tab label="Sportsbook" {...a11yProps(2)} />
                                            <Tab label="BookMaker" {...a11yProps(3)} />
                                            <Tab label="Binary" {...a11yProps(4)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel className="current-bet exchngtab-con" value={valuen} index={0}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list">
                                                <Typography variant="span" >Bet Status</Typography>
                                                <select id="betStatus" onChange={handleChangeProfit} value={profitAndLossCa.is_unmatch} name="is_unmatch" className="bet-status">
                                                    <option value="">All</option>
                                                    <option value="Yes">Unmatched</option>
                                                    <option value="No">Matched</option>
                                                </select>
                                                <Typography variant="span">Order By</Typography>
                                                <input type="checkbox" name="betplace" />
                                                <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                <input type="checkbox" name="market" />
                                                <Typography variant="span" className="ml-0">Market</Typography>
                                            </Box>
                                        </Box>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Unmatched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection	Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Unmatched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">

                                                <TableRow>
                                                    <TableCell colspan="8">You have no bets in this time period.</TableCell>
                                                </TableRow>


                                            </TableBody>
                                        </Table>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Matched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection	Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Unmatched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                {
                                                    profitAndLossListt.length > 0 ? profitAndLossListt.map((row) => (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">{row.event_name} {row.place_name}</TableCell>
                                                            <TableCell align="right">{row.is_back ? "Back" : "Lay"}</TableCell>
                                                            <TableCell align="right">{row._id}</TableCell>
                                                            <TableCell align="right">{row.place_name}</TableCell>
                                                            <TableCell align="right">{row.price_val}</TableCell>
                                                            <TableCell align="right">{row.unmatch_bet}</TableCell>
                                                            <TableCell align="right">{row.unmatch_bet}</TableCell>
                                                            <TableCell align="right">{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                        </TableRow>
                                                    )) : (<TableRow>
                                                        <TableCell colspan="8">You have no bets in this time period.</TableCell>
                                                    </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                    <TabPanel className="current-bet current-fancyyy" value={valuen} index={1} >
                                        {
                                            value == 1 ?
                                                <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list" style={{ width: "100%" }}>
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" onChange={handleChange} name="status" className="bet-status">
                                                            <option value="All">All</option>
                                                            <option value=" ">Unmatched</option>
                                                            <option value="Open">Matched</option>
                                                            <option value="Settled">Settled</option>
                                                            <option value=" ">Cancelled</option>
                                                            <option value=" ">Voided</option>
                                                        </select>
                                                        <Typography variant="span">Period</Typography>
                                                        {/* <input type="checkbox" name="betplace" /> */}
                                                        <input onChange={handleChange} value={formData.from_date} type="date" name="from_date" />
                                                        {/* <input type="checkbox" name="market" /> */}
                                                        <TimeField onChange={handleChange} />
                                                        <Typography variant="span"> to</Typography>
                                                        <input onChange={handleChange} value={formData.to_date} type="date" name="to_date" />
                                                        {/* <input type="checkbox" name="market" /> */}
                                                        <TimeField onChange={handleChange} />
                                                    </Box>
                                                    <ul className="input-list">
                                                        <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                        <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                        <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                                    </ul>
                                                </Box>
                                                </> : <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list">
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" className="bet-status" onChange={handleChange} value={formData.is_unmatch} name="is_unmatch">
                                                            <option value="">All</option>
                                                            <option value="Yes">Unmatched</option>
                                                            <option value="No">Matched</option>
                                                        </select>
                                                        <Typography variant="span">Order By</Typography>
                                                        <input type="checkbox" name="betplace" />
                                                        <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                        <input type="checkbox" name="market" />
                                                        <Typography variant="span" className="ml-0">Market</Typography>
                                                    </Box>
                                                </Box>
                                                    <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                        <Typography variant="span">Matched</Typography>
                                                    </Box>
                                                </>}

                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="right">Selection</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="center">Matched</TableCell>
                                                    <TableCell align="center">Avg. odds matched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {profitAndLossListt.length > 0 ?
                                                <TableBody className="table_body">
                                                    {valuen == 1 && valuex == 0 && profitAndLossListt.map((rowinner) => (
                                                        <TableRow>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                            <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.market_id}`} className="" to="#" onClick={() => showCurrentBookmaker(rowinner.market_id)}>{rowinner.market_id}</Link></TableCell>
                                                            <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody> : <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell colspan="8">You have no bets in this time period.</TableCell>
                                                    </TableRow>
                                                </TableBody>}
                                        </Table>
                                    </TabPanel>
                                    <TabPanel className="current-bet match-odds-current" value={valuen} index={2} >
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list">
                                                <Typography variant="span">Bet Status</Typography>
                                                <select id="betStatus" className="bet-status" onChange={handleChange} value={formData.is_unmatch} name="is_unmatch">
                                                    <option value="">All</option>
                                                    <option value="Yes">Unmatched</option>
                                                    <option value="No">Matched</option>
                                                </select>
                                                <Typography variant="span">Order By</Typography>
                                                <input type="checkbox" name="betplace" />
                                                <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                <input type="checkbox" name="market" />
                                                <Typography variant="span" className="ml-0">Market</Typography>
                                            </Box>
                                        </Box>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Unmatched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Avg. odds matched	</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell colspan="9">You have no bets in this time period.</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Matched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Avg. odds matched	</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {profitAndLossListt.length > 0 ? <TableBody className="table_body">
                                                {valuen == 2 && valuex == 1 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                    <><TableRow>

                                                        <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                        <TableCell align="left">{rowinner.place_name}</TableCell>
                                                        <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                        <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}`} className="" to="#" onClick={() => showCurrentBookmaker(rowinner._id.substr(rowinner._id.length - 6))}>{rowinner._id.substr(rowinner._id.length - 6)}</Link></TableCell>
                                                        <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                        <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                    </TableRow>
                                                        <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner._id.substr(rowinner._id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                            <TableCell colspan="3"></TableCell>
                                                            <TableCell colspan="7">
                                                                <Table className={`bets-table ${classes.table}`}>
                                                                    <TableHead className="table_head">
                                                                        <TableCell>Bet taken</TableCell>
                                                                        <TableCell>Odds req.</TableCell>
                                                                        <TableCell>Stake</TableCell>
                                                                        <TableCell>Liability</TableCell>
                                                                        <TableCell>Odds matched</TableCell>
                                                                    </TableHead>
                                                                    <TableBody className="table_body">
                                                                        <TableRow>
                                                                            <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                            <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                            <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                            <TableCell className={`${rowinner.loss == 0 ? "zeroliability" : "withliability"}`}>{rowinner.loss == 0 ? "-" : `(${rowinner.loss})`}</TableCell>
                                                                            <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow></>
                                                ))}
                                            </TableBody> : <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell colspan="9">You have no bets in this time period.</TableCell>
                                                </TableRow>
                                            </TableBody>}
                                        </Table>
                                    </TabPanel>
                                    <TabPanel className="current-bet bookmaker-current" value={valuen} index={3} >
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list">
                                                <Typography variant="span">Bet Status</Typography>
                                                <select id="betStatus" className="bet-status" onChange={handleChange} value={formData.is_unmatch} name="is_unmatch">
                                                    <option value="">All</option>
                                                    <option value="Yes">Unmatched</option>
                                                    <option value="No">Matched</option>
                                                </select>
                                                <Typography variant="span">Order By</Typography>
                                                <input type="checkbox" name="betplace" />
                                                <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                <input type="checkbox" name="market" />
                                                <Typography variant="span" className="ml-0">Market</Typography>
                                            </Box>
                                        </Box>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Matched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Avg. odds matched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {profitAndLossListt.length > 0 ? <TableBody className="table_body">

                                                {valuen == 3 && valuex == 0 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                    <><TableRow>
                                                        <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                        <TableCell align="left">{rowinner.place_name}</TableCell>
                                                        <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                        <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.market_id}`} className="" to="#" onClick={() => showCurrentBookmaker(rowinner.market_id)}>{rowinner.market_id}</Link></TableCell>
                                                        <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                        <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                    </TableRow>
                                                    </>
                                                ))}
                                            </TableBody> : <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell colspan="9">You have no bets in this time period.</TableCell>
                                                </TableRow>
                                            </TableBody>}
                                        </Table>
                                    </TabPanel>
                                    <TabPanel className="current-bet" value={valuen} index={4} >
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list">
                                                <Typography variant="span">Bet Status</Typography>
                                                <select id="betStatus" className="bet-status" onChange={handleChange} value={formData.is_unmatch} name="is_unmatch">
                                                    <option value="">All</option>
                                                    <option value="Yes">Unmatched</option>
                                                    <option value="No">Matched</option>
                                                </select>
                                                <Typography variant="span">Order By</Typography>
                                                <input type="checkbox" name="betplace" />
                                                <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                <input type="checkbox" name="market" />
                                                <Typography variant="span" className="ml-0">Market</Typography>
                                            </Box>
                                        </Box>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Matched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection	Type</TableCell>
                                                    <TableCell align="center">Bet ID</TableCell>
                                                    <TableCell align="center">Bet placed</TableCell>
                                                    <TableCell align="center">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Unmatched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                </TabPanel>
                                <TabPanel className="Bets History bet-history-pd" style={{ padding: 0 }} value={valuex} index={1}>
                                    <AppBar position="static" className="tab">
                                        <Tabs value={valuen} variant="fullWidth" onChange={handlenChange}>
                                            <Tab label="Exchange" {...a11yProps(0)} />
                                            <Tab label="FancyBet" {...a11yProps(1)} />
                                            <Tab label="Sportsbook" {...a11yProps(2)} />
                                            <Tab label="BookMaker" {...a11yProps(3)} />
                                            <Tab label="Binary" {...a11yProps(4)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel className="Bets History bethistory-filters-fancy" value={valuen} index={0}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Bet Status</Typography>
                                                <select id="betStatus" onChange={handleChangeProfit} name="status" className="bet-status">
                                                    <option value="All">All</option>
                                                    <option value=" ">Unmatched</option>
                                                    <option value="Open">Matched</option>
                                                    <option value="Settled">Settled</option>
                                                    <option value=" ">Cancelled</option>
                                                    <option value=" ">Voided</option>
                                                </select>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                {/* <TimeField onChange={handleChangeProfit} type="time" name="fromTime" style={{ width: 75 }} value={profitAndLossCa.fromTime} /> */}


                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />


                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                {/* <TimeField onChange={handleChangeProfit} type="time" name="toTime" style={{ width: 75 }} value={profitAndLossCa.toTime} /> */}


                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />

                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                            </ul>
                                        </Box>
                                        {valuex == 1 && !profitAndLossListt.length == 0 ?
                                            <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                                <Table className={`bets-table ${classes.table}`}>
                                                    <TableHead className="table_head">
                                                        <TableRow>
                                                            <TableCell align="center">Bet ID</TableCell>
                                                            <TableCell>Market</TableCell>
                                                            <TableCell align="left">Selection</TableCell>
                                                            <TableCell align="right">Type</TableCell>
                                                            <TableCell align="center">Bet placed</TableCell>
                                                            <TableCell align="center">Odds req.</TableCell>
                                                            <TableCell align="right">Stake</TableCell>
                                                            <TableCell align="right">Avg Odds matched</TableCell>
                                                            <TableCell align="right">Profit/Loss</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="table_body">
                                                        {valuen == 0 && valuex == 1 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                            <><TableRow>
                                                                <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowinner.betting_id?.substr(rowinner.betting_id.length - 6))}>{rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}</Link></TableCell>
                                                                <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                                <TableCell align="left">{rowinner.place_name}</TableCell>
                                                                <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                                <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.totalPL?.toFixed(2)}</TableCell>
                                                            </TableRow>
                                                                <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                    <TableCell colspan="3"></TableCell>
                                                                    <TableCell colspan="7">
                                                                        {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                        <Table className={`bets-table ${classes.table}`}>
                                                                            <TableHead className="table_head">
                                                                                <TableCell>Bet taken</TableCell>
                                                                                <TableCell>Odds req.</TableCell>
                                                                                <TableCell>Stake</TableCell>
                                                                                <TableCell align='right'>Liability</TableCell>
                                                                                <TableCell align='right'>Odds matched</TableCell>
                                                                            </TableHead>
                                                                            <TableBody className="table_body">
                                                                                {
                                                                                    rowinner.bettings.map((betting) => (
                                                                                        <TableRow>
                                                                                            <TableCell>{moment(betting.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                            <TableCell align='right'>{betting.price_val}</TableCell>
                                                                                            <TableCell>{betting.stake}</TableCell>
                                                                                            <TableCell align="right" className={rowinner.totalPL?.toFixed(2) < 0 ? 'red' : ''}>{
                                                                                                rowinner.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowinner.totalPL?.toFixed(2)) + ')' : '-'}</TableCell>
                                                                                            <TableCell align='right'>{betting.price_val}</TableCell>
                                                                                        </TableRow>
                                                                                    ))
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                                <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                    <Typography variant="span">UnMatched</Typography>
                                                </Box>
                                                <Table className={`bets-table ${classes.table}`}>
                                                    <TableHead className="table_head">
                                                        <TableRow>
                                                            <TableCell>Market</TableCell>
                                                            <TableCell align="left">Selection	Type</TableCell>
                                                            <TableCell align="center">Bet ID</TableCell>
                                                            <TableCell align="center">Bet placed</TableCell>
                                                            <TableCell align="center">Odds req.</TableCell>
                                                            <TableCell align="right">Matched</TableCell>
                                                            <TableCell align="right">Unmatched</TableCell>
                                                            <TableCell align="right">Date matched</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="table_body">
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                            <TableCell align="right"></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </> : noReport}

                                    </TabPanel>
                                    <TabPanel className="Bets History bethistory-filters-fancy" value={valuen} index={1} >
                                        {
                                            valuex == 1 ?
                                                <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list" style={{ width: "100%" }}>
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" onChange={handleChangeProfit} name="status" className="bet-status">
                                                            <option value="All">All</option>
                                                            <option value=" ">Unmatched</option>
                                                            <option value="Open">Matched</option>
                                                            <option value="Settled">Settled</option>
                                                            <option value=" ">Cancelled</option>
                                                            <option value=" ">Voided</option>
                                                        </select>
                                                        <Typography variant="span">Period</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                        <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                        <Typography variant="span"> to</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                        <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                                    </Box>
                                                    <ul className="input-list">
                                                        <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                        <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                        <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                                    </ul>
                                                </Box>
                                                </> : <>

                                                </>}
                                        {valuex == 1 && !profitAndLossListt.length == 0 ?
                                            <><Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                                <Table className={`bets-table ${classes.table}`}>
                                                    <TableHead className="table_head">
                                                        <TableRow>
                                                            <TableCell align="center">Bet ID</TableCell>
                                                            <TableCell align="left">Market</TableCell>
                                                            <TableCell align="left">Selection</TableCell>
                                                            <TableCell align="right">Type</TableCell>
                                                            <TableCell align="center">Bet placed</TableCell>
                                                            <TableCell align="center">Odds req.</TableCell>
                                                            <TableCell align="right">Stake</TableCell>
                                                            <TableCell align="right">Avg. odds matched</TableCell>
                                                            <TableCell align="right">Profit/Loss</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="table_body">

                                                        {valuen == 1 && valuex == 1 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                            <><TableRow>
                                                                <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowinner.betting_id?.substr(rowinner.betting_id.length - 6))}>{rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}</Link></TableCell>
                                                                <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                                <TableCell align="left">{rowinner.place_name}</TableCell>
                                                                <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                                <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.totalPL?.toFixed(2)}</TableCell>
                                                            </TableRow>
                                                                <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                    <TableCell colspan="3"></TableCell>
                                                                    <TableCell colspan="7">
                                                                        {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                        <Table className={`bets-table ${classes.table}`}>
                                                                            <TableHead className="table_head">
                                                                                <TableCell>Bet taken</TableCell>
                                                                                <TableCell>Odds req.</TableCell>
                                                                                <TableCell>Stake</TableCell>
                                                                                <TableCell align='right'>Liability</TableCell>
                                                                                <TableCell align='right'>Odds matched</TableCell>
                                                                            </TableHead>
                                                                            <TableBody className="table_body">
                                                                                {
                                                                                    rowinner.bettings.map((betting) => (
                                                                                        <TableRow>
                                                                                            <TableCell>{moment(betting.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                            <TableCell align='left'>{betting.price_val}</TableCell>
                                                                                            <TableCell>{betting.stake}</TableCell>
                                                                                            <TableCell align="right" className={rowinner.totalPL?.toFixed(2) < 0 ? 'red' : ''}>{
                                                                                                rowinner.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowinner.totalPL?.toFixed(2)) + ')' : '-'}</TableCell>
                                                                                            <TableCell align='right'>{betting.price_val}</TableCell>
                                                                                        </TableRow>
                                                                                    ))
                                                                                }

                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow></>


                                                        ))}
                                                    </TableBody>
                                                </Table></> : noReport}
                                    </TabPanel>
                                    <TabPanel className="Bets History bethistory-filters-matchodds" value={valuen} index={2} >
                                        {
                                            valuex == 1 ?
                                                <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list" style={{ width: "100%" }}>
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" onChange={handleChangeProfit} name="status" className="bet-status">
                                                            <option value="All">All</option>
                                                            <option value=" ">Unmatched</option>
                                                            <option value="Open">Matched</option>
                                                            <option value="Settled">Settled</option>
                                                            <option value=" ">Cancelled</option>
                                                            <option value=" ">Voided</option>
                                                        </select>
                                                        <Typography variant="span">Period</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                        <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                        <Typography variant="span"> to</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                        <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                                    </Box>
                                                    <ul className="input-list">
                                                        <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                        <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                        <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                                    </ul>
                                                </Box>
                                                </> : <>

                                                </>}
                                        {valuex == 1 && !profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Unmatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell align="center">Bet ID</TableCell>
                                                        <TableCell align="left">Market</TableCell>
                                                        <TableCell align="left">Selection</TableCell>
                                                        <TableCell align="right">Type</TableCell>
                                                        <TableCell align="center">Bet placed</TableCell>
                                                        <TableCell align="center">Odds req.</TableCell>
                                                        <TableCell align="right">Stake</TableCell>
                                                        <TableCell align="right">Avg. odds matched</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell align="center">Bet ID</TableCell>
                                                        <TableCell align="left">Market</TableCell>
                                                        <TableCell align="left">Selection</TableCell>
                                                        <TableCell align="right">Type</TableCell>
                                                        <TableCell align="center">Bet placed</TableCell>
                                                        <TableCell align="center">Odds req.</TableCell>
                                                        <TableCell align="right">Stake</TableCell>
                                                        <TableCell align="right">Avg. odds matched</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {valuen == 3 && valuex == 1 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                        <><TableRow>
                                                            <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowinner.betting_id?.substr(rowinner.betting_id.length - 6))}>{rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}</Link></TableCell>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.totalPL?.toFixed(2)}</TableCell>
                                                        </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell>Odds req.</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            {
                                                                                rowinner.bettings.map((betting) => (
                                                                                    <TableRow>
                                                                                        <TableCell>{moment(betting.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                        <TableCell align='right'>{betting.price_val}</TableCell>
                                                                                        <TableCell>{betting.stake}</TableCell>
                                                                                        <TableCell align="right" className={rowinner.totalPL?.toFixed(2) < 0 ? 'red' : ''}>{
                                                                                            rowinner.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowinner.totalPL?.toFixed(2)) + ')' : '-'}</TableCell>
                                                                                        <TableCell align='right'>{betting.price_val}</TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow></>


                                                    ))}
                                                </TableBody>
                                            </Table></> : noReport}
                                    </TabPanel>
                                    <TabPanel className="Bets History bethistory-filters-bookmaker" value={valuen} index={3} >
                                        {
                                            valuex == 1 ?
                                                <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list" style={{ width: "100%" }}>
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" onChange={handleChangeProfit} name="status" className="bet-status">
                                                            <option value="All">All</option>
                                                            <option value=" ">Unmatched</option>
                                                            <option value="Open">Matched</option>
                                                            <option value="Settled">Settled</option>
                                                            <option value=" ">Cancelled</option>
                                                            <option value=" ">Voided</option>
                                                        </select>
                                                        <Typography variant="span">Period</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                        <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                        <Typography variant="span"> to</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                        <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                                    </Box>
                                                    <ul className="input-list">
                                                        <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                        <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                        <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                                    </ul>
                                                </Box>
                                                </> : <>

                                                </>}
                                        {valuex == 1 && !profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell align="center">Bet ID</TableCell>
                                                        <TableCell align="left">Market</TableCell>
                                                        <TableCell align="left">Selection</TableCell>
                                                        <TableCell align="right">Type</TableCell>
                                                        <TableCell align="center">Bet placed</TableCell>
                                                        <TableCell align="center">Odds req.</TableCell>
                                                        <TableCell align="right">Stake</TableCell>
                                                        <TableCell align="right">Avg. odds matched</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {valuen == 3 && valuex == 1 && profitAndLossListt.length > 0 && profitAndLossListt.map((rowinner) => (
                                                        <><TableRow>
                                                            <TableCell align="left"><Link data-id="0" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowinner.betting_id?.substr(rowinner.betting_id.length - 6))}>{rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}</Link></TableCell>
                                                            <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                            <TableCell align="left">{rowinner.place_name}</TableCell>
                                                            <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Back" : "Lay"}</TableCell>
                                                            <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                            <TableCell align="right">{rowinner.totalPL?.toFixed(2)}</TableCell>
                                                        </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowinner.betting_id?.substr(rowinner.betting_id.length - 6)}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="3"></TableCell>
                                                                <TableCell colspan="7">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet taken</TableCell>
                                                                            <TableCell align='left'>Odds req.</TableCell>
                                                                            <TableCell align='left'>Stake</TableCell>
                                                                            <TableCell>Liability</TableCell>
                                                                            <TableCell>Odds matched</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">
                                                                            {
                                                                                rowinner.bettings.map((betting) => (
                                                                                    <TableRow>
                                                                                        <TableCell>{moment(betting.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                        <TableCell align='left'>{betting.price_val}</TableCell>
                                                                                        <TableCell align='left'>{betting.stake}</TableCell>
                                                                                        <TableCell align='left' className={rowinner.totalPL?.toFixed(2) < 0 ? 'red' : ''}>{
                                                                                            rowinner.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowinner.totalPL?.toFixed(2)) + ')' : '-'}</TableCell>
                                                                                        <TableCell align='left'>{betting.price_val}</TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow></>


                                                    ))}
                                                </TableBody>
                                            </Table></> : noReport}
                                    </TabPanel>
                                    <TabPanel TabPanel className="Bets History bethistory-filters-bookmaker" value={valuen} index={4}>
                                        {
                                            valuex == 1 ?
                                                <> <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                                    <Box className="input-list" style={{ width: "100%" }}>
                                                        <Typography variant="span">Bet Status</Typography>
                                                        <select id="betStatus" onChange={handleChangeProfit} name="status" className="bet-status">
                                                            <option value="All">All</option>
                                                            <option value=" ">Unmatched</option>
                                                            <option value="Open">Matched</option>
                                                            <option value="Settled">Settled</option>
                                                            <option value=" ">Cancelled</option>
                                                            <option value=" ">Voided</option>
                                                        </select>
                                                        <Typography variant="span">Period</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                        <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                        <Typography variant="span"> to</Typography>
                                                        <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                        <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                                    </Box>
                                                    <ul className="input-list">
                                                        <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                        <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                        <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get History</button></li>
                                                    </ul>
                                                </Box>
                                                </> : <>

                                                </>}
                                        {valuex == 1 && !profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Selection	Type</TableCell>
                                                        <TableCell align="center">Bet ID</TableCell>
                                                        <TableCell align="center">Bet placed</TableCell>
                                                        <TableCell align="center">Odds req.</TableCell>
                                                        <TableCell align="right">Matched</TableCell>
                                                        <TableCell align="right">Unmatched</TableCell>
                                                        <TableCell align="right">Date matched</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {betHistoryList.map((rowinner) => (
                                                        (rowinner.market_name == "Bookmaker" && rowinner.status == "Settled" && rowinner.unmatch_bet == "Yes") ?
                                                            <TableRow>

                                                                <TableCell align="left">{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                <TableCell align="left">{rowinner.user_name}</TableCell>
                                                                <TableCell>{rowinner.event_type == 4 ? 'CRICKET' : null} {rowinner.event_type == 1 ? 'SOCCER' : null} {rowinner.event_type == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowinner.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowinner.market_name}</TableCell>
                                                                <TableCell align="left">{rowinner.place_name}</TableCell>
                                                                <TableCell align="left" className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 1 ? "Yes" : "No"}</TableCell>
                                                                <TableCell align="right">{moment(rowinner.settledTime).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.stake?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                <TableCell align="right">{rowinner.p_l}</TableCell>
                                                            </TableRow>
                                                            : null
                                                    ))}
                                                </TableBody>
                                            </Table></> : noReport}
                                    </TabPanel>
                                </TabPanel>
                                <TabPanel className="Profit &amp; Loss pandlcont white-wrap" value={valuex} index={2}>
                                    <h4 style={{ margin: "11px 0" }}>Profit & Loss - Main wallet</h4>
                                    <ul class="acc-info acc-info1">
                                        <li class="user user1">{userInfo && userInfo.hasOwnProperty("user_name") ? userInfo.user_name : ''}</li>
                                        <li class="user user1">{moment().format("YYYY-MM-DD HH:mm")}</li>
                                    </ul>
                                    <AppBar position="static" className="tab" >
                                        <Tabs value={valuen} variant="fullWidth" className='pltab' onChange={handlenChange}>
                                            <Tab label="Exchange" {...a11yProps(0)} />
                                            <Tab label="FancyBet" {...a11yProps(1)} />
                                            <Tab label="Sportsbook" {...a11yProps(2)} />
                                            <Tab label="BookMaker" {...a11yProps(3)} />
                                            <Tab label="Binary" {...a11yProps(4)} />
                                            <Tab label="Casino" {...a11yProps(5)} />
                                            <Tab label="BPoker" {...a11yProps(6)} />
                                            <Tab label="SABA" {...a11yProps(7)} />
                                            <Tab label="MT5" {...a11yProps(8)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={0}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>

                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell >{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Back Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Lay Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={1}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}<img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.place_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id?.x}${rowfancy._id?.y}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(`${rowfancy._id?.x}${rowfancy._id?.y}`)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id?.x}${rowfancy._id?.y}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.is_back == 0 ? "No" : "Yes"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>

                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>

                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Yes Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    No Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={2}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>

                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Back Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Lay Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={3}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Back Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Lay Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={4}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>

                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Back Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Lay Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                    <TabPanel className="Profit &amp; Loss proftLossContainer" value={valuen} index={5}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list" style={{ width: "100%" }}>
                                                <Typography variant="span">Period</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.from_date} max={moment().format("YYYY-MM-DD")} type="date" name="from_date" />
                                                <input id="fromTime" onChange={handleChangeProfit} value={profitAndLossCa.fromTime} style={{ width: 100 }} className="time-input" name="fromTime" type="time" placeholder="08:59" maxLength="5" />
                                                <Typography variant="span"> to</Typography>
                                                <input onChange={handleChangeProfit} value={profitAndLossCa.to_date} max={moment().format("YYYY-MM-DD")} type="date" name="to_date" />
                                                <input id="toTime"  name="toTime" onChange={handleChangeProfit} value={profitAndLossCa.toTime} style={{ width: 100 }} className="time-input" type="time" placeholder="08:59" maxLength="5" />
                                            </Box>
                                            <ul className="input-list">
                                                <li onClick={() => justFor(1)}><button id="today" className="btn">Just For Today</button></li>
                                                <li onClick={() => justFor(2)}><button id="yesterday" className="btn">From Yesterday</button></li>
                                                <li onClick={() => handleFilterSubmitProfit()}><button id="getPL" className="btn-send">Get P & L</button></li>
                                            </ul>
                                        </Box>
                                        {!profitAndLossListt.length == 0 ? <>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">Matched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    {profitAndLossListt.map((rowfancy) => (

                                                        <>
                                                            <TableRow className="pandlbets">
                                                                <TableCell>{rowfancy.event_type_id == 4 ? 'CRICKET' : null} {rowfancy.event_type_id == 1 ? 'SOCCER' : null} {rowfancy.event_type_id == 2 ? 'TENNIS' : null}<img id="arrowww" className="fromto" src={Transparent} /><strong>{rowfancy.event_name}</strong> <img id="arrowww" className="fromto" src={Transparent} /> {rowfancy.market_name}</TableCell>
                                                                <TableCell>{moment(rowfancy.startTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                                                                <TableCell>{moment(rowfancy.settledTime).format("YYYY-MM-DD h:mm:ss")}</TableCell>

                                                                <TableCell align="right" className={rowfancy.totalPL?.toFixed(2) < 0 ? 'red' : ''}><Link data-id="0" id={`expandCurrentBookmaker-${rowfancy._id}`} className="expand-close" to="#" onClick={() => showCurrentBookmaker(rowfancy._id)}>{
                                                                    rowfancy.totalPL?.toFixed(2) < 0 ? '(' + Math.abs(rowfancy.totalPL?.toFixed(2)) + ')' : rowfancy.totalPL?.toFixed(2)}</Link></TableCell>
                                                            </TableRow>
                                                            <TableRow className="second-spec-row" id={`expandCurrentBookmaker-${rowfancy._id}-toggle`} style={{ display: "none" }}>
                                                                <TableCell colspan="1"></TableCell>
                                                                <TableCell colspan="9">
                                                                    {/* <img className="expand-arrow" src={Transparent} /> */}
                                                                    <Table className={`bets-table ${classes.table}`}>
                                                                        <TableHead className="table_head">
                                                                            <TableCell>Bet Id</TableCell>
                                                                            <TableCell>Selection</TableCell>
                                                                            <TableCell>Odds</TableCell>
                                                                            <TableCell>Stake</TableCell>
                                                                            <TableCell>Type</TableCell>
                                                                            <TableCell>Placed</TableCell>
                                                                            <TableCell>Profit/Loss</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className="table_body">

                                                                            {rowfancy.bettings?.length > 0 && rowfancy.bettings.map((rowinner) => (
                                                                                <TableRow>
                                                                                    <TableCell>{rowinner._id.substr(rowinner._id.length - 6)}</TableCell>
                                                                                    <TableCell>{rowinner.selection_id}</TableCell>
                                                                                    <TableCell>{rowinner.price_val?.toFixed(2)}</TableCell>
                                                                                    <TableCell>{rowinner.stake?.toFixed(2)}</TableCell>
                                                                                    <TableCell className={rowinner.is_back == 1 ? "back" : "lay"}>{rowinner.is_back == 0 ? "Lay" : "Back"}</TableCell>
                                                                                    <TableCell>{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm:ss ')}</TableCell>
                                                                                    <TableCell align="left" className={rowinner.bet_result == 'Plus' ? '' : 'red'}>{rowinner.bet_result == 'Plus' ? rowinner.profit?.toFixed(2) : rowinner.loss?.toFixed(2)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Total Stakes
                                                                                </TableCell>
                                                                                <TableCell colspan="3">
                                                                                    {getTotalStakes(rowfancy.bettings)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Back Subtotal
                                                                                </TableCell>
                                                                                <TableCell align="left" colspan="3" className={getYesTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getYesTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Lay Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getNoTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getNoTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="sum-pl">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getSubTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getSubTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="dottedborder-td">
                                                                                <TableCell align="right" colspan="6">
                                                                                    Net Market Subtotal
                                                                                </TableCell>
                                                                                <TableCell colspan="3" className={getMarketTotalStakes((rowfancy.bettings)) < 0 ? 'red' : ''}>{Math.abs(getMarketTotalStakes((rowfancy.bettings)))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>

                                                    ))}

                                                </TableBody>
                                            </Table>
                                            <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                                <Typography variant="span">UnMatched</Typography>
                                            </Box>
                                            <Table className={`bets-table ${classes.table}`}>
                                                <TableHead className="table_head">
                                                    <TableRow>
                                                        <TableCell>Market</TableCell>
                                                        <TableCell align="left">Start Time</TableCell>
                                                        <TableCell align="left">Settled date</TableCell>
                                                        <TableCell align="right">Profit/Loss</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className="table_body">
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="right"></TableCell>

                                                    </TableRow>
                                                </TableBody>
                                            </Table></> : noPlReport}
                                    </TabPanel>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid >
        </>
    );
}
