import React from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,Button, makeStyles, Grid, Link, Tabs, Tab, Box, Typography } from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import Telegram from '@material-ui/icons/Telegram';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import { BrowserView, MobileView } from "react-device-detect";
// IMPORT IMAGES
import Powered from '../assets/images/powered.gif';
import GCLogo from '../assets/images/gc-logo.png';
import DownloadApp from '../assets/images/download-app.png';
import Skype from '../assets/images/skype.svg';
import BetFair from '../assets/images/betfair_black.png';
import AndroidBtn from '../assets/images/btn-appdl-android.png';
import TermsTemplate from '../pages/TermsTemplate';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    dashboardWrap: {
        width: '75%',
        margin: 'auto',
        marginTop: '2px',
    },
    AlignCenter: {
        alignItems: 'center'
    },
    borderRight: {
        borderRight: '1px solid #999',
        height: '100%'
    },
    marginBottomLast: {
        marginBottom: '24px !important'
    }
}))
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const Footer = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [modalTitle, setModalTitle] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (title) => {
        setModalTitle(title)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="dashboard-footer">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="social">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            {/* <Tab onMouseEnter={() => setValue(0)} label={<Email />} /> */}
                            <Tab label={<WhatsApp />} />

                            {/* <Tab  onClick={() => window.open("https://wa.me/919002363070?text,=hi", "_blank")}  label={<WhatsApp />} /> */}
                            <Tab  onMouseEnter={() => setValue(1)} label={<Facebook />} />
                            {/* <Tab onMouseEnter={() => setValue(2)} label={<Telegram />} />
                            <Tab onMouseEnter={() => setValue(3)} label={<img alt="skyexchange" src={Skype} />} />
                            <Tab onMouseEnter={() => setValue(4)} label={<Instagram />} />  */}
                        </Tabs>
                        <TabPanel className="tab-description" value={value} index={0}>
                            <Link to="#">
                                info@skyexchangeart.in
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={2}>
                            <Link to="#">
                                www.t.me/officialskyexchangeart.in
                            </Link>
                            <Link to="#" className="borderLeft">
                                www.t.me/9-wicketscustomersupport
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={3}>
                            <Link to="#">
                            skyexchangeart.inofficial
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={4}>
                            <Link to="#">
                                officialskyexchangeart.in
                            </Link>
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>
            <Grid id container spacing={2}>
                <Grid item md={2} className="xs-none"></Grid>
                <Grid item md={8} xs={12} className="xp-0">
                    <BrowserView>
                        <div className="powered-block">
                            <Grid container className={classes.AlignCenter} spacing={2}>
                                <Grid item xs={2} className={classes.borderRight}>
                                    <p>Powered By</p>
                                    <img alt="skyexchange" src={Powered} />
                                </Grid>
                                <Grid item xs={2}>
                                    <img alt="skyexchange" src={GCLogo} />
                                </Grid>
                            </Grid>
                        </div>
                    </BrowserView>
                    <div className="footer-bottom">
                        <BrowserView>
                            <div className="browsers">
                                <Instagram />
                                <Instagram />
                            </div>
                            <p>Our website works best in the newest and last prior version of these browsers:</p>
                            <p>Google Chrome. Firefox</p>
                            <hr />
                            <div className="footer-links">
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Privacy Policy')}>
                                    Privacy Policy
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Terms and Conditions')}>
                                    Terms and Conditions
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Rules and Regulations')}>
                                    Rules and Regulations
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('KYC')}>
                                    KYC
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Responsible Gaming')}>
                                    Responsible Gaming
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('About Us')}>
                                    About Us
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Self-exclusion Policy')}>
                                    Self-exclusion Policy
                                </Link>
                                &nbsp; - &nbsp;
                                <Link to="#" onClick={() => handleClickOpen('Underage Policy')}>
                                    Underage Policy
                                </Link>
                                &nbsp; - &nbsp;
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="footer-links">
                                <Link to="#" onClick={() => handleClickOpen('Privacy Policy')}>
                                    Privacy Policy
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('Terms and Conditions')}>
                                    Terms and Conditions
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('Rules and Regulations')}>
                                    Rules and Regulations
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('KYC')}>
                                    KYC
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('Responsible Gaming')}>
                                    Responsible Gaming
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('About Us')}>
                                    About Us
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('Self-exclusion Policy')}>
                                    Self-exclusion Policy
                                </Link>
                                <Link to="#" onClick={() => handleClickOpen('Underage Policy')}>
                                    Underage Policy
                                </Link>
                            </div>
                        </MobileView>
                        <BrowserView>
                            <div className="download-app">
                                <img alt="skyexchange" src={DownloadApp} />
                            </div>
                            <p className={classes.marginBottomLast}>
                                v1.07 - 2020-11-11 - 8.2MB
                            </p>
                        </BrowserView>
                        <MobileView>
                            <div className="download-app xs-down-app">
                                <div>
                                    <div>
                                        <p>Powered By</p>
                                        <img className="bet-img" src={BetFair} />
                                    </div>
                                    <br />
                                </div>
                                <div>
                                    <img alt="skyexchange" src={AndroidBtn} />
                                    <p style={{ fontSize: '9.2px', textAlign: 'center', color: '#666' }} className={classes.marginBottomLast}>
                                        v1.07 - 2020-11-11 - 8.2MB
                                    </p>
                                </div>
                            </div>
                        </MobileView>
                    </div>
                </Grid>
            </Grid>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {modalTitle}
                </DialogTitle>
                <DialogContent dividers>

                    <TermsTemplate title={modalTitle} />

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                      Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default React.memo(Footer);
