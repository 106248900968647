import React from 'react';

import { Route, Redirect } from 'react-router'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItem, Tabs, Button } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router';

import Header from '../includes/Header';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MoreVert } from '@material-ui/icons';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { isMobile } from "react-device-detect";
import Loader from '../assets/images/loading40.gif';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }
}

function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }

}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));


function AccountMobile() {
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const history = useHistory();
    const lastSegment = window.location.pathname;
    if (!isLoggedIn) {
        history.push('/login');
    }
    let segment = 0;
    if (lastSegment === '/summary') {
        segment = 1;
    } else if (lastSegment === '/account-statement') {
        segment = 2;
    } else if (lastSegment === '/current-bets') {
        segment = 3;
    } else if (lastSegment === '/activity-log') {
        segment = 4;
    }



    const openAccount = (URL, search = '') => {
        if (URL === 'current-bets') {
            history.push({
                pathname: '/' + URL,
                search: search,
            });
        } else {
            history.push(`/${URL}`);
        }
    }

    const classes = useStyles();
    const [value, setValue] = React.useState(isMobile ? 6 : segment);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const logout = () => {
        sessionStorage.clear();
        sessionStorage.setItem("loggedIn", false);
        history.push("/");
    };
    React.useEffect(() => {
        loader_remove();
        // setTimeout(() => {
        //   loader_remove();
        // }, 1500);
    }, []);
    return (
        <>
            <Header />
            {isLoggedIn ? null :
                <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
            }

            <div className="xs-account-tabs">
                <Box className="namegmt" style={{ background: "#000" }}>
                    <div>{userInfo  && userInfo.hasOwnProperty("user_name")  ? userInfo.user_name : ''}</div> 
                    <div >
                        GMT+6:00
                    </div>
                </Box>

                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    <Tab label="My Profile" onClick={() => openAccount('account')} icon={<ArrowForwardIosIcon />} {...a11yProps(0)} />
                    <Tab label="Balance Overview" onClick={() => openAccount('summary')} icon={<ArrowForwardIosIcon />} {...a11yProps(1)} />
                    <Tab label="My Bets" onClick={() => openAccount('current-bets', 'current')} icon={<ArrowForwardIosIcon />} {...a11yProps(2)} />
                    <Tab label="Bets History" onClick={() => openAccount('current-bets', 'history')} icon={<ArrowForwardIosIcon />} {...a11yProps(3)} />
                    <Tab label="Profit & Loss" onClick={() => openAccount('current-bets', 'PandL')} icon={<ArrowForwardIosIcon />} {...a11yProps(4)} />
                    <Tab label="Activity Log" onClick={() => openAccount('activity-log')} icon={<ArrowForwardIosIcon />} {...a11yProps(5)} />
                    <Tab label="Account Statement" onClick={() => openAccount('account-statement')} icon={<ArrowForwardIosIcon />} {...a11yProps(0)} />
                </Tabs>
                <Button variant="contained" onClick={() => logout()} className="acc-logout logout-dark-btn">Logout <ExitToApp /></Button>
            </div>
        </>
    );
}

export default AccountMobile;