import '../assets/css/style.css';
import React from 'react';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip';
import LeftSideBar from '../includes/LeftSideBar';

import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import { Grid, makeStyles, Table, TableRow, TableCell, TableHead, TableBody, Link } from '@material-ui/core';

//IMAGES
import time from '../assets/images/time.png';
import pin from '../assets/images/pin.png';
import MinMax from '../assets/images/min-max.svg';
import Depth from '../assets/images/depth.svg';
import BackballDisabled from '../assets/images/bg-backall-disabled.png'
import darkPin from '../assets/images/dark-pin-off.svg';
import transparent from '../assets/images/transparent.gif';
import LayallDisabled from '../assets/images/bg-layall-disabled.png'
import ReplayIcon from '@material-ui/icons/Replay';

function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    tableHeight: {
        height: 'calc(100vh - 120px)',
    },
}));
const Indian = () => {

    const classes = useStyles();
    const rows = [
        createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '1 - 1 ', 'maxime', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('king', '3 - 1 ', 'cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring', '2 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Forge', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
    ];
    return (
        <>
            <Header />
            <div className={classes.root}>
                <Grid container  style={{ backgroundColor: '#F0ECE1' }}>
                    <Grid item lg={2}  className="xs-none" style={{ backgroundColor: '#fff' }}>
                        <LeftSideBar />
                    </Grid>
                    <Grid item lg={7} xs={12} className={`ipl-section ${classes.tableHeight}`}>
                        <Grid container spacing={2} style={{ padding: '10px' }} className="ipl-xs-section">
                            <Grid item lg={12} className="ipl-banner xs-none">
                            </Grid>
                            <Grid item lg={12} xs={12} className="xp-0">
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow className="tbl_head">
                                            <TableCell colSpan="1" className="xs-none">
                                                <Link  to="#" className="header-pin">
                                                    <img alt="cricket" src={pin} />
                                                </Link>
                                                <span style={{ fontWeight: "bold", fontSize: "16px" }}>Indian Premier League </span>
                                                <span className="winner">Winner</span>
                                                <span className="refresh-icon"><ReplayIcon /></span>
                                            </TableCell>
                                            <TableCell colSpan="1" className="md-none ipl-header">
                                                <Link  to="#" className="pin-icon">
                                                    <img src={darkPin} />
                                                </Link>
                                                <span className="ipl-header-text">Indian Premier League - Winner <small>15 Oct, 19:30</small></span>
                                                <span className="refresh-icon"><ReplayIcon /></span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell align="center" className="ipl-table-space-bet xs-none">
                                            <div className="game-info">
                                                <img width="15px" src={time} alt="Live" style={{ marginBottom: "-2px", marginRight: "8px" }} />
                                                <span>Live</span>
                                            </div>
                                            <div className="fancy-info">Max 800</div>
                                            <div className="margin-match">
                                                <span>Matched</span>
                                                <span className="game-matched-bold">INR 1,504,230</span>
                                            </div>
                                        </TableCell>
                                    </TableBody>
                                </Table>
                                <Table className={classes.table} style={{ background: '#E0E6E6' }} aria-label="simple table">
                                    {isMobile ?
                                        <TableHead>
                                            <TableRow className="md-none">
                                                <TableCell style={{ padding: '5px' }} colSpan="3" className="winner-th px-0">
                                                    <Link  to="#" className="winner">Winner</Link>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className="ipl-tbl-head">
                                                <TableCell colSpan="1" className="ipl-xs-head px-0">
                                                    <Link  to="#"><img src={MinMax} /></Link>
                                                    <div>
                                                        <Link  to="#"><img src={Depth} /></Link>
                                                        <div className="matched-match">
                                                            <span>Matched</span>
                                                            <span>INR 1,504,230</span>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right" className="ipl-middle-head" colSpan="1">Back</TableCell>
                                                <TableCell align="right" className="ipl-middle-head" colSpan="1">Lay</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        :
                                        <TableHead>
                                            <TableRow className="ipl-tbl-head">
                                                <TableCell colSpan="1">
                                                    <span>8 selections </span>
                                                </TableCell>
                                                <TableCell align="left" colSpan="2">
                                                    <span>200.8%</span>
                                                </TableCell>
                                                <TableCell align="right" style={{ backgroundImage: `url(${BackballDisabled})` }} className="ipl-middle-head" colSpan="1">Back</TableCell>
                                                <TableCell align="right" className="ipl-middle-head" colSpan="1">Lay</TableCell>
                                                <TableCell align="right" colSpan="2">
                                                    <span>95.3%</span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    }
                                    <TableBody className="ipl_tbl_body">
                                        {rows.map((row) => (
                                            <TableRowLink to={row.link} p={1} key={row.name1} className="odd_even_clr" button>
                                                <TableCell className="table_first_row" colSpan="1">
                                                    <div className="text_left-in-ipl">
                                                        {!isMobile && (
                                                            <img className="icon-predict" alt="chart" src={transparent} />
                                                        )}
                                                        {row.name1}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-none" style={{ backgroundColor: '#D7E8F4' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.calories}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-none" style={{ backgroundColor: '#B7D5EB' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.calories}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-ipl-block" style={{ backgroundColor: '#72BBEF' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.calories}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-ipl-block" style={{ backgroundColor: '#FAA9BA' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.fat}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-none" style={{ backgroundColor: '#EFD3D9' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.fat}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center" className="disabled_td xs-none" style={{ backgroundColor: '#F6E6EA' }}>
                                                    <div style={{ display: 'block' }}>
                                                        <div>{row.protein}</div>
                                                        <span>72</span>
                                                    </div>
                                                </TableCell>
                                            </TableRowLink>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3}  className="betSlipGrid xs-none">
                        <BetSlip />
                    </Grid>
                </Grid>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </>

    )
}
export default Indian;

