const TermsTemplate = (props) => {
    if (props.title == "About Us") {
        return <div >
            <ul>
                <li>BP SOFT N.V.Group is one of the upcoming providers for online gaming entertainment across Sports Betting, Online and Live Casino operating in the emerging and the regulated markets.</li>
                <li>We aim to utilize the latest technologies to provide innovative and interactive gaming experiences in a secure environment.</li>
                <li><strong>We have dedicated ourselves to offering our customers a seamless and thrilling gaming experience while you are on the go. We aim to provide an exceptional and fully customizable online betting experience.</strong></li>
                <li><strong>We are innovative, ambitious and passionate about what we do. We do it in a credible and responsible way, always aiming for the top.</strong></li>
                <li>We only operate in regulated markets where we hold the appropriate licenses. We take our responsibilities to customers and our other stakeholders seriously and place great emphasis on working to a ‘compliance first’ model across the business.</li>
                <li><strong>Dedicated Customer Service Team: </strong>We are here for you every step of the way with dedicated customer service managers standing by to provide you with a 24/7 top notch customer care service, handling any issues quickly and efficiently.</li>
                <li>When customers bet on our site they can rest assured that they are getting a wide variety of betting options, up to date information and the best odds available.</li>
                <li>Our customers also have peace of mind, knowing that when it’s time to collect, they are betting with a well-known reputable company.</li>
                <li>We have integrated best and secured payment methods on our site and a transaction process that is quick, easy enabling our players to cash out their winnings quickly and securely.</li>
            </ul>
            <h2>BUSINESS ADDRESS</h2>

            <address>Name- BP SOFT N.V.<br />
                Address- Al Quesis Sreet , Dubai , U.A.E ,P.O Box 00000</address>


            <address>Name- BP SOFT N.V.Limited<br />
                Address- Al Quesis Sreet , Dubai , U.A.E ,P.O Box 00000</address>

        </div>
    }
    if (props.title == "Terms and Conditions") {
        return <div >
            <p><strong>Description: Initial Terms and Conditions replacing general rules</strong></p>
            <p>Introduction</p>
            <p>These terms and conditions and the documents referred and linked to below (the “Terms”) set out the basis upon which the website operated under the URL www.skyexchangeart.in (the “Website”) and its related or connected services (collectively, the “Service”) will be provided to you.</p>
            <p>Please read these terms very carefully as they form a binding legal agreement between you - our customer (the “Customer”) - and us. By opening an account (the “Account”) and using the Service you agree to be bound by these terms, together with any amendment which may be published from time to time.</p>

            <p>The Service is supplied by Sky Infotech N.V.</p>
            <p>Transactions and payment services are operated by Sky Infotech N.V a limited liability company registered in Curacao, with company registration number 152377 and holding a license no. 365/JAZ Sub-License GLH- OCCHKTW0707072017.</p>
            <p>lucky7 will only communicate with Customers by email to their registered email address (the “Registered Email Address”) as provided when opening your lucky7 account: Communication from lucky7 will be issued through the following:</p>
            <p>Email only: support@skyexchangeart.in</p>
            <p><u>General Terms</u></p>
            <p>We reserve the right to amend the terms (including to any documents referred and linked to below) at any time. When such amendment is not substantial, we may not provide you with prior notice. You will be notified in advance for material changes to the terms and may require you to re-confirm acceptance to the updated terms before the changes come into effect. If you object to any such changes, you must immediately stop using the service and the termination provisions below will apply. Continued use of the service indicates your agreement to be bound by such changes. Any bets not settled prior to the changed terms taking effect will be subject to the pre-existing terms.</p>
            <p>If at any time you are in any doubt about how to place bets or otherwise use the service you should refer back to these terms or contact our customer service department (Customer Service Department) at support@skyexchangeart.in</p>
            <p>1. <u>Your Obligations</u></p>
            <p>You agree that at all times when using the Service:</p>
            <p>You are over 18 years of age (or over the age of majority as stipulated in the laws of the jurisdiction applicable to you) and can enter into a binding legal agreement with us.</p>
            <p>It is the User’s responsibility to check and enter this site only if the user is in a country where it is lawful to place bets on the service (if in doubt, you should seek local legal advice). It is your responsibility to ensure that your use of the service is legal.</p>
            <p>When sending money to us you are authorised to do so e.g. you are the authorised user of the debit/credit card or other payment method you use.</p>
            <p>You will not, by participating in the Services and/or placing bets be placed in a position of actual, potential or perceived conflict of interest in any manner.</p>
            <p>You have never failed to pay, or attempted to fail to pay a liability on a bet.</p>
            <p>You are acting solely on your own behalf as a private individual in a personal capacity and not on behalf of another party or for any commercial purposes.</p>
            <p>By placing bets you may lose some or all of your money lodged with us in accordance with these terms and you will be fully responsible for that loss.</p>
            <p>You must use the service for legitimate betting purposes only and must not nor attempt to manipulate any market or element within the service in bad faith or in a manner that adversely affects the integrity of the Service or us.</p>
            <p>When placing bets on the service you must not use any information obtained in breach of any legislation in force in the country in which you were when the bet was placed.</p>
            <p>You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party in order to avoid a liability legitimately incurred.</p>
            <p>You must otherwise generally act in good faith in relation to us of the service at all times and for all bets made through the service.</p>
            <p>2. <u>Registration</u></p>
            <p>You agree that at all times when using the service:</p>
            <p>In order to protect the integrity of the service and for other operational reasons we reserve the right to refuse to accept a registration application from any applicant at our sole discretion and without any obligation to communicate a specific reason.</p>
            <p>Before using the service, you must personally complete the registration form and read and accept these terms. In order to start betting on the service, we will require you to become a verified Customer which includes passing certain checks. You may be required to provide a valid proof of identification and any other document as it may be deemed necessary.</p>
            <p>This includes but is not limited to, a picture ID (copy of passport, driver’s licence or national ID card) and a recent utility bill listing your name and address as proof of residence to the minimum. We reserve the right to suspend wagering or restrict Account options on any Account until the required information is received. This procedure is a statutory requirement and is done in accordance with the applicable gaming regulation and the anti-money laundering legal requirements. Additionally, you will need to fund your lucky7 Account using the payment methods set out on the payment section of our Website.</p>
            <p>You must provide complete and accurate information about yourself, inclusive of a valid name, surname, address and email address, and update such information in the future to keep it complete and accurate. It is your responsibility to keep your contact details up to date on your account. Failure to do so may result in you failing to receive important account related notifications and information from us, including changes we make to these terms. We identify and communicate with our Customers via their Registered Email Address. It is the responsibility of the Customer to maintain an active and unique email account, to provide us with the correct email address and to advise lucky7 of any changes in their email address. Each Customer is wholly responsible for maintaining the security of his Registered Email Address to prevent the use of his Registered Email Address by any third party. lucky7 shall not be responsible for any damages or losses deemed or alleged to have resulted from communications between lucky7 and the Customer using the Registered Email Address. Any Customer not having an email address reachable by lucky7 will have his Account suspended until such an address is provided to us. We will immediately suspend your Account upon written notice to you to this effect if you intentionally provide false or inaccurate personal information. We may also take legal action against you for doing so in certain circumstances and/or contact the relevant authorities who may also take action against you.</p>
            <p>You are only allowed to register one Account with the service. Accounts are subject to immediate closure if it is found that you have multiple Accounts registered with us.</p>
            <p>This includes the use of representatives, relatives, associates, affiliates, related parties, connected persons and/ or third parties operating on your behalf.</p>
            <p>In order to ensure your financial worthiness and to confirm your identity, we may use any third party information providers we consider necessary.</p>
            <p>You must keep your password for the service confidential. Provided that the Account information requested has been correctly supplied, we are entitled to assume that bets, deposits and withdrawals have been made by you. We advise you to change your password on a regular basis and never disclose it to any third party. Passwords must contain at least one letter, one number and one special character and must be at least eight characters long. It is your responsibility to protect your password and any failure to do so shall be at your sole risk and expense. You must log out of the Service at the end of each session. If you believe any of your Account information is being misused by a third party, or your Account has been hacked into, or your password has been discovered by a third party, you must notify us immediately by email using your registered Email Address to support@skyexchangeart.in</p>
            <p>You must notify us if your registered email Address has been hacked into, we may, however, require you to provide additional information/ documentation so that we can verify your identity. We will immediately suspend your Account once we are aware of such an incident. In the meantime you are responsible for all activity on your Account including third party access, regardless of whether or not their access was authorised by you.</p>
            <p>You must not at any time transmit any content or other information on the service to another Customer or any other party by way of a screen capture (or other similar method), nor display any such information or content in a frame or in any other manner that is different from how it would appear if such Customer or third party had typed the URL for the service into the browser line;</p>
            <p>When registering, you will be required to choose the currency in which you will operate your account. This will be the currency of your deposits, withdrawals and bets placed and matched into the service as set out in these terms. Some payment methods do not process in all currencies. In such cases a processing currency will be displayed, along with a conversion calculator available on the page.</p>
            <p>We are under no obligation to open an account for you and our website sign-up page is merely an invitation to treat. It is entirely within our sole discretion whether or not to proceed with the opening of an account for you and, should we refuse to open an Account for you, we are under no obligation to provide you with a reason for the refusal.</p>
            <p>Upon receipt of your application, we may be in touch to request further information and/ or documentation from you in order for us to comply with our regulatory and legal obligations.</p>
            <p><u>3. Restricted Use</u></p>
            <ul>
                <li>3.1 You must not use the Service:</li>
                <p>if you are under the age of 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) or if you are not legally able to enter into a binding legal agreement with us;</p>
                <p>to collect nicknames, e-mail addresses and/or other information of other Customers by any means (for example, by sending spam, other types of unsolicited e-mails or the unauthorised framing of, or linking to, the Service);</p>
                <p>to disrupt or unduly affect or influence the activities of other Customers or the operation of the Service generally;.</p>
                <p>to promote unsolicited commercial advertisements, affiliate links, and other forms of solicitation which may be removed from the Service without notice;.</p>
                <p>in any way which, in our reasonable opinion, could be considered as an attempt to: (i) cheat the Service or another Customer using the Service; or (ii) collude with any other Customer using the Service in order to obtain a dishonest advantage;</p>
                <p>to scrape our odds or violate any of our Intellectual Property Rights; or.</p>
                <p>for any unlawful activity whatsoever.</p>
                <li>3.2 You cannot sell or transfer your account to third parties, nor can you acquire a player account from a third party.</li>
                <li>3.3 You may not, in any manner, transfer funds between player accounts.</li>
                <li>3.4 We may immediately terminate your Account upon written notice to you if you use the Service for unauthorised purposes. We may also take legal action against you for doing so in certain circumstances.</li>
            </ul>
            <p>4. <u>Privacy</u></p>
            <p>Any information provided to us by you will be protected and processed in strict accordance with these Terms and our Privacy Policy.</p>
            <p>We will not reveal the identity of any person who places bets using the service unless the information is lawfully required by competent authorities such as Regulators, the Police (e.g. to investigate fraud, money laundering or sports integrity issues), or by Financial Entities such as banks or payment suppliers or as permitted from time to time pursuant to the Privacy Policy.</p>
            <p>Upon registration ,  your information will be stored in our database. This means that your personal information may be transferred outside the European Economic Area (EEA) to jurisdictions that may not provide the same level of protection and security as applied within the EU or EEA. By agreeing to these Terms you agree to the transfer of your personal information for the purpose of the provision of the Service object of this agreement and as further detailed in our Privacy Policy.</p>
            <p>5. <u>Your Account</u></p>

            <p>We accept Accounts in multiple currencies.</p>

            <p>We do not give credit for the use of the service.</p>
            <p>We may close or suspend an Account and refund any monies held if you are not or we reasonably believe that you are not complying with these Terms, or to ensure the integrity or fairness of the Service or if we have other reasonable grounds to do so. We may not always be able to give you prior notice.</p>
            <p>We reserve the right to suspend an account without prior notice and return all funds. Contractual obligations already matured will however be honoured.</p>
            <p>We reserve the right to refuse, restrict, cancel or limit any wager at any time for whatever reason, including any bet perceived to be placed in a fraudulent manner in order to circumvent our betting limits and/ or our system regulations.</p>
            <p>If we close or suspend your account due to you not complying with these terms, we may cancel and/or void any of your bets.</p>
            <p>If any amount is mistakenly credited to your account it remains our property and when we become aware of any such mistake, we shall notify you and the amount will be withdrawn from your Account.</p>
            <p>If, for any reason, your account goes overdrawn, you shall be in debt to us for the amount overdrawn.</p>
            <p>You must inform us as soon as you become aware of any errors with respect to your Account.</p>
            <p>Customers have the right to self-exclude themselves from skyexchangeart.in. These requests have to be received from the Customer’s Registered Email Address and have to be sent to  support@skyexchangeart.in.</p>
            <p>Customers may set limitations on the amount they may wager and lose. Such request has to be sent from the Customer’s Registered Email Address to support@skyexchangeart.in .Implementation and increasing of limits shall be processed diligently, however, any request for removing or reducing limitations shall be done after a cooling-off period of seven days following your request.</p>

            <p>Should you wish to close your account with us, please send an email from your Registered Email Address to support@skyexchangeart.in.</p>
            <p>6. <u>Deposit of Funds</u></p>
            <p>You may deposit funds into your Account by any of the methods set out on our Website. All deposits should be made in the same currency as your Account and any deposits made in any other currency will be converted using the daily exchange rate obtained from www.oanda.com, or at our own bank’s prevailing rate of exchange following which your Account will be deposited accordingly.</p>

            <p>Fees and charges may apply to customer’s deposits and withdrawals. Any deposit made to an account which is not rolled over (risked) three times will incur a 3% processing fee and any applicable withdrawal fee. You are responsible for your own bank charges that you may incur due to depositing funds with us. Exceptions to this rule are outlined in our “Payment Options” pages.</p>

            <p>lucky7 is not a financial institution and uses a third party electronic payment processors to process credit and debit card deposits; they are not processed directly by us. If you deposit funds by either a credit card or a debit card, your Account will only be credited if we receive an approval and authorisation code from the payment issuing institution. If your card’s issuer gives no such authorisation, your account will not be credited with those funds.</p>
            <p>Your funds are deposited and held in the respective client account based on the currency of your account.</p>
            <p>We are not a financial institution and you will not be entitled to any interest on any outstanding account balances and any interest accrued on the client accounts will be paid to us.</p>
            <p>Funds originating from ill-gotten means must not be deposited with us.</p>

            <p>7. <u>Withdrawal of Funds</u></p>

            <p>You may withdraw any or all of your account Balance within the transaction.</p>
            <p>Note that fees may apply as outlined in section 7.b.</p>

            <p>All withdrawals must be made in the currency of your account, unless otherwise stipulated by us.</p>
            <p>We reserve the right to request documentation for the purpose of identity verification prior to granting any withdrawals from your Account. We also reserve our rights to request this documentation at any time during the lifetime of your relationship with us.</p>
            <p>All withdrawals must be made to the original debit, credit card, bank account, method of payment used to make the payment to your skyexchangeart.in Account. We may, and always at our own discretion, allow you to withdraw to a payment method from which your original deposit did not originate. This will always be subject to additional security checks.</p>
            <p>Should you wish to withdraw funds but your account is either inaccessible, dormant, locked or closed, please contact our Customer Service Department at support@skyexchangeart.in</p>
            <p></p>
            <p>8. <u>Payment Transactions and Processors</u></p>
            <p>You are fully responsible for paying all monies owed to us. You must make all payments to us in good faith and not attempt to reverse a payment made or take any action which will cause such payment to be reversed by a third party in order to avoid a liability legitimately incurred. You will reimburse us for any charge-backs, denial or reversal of payment you make and any loss suffered by us as a consequence thereof. We reserve the right to also impose an administration fee of €60, or currency equivalent per charge-back, denial or reversal of payment you make.</p>
            <p>We reserve the right to use third party electronic payment processors and or merchant banks to process payments made by you and you agree to be bound by their terms and conditions providing they are made aware to you and those terms do not conflict with these Terms.</p>
            <p>All transactions made on our site might be checked to prevent money laundering or terrorism financing activity. Suspicious transactions will be reported to the relevant authority depending on the jurisdiction governing the transaction.</p>
            <p>9. <u>Errors</u></p>
            <p>In the event of an error or malfunction of our system or processes, all bets are rendered void. You are under an obligation to inform us immediately as soon as you become aware of any error with the service. In the event of communication or system errors or bugs or viruses occurring in connection with the service and/or payments made to you as a result of a defect or effort in the Service, we will not be liable to you or to any third party for any direct or indirect costs, expenses, losses or claims arising or resulting from such errors, and we reserve the right to void all games/bets in question and take any other action to correct such errors.</p>
            <p>In the event of a casino system malfunction, or disconnection issues, all bets are rendered void. In the event of such error or any system failure or game error that results in an error in any odds calculation, charges, fees, rake, bonuses or payout, or any currency conversion as applicable, or other casino system malfunction (the “Casino Error”), we reserve the right to declare null and void any wagers or bets that were the subject of such Casino Error and to take any money from your Account relating to the relevant bets or wagers.</p>
            <p>We make every effort to ensure that we do not make errors in posting lines. However, if as a result of human error or system problems a bet is accepted at an odd that is: materially different from those available in the general market at the time the bet was made; or clearly incorrect given the chance of the event occurring at the time the bet was made then we reserve the right to cancel or void that wager, or to cancel or void a wager made after an event has started.</p>
            <p>We have the right to recover from you any amount overpaid and to adjust your account to rectify any mistake. An example of such a mistake might be where a price is incorrect or where we enter a result of an event incorrectly. If there are insufficient funds in your Account, we may demand that you pay us the relevant outstanding amount relating to any erroneous bets or wagers. Accordingly, we reserve the right to cancel, reduce or delete any pending plays, whether placed with funds resulting from the error or not.</p>
            <p>10. <u>General Rules</u></p>
            <p>If a sport-specific rule contradicts a general rule, then the general rule will not apply.</p>
            <p>The winner of an event will be determined on the date of the event’s settlement; we do not recognise protested or overturned decisions for wagering purposes. The result of an event suspended after the start of competition will be decided according to the wagering rules specified for that sport by us.</p>
            <p>All results posted shall be final after 72 hours and no queries will be entertained after that period of time. Within 72 hours after results are posted, the company will only reset/correct the results due to human error, system error or mistakes made by the referring results source.</p>
            <p>Minimum and maximum wager amounts on all sporting events will be determined by us and are subject to change without prior written notice. We also reserve the right to adjust limits on individual Accounts as well.</p>
            <p>Customers are solely responsible for their own account transactions. Please be sure to review your wagers for any mistakes before sending them in. Once a transaction is complete, it cannot be changed. We do not take responsibility for missing or duplicate wagers made by the Customer and will not entertain discrepancy requests because a play is missing or duplicated. Customers may review their transactions in the My Account section of the site after each session to ensure all requested wagers were accepted.</p>
            <p>For a wager to have action on any named contestant in a Yes/No Proposition, the contestant must enter and compete in the event.</p>
            <p>We attempt to follow the normal conventions to indicate home and away teams by indicating the home and away team by means of vertical placement on the desktop site version. This means in American Sports we would place the home team on the bottom. For Non-US sports, we would indicate the home team on top. In the case of a neutral venue, we attempt to include the letter “N” next to the team names to indicate this. For the Asian and mobile versions, we do not distinguish between European and American Sports. On the Asian and mobile versions of the site, the home team is always listed first. However, we do not guarantee the accuracy of this information and unless there is an official venue change subsequent to bets being placed, all wagers have action.</p>
            <p>A game/match will have action regardless of the League heading that is associated with the matchup. For example, two teams from the same League are playing in a Cup competition. If the matchup is mistakenly placed in the League offering, the game/match will still have action, as long as the matchup is correct. In other words, a matchup will have action as long as the two teams are correct, and regardless of the League header in which it is placed on our Website.</p>
            <p>If an event is not played on the same date as announced by the governing body, then all wagers on the event have no action. If an event is posted by us, with an incorrect date, all wagers have action based on the date announced by the governing body.</p>
            <p>lucky7 reserves the right to remove events, markets and any other product from the website.</p>
            <p>lucky7 reserves the right to restrict the casino access of any player without prior notice.</p>
            <p>In all futures wagering (for example, total season wins, Super Bowl winner, etc.), the winner as determined by the Governing Body will also be declared the winner for betting purposes except when the minimum number of games required for the future to have “action” has not been completed.</p>
            <p>11. <u>Communications and Notices</u></p>
            <p>All communications and notices to be given under these terms by you to us shall be sent to support@skyexchangeart.in</p>
            <p>All communications and notices to be given under these terms by us to you shall, unless otherwise specified in these terms, be either posted on the Website and/or sent to the Registered Email Address we hold on our system for the relevant Customer. The method of such communication shall be in our sole and exclusive discretion.</p>
            <p>All communications and notices to be given under these terms by either you or us shall be in writing in the English language when the service is not operated by lucky7, and must be given to and from the Registered Email Address in your Account.</p>
            <p>12. <u>Matters Beyond Our Control</u></p>
            <p>We cannot be held liable for any failure or delay in providing the service due to an event of Force Majeure which could reasonably be considered to be outside our control despite our execution of reasonable preventative measures such as: an act of God; trade or labour dispute; power cut; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party, and we will not be liable for any resulting loss or damage that you may suffer. In such an event, we reserve the right to cancel or suspend the Service without incurring any liability.</p>
            <p>13. <u>Liability</u></p>
            <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT COMPENSATE YOU FOR ANY REASONABLY FORESEEABLE LOSS OR DAMAGE (EITHER DIRECT OR INDIRECT) YOU MAY SUFFER IF WE FAIL TO CARRY OUT OUR OBLIGATIONS UNDER THESE TERMS UNLESS WE BREACH ANY DUTIES IMPOSED ON US BY LAW (INCLUDING IF WE CAUSE DEATH OR PERSONAL INJURY BY OUR NEGLIGENCE) IN WHICH CASE WE SHALL NOT BE LIABLE TO YOU IF THAT FAILURE IS ATTRIBUTED TO</p>
            <ul>
                <li>(I) YOUR OWN FAULT;</li>
                <p></p>
                <li>(II) A THIRD PARTY UNCONNECTED WITH OUR PERFORMANCE OF THESE TERMS (FOR INSTANCE PROBLEMS DUE TO COMMUNICATIONS NETWORK PERFORMANCE, CONGESTION, AND CONNECTIVITY OR THE PERFORMANCE OF YOUR COMPUTER EQUIPMENT); OR</li>
                <li>(III) ANY OTHER EVENTS WHICH NEITHER WE NOR OUR SUPPLIERS COULD HAVE FORESEEN OR FORESTALLED EVEN IF WE OR THEY HAD TAKEN REASONABLE CARE. AS THIS SERVICE IS FOR CONSUMER USE ONLY WE WILL NOT BE LIABLE FOR ANY BUSINESS LOSSES OF ANY KIND.
                    IN THE EVENT THAT WE ARE HELD LIABLE FOR ANY EVENT UNDER THESE TERMS, OUR TOTAL AGGREGATE LIABILITY TO YOU UNDER OR IN CONNECTION WITH THESE TERMS SHALL NOT EXCEED</li>
                <ul>
                    <li>(A) THE VALUE OF THE BETS AND OR WAGERS YOU PLACED VIA YOUR ACCOUNT IN RESPECT OF THE RELEVANT BET/WAGER OR PRODUCT THAT GAVE RISE TO THE RELEVANT LIABILITY, OR</li>
                    <li>(B) EUR €500 IN AGGREGATE, WHICHEVER IS LOWER.
                        WE STRONGLY RECOMMEND THAT YOU (I) TAKE CARE TO VERIFY THE SUITABILITY AND COMPATIBILITY OF THE SERVICE WITH YOUR OWN COMPUTER EQUIPMENT PRIOR TO USE; AND
                        (II) TAKE REASONABLE PRECAUTIONS TO PROTECT YOURSELF AGAINST HARMFUL PROGRAMS OR DEVICES INCLUDING THROUGH INSTALLATION OF ANTI-VIRUS SOFTWARE.</li>
                </ul>
            </ul>
            <p></p>
            <p>14. <u>Gambling By Those Under Age</u></p>
            <p>If we suspect that you are or receive notification that you are currently under 18 years or were under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you) when you placed any bets through the service your account will be suspended to prevent you placing any further bets or making any withdrawals from your account. We will then investigate the matter, including whether you have been betting as an agent for, or otherwise on behalf, of a person under 18 years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you). If having found that you: (a) are currently; (b) were under 18 years or below the majority age which applies to you at the relevant time; or (c) have been betting as an agent for or at the behest of a person under 18 years or below the majority age which applies:</p>
            <ul>
                <li>i. all winnings currently or due to be credited to your account will be retained;</li>
                <li>ii. all winnings gained from betting through the service whilst under age must be paid to us on demand (if you fail to comply with this provision we will seek to recover all costs associated with recovery of such sums); and/or</li>
                <li>iii. any monies deposited in your skyexchangeart.in account which are not winnings will be returned to you.</li>
            </ul>
            <p>This condition also applies to you if you are over the age of 18 years but you are placing your bets within a jurisdiction which specifies a higher age than 18 years for legal betting and you are below that legal minimum age in that jurisdiction.</p>
            <p>In the event we suspect you are in breach of the provisions of this Clause 15 or are attempting to rely on them for a fraudulent purpose, we reserve the right to take any action necessary in order to investigate the matter, including informing the relevant law enforcement agencies.</p>
            <p></p>
            <p></p>

            <p>15. <u>Anti Money-Laundering and KYC Policy</u></p>
            <p>It is the policy of lucky7 to actively prevent the services of the firm being used to facilitate financial crime, money laundering and terrorist financing.</p>
            <p>Strict compliance with all applicable regulations will also protect the senior management and staff of the firm, as individuals, from the risks of breaches of the law, regulations, and supervisory requirements, and to preserve the reputation of the lucky7 against the damage that could be caused by being implicated in money laundering and terrorist financing activities.</p>
            <p>To achieve these objectives, it is the policy of this firm that:</p>
            <ul>
                <li>Every staff member shall meet their personal obligations as appropriate to their role and responsibilities;</li>
                <li>Commercial considerations cannot take precedence over lucky7 anti-money laundering commitment;</li>
                <li>The firm shall appoint a Money Laundering Reporting Officer/Nominated Officer (“MLRO”), and a deputy to cover in their absence, and they shall be afforded every assistance and cooperation by all members of staff in carrying out their duties and responsibilities. The deputy will be the Director Chief Executive Officer (CEO).</li>
            </ul>
            <p>lucky7 will strictly comply with all applicable AML/CFT rules and regulations with specific emphasis on:</p>
            <ul>
                <li>A culture of compliance to be adopted and promulgated throughout the firm towards the prevention of financial crime;</li>
                <li>A commitment to ensuring that customer’s identities will be satisfactorily verified at required thresholds;</li>
                <li>A commitment to “knowing its customer” appropriately - both at acceptance and throughout the business relationship - through taking appropriate steps to verify the customer’s identity and monitoring their use lucky7 services;</li>
                <li>A commitment to ensuring that staff are trained and made aware of the law and their obligations under it, and to establishing procedures to implement these requirements; and</li>
                <li>Recognition of the importance of staff promptly reporting their suspicions internally:</li>
            </ul>
            <p>At the heart of our policies, procedures and controls, and consistent with FATF Recommendation 1, is the risk-based approach. The risk-based approach means that we focus our resources on the areas of greatest risk.</p>
            <p>Our policies, procedures and internal controls are designed to ensure compliance with all applicable anti-money laundering and anti-terrorist financing regulations and regulatory guidelines and will be reviewed and updated on a regular basis to ensure appropriate policies, procedures and internal controls are in place to account for both changes in regulations and changes in our business. </p>
            <p></p>
            <p>15.1 <u>Money Laundering and Terrorism Financing</u></p>
            <p>Money Laundering means:</p>
            <p>a. The conversion or transfer of property, knowing that such property is derived from criminal activity or from an act of participation in such activity, for the purpose of concealing or disguising the illicit origin of the property or of assisting any person who is involved in the commission of such an activity to evade the legal consequences of that person's action;</p>
            <p>b. The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such an activity;</p>
            <p>c. The acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from an act of participation in such an activity;</p>
            <p>d. Participation in, association to commit, attempts to commit and aiding, abetting, facilitating and counselling the commission of any of the actions referred to in points (a), (b) and (c).</p>
            <p>Money laundering shall be regarded as such even where the activities which generated the property to be laundered were carried out in the territory of another Member State or in that of a third country.</p>
            <p>Terrorism financing means the provision or collection of funds, by any means, directly or indirectly, with the intention that they be used or in the knowledge that they are to be used, in full or in part, in order to  carry out any terrorist act.</p>
            <p></p>
            <p>15.2 <u>Organization of the AML/CFT function</u></p>
            <p>15.2.1 <u>Senior Management</u></p>
            <p>Senior Management are responsible for the overall compliance policy of lucky7 and ensuring adequate resources are provided for the proper training of staff and the implementing of risk systems. This includes computer software to assist in oversight. Senior management will receive and consider the monthly compliance reports sent by the MLRO and authorise changes based on the recommendations if required.</p>
            <p>Senior Management consists of the Board of Directors, the Executive Management Committee and the MLRO.</p>
            <p>15.2.2 <u>The Nominated Officer/Money Laundering Reporting Officer</u></p>
            <p>Responsible for receiving internal disclosures and making reports to the Gaming Control Board, Curacao.  First point of contact for all compliance issues from staff. Prepares monthly report for consideration of senior management and conducts risk assessments of compliance systems. Undertakes regular random analysis of transactions including assessment of documentary evidence provided by customers. Assists in making any necessary amendments to AML Policy in line with risk assessment. Ensures everyone is periodically informed of any changes in anti- money laundering or anti-terrorist financing legislation, policies and procedures, as well as current developments and changes in money laundering or terrorist activity financing schemes particular to their jobs. Reviews customer identification information to ensure that all the necessary information has been obtained. Establishes and implement the risk scoring matrix following regulatory guidance and for review and approval by Senior Management.</p>
            <p>15.2.3 <u>Staff</u></p>
            <p>Responsible for knowing the AML Compliance Policy and understanding responsibilities. Ensure company procedures are adhered to and obtaining all documentary evidence as outlined within the manual. Ensure that all suspicious and unusual activity is reported to the MLRO.</p>
            <p>15.3 <u>The Compliance Programme</u></p>
            <p>5.1  <u>The Money Laundering Reporting Officer (MLRO)</u></p>
            <p>lucky7 has appointed a MLRO with full responsibility for lucky7’s anti-money laundering compliance.</p>
            <p>The MLRO:</p>
            <ul>
                <li>Will monitor the day-to-day operation of lucky7 TRADING’s AML/CFT policies and respond to any reasonable request made by the law enforcement and/or the Gaming Control Board, Curacao.</li>
                <li>Has the authority to act independently in carrying out their responsibilities, which includes direct access to the Gaming Control Board, Curacao and appropriate law enforcement agencies, in order that any suspicious activity may be reported to the right quarter as soon as is practicable.</li>
                <li>Has the authority and the resources necessary to discharge their responsibilities effectively.</li>
                <li>Is from a senior level and has direct access to senior management and the board of directors.</li>
                <li>May choose to delegate certain duties to other employees, but wherever such a delegation is made, the MLRO retains ultimate responsibility for the implementation of the compliance regime.</li>
                <li>At least annually, the MLRO will issue a report (the MLRO Report) to the senior management of lucky7  on the operation and effectiveness of the money laundering controls. This report will cover improvements, remedial programmes, the outcome of any internal audit reviews of the AML/CFT processes, and other relevant items.</li>
            </ul>
            <p>15.4 <u>Compliance Policies and Procedures</u></p>
            <p>lucky7 has policies and procedures to assess the risks related to money laundering and terrorist financing. These policies and procedures are:</p>
            <ul>
                <li>Written and maintained by the MLRO under the supervision of senior management</li>
                <li>Approved by senior management</li>
                <li>Communicated, understood and adhered to by everyone dealing with customers or their transactions, including those who work in the areas relating to customer identification, record keeping, and reportable transactions, who need enough information to process and complete a transaction properly as well as to ascertain the identity of customers and keep records as required. </li>
                <li>Policies and procedures which incorporate the reporting, record keeping, customer identification, risk assessment and risk mitigation requirements applicable.</li>
            </ul>
            <p>Although directors and senior officers may not be involved in day-to-day compliance, they need to understand the statutory duties placed upon them, their staff and lucky7 itself.</p>
            <p>15.4.1 <u>The Risk-Based Approach</u></p>
            <p>In money laundering and terrorist financing, a risk-based approach covers the following:</p>
            <ul>
                <li>The risk assessment of customer relationships and business activities;</li>
                <li>The risk mitigation to implement controls to handle identified risks;</li>
                <li>Keeping customer identification, beneficial ownership and business relationship information up to date; and</li>
                <li>The ongoing monitoring of business relationships and transactions.</li>
            </ul>
            <p>Existing regulatory obligations, such as for customer identification, are a minimum baseline requirement. Where enhanced due diligence is appropriate, a principle of the risk-based approach is to focus resources where they are most needed to manage risks within our tolerance level.</p>
            <p>15.4.2 <u>Risk Mitigation</u></p>
            <p>Risk mitigation is implementing controls to limit the potential money laundering and terrorist financing risks identified in the risk assessment so as to stay within the risk tolerance level. When the risk assessment determines that risk is high for money laundering or terrorist financing, then we will develop risk mitigation strategies and apply them.</p>
            <p>In all situations, risk mitigation controls and measures include:</p>
            <ul>
                <li>Focussing on operations (products and services, customers and business relationships, geographic locations, and any other relevant factors) that are more vulnerable to abuse by money launderers and criminals;</li>
                <li>Informing senior management of compliance initiatives, identified compliance deficiencies, corrective action taken, and suspicious transaction reports filed;</li>
                <li>Providing for program continuity despite changes in management, employees or structure;</li>
                <li>Focussing on meeting all regulatory record keeping and reporting requirements, recommendations for anti-money laundering and anti-terrorist financing compliance and providing for timely updates in response to changes in requirements;</li>
                <li>Enabling the timely identification of reportable transactions and ensure accurate filing of required reports;</li>
                <li>Incorporating anti-money laundering and anti-terrorist financing compliance into job descriptions and performance evaluations of appropriate personnel; and</li>
                <li>Providing for adequate supervision and training of employees that handle currency transactions, complete reports, monitor for suspicious transactions, or engage in any other activity that forms part of the anti-money laundering and anti-terrorist financing program.</li>
                <li>Increasing awareness of high risk situations within all business lines;</li>
                <li>Increasing the frequency of ongoing monitoring of transactions or business relationships;</li>
                <li>Escalating the approval of the establishment of an account or relationship even if not otherwise required to do so;</li>
                <li>Increasing the levels of ongoing controls and reviews of relationships;</li>
                <li>Requesting high-risk customers provide additional, documented information regarding controls they have implemented to safeguard their operations from abuse by money launderers and terrorists;</li>
                <li>Verifying the identity of customers by reference to reliable, independent source documents, data or information;</li>
                <li>Preventing any transaction with a potential customer until identification and account opening information has been obtained;</li>
                <li>Implementing an appropriate process to approve all relationships identified as high-risk as part of the customer acceptance process or declining to do business with potential customers because they exceed lucky7’s risk tolerance level;</li>
                <li>Implementing a process to exit from an existing high-risk relationship which management sees as exceeding lucky7’s risk tolerance level.</li>
            </ul>
            <p>15.4.3 <u>Risk Assessment</u></p>
            <p>lucky7 is required to and will analyse potential threats and vulnerabilities to money laundering and terrorist financing to which the business may be exposed to in a risk assessment.</p>
            <p>The risk assessment will document and consider the following:</p>
            <ul>
                <li>Products, services and delivery channels</li>
                <li>Geographic locations and areas of operation </li>
                <li>Customers</li>
            </ul>
            <p>The risk assessment may identify high-risk situations for which risk mitigation controls and monitoring may be required. The risk assessment is not static and will change over time.</p>
            <p>When a customer is identified as high-risk, they are subject to more frequent ongoing monitoring and updating of customer identification information, as well as any other appropriate enhanced measures.</p>
            <p>lucky7 shall perform an initial risk assessment at the beginning of a new customer relationship and for existing customers on an ongoing basis.</p>
            <p>15.5 <u>Client and Business Risk</u></p>
            <p>15.5.1 <u>Products, Services and Delivery Channels</u></p>
            <p>lucky7 will identify products and services or combinations of them that may pose an elevated risk of money laundering or terrorist financing. For example, products and services that support the movement and conversion of assets into, through and out of the financial system pose a high risk. Certain services have also been identified by financial regulators, governmental authorities or other credible sources as being potentially high-risk for money laundering or terrorist financing.</p>
            <ul>
                <li>Wire transactions;</li>
                <li>Transactions involving third parties;</li>
                <li>Non-face-to-face business relationships.</li>
            </ul>
            <p>15.5.2 <u>Geographic Locations and Areas of Operation</u></p>
            <p>Certain geographic locations potentially pose an elevated risk for money laundering and terrorist financing. These have been described by the FATF and by other resources such as Transparency International. </p>
            <p>Clients from, or identified as linked to these countries will be regarded as high risk:</p>
            <ul>
                <li>Any country subject to sanctions, embargoes, or similar measures;</li>
                <li>Any country identified by credible sources as providing funding or support for terrorist activities, or that have designated terrorist organisations operating within their country;</li>
                <li>Any country known to be a tax haven, source of narcotics or other significant criminal activity;</li>
                <li>Any country identified by the Financial Action Task Force (FATF) as non-cooperative in the fight against money laundering or terrorist financing or subject to a FATF statement;</li>
                <li>Any country identified by credible sources as lacking appropriate money laundering or terrorist financing laws and regulations, or as having significant levels of corruption.</li>
            </ul>
            <p>lucky7  does not do business with or provide services to anyone in any country belonging to a list of select countries subject to comprehensive international sanctions.</p>
            <p></p>
            <p></p>
            <p></p>

            <p>15.5.3 <u>Customer Relationships</u></p>
            <p>The risk assessment requires to know your customers (“KYC”). This is not limited to identification or</p>
            <p>record keeping, but it is also about understanding the customers – including their activities,</p>
            <p>transaction patterns, and how they operate. </p>
            <p>Examples of the factors that will be considered are:</p>
            <ul>
                <li>How long we have known the customer and had a business relationship;</li>
                <li>Customers wanting to carry out large transactions;</li>
                <li>Customers with regulatory or enforcement issues;</li>
                <li>Customers with multiple online complaints;</li>
                <li>Customers whose identification is difficult to verify;</li>
                <li>Customers whose who are politically exposed. </li>
            </ul>
            <p>Each customer is risk-rated as either posing a low, medium or high risk of money laundering and/or</p>
            <p>terrorist financing. The rating given at the beginning of the business relationship may be adjusted</p>
            <p>after the re-assessment and may change over time.  The level of customer due diligence will vary</p>
            <p>depending on the risk rating of the customer at the beginning of the relationship and as it changes.</p>
            <p>For all high-risk players, enhanced due diligence is applied right away.</p>

            <p>15.5.4 <u>Regulatory risk</u></p>
            <p>Regulatory risk means not meeting our obligations under applicable legislation. Examples of breaches of regulatory obligations include:</p>

            <p>Customer identification not done properly;</p>
            <ul>
                <li>Failure to train staff adequately;</li>
                <li>Bot having an anti-money laundering and anti-terrorist financing (AML/CFT) program;</li>
                <li>Failure to report suspicious transactions;</li>
                <li>Not having an MLRO;</li>
                <li>Failing to keep complete customer records.</li>
            </ul>
            <p>15.5 <u>Compliance Training</u></p>
            <p>lucky7 has a training program for all relevant employees and other individuals who act on behalf of the Company.  The goal of this training is to make sure that those who have contact with customers, who see customer transaction activity or who handle funds in any way understand the reporting, customer identification and record keeping requirements.</p>
            <p>All new employees of lucky7 are required to complete anti-money laundering and terrorist financing compliance training within their first three months of employment, and for customer-facing staff training is delivered prior to dealing with customers.</p>
            <p>Training is currently conducted through a customised course.  The training program is delivered electronically (via a learning management system). The training program is reviewed and updated by the MLRO to reflect requirements as they change.  The compliance training includes a test which everyone must pass in order to consider their training complete.</p>
            <p>To ensure employee training is kept up to date, all existing employees will receive follow up training on new and existing AML and regulatory requirements on a regular basis (at least within one year of their last training). If the online training test results show that a staff member does not understanding the training material, lucky7 will ensure that the staff member receives specialised one-on-one training to understand the material.</p>
            <p>An employee log of assigned and completed training materials is kept by the MLRO and the Human Resources department for administrative purposes.  Retention of these records is for a period of five years.</p>
            <p>Compliance training is adjusted in accordance to the employee’s role within the company. The MLRO will review functions and arrange to provide suitable and customised training.</p>
            <p>Our training will include at a minimum:</p>
            <ul>
                <li>An understanding of the reporting, customer identification and record keeping requirements as well as penalties for not meeting those requirements;</li>
                <li>Making all employees aware of the internal policies and procedures for deterring and detecting money laundering and terrorist financing that are associated with their jobs;</li>
                <li>Delivering to employees and suppliers a clear understanding of their responsibilities under these policies and procedures;</li>
                <li>All those who have contact with customers, who see customer transaction activity, who handle cash or funds in any way or who are responsible for implementing or overseeing the compliance regime must understand the reporting, customer identification and record keeping requirements.</li>
                <li>Making all employees aware of how lucky7 is vulnerable to abuse by criminals laundering the proceeds of crime or by terrorists financing their activities;</li>
                <li>Making all employees and agents aware that they cannot disclose that they have made a suspicious transaction report, or disclose the contents of such a report, with the intent to prejudice a criminal investigation, whether it has started or not;</li>
                <li>That all employees and agents understanding that no criminal or civil proceedings may be brought against them for making a report in good faith;</li>
                <li>Background information on money laundering so everyone who needs to can understand what money laundering is, why criminals choose to launder money and how the process usually works;</li>
                <li>Details of what terrorist financing is and how that process usually works.</li>
            </ul>
            <p>The MLRO is responsible for ensuring that everyone is informed of changes in anti-money laundering or anti-terrorist financing legislation, policies and procedures, and current developments in money laundering or terrorist activity financing schemes particularly relevant to their jobs.</p>
            <p>Certain employees, such as those in compliance, customer services and operations, require types of specialised additional training which will be provided either through external services or internally.  The training program is reviewed and updated to reflect changes in requirements.</p>
            <p>15.6. <u>Minimum standards – KYC POLICY</u></p>
            <p>lucky7 has established standards regarding Know-Your-Customer (“KYC”). These standards require due diligence on each prospective customer before entering into a business relationship via identification and verification of his identity and, as the case may be, his representatives and beneficial owners on the basis of documents, data or information obtained from a reliable and independent source compliant with the domestic and European AML/CFT legislation and regulation.</p>
            <p>Interpretation of the KYC principle begins with identification of the customer by means of the necessary identification documents.</p>
            <p>That identification, completed by other information gathered, enables the Customer Acceptance Policy to be applied.</p>
            <p>In addition to these objective criteria, there are subjective elements which may arouse suspicions regarding a customer and to which particular attention should be paid.</p>
            <p>Finally, as KYC does not involve static data, but dynamic data through the relationship with the customer, it also needs follow-up and ongoing monitoring of the customer.</p>
            <p>15.6.1. <u>Customer identification and verification (KYC)</u></p>
            <p>The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy.</p>
            <p>This identification relies on the following fundamental principles :</p>
            <ul>
                <li>Each customer (= each individual person and/or each person involved in the case of a legal entity) must be identified by means of original supporting documents.</li>
                <li>These documents will be recorded in a centralised system.</li>
                <li>The identification must be completed by "face-to-face" contact.</li>
                <li>Distance identification is also authorised and possible within a dedicated acceptance process, but limits the opportunity to carry out certain transactions or to access certain products.</li>
                <li>Each person identified must be registered by IT means.</li>
                <li>A person will not be accepted as a customer if the identification process proves to be incomplete.</li>
            </ul>
            <p>The specific case of the due diligence exercised on the acceptance of politically exposed persons (PEP).</p>
            <p>Concrete application at lucky7 is reflected by a specific identification procedure for customers referenced as PEP, whatever their place of residence.</p>
            <p>15.6.2. <u>Risk Profile calculation</u></p>
            <p>To assist in determining the level of AML/CFT due diligence to be exercised with regard to the Customer, a “Compliance” risk profile is calculated first of all on entry into relations (Low, Medium, High), and is then recalculated daily.</p>
            <p>15.6.3. <u>Customer acceptance policy</u></p>
            <p>15.6.3.1. <u>Customer Identification</u></p>
            <p>Client Identification is intended to confirm the existence and identify the individual with which lucky7 has a business relationship and obtain supporting identity information.  This includes measures to:</p>
            <ul>
                <li>Confirm the existence of the individual through identification documents;</li>
                <li>Conduct due diligence into the identity of the customer including:</li>
                <li>Cross-referencing customer names against government watch lists;</li>
                <li>Determining whether the customers are politically exposed persons;</li>
                <li>Determining whether any third parties are involved;</li>
                <li>Reviewing relevant publicly available information on the customer (e.g. adverse media, social media);</li>
                <li>Reviewing their background, reputation;</li>
                <li>Keep records of all information and documents obtained.</li>
            </ul>
            <p>15.6.3.2. <u>Identifying Information for Customers</u></p>
            <p>This section sets out the standard identification requirements for individuals who have entered into a business relationship with lucky7. This outline is likely to be sufficient for most situations. If, however, the customer is assessed as presenting a higher money laundering or terrorist financing risk, in line with our identified risk matrix, then lucky7 requires additional identity information to be provided and increase the level or verification accordingly.</p>
            <p>Where the result of the standard verification check gives rise to concern or uncertainty over identity, the number of matches that will be required to be reasonably satisfied as to the individual’s identity will increase.  Any concerns must be reported to the MLRO.</p>
            <p>If a customer is unable to produce a primary ID, the reasons for this should be noted and they may not be able to maintain an account.</p>
            <p>All documentary evidence must be recent.</p>
            <ul>
                <li>Passports and drivers’ licenses should be valid and not expired</li>
                <li>Utility bills should be dated within the last 3 months</li>
            </ul>
            <p>In terms of beneficial ownership, we will ask every customer whether they are acting in their own capacity or on behalf of another person. If they are acting for another person then we will require details of such.</p>
            <p>Sources of evidence</p>
            <p>Proof of Identity - Acceptable photo identity:</p>
            <ul>
                <li>Valid passport; or</li>
                <li>Valid photo card driving licence (full or provisional); or</li>
                <li>National identity card (non-UK nationals issued by EEA member states and Switzerland); or</li>
                <li>Identity card issued by the Electoral Office for Northern Ireland</li>
            </ul>
            <p>Proof of Identity - Acceptable non-photo evidence of identity:</p>
            <p>Documents issued by a government department, incorporating the person’s name and residential address or their date of birth. E.g. (for proof of address or date of birth):</p>
            <ul>
                <li>Instrument of a court appointment (such as a grant of probate, bankruptcy); or</li>
                <li>Current council tax demand letter or statement; or</li>
                <li>Current (within the last 3 months) bank statements, or credit/debit card statements issued by a regulated financial sector firm in the UK, EU or equivalent jurisdiction; or</li>
                <li>A recent (last available) utility bill (gas, water, electricity, telephone); it must be a bill or statement of account (not correspondence); statements printed off the internet are not acceptable on their own (a secondary check will be required such as a reference to a telephone directory including an online telephone directory); or</li>
                <li>Valid photo card driving licence (full or provisional); or</li>
                <li>A solicitor’s letter confirming recent house purchase or land previous address.</li>
            </ul>
            <p>When accepting evidence of identity from a customer, it is important that we make sufficient checks on the evidence provided to satisfy us of the customer’s identity, and that we keep a record of the checks made. This will be done by the MLRO or under the supervision of the MLRO.</p>
            <p>Usual checks on photo ID may include:</p>
            <p>Likeness against the customer</p>
            <ul>
                <li>Does the date of birth on the evidence match the apparent age of the customer?</li>
                <li>Is the ID valid?</li>
                <li>Is the spelling of names the same as other documents provided by the customer?</li>
            </ul>
            <p>Checks on secondary evidence of ID may include:</p>
            <ul>
                <li>Do the addresses match the address given on the photo ID?</li>
                <li>Does the name of the customer match with the name on the photo ID?</li>
            </ul>
            <p>15.6.3.3. <u>Identity Verification Triggers</u></p>
            <p>Verification of the identity of the customer occurs either when the customer has reached the €2,000 threshold or if the player is considered to be a high-risk player upon registration. The main trigger for Identity Verification is considered a single transaction (or smaller, linked transactions) that reach or exceed €2,000 either at deposit or withdrawal stage. For example, transactions will be considered as linked when they are carried out by the same customer during a single period of being logged on to the lucky7 website or if they are carried out in 48 hours.</p>
            <p>In all cases, the customer will receive a notification to upload identity verification documents listed above.  Each document that is provided by customers to lucky7 is checked manually by dedicated staff.</p>
            <p>Further events that may trigger Identity Verification processes:</p>
            <ul>
                <li>When a customer requests a withdrawal to a different account than one that was used to make a deposit (e.g. due to the fact that certain payment service providers do not support incoming payments or other reasons). In these cases, the withdrawal requests will be immediately deducted from the customer’s account, however, will not be processed until the customer’s identity has been verified to ensure that the funds are going to the right person;</li>
                <li>When a customer makes multiple deposits within a short timeframe (five or more deposits in one hour);</li>
                <li>Staff identifies suspicious deposit patterns and or several payment cards are used (three or more different payment methods used within one week);</li>
                <li>Registration country and IP do not match – In such cases, the player account may not be blocked and the player may still make deposits and play games, however, no withdrawal will be allowed until verification has been carried out;</li>
                <li>When a deposit exceeds €5,000.</li>
            </ul>
            <p>Copies will be kept of all the documents used for identification.</p>
            <p>15.6.3.4. <u>Additional Documents</u></p>
            <p>Additional documents may be requested from customers to substantiate their activities or to better understand their patters:</p>
            <ul>
                <li>Proof of Bank Account – This may be a screenshot or copy of the bank statement stating the customer’s name and IBAN and the name of the bank (for instances when a customer requests a withdrawal to be deposited to a bank account);</li>
                <li>Proof of Payment Card – This may be a photo or copy of the front side of the card in question or a bank statement that will include both the card details and the name of the customer (for instances when suspicious deposit patters are identified) –</li>
                <li>To protect the customer’s card data, lucky7 informs them that they may obfuscate some of the card details, however, the customer’s name, as well as the first and last four digits of the card number must be visible.</li>
                <li>Bank Statements and Payslips – These may be requested from customers displaying potentially problematic gambling patterns and when external information available on the customer does not validate the amounts being gambled.  In such cases lucky7 would ask its higher volume customers for documents which would support the funds being spent on gambling.</li>
            </ul>
            <p>15.6.3.5.<u>Mitigation of Impersonation Risk</u></p>
            <p>Various additional customer identity checks are permitted on a risk-based approach. These checks may include:</p>
            <ul>
                <li>Requiring the first payment to be carried out through an account in the customer’s name with an EU/EEA regulated credit institution or one from an equivalent jurisdiction;</li>
                <li>Verifying additional aspects of the customer’s identity, or of their electronic ‘footprint’;</li>
                <li>Telephone contact with the customer prior to opening the account on a home or business number which has been verified (electronically or otherwise) or a call to the customer before further transactions are permitted, using it to verify additional aspects of personal identity information that have been previously provided during the setting up of the account;</li>
            </ul>
            <p>15.6.3.6. <u>Keeping Information Up-to-Date</u></p>
            <p>Once the identity of a customer has been confirmed, it does not have to be confirmed again. However, if there is any doubt about the information held, then that identification will be obtained again, including the identification of the customer.</p>
            <p>lucky7 may review the customer documentation and then update it as appropriate.  Any changes to identification such as a change of name of the customer would require new documents to be obtained.</p>
            <p>15.6.4. <u>Ongoing transaction monitoring</u></p>
            <p>There are certain automated controls in place to monitor customer activities, for example:</p>
            <ul>
                <li>First time deposits;</li>
                <li>Cash-out alerts for cash-outs greater than €1,000;</li>
                <li>Activity alerts when customer pays to, or stakes with the casino €2,000 during any period of 24 hours;</li>
                <li>Credit Card BIN alerts (when Credit Card country differs from player account country/IP);</li>
            </ul>
            <p>In addition, all cash-out requests are processed manually by the Payments Team and are reviewed on a daily basis.  Every request undergoes security checks before approval in order to determine that the origin of the funds/wins is compliant with the Gaming Control Board Curacao Policy and the terms and conditions of the Company.</p>
            <p>15.7. <u>Organization of internal control</u></p>
            <p>Any member of lucky7 can report suspicious activities to the MLRO, as lucky7’s Nominated Officer. A suspicious activity is one where it is known, or suspected, there are reasonably grounds to know or suspect that a person is engaged in, or attempting, money laundering or terrorist financing.</p>

            <p>15.7.1. <u>Suspicious transactions reporting</u></p>
            <p>Having knowledge means knowing something to be true. Knowledge can be inferred from the surrounding circumstances; so, for example, a failure to ask obvious questions may imply  knowledge.  The knowledge must, however, have come to lucky7 during the course of business, or from a disclosure (to the MLRO).</p>
            <p>Suspicion is more subjective and falls short of proof based on firm evidence. Suspicion is more than speculation and is based on some foundation.</p>
            <p>An unusual transaction is not necessarily suspicious. Unusual patterns of gambling, including the spending of particularly large amounts of money in relation to the casino or customer’s profile, should receive attention, but unusual behaviour should not necessarily lead to grounds for knowledge or suspicion of money laundering, or the making of a report to the FIAU. The MLRO is required to assess all of the circumstances and, in some cases, it may be helpful to ask the customer or others more questions. The choice depends on what is already known about the customer and the transaction, and how easy it is to make enquiries.</p>
            <p>Members of staff who consider a transaction or activity to be suspicious, would not necessarily be expected to know or to establish the exact nature of any underlying criminal offence, or that the particular funds or property were those arising from a crime, money laundering or terrorist financing. “Reasonable grounds to know or suspect” introduces an objective test of suspicion. The test is likely to be met when there are facts or circumstances, known to the member of staff, from which a reasonable person (in a payments firm) would have inferred knowledge, or formed the suspicion, that another person was engaged in money laundering or terrorist financing.</p>
            <p>A defence of failing to meet the test of suspicion, would be for staff to demonstrate that they took reasonable steps to know the customer and the rationale for the transaction, activity or instruction.</p>
            <p>15.7.2 <u>How to Make a Suspicious Activity Report</u></p>
            <p>Any member of staff, who is suspicious that a transaction may involve money laundering or who becomes aware during the course of their work that someone else is involved in money laundering, must make a disclosure to the MLRO using the lucky7 Suspicious Transaction Report form.</p>
            <p>Once completed, the form should be printed off. The member of staff making the report must sign and date the form before giving it to the MLRO.  The MLRO will take note of the date and time of report receipt.</p>
            <p>Upon receipt of the form by the MLRO, they will then decide what is to be done as a result of the report, e.g., whether the matter must be reported to Gaming Control board, Curacao. or not, or further enquiries made and record its decision and the reason for it in the customer files. The member of staff concerned must be informed of the decision and the reasons for it.</p>
            <p>If the matter is to be referred to GCB, the MLRO or a subordinate will complete the FIAU report form and discussing with the reporting member of staff how matters with the customer are to be conducted from that stage. The GCB report form must be signed off by the MLRO or the CEO.</p>
            <p>lucky7 must not proceed with a transaction whilst we await consent from the concerned from GCB it is given 7 working days (not including weekends or bank holidays) to consider the report.</p>
            <p>If we hear nothing then we may continue with the transaction but not inform the customer about the report. GCB may give consent to proceed earlier than this time limit. They may also refuse consent in which case they have a further 31 calendar days (including weekends or bank holidays) to further consider the report. After this they must either begin proceedings or allow the transaction to continue.</p>
            <p>The MLRO will submit a softcopy version of the report to the GCB. Any paper file for each matter will be kept by the MLRO.</p>
            <p>There must be no record on the customer file or on the computer system which refers in any way to suspicious activity reporting, money laundering, etc., to avoid the risk of tipping off. It is a criminal offence to inform a customer that a SAR has been submitted, or to inform them of an investigation into their affairs.</p>
            <p>All records of SARs will be kept in the central reporting file, which is kept by the MLRO.</p>
            <p>15.7.3    <u>How to Identify Suspicious Activity</u></p>
            <p>Transactions may give reasonable grounds to suspect that they are related to money laundering or terrorist activity financing regardless of the sum of money involved. There is no monetary threshold for making a SAR. Suspicious transactions may involve several factors that on their own seem insignificant, but together raise suspicion that the transaction is related to the commission or attempted commission of a money laundering or terrorist activity financing offence, or both. As a general guide, a transaction may be connected to money laundering or terrorist activity financing when we think that it (or a group of transactions) raises questions or gives rise to discomfort, apprehension or mistrust.</p>
            <p>lucky7 will examine, as far as reasonably possible, the background and purpose of all complex, unusual large transactions, and all unusual patterns of transactions, which have no apparent economic or lawful purpose.</p>
            <p>The context in which the transaction occurs or is attempted is a significant factor in assessing suspicion and this will vary from one customer to another.</p>
            <p>Transactions will be evaluated in terms of what seems appropriate and is within normal practices in business and based on our knowledge of the customer. The fact that transactions do not appear to be in keeping with normal industry practices may be a relevant factor.</p>
            <p>Reasonable evaluation of relevant factors, including the knowledge of the customer's business, financial history, background and behaviour is the basis of assessing suspicion. Behaviour is suspicious, not people. We have listed below some indicators to help with this assessment.</p>
            <p>15.7.4 <u>Indicators of Suspicious Transactions</u></p>
            <p>Certain products, services, activities or channels may pose a higher risk of misuse for money laundering. Listed below are several anti-money laundering risk indicators or “red flags” that might be grounds for suspicion. The list is not exhaustive and not conclusive but will be used as a guide for inquiry and follow-up, alongside other material.</p>
            <p>A single indicator does not necessarily indicate reasonable grounds to suspect money laundering or terrorist financing activity. However, if several indicators are present during one or a series of transactions, then we will take a closer look at other factors before deciding whether the transaction must be reported.</p>
            <p>In the case of a transaction aborted in the belief that the property is owned or controlled by or on behalf of a terrorist or a terrorist group, then there must be a terrorist property report. If there are reasonable grounds to suspect that the transaction is related to an attempted commission of a terrorist activity financing or money laundering offence, then a suspicious transaction report about the attempted transaction is also required.</p>
            <p>Becoming aware of certain indicators could trigger reasonable grounds to suspect that one or more transactions from the past (that had not previously seemed suspicious) were related to money laundering or terrorist financing.</p>
            <p>15.7.4.1 <u>Application and Identification</u></p>
            <ul>
                <li>Information mismatch from application</li>
                <li>Application information/address/customer differs from initial registration</li>
                <li>Inability to provide government issued identification details</li>
                <li>Change of address to high-fraud area or to problematic jurisdiction, shortly after the application</li>
                <li>Lack of reliable third party and/or governmental verification of individual</li>
                <li>The address indicated (or corroborated) is identified as mail drop or other high-risk address, as opposed to a physical street address</li>
                <li>Transaction volume, deposits, and cash-outs are inconsistent</li>
            </ul>
            <p>15.7.4.2 <u>Account Settlement</u></p>
            <ul>
                <li>Large, cross-border wire transfer payments</li>
                <li>Settlements/partial settlements from unrelated third parties</li>
                <li>Excessive/ongoing large credit refunds</li>
            </ul>
            <p>15.7.4.3 <u>Transaction and Customer Monitoring</u></p>
            <ul>
                <li>Stakes wagered by a customer become unusually high or out of the ordinary and the customer is believed to be spending beyond his or her known means. This requires some knowledge of the customer but, nevertheless, there may be circumstances that appear unusual and raise the suspicion that he is using money obtained unlawfully.</li>
                <li>It may be that the customer lives in low cost accommodation with no known source of income but nonetheless is spending money well above his or her apparent means.</li>
                <li>There is no set amount which dictates when a SAR should be made and much will depend on what is known, or suspected, about the customer.</li>
                <li>A customer exhibits unusual gambling patterns with an almost guaranteed return or very little financial risk.</li>
                <li>It is accepted that some customers prefer to gamble in this way but, in some instances, the actions may raise suspicion because they are different from the customer’s normal gambling practices.</li>
                <li>Money is deposited by a customer or held over a period and withdrawn by the customer without being used for gambling.</li>
                <li>For instance, suspicions should be raised by any large amounts deposited in gaming machines or gambling accounts that are then cashed or withdrawn after very little game play or gambling.</li>
                <li>A customer regularly gambles large amounts of money and appears to find a level of losses acceptable.</li>
                <li>In this instance, the customer may be spending the proceeds of crime and sees the losses as an acceptable consequence of the process of laundering those proceeds.</li>
                <li>A customers spend increases over a period of time, thereby masking high spend and potential money laundering.</li>
                <li>A customer spends little, but often, and his annual aggregate spend is high and out of kilter with his expected spend.</li>
                <li>A customer displays gambling patterns where spend is high but the risk is low, for example gambling on red and black in roulette. The customer could be laundering money in a way that guarantees minimal loss.</li>
                <li>Instances of high spend by customers that lead to significant commercial risk for the operator may also indicate suspicious activity.</li>
            </ul>
            <p>Indicators to help establish suspicion that a transaction may be related to a terrorist activity financing offence mostly resemble those relating to money laundering, although there are some differences. For example, amounts relating to terrorist financing generally may be smaller.</p>
            <p>15.7.4. <u>Financial Sanctions</u></p>
            <p>It is the policy of lucky7 to verify the identity of all customers, and to check that they are not the subject of sanctions or other statutory measures prohibiting the Company from providing its services. The member of staff conducting verification of identity will complete the process by checking that the customer is not the subject of sanctions or other statutory measures, using the screening methods set out by the MLRO.  These include the direct questions to the prospective customer, screening of all new customers against sanctions and other databases (via direct reference to an official list or using a commercially available product), and ongoing screening of all customer names (via ongoing subscription to commercially available products and automatic uploads).</p>
            <p>15.7.5. <u>Adverse Media</u></p>
            <p>Along with Sanctions and PEP databases, third-party providers offer a service that screens individuals for adverse media. lucky7 uses such a service to identify if a customer has been identified by the media as having links to financial crime, terrorist financing and/or other criminal activity.  These individuals, once confirmed to be one and the same in both media and among the lucky7  customers, would only be allowed to continue using lucky7 services after a close review by the MLRO and/or senior management.</p>
            <p>15.8. <u>Anti-Bribery and Whistleblowing</u></p>
            <p>As an extension to the AML/CFT efforts, lucky7 also employs Anti-Bribery and Whistleblowing Policies and Procedures.  These are standalone documents that are available to all employees and are part of their onboarding package.</p>
            <p>15.9. <u>Employee Background Checks</u></p>
            <p>lucky7 is committed to establish and maintain procedures which enable it to be satisfied as to the integrity of all new staff members and by extension their likeliness to follow the lucky7 policies and procedures. To meet this requirement, the Human Resource Department is responsible for undertaking all of the checks listed in relation to new members of staff and for retaining the appropriate records:</p>
            <ul>
                <li>Obtain and confirm references, if required;</li>
                <li>Confirm their employment history and the qualifications they have;</li>
                <li>Request a recent police conduct certificate; and</li>
                <li>Request details of any regulatory action taken against them.</li>
            </ul>
            <p>16. <u>Fraud</u></p>
            <p>We will seek criminal and contractual sanctions against any Customer involved in fraud, dishonesty or criminal acts. We will withhold payment to any Customer where any of these are suspected. The Customer shall indemnify and shall be liable to pay to us on demand, all costs, charges or losses sustained or incurred by us (including any direct, indirect or consequential losses, loss of profit, loss of business and loss of reputation) arising directly or indirectly from the Customer’s fraud, dishonesty or criminal act.</p>

            <p>17. <u>Responsible Gaming Policy</u></p>
            <p>lucky7 is committed to endorsing responsible wagering among its customers as well as promoting the awareness of problem gambling and improving prevention, intervention and treatment.</p>
            <p>lucky7’s Responsible Gambling Policy sets out its commitment to minimizing the negative effects of problem gambling and to promoting responsible gambling practices.</p>
            <p>lucky7 supports the generation of online gamblers offering them a wide range of games and entertainment. We also take responsibility for our product line-up.</p>
            <p>The aim of lucky7 is to provide the world’s safest and most innovative gaming platform for adults. The offered clear and safe products allow each user to play within his financial means and to receive the highest quality service.  Integrity, fairness and reliability are the guiding principles of lucky7’s work. It is therefore clear that lucky7 should do its best to avoid and reduce the problems, which can arise from participation in gambling, particularly in cases of immoderate playing. At the same time it is important to respect the rights of those who take part in games of chance to a reasonable extent as means of entertainment.</p>
            <p>Responsible Gaming at lucky7 is based on three fundamental principles: Security of the player, Security of the game and Protection against gaming addiction. Together with research institutes, associations and therapy institutions, we work on creation of a responsible, secure and reliable framework for online gaming.</p>
            <p>Player security</p>
            <p>We take responsibility for the security of our players. Protection of the players is based on forbidding the attendance of the minors from participation in games and the protection of privacy, which involves responsible processing of personal data and payments. Fairness and the random nature of the products offered are monitored closely by independent organizations. Marketing communication is also geared towards player protection: we promise only what players can receive in our transparent line.</p>
            <p>Protection against gaming addiction: research – prevention – intervention</p>
            <p>The majority of users who make sports bets, casino bets and other gaming offers play in moderation for entertainment. Certain habits and behavior patterns (such as shopping, playing sports, eating or consumption of alcohol) which are considered to be normal and not causing any concern can develop into addiction for some people and cause problems. In the same way, bets on sports and gambling can lead to problems for a small group of customers.</p>
            <p>Clients with gaming addiction are prohibited from further participation in the gaming line-up. Subsequently the customers are provided with contacts of organizations where they can receive professional advice and support.</p>
            <p>Self-responsibility is the most sustainable form of prevention</p>
            <p>The basic principle promoted by lucky7 is that the final decision and responsibility on whether to play or not, and how much money can be spent on the game should be assumed by the customer himself.</p>
            <p>Self-responsibility of the customer is therefore the most effective form of protection from addiction. lucky7 sees its responsibility in assisting the customers by providing transparent products, full information and keeping a clear line of conduct.</p>
            <p>Protection of minors</p>
            <p>lucky7 does not allow minors (persons under the age of 18) to participate in games and make bets. That’s why the confirmation of having reached the age of majority and the confirmation of date of birth are mandatory requirements during registration. lucky7 considers the issue of minors taking part in games and betting very seriously. In order to offer the best possible protection of minors, we also rely on the support of parents and caregivers. Please keep your data for account access in a safe place (user ID and password).</p>
            <p>Furthermore, we would recommend that you install filter software. This software will allow you to restrict the access to Internet resources inappropriate for children and teenagers.</p>
            <p>Responsibility towards problems</p>
            <p>lucky7 offers a variety of games and bets, which are forms of entertainment for the majority of customers. At the same time the company takes responsibility for its customers by providing support and tools for maintenance of a secure and entertaining environment taking into account the associated risks.</p>
            <p>The clients who have difficulty in assessing risks, recognizing their own limits or those who suffer from gambling addiction are not able to enjoy our product line-up responsibly and perceive it as a form of entertainment. lucky7 takes responsibility for such users by blocking their access to its products for their own protection.</p>
            <p>Get informed with the main issues</p>
            <p>Most people play for pleasure. Moderate participation in games within their financial capacity is fully acceptable. However, for a small percentage of people gambling is not a form of entertainment, it is a challenge that must be considered seriously.</p>
            <p>What is the problematic game behavior?</p>
            <p>A problematic game behavior is considered to be such behavior, which interferes mode of life, work, financial position or health of a person or his family. Long participation in games is counter indicative to such person as it can lead to negative consequences.</p>
            <p>In 1980 the pathological game dependence has been officially recognized and enlisted in the list of psychological diseases of international classification system DSM-IV and ICD-10. It is defined as long, repeating and frequently amplifying inclination for game, despite of existing negative personal and social circumstances, such as a debt, rupture of family relations and delay of professional growth.</p>
            <p>In what cases behavior of a person should be considered as dependence?</p>
            <p>It is necessary to underline that the diagnoses of game dependence can be qualified only by experts. The material presented on this web-page will help you to estimate and define your own game behaviour.</p>
            <p>The special hazard of addictions that are not associated with any substance is that it is very difficult to define the line between pleasure and addiction. Nevertheless, there are some diagnostic signals that may point out the existing problems. In the presence of at least five of the following symptoms, the likelihood of the severe dependence is high:</p>
            <p>1. The player is deeply involved in gambling, all his thoughts are only about the game.</p>
            <p>2. Bet sum increases in course of time.</p>
            <p>3. Attempts to quit or control his participation in the games appear to be unsuccessful.</p>
            <p>4. When limiting his participation in gambling, a person experiences irritation and disappointment.</p>
            <p>5. The game is a way to escape from problems or discomfort.</p>
            <p>6. The player tries to win back the lost amount,</p>
            <p>7. Lies about his playing behavior,</p>
            <p>8. Commits illegal acts,</p>
            <p>9. Spoils or breaks the relationship with family and colleagues,</p>
            <p>10. Borrows to participate in the games.</p>
            <p>Rules for responsible games</p>
            <p>Following the rules placed below, you can enjoy the game without anxiety:</p>
            <p>1. Start playing only when you are calm and concentrated.</p>
            <p>2. Take regular breaks.</p>
            <p>3. Define for yourself beforehand the monthly amount you can spend on the game.</p>
            <p>4. Once setting a maximum limit, do not further increase it.</p>
            <p>5. Before you start playing, define the maximum amount of winning, after reaching of which you should stop playing.</p>
            <p>6. Define the amount you can afford to lose beforehand.</p>
            <p>7. Do not start playing under alcohol or drug influence.</p>
            <p>8. Do not start playing in a depressed state.</p>

            <p>18. <u>Intellectual Property</u></p>
            <p>We trade as lucky7 and the lucky7 name and logo are registered trademarks. Any unauthorised use of our trademark and logo may result in legal action being taken against you.</p>
            <p>The www.skyexchangeart.in  uniform resource locator (URL) is owned by us and no unauthorised use of the URL is permitted on another website or digital platform without our prior written consent.</p>
            <p>As between us and you, we are the sole owners of the rights in and to the Service, our technology, software and business systems (the “Systems”) as well as our odds.</p>
            <ul>
                <li>i. you must not use your personal profile for your own commercial gain (such as selling your status update to an advertiser); and</li>
                <li>ii. when selecting a nickname for your Account we reserve the right to remove or reclaim it if we believe it appropriate.</li>
            </ul>
            <p>You may not use our URL, trademarks, trade names and/or trade dress, logos (the “Marks”) and/or our odds in connection with any product or service that is not ours, that in any manner is likely to cause confusion among Customers or in the public or that in any manner disparages us.</p>
            <p>Except as expressly provided in these Terms, we and our licensors do not grant you any express or implied rights, licence, title or interest in or to the Systems or the Marks and all such rights, licence, title and interest specifically retained by us and our licensors. You agree not to use any automatic or manual device to monitor or copy web pages or content within the Service. Any unauthorised use or reproduction may result in legal action being taken against you.</p>
            <p>19. <u>Your Licence</u></p>
            <p>Subject to these terms and your compliance with them, we grant to you a non-exclusive, limited, non transferable and non sub-licensable licence to access and use the Service for your personal non-commercial purposes only. Our licence to you terminates if our agreement with you under these Terms ends.</p>
            <p>Save in respect of your own content, you may not under any circumstances modify, publish, transmit, transfer, sell, reproduce, upload, post, distribute, perform, display, create derivative works from, or in any other manner exploit, the service and/or any of the content thereon or the software contained therein, except as we expressly permit in these terms or otherwise on the website. No information or content on the service or made available to you in connection with the Service may be modified or altered, merged with other data or published in any form including for example screen or database scraping and any other activity intended to collect, store, reorganise or manipulate such information or content.</p>
            <p>Any non-compliance by you with this Clause may also be a violation of our or third parties’ intellectual property and other proprietary rights which may subject you to civil liability and/or criminal prosecution.</p>
            <p>20. <u>Your Conduct and Safety</u></p>
            <p>We would like you to enjoy the Service. However, for your protection and that of all Customers, the posting of any content on the service, as well as conduct in connection therewith and/or the service, which is in any way unlawful, inappropriate or undesirable is strictly prohibited - it is Prohibited Behaviour. If you engage in Prohibited Behaviour, or we determine in our sole discretion that you are engaging in Prohibited Behaviour, your skyexchangeart.in Account and/or your access to or use of the Service may be terminated immediately without notice to you.</p>
            <p>Legal action may be taken against you by another Customer, other third party, enforcement authorities and/or us with respect to you having engaged in Prohibited Behaviour.</p>
            <p>Prohibited Behaviour includes, but is not limited to, accessing or using the Service to:</p>
            <ul>
                <li>i. promote or share information that you know is false, misleading or unlawful;</li>
                <li>ii. conduct any unlawful or illegal activity, such as, but not limited to, any activity that furthers or promotes any criminal activity or enterprise, provides instructional information about making or buying weapons, violates another Customer’s or any other third party’s privacy or other rights or that creates or spreads computer viruses;</li>
                <li>iii. harm minors in any way;</li>
                <li>iv. transmit or make available any content that is unlawful, harmful, threatening, abusive, tortuous, defamatory, vulgar, obscene, lewd, violent, hateful, or racially or ethnically or otherwise objectionable;</li>
                <li>v. transmit or make available any content that the user does not have a right to make available under any law or contractual or fiduciary relationship, including without limitation, any content that infringes a third party’s copyright, trademark or other intellectual property and proprietary rights;</li>
                <li>vi. transmit or make available any content or material that contains any software virus or other computer or programming code (including HTML) designed to interrupt, destroy or alter the functionality of the Service, its presentation or any other website, computer software or hardware;</li>
                <li>vii. interfere with, disrupt or reverse engineer the Service in any manner, including, without limitation, intercepting, emulating or redirecting the communication protocols used by us, creating or using cheats, mods or hacks or any other software designed to modify the Service, or using any software that intercepts or collects information from or through the Service;</li>
                <li>viii. retrieve or index any information from the Service using any robot, spider or other automated mechanism;</li>
                <li>ix. participate in any activity or action that, in the sole and entire unfettered discretion of us results or may result in another Customer being defrauded or scammed;</li>
                <li>x. transmit or make available any unsolicited or unauthorised advertising or mass mailing such as, but not limited to, junk mail, instant messaging, "spim", "spam", chain letters, pyramid schemes or other forms of solicitations;</li>
                <li>xi. create skyexchangeart.in Accounts by automated means or under false or fraudulent pretences;</li>
                <li>xii. impersonate another Customer or any other third party, or</li>
                <li>xiii. any other act or thing done that we reasonably consider to be contrary to our business principles.</li>
            </ul>
            <p>The above list of Prohibited Behaviour is not exhaustive and may be modified by us at any time or from time to time. If you become aware of the misuse of the service by another Customer or any other person, please contact us through the “Contact Us” section of the Website. We reserve the right to investigate and to take all such actions as we in our sole discretion deems appropriate or necessary under the circumstances, including without limitation, deleting the Customer’s posting(s) from the Service and/or terminating their account, and take any action against any Customer or third party who directly or indirectly in, or knowingly permits any third party to directly or indirectly engage in, Prohibited Behaviour, with or without notice to such Customer or third party.</p>
            <p>21. <u>Links to Other Websites</u></p>
            <p>The Service may contain links to third party websites that are not maintained by, or related to, us, and over which we have no control. Links to such websites are provided solely as a convenience to Customers, and are in no way investigated, monitored or checked for accuracy or completeness by us. Links to such websites do not imply any endorsement by us of, and/or any affiliation with, the linked websites or their content or their owner(s). We have no control over or responsibility for the availability nor their accuracy, completeness, accessibility and usefulness. Accordingly when accessing such websites we recommend that you should take the usual precautions when visiting a new website including reviewing their privacy policy and terms of use.</p>

            <p>22. <u>Self-exclusion</u></p>
            <p>If you feel you are at risk of developing a gambling problem or believe you currently have a gambling problem, please consider using Self-Exclusion which prevents you gambling with lucky7 for a specified period of 6 months, 1 year, 2 years, 5 years or permanently.</p>
            <p>If you want to stop playing for other reasons, please consider a Time-Out or using Account Closure.</p>
            <p>What happens when you self-exclude?</p>
            <p>During a period of Self-Exclusion you will not be able to use your account for betting, although you will still be able to login and withdraw any remaining balance. It will not be possible to re-open your account for any reason, and lucky7 will do all it can to detect and close any new accounts you may open.</p>
            <p>Next steps</p>
            <p>Whilst we will remove you from our marketing databases, we also suggest that you remove bet365 from your notifications and delete/uninstall all lucky7 apps, downloads and social media links. You may also wish to consider installing software that blocks access to gambling websites, click here for more information.</p>
            <p>We recommend that you seek support from a problem gambling support service to help you deal with your problem.</p>
            <p>You can self-exclude your account in the My Gambling Controls section of Members by choosing Self-Exclusion.</p>
            <p>Alternatively you can contact our customer care team for assistance and further information.</p>
            <p>Self-Exclusion Notice</p>
            <p>Should you opt to self-exclude from lucky7, we strongly recommend that you seek exclusion from all other gambling operators you have an account with.</p>
            <p>You can self-exclude by contacting other gambling operators directly or you can exclude from other Northern Territory licensed operators by completing a Self-Exclusion Notice form.</p>
            <p>Once completed the Northern Territory Self-Exclusion Notice form should be submitted to the nominated site, sports bookmaker or betting exchange operator.</p>

            <p>23. <u>Complaints</u></p>
            <p>If you have any concerns or questions regarding these terms you should contact our Customer Service Department via email at support@skyexchangeart.in</p>
            <p>NOTWITHSTANDING THE FOREGOING, WE TAKE NO LIABILITY WHATSOEVER TO YOU OR TO ANY THIRD PARTY WHEN RESPONDING TO ANY COMPLAINT THAT WE RECEIVED OR TOOK ACTION IN CONNECTION THEREWITH.</p>
            <p>Any Customer of the Service who has any concerns or questions regarding these Terms regarding the settlement of any skyexchangeart.in market should contact our Customer Service Department at   support@skyexchangeart.in using their Registered Email Address.</p>
            <p>If a Customer is not satisfied with how a bet has been settled then the Customer should provide details of their grievance to our Customer Service Department via email at support@skyexchangeart.in. We shall use our reasonable endeavours to respond to queries of this nature within a few days (and in any event we intend to respond to all such queries within 28 days of receipt).</p>
            <p>Disputes must be lodged within three (3) days from the date the wager in question has been decided. No claims will be honored after this period. The Customer is solely responsible for their Account transactions. Complaints/disputes have to be sent to support@skyexchangeart.in and must be sent from the Customer’s Registered Email Address.</p>
            <p>In the event of a dispute arising between you and us our Customer Service Department will attempt to reach an agreed solution. Should our Customer Service Department be unable to reach an agreed solution with you, the matter will be escalated to our management in accordance with our Complaints Procedure (available upon request).</p>
            <p>The Customer has the right to lodge a complaint with one of our licensing bodies should all efforts to resolve a dispute to the Customer’s satisfaction have failed.</p>
            <p>24. <u>Registration and Account Security</u></p>
            <p>Customers of the service must provide their real names and information and, in order to comply with this, all Customers must commit to the following rules when registering & maintaining your Account:</p>
            <ul>
                <li>i. you must not provide any false personal information on the Service, or create an Account for anyone other than yourself;</li>
                <li>ii. you must not use your personal profile for your own commercial gain (such as selling your status update to an advertiser); and</li>
                <li>iii. when selecting a nickname for your Account we reserve the right to remove or reclaim it if we believe appropriate.</li>
            </ul>
            <p>25. <u>Assignment</u></p>
            <p>Neither these terms nor any of the rights or obligations hereunder may be assigned by you without the prior written consent of us, which consent will not be unreasonably withheld. We may, without your consent, assign all or any portion of our rights and obligations hereunder to any third party provided such third party is able to provide a service of substantially similar quality to the Service by posting written notice to this effect on the Service.</p>
            <p>26. <u>Severability</u></p>
            <p>In the event that any provision of these terms is deemed by any competent authority to be unenforceable or invalid, the relevant provision shall be modified to allow it to be enforced in line with the intention of the original text to the fullest extent permitted by applicable law. The validity and enforceability of the remaining provisions of these terms shall not be affected.</p>
            <p></p>
            <p>27. <u>Breach of These Terms</u></p>
            <p>Without limiting our other remedies, we may suspend or terminate your account and refuse to continue to provide you with the service, in either case without giving you prior notice, if, in our reasonable opinion, you breach any material term of these Terms. Notice of any such action taken will, however, be promptly provided to you.</p>
            <p>28. <u>Governing Law and Jurisdiction</u></p>

            <p>This Agreement shall in all respects be governed, interpreted by, and construed in accordance with the laws of Curacao. All disputes, differences, complaints etc., shall be subject to Arbitration under the Curacao. The arbitrator will be appointed by the company after due consent from the company and the user. The place of arbitration shall be Curacao.</p>

            <p>29. <u>General Provisions</u></p>
            <p><u>Term of agreement.</u> These terms shall remain in full force and effect while you access or use the service or are a Customer of skyexchangeart.in. These terms will survive the termination of your skyexchangeart.in Account for any reason.</p>
            <p><u>Gender.</u> Words importing the singular number shall include the plural and vice versa, words importing the masculine gender shall include the feminine and neuter genders and vice versa and words importing persons shall include individuals, partnerships, associations, trusts, unincorporated organisations and corporations.</p>
            <p><u>Waiver.</u> No waiver by us, whether by conduct or otherwise, of a breach or threatened breach by you of any term or condition of these Terms shall be effective against, or binding upon, us unless made in writing and duly signed by us, and, unless otherwise provided in the written waiver, shall be limited to the specific breach waived. The failure of us to enforce at any time any term or condition of these Terms shall not be construed to be a waiver of such provision or of the right of us to enforce such provision at any other time.</p>
            <p><u>Headings.</u> The division of these Terms into paragraphs and sub-paragraphs and the insertion of headings are for convenience of reference only, and shall not affect or be utilised in the construction or interpretation of these Terms agreement. The terms "these Terms", "hereof", “hereunder” and similar expressions refer to these Terms and not to any particular paragraph or sub-paragraph or other portion hereof and include any agreement supplemental hereto. Unless the subject matter or context is inconsistent therewith, references herein to paragraphs and sub-paragraphs are to paragraphs and sub-paragraphs of these Terms.</p>
            <p><u>Acknowledgement.</u> By hereafter accessing or using the Service, you acknowledge having read, understood and agreed to each and every paragraph of these Terms. As a result, you hereby irrevocably waive any future argument, claim, demand or proceeding to the contrary of anything contained in these Terms.</p>
            <p><u>Language.</u> In the event of there being a discrepancy between the English language version of these rules and any other language version, the English language version will be deemed to be correct.</p>

            <p><u>Entire agreement.</u> These Terms constitute the entire agreement between you and us with respect to your access to and use of the Service, and supersedes all other prior agreements and communications, whether oral or written with respect to the subject matter hereof.</p>
            <p><u>Betting Rules</u></p>
            <p>Any dispute related to the sports betting product shall be emailed to: support@skyexchangeart.in</p>
            <p><u>Casino Rules</u></p>
            <p>Any dispute related to the casino product shall be emailed to: support@skyexchangeart.in</p>
            <p>Complete casino rules can be accessed from within the casino games.</p>
            <p><u>ACCEPTING THE TERMS AND CONDITIONS</u></p>
            <p>You hereby accept the fact that you have read, understood and are willing to abide by the above Terms and Conditions.</p>

            <p>30. <u>Multiple Account & Bet Limit Rule Acceptance</u></p>
            <p>Members are not permitted to hold multiple accounts. This includes holding an account with any associated site operating on the same platform as this site.</p>
            <p>Where maximum bet or maximum market limits are imposed, the Site reserves to the right to void bets from accounts found to be exceeding these limits by using multiple accounts across this and any other associated sites. </p>

        </div>
    }
    if (props.title == "KYC") {
        return <div >
            <h3 align="center">skyexchangeart.in</h3>
            <h3>KNOW YOUR CUSTOMER POLICY</h3>
            <p>To maintain the highest level of security, we require all our members to provide us with certain documentation in order to validate their accounts.</p>
            <p>Please note that the identification procedures shall be done before a cardholder starts operating and using services of our merchants.</p>

            <h3>Why do I need to provide documentation?</h3>
            <p>There are several reasons:</p>
            <p>We are committed to providing a socially responsible platform for online gaming. All of our members must be 18 or older and we are bound by our licensing agreement to verify this.</p>
            <p>Secondly, as a respected online and global company it is in our interests to guarantee maximum security on all transactions.</p>
            <p>Thirdly, our payment processors require that our policies are in line with international banking standards. A proven business relationship with each and every member is mandatory for the protection of all parties. Our licensing agreement also obliges us to comply with this.</p>
            <p>Finally, by ensuring that your account details are absolutely correct, the inconvenience of 'missing payments' can be avoided. It can take weeks (and sometimes months) to trace, recall and resend using the correct information. This lengthy process also results in additional fees from our processors.</p>

            <h3>WHAT DOCUMENTS DO I NEED TO PROVIDE?</h3>
            <p>PROOF OF ID:</p>
            <p>A color copy of a valid government issued form of ID (Driver's License, Passport, State ID or Military ID)</p>

            <h3>PROOF OF ADDRESS:</h3>
            <p>A copy of a recent utility bill showing your address</p>
            <p>Note: If your government Id shows your address, you do not need to provide further proof of address.</p>
            <p>Additional documentation might be required depending on the withdrawal method you choose</p>

            <h3>When do I need to provide these documents?</h3>
            <p>We greatly appreciate your cooperation in providing these at your earliest possible convenience to avoid any delays in processing your transactions. We must be in receipt of the documents before any cash transactions can be sent back to you. Under special circumstances we may require the documents before further activity (deposits and wagering) can take place on your account</p>
            <p>Please understand, if we do not have the required documents on file, your pending withdrawals will be cancelled and credited back to your account. You will be notified when this happens via the notification system.</p>

            <h3>How can I send you these documents?</h3>
            <p>Please scan your documents, or take a high quality digital camera picture, save the images as jpegs, then upload the files using our secure form <a href="https://www.playersonly.ag/ctr/acctmgt/pl/rui/uploadDocuments.nctr">here</a>.</p>

            <h3>How do I know my documents are safe with you?</h3>
            <p>The security of your documentation is of paramount importance. All files are protected with the highest level of encryption at every step of the review process. All documentation received is treated with the utmost respect and confidentiality.</p>
            <p>We’d like to thank you for your cooperation in helping us make skyexchangeart.in a safer place to play. As always, if you have any questions about this policy, or anything else, don’t hesitate to contact us using the contact us links on this page.</p>

        </div>
    }
    if (props.title == "Privacy Policy") {
        return <div >
            <h3>1. PRIVACY</h3>
            <p>lucky7 is committed to protecting your personal information. This Privacy Policy lets you know what information we collect when you use our services, why we collect this information and how we use the collected information.</p>
            <p>Please note that this Privacy Policy will be agreed between you and lucky7. (‘We’, ‘Us’ or ‘Our’, as appropriate). This Privacy Policy is an integrated part of lucky7’s Terms and Conditions.</p>
            <p>We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our platforms. We recommend that you revisit this Privacy Policy regularly.</p>

            <h3>2.  INFORMATION COLLECTED</h3>
            <p>We consider information that may be used to identify an individual, including, but not limited to, first and last name, date of birth, home or other physical address, email address, phone number or other relevant information to be Personal Information (‘Personal Information’). You may be asked to provide Personal Information when you use our website, register for an account or use our services. The Personal Information that we collect may include information such as: contact information (including telephone number), shipping information, billing information, transaction history, website usage preferences, and feedback regarding the Services. This information is held by us on servers based in various location from time to time and elsewhere from time to time. When you interact with the services, our servers keep an activity log unique to you that collects certain administrative and traffic information including: source IP address, time of access, date of access, web page(s) visited, language use, software crash reports and type of browser used. This information is essential for the provision and quality of our services. We do not collect Personal Information about you without your knowledge.</p>

            <h3>3. MEANS OF COLLECTING AND PROCESSING DATA</h3>
            <p>We may automatically collect certain data as discussed above and receive Personal Information about you where you provide such information through the services or other communications and interactions on the lucky7 site. We may also receive Personal Information from online vendors and service providers, and from customer lists lawfully acquired from third-party vendors. In addition, we may engage the services of third-party service providers to provide technical support process your online transactions and maintain your account. We will have access to any information you provide to such vendors, service providers and third-party e-commerce services, and we will use the Personal Information as set out in this Privacy Policy below. This information will only be disclosed to third parties outside the company in accordance with this Privacy Policy. We take steps to ensure that our arrangements with third-party service providers and online vendors protect your privacy.</p>

            <h3>4. INFORMATION USE</h3>
            <p>We use the Personal Information we collect from you to deliver our Services, to provide customer support, to undertake necessary security and identify verification checks, to process any of your online transactions, to assist your participation in third-party promotions, meet certain business requirements and for any other purpose related to the operation of the Services. As such, we may share your Personal Information with our carefully selected partners (including any other parties that have data sharing arrangements with the latter).</p>

            <p>Your Personal Information may also be used by us to provide you with:(1) promotional offers and information regarding our products and services; and (2) promotional offers and information regarding the products and services of our partners, in order to enlarge the range of provided products and improve our customer service. From time-to-time, we may request information from you via surveys or contests. Participation in these surveys or contests is completely voluntary and you have the choice of whether or not to disclose such information. Information requested may include contact information (such as name, correspondence address and telephone number), and demographic information (such as zip or postal code or age). By accepting any contest prize or winnings from us, you consent to use of your name for advertising and promotional purposes without additional compensation, except where prohibited by law. Unless you have elected not to receive promotional information, we may also use your Personal Information (including your email address and phone number) to provide you with information regarding our products, services and promotions, including other gaming products (including online poker, casino, betting, backgammon) and products and services from third parties carefully selected by us.</p>

            <h3>5. CERTAIN EXCLUDED DISCLOSURES</h3>
            <p>We may disclose your Personal Information if required to do so by law, or if we believe in good faith that such action is necessary to: (1) comply with any legal process served on us, any of our sites or the services or in circumstances where we are under a substantially similar legal obligation; (2) protect and defend our rights or property; or (3) act to protect the personal safety of users of the services or the public. If, in our sole determination, you are found to have cheated or attempted to defraud us, the company , or any other user of the services in any way including but not limited to game manipulation or payment fraud, or if we suspect you of fraudulent payment, including use of stolen credit cards, or any other fraudulent activity (including any chargeback or other reversal of a payment) or prohibited transaction (including money laundering), we reserve the right to share this information (together with your identity) with other online gaming sites, banks, credit card companies, appropriate agencies and relevant authorities. (4) For the purpose of research on the prevention of addiction, data can be made anonymous and passed on to the respective institutions.</p>

            <h3>6. ACCESS</h3>
            <p>You may ‘opt out’ of receiving any promotional communications either by choosing to opt out via your account settings available on our sites or the services or in an email you receive from us, or at any time by sending an email, or by writing to us at Customer Service.</p>
            <p>In addition, You may contact us if you: 1) want to confirm the accuracy of the Personal Information we have collected about you; 2) would like to update your Personal Information; and/or 3) have any complaint regarding our use of your Personal Information. If requested, we will (1) update any information you have provided to us, in case you prove the necessity for such changes or (2) mark any information to prohibit future use for marketing purposes. For the avoidance of doubt, nothing in this Privacy Policy shall prevent us from retaining your Personal Information where we are required to do so by law.</p>

            <h3>7. COOKIES</h3>
            <p>Information placed on your device</p>
            <p>When accessing our services, we may store information on your device. This information is referred to as cookies, which are small text files that are stored on your device when you visit online pages that record your preferences. We also use Local Shared Objects or ‘flash cookies’. ‘Flash cookies’ are similar to browser cookies. They allow us to remember things about your visits across our sites.</p>
            <p>Neither cookies nor flash cookies can be used to access or use other information on your computer. We only use these methods to track your use of our services. Cookies help us monitor traffic to the site, improve our services and make it easier and/or more relevant for your use. We use flash cookies and third party cookies to help us show you more relevant and desirable advertisements.</p>
            <h4>Strictly necessary cookies</h4>
            <p>Strictly necessary cookies are essential to allow a user move around a website and use its features, such as accessing secure areas of the website or making financial transactions. Without these cookies, we would not be able to make our websites work efficiently.</p>
            <h4>During the registration process</h4>
            <p>These cookies will hold information collected during your registration and will allow us to recognize you as a customer and provide you with the services you require. We may also use this data to better understand your interests while online and to enhance your visits to our platforms.</p>
            <h4>On our website</h4>
            <p>For visitors to our website, we use cookies to collect information. Our servers use three different types of cookies:</p>
            <p>A ‘session-based’ cookie: This type of cookie is only allocated to your computer for the duration of your visit to our website. A session-based cookie helps you move around our website faster and, if you’re a registered customer, it allows us to give you information that is more relevant to you. This cookie automatically expires when you close your browser.</p>
            <p>A ‘persistent’ cookie: This type of cookie will remain on your computer for a period of time set for each cookie. Flash cookies are also persistent.</p>
            <p>‘Analytical’ cookies: This type of cookie allows us to recognize and count the number of visitors to our site and see how visitors use our services. These helps us improve the way our sites work, for example, by ensuring you can find what you are looking for easily.</p>
            <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but, if you prefer, you can usually modify your browser setting to decline cookies. The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether.</p>
            <h4>Flash cookies</h4>
            <p>You can modify your Flash Player settings to prevent the use of flash cookies. The Settings Manager of your Flash Player allows you manage your preferences. To disallow flash cookies from all third party content go to the ‘Global Storage Settings’ panel of the Settings Manager and de-select the check box labelled ‘Allow third party flash content to store information on your computer’ and close the Settings Manager. Alternatively you can also adjust your settings for specific websites you visit through the ‘Website Storage Settings’ panel which is also found in the Settings Manager.</p>
            <p>If you are using an old version of Flash Player or older web browser, the Settings Manager may not be available to you. We recommend that you ensure that you refresh your Flash Player and browser to the latest available versions.</p>
            <p>If you choose to decline cookies, you may not be able to experience all of the interactive features on our sites.</p>

            <h3>8. CONSENT TO USE OF ELECTRONIC SERVICE PROVIDERS</h3>
            <p>In order to play real money games on our services, you will be required to send money to and receive money from us. We may use third-party electronic payment systems to process such financial transactions. By accepting this Privacy Policy, you expressly consent to Personal Information necessary for the processing of transactions including, where necessary, the transfer of information outside of your country. We take steps to ensure that our arrangements with payments systems protect your privacy.</p>
            <h3>9. CONSENT TO SECURITY REVIEW</h3>
            <p>We reserve the right to conduct a security review at any time to validate the registration data provided by you and to verify your use of the services and your financial transactions for potential breach of our Terms and Conditions and of applicable law. By using our services and thereby agreeing to our Terms and Conditions   you authorize us to use your Personal Information and to disclose your Personal Information to third parties for the purposes of validating the information you provide during your use of our services, including, where necessary, the transfer of information outside of your country. Security reviews may include but are not limited to ordering a credit report and/or otherwise verifying the information you provide against third-party databases. In addition, to facilitate these security reviews, you agree to provide such information or documentation as we may request.</p>
            <h3>10. SECURITY</h3>
            <p>We understand the importance of security and the techniques needed to secure information. We store all of the Personal Information we receive directly from you in an encrypted and password-protected database residing within our secure network behind active state-of-the-art firewall software. (Our Services support SSL). We also take measures to ensure our subsidiaries, agents, affiliates and suppliers employ adequate security measures.</p>
            <h3>11. PROTECTION OF MINORS</h3>
            <p>Our Services are not intended for or directed at persons under the age of eighteen (18) (or the lawful age in their respective jurisdiction). Any person who provides their information to us through any part of the services signifies to us that they are eighteen (18) years of age (or the lawful age in their respective jurisdiction) or older. It is our policy to uncover attempts by minors to access our services which may involve having to initiate a security review. If we become aware that a minor has attempted to or has submitted personal information via our services, we will not accept their information and will take steps to purge the information from our records.</p>
            <h3>12. INTERNATIONAL TRANSFERS</h3>
            <p>Personal Information collected on the services may be stored and processed in any country in which we or our affiliates, suppliers or agents maintain facilities. By using our services, you expressly consent to any transfer of information outside of your country (including to countries that may not be assessed as having adequate privacy laws). Nevertheless, we take steps to ensure that our agents, affiliates and suppliers comply with our standards of privacy regardless of their location.</p>
            <h3>13. THIRD-PARTY PRACTICES</h3>
            <p>We cannot ensure the protection of any information that you provide to a third-party online site that links to or from the services or any information collected by any third party administering our affiliate program (if applicable) or any other program, since these third-party online sites are owned and operated independently from us. Any information collected by these third parties is governed by the privacy policy, if any, of such third party.</p>
            <h3>14. LEGAL DISCLAIMER</h3>
            <p>The Services operate ‘AS-IS’ and ‘AS-AVAILABLE’ without liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee nor do we claim that there will be error-free performance regarding the privacy of your Personal Information, and we will not be liable for any indirect, incidental, consequential or punitive damages relating to the use or release of said Personal Information.</p>
            <h3>15. CONSENT TO PRIVACY POLICY</h3>
            <p>Your use of our services constitutes an agreement to our Privacy Policy.</p>
            <p>This is our entire and exclusive Privacy Policy and it supersedes any earlier version. This Privacy Policy should be read in conjunction with our Terms and Conditions and any additional applicable terms posted on our platforms. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our Platforms. Your continued use of our services following any changes to this Privacy Policy constitutes your acceptance of the changes. We recommend that you revisit this Privacy Policy regularly.</p>
            <h3>16. Other web sites</h3>
            <p>Our web site may contain links to other web sites, which are outside our control and are not covered by this Privacy Policy. If you access other sites using the links provided, the operators of these sites may collect information from you, which will be used by them in accordance with their privacy policy, which may differ from ours. We are not responsible. Solely the operators of these websites shall be responsible for their functionality or possible errors on the linked sites.</p>
        </div>
    }
    if (props.title == "Responsible Gaming") {
        return <div >
            <p>Skyexchange is committed to endorsing responsible wagering among its customers as well as promoting the awareness of problem gambling and improving prevention, intervention and treatment.</p>

            <p>Skyexchange’s Responsible Gambling Policy sets out its commitment to minimizing the negative effects of problem gambling and to promoting responsible gambling practices.</p>

            <p>Skyexchange supports the generation of online gamblers offering them a wide range of games and entertainment. We also take responsibility for our product line-up.</p>

            <p>The aim of Skyexchange is to provide the world’s safest and most innovative gaming platform for adults. The offered clear and safe products allow each user to play within his financial means and to receive the highest quality service.  Integrity, fairness and reliability are the guiding principles of Skyexchange’s work. It is therefore clear that Skyexchange should do its best to avoid and reduce the problems, which can arise from participation in gambling, particularly in cases of immoderate playing. At the same time it is important to respect the rights of those who take part in games of chance to a reasonable extent as means of entertainment.</p>

            <p>Responsible Gaming at Skyexchange is based on three fundamental principles: Security of the player, Security of the game and Protection against gaming addiction. Together with research institutes, associations and therapy institutions, we work on creation of a responsible, secure and reliable framework for online gaming.</p>

            <p><strong>Player security</strong></p>
            <p>We take responsibility for the security of our players. Protection of the players is based on forbidding the attendance of the minors from participation in games and the protection of privacy, which involves responsible processing of personal data and payments. Fairness and the random nature of the products offered are monitored closely by independent organizations. Marketing communication is also geared towards player protection: we promise only what players can receive in our transparent line.</p>

            <p><strong>Protection against gaming addiction: research – prevention – intervention</strong></p>
            <p>The majority of users who make sports bets, casino bets and other gaming offers play in moderation for entertainment. Certain habits and behavior patterns (such as shopping, playing sports, eating or consumption of alcohol) which are considered to be normal and not causing any concern can develop into addiction for some people and cause problems. In the same way, bets on sports and gambling can lead to problems for a small group of customers.</p>
            <p>Clients with gaming addiction are prohibited from further participation in the gaming line-up. Subsequently the customers are provided with contacts of organizations where they can receive professional advice and support.</p>

            <p><strong>Self-responsibility is the most sustainable form of prevention</strong></p>
            <p>The basic principle promoted by Skyexchange is that the final decision and responsibility on whether to play or not, and how much money can be spent on the game should be assumed by the customer himself.</p>
            <p>Self-responsibility of the customer is therefore the most effective form of protection from addiction. Skyexchange sees its responsibility in assisting the customers by providing transparent products, full information and keeping a clear line of conduct.</p>

            <p><strong>Protection of minors</strong></p>
            <p>Skyexchange does not allow minors (persons under the age of 18) to participate in games and make bets. That’s why the confirmation of having reached the age of majority and the confirmation of date of birth are mandatory requirements during registration. Skyexchange considers the issue of minors taking part in games and betting very seriously. In order to offer the best possible protection of minors, we also rely on the support of parents and caregivers. Please keep your data for account access in a safe place (user ID and password).</p>

            <p>Furthermore, we would recommend that you install filter software. This software will allow you to restrict the access to Internet resources inappropriate for children and teenagers. These include: <br />www.netnanny.com<br />www.cybersitter.com</p>

            <p><strong>Responsibility towards problems</strong></p>
            <p>Skyexchange offers a variety of games and bets, which are forms of entertainment for the majority of customers. At the same time the company takes responsibility for its customers by providing support and tools for maintenance of a secure and entertaining environment taking into account the associated risks.</p>
            <p>The clients who have difficulty in assessing risks, recognizing their own limits or those who suffer from gambling addiction are not able to enjoy our product line-up responsibly and perceive it as a form of entertainment. Skyexchange takes responsibility for such users by blocking their access to its products for their own protection.</p>

            <p><strong>Get informed with the main issues!</strong></p>
            <p>Most people play for pleasure. Moderate participation in games within their financial capacity is fully acceptable. However, for a small percentage of people gambling is not a form of entertainment, it is a challenge that must be considered seriously.</p>

            <p><strong>What is the problematic game behavior?</strong></p>
            <p>A problematic game behavior is considered to be such behavior, which interferes mode of life, work, financial position or health of a person or his family. Long participation in games is counter indicative to such person as it can lead to negative consequences.</p>
            <p>In 1980 the pathological game dependence has been officially recognized and enlisted in the list of psychological diseases of international classification system DSM-IV and ICD-10. It is defined as long, repeating and frequently amplifying inclination for game, despite of existing negative personal and social circumstances, such as a debt, rupture of family relations and delay of professional growth.</p>

            <p><strong>In what cases behavior of a person should be considered as dependence?</strong></p>
            <p>It is necessary to underline that the diagnoses of game dependence can be qualified only by experts. The material presented on this web-page will help you to estimate and define your own game behaviour.</p>
            <p>The special hazard of addictions that are not associated with any substance is that it is very difficult to define the line between pleasure and addiction. Nevertheless, there are some diagnostic signals that may point out the existing problems. In the presence of at least five of the following symptoms, the likelihood of the severe dependence is high:</p>
            <ol>
                <li>The player is deeply involved in gambling, all his thoughts are only about the game.</li>
                <li>Bet sum increases in course of time.</li>
                <li>Attempts to quit or control his participation in the games appear to be unsuccessful.</li>
                <li>When limiting his participation in gambling, a person experiences irritation and disappointment.</li>
                <li>The game is a way to escape from problems or discomfort.</li>
                <li>The player tries to win back the lost amount,</li>
                <li>Lies about his playing behavior,</li>
                <li>Commits illegal acts,</li>
                <li>Spoils or breaks the relationship with family and colleagues,</li>
                <li>Borrows to participate in the games.</li>
            </ol>

            <p><strong>Rules for responsible games</strong></p>
            <p>Following the rules placed below, you can enjoy the game without anxiety:</p>
            <ol>
                <li>Start playing only when you are calm and concentrated.</li>
                <li>Take regular breaks.</li>
                <li>Define for yourself beforehand the monthly amount you can spend on the game.</li>
                <li>Once setting a maximum limit, do not further increase it.</li>
                <li>Before you start playing, define the maximum amount of winning, after reaching of which you should stop playing.</li>
                <li>Define the amount you can afford to lose beforehand.</li>
                <li>Do not start playing under alcohol or drug influence.</li>
                <li>Do not start playing in a depressed state.</li>
            </ol>

            <p><strong>Exclusion from games</strong></p>
            <p>Should the player feel they have a problem or wish to take an enforced break they can do so by using the following online tool to prevent access to gambling sites:www.gamblock.com</p>
            <p>At any time should the player feel that they have a problem they can contact us to have their account permanently closed.</p>
            <p>In addition to this, should we feel that at any time the player has a problem then we reserve the right to suspend the account until the player has proven their level of activity is not a problem or permanently close the account.</p>

            <p><strong>Seeking help</strong></p>
            <p>Organizations are available to support players with gaming addictions and other disorders related to games and we recommend any player with an issue contacts a self-help organization for additional assistance. The following website can offer help and guidance to those in need: www.gamblingtherapy.org</p>

        </div>
    }
    if (props.title == "Self-exclusion Policy") {
        return <div >
            <p>If you feel you are at risk of developing a gambling problem or believe you currently have a gambling
                problem, please consider using Self-Exclusion which prevents you gambling with lucky7 for a specified
                period of 6 months, 1 year, 2 years, 5 years or permanently.</p>
            <p>If you want to stop playing for other reasons, please consider a Time-Out or using Account Closure.</p>
            <h3>What happens when you self-exclude?</h3>
            <p>During a period of Self-Exclusion you will not be able to use your account for betting, although you will
                still be able to login and withdraw any remaining balance. It will not be possible to re-open your
                account for any reason, and lucky7 will do all it can to detect and close any new accounts you may
                open.</p>
            <h3>Next steps</h3>
            <p>Whilst we will remove you from our marketing databases, we also suggest that you remove lucky7 from your
                notifications and delete/uninstall all lucky7 apps, downloads and social media links. You may also wish
                to consider installing software that blocks access to gambling websites, click here for more
                information.</p>
            <p>We recommend that you seek support from a problem gambling support service to help you deal with your
                problem.</p>
            <p>You can self-exclude your account in the My Gambling Controls section of Members by choosing
                Self-Exclusion.</p>
            <p>Alternatively you can contact our customer care team for assistance and further information.</p>
            <h3> Self-Exclusion Notice</h3>
            <p>Should you opt to self-exclude from lucky7, we strongly recommend that you seek exclusion from all other
                gambling operators you have an account with.</p>
            <p>You can self-exclude by contacting other gambling operators directly or you can exclude from other
                licensed operators by completing a Self-Exclusion Notice form.</p>
            <p>Once completed the Self-Exclusion Notice form should be submitted to the nominated site, sports bookmaker
                or betting exchange operator.</p>

        </div>
    }
    if (props.title == "Underage Policy") {
        return <div >

            <p>It is illegal for anyone under the age of 18 to open an account or gamble with lucky7. We require new
                members to declare that they are over 18 years of age and carry out age verification checks on all new
                accounts.</p>
            <p>If you are under the age of 18 years (or below the age of majority as stipulated in the laws of the
                jurisdiction applicable to you) or if you are not legally able to enter into a binding legal agreement
                with us, you must not use our service.</p>
            <p>If we suspect that you are or receive notification that you are currently under 18 years or were under 18
                years (or below the age of majority as stipulated in the laws of the jurisdiction applicable to you)
                when you placed any bets through the service your account will be suspended to prevent you placing any
                further bets or making any withdrawals from your account. We will then investigate the matter, including
                whether you have been betting as an agent for, or otherwise on behalf, of a person under 18 years (or
                below the age of majority as stipulated in the laws of the jurisdiction applicable to you). If having
                found that you: (a) are currently; (b) were under 18 years or below the majority age which applies to
                you at the relevant time; or (c) have been betting as an agent for or at the behest of a person under 18
                years or below the majority age which applies:</p>
            <ul>
                <li>i. all winnings currently or due to be credited to your account will be retained;</li>
                <li>ii. all winnings gained from betting through the service whilst under age must be paid to us on
                    demand (if you fail to comply with this provision we will seek to recover all costs associated with
                    recovery of such sums); and/or</li>
                <li>iii. any monies deposited in your www.lucky7 account which are not winnings will be returned to
                    you.</li>
            </ul>
            <p>This condition also applies to you if you are over the age of 18 years but you are placing your bets
                within a jurisdiction which specifies a higher age than 18 years for legal betting and you are below
                that legal minimum age in that jurisdiction.</p>
            <p>In the event we suspect you are in breach of this policy or that you are attempting to rely on it for a
                fraudulent purpose, we reserve the right to take any action necessary in order to investigate the
                matter, including informing the relevant authorities.</p>

        </div>
    }
    if (props.title == "Rules and Regulations") {
        return <div class="announce-wrap">
            <div class="declared">
                <p>NOTE:</p>
                <p>Players using VPN and login from different IP frequently may result to void bets. And on the basis of different IP from multiple city we can suspend the account and void bets.</p>
            </div>
            <p>All lucky7 users including ‘Super’, ‘Master’ and ‘Sub’ account holders are advised to read the following ‘Terms and Conditions’. All users who use lucky7 agree and accept to the following:</p>
            <ol>
                <li>The site www.skyexchangeart.in and all of its original content are the sole property of ‘Bet Infotech Limited’ and are, as such, fully protected by International Copyright and other intellectual property rights laws.</li>
                <li>Any form of ‘Passing of funds’, ‘Self Matching’ will not be tolerated on lucky7. Any users found participating in such activites will be locked with the funds being reversed. Accounts participating in such activities must note that lucky7 reserves the right to Void any bets of such nature at any time within 1 week of the bet being placed. </li>
                <li>Please note that if any account has been locked due to ‘Passing of funds’ within the last 72 hours, lucky7 reserves the right to void any bet of this nature within the account irrespective of when the bet was placed. </li>
                <li>No Arguments or claim in the above context will be entertained by lucky7 and the decision made by lucky7 will stand as final.</li>
                <li>lucky7 Endeavors to have our services run 24 hours a day. However due to any technical issue, or disruption of services from our provider (Betfair.com), lucky7 will not be liable for any market positions that any account holders may hold.</li>
                <li>Betfair.com reserves the right to resettle or void any market for reasons such as technical delayed suspension from the provider. In any such case lucky7 will settle according to Betfair.com</li>
                <li>‘Super’ and ‘Master’ account holders will be held responsible for the financial penalties incurred for any misuse or ‘passing of funds’ from any of the ‘Sub’ account holders under their accounts irregardless whether or not the financial positions of the sub accounts have been settled by their superior ‘Super’ or ‘Master’ holders.</li>
                <li>In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will be cancelled.</li>
                <li>Any bets which are deemed of being suspicious, including bets which have been placed from the stadium or from a source at the stadium maybe voided at anytime. The decision of whether to void the particular bet in question or to void the entire market will remain at the discretion of lucky7. The final decision of whether bets are suspicious will be taken by lucky7 and that decision will be full and final.</li>
                <li>Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in lucky7, If any lucky7 User is caught in any of such act then all the funds belonging that account would be seized and confiscated. No argument or claim in that context would be entertained and the decision made by lucky7 management will stand as final authority.</li>
                <li>Fluke hunting/Seeking is prohibited in lucky7, All the fluke bets will be reversed. Cricket commentary is just an additional feature and facility for lucky7 user but lucky7 is not responsible for any delay or mistake in commentary.</li>
            </ol>
            <h1>Exchange Rules and Regulations</h1>
            <h2>Part A - Introduction</h2>
            <h3>Restricted territory information</h3>
            <p>Please be advised that our restricted territory information pertains to both residents and visitors in the restricted areas. Accounts registered in a non-restricted territory will become restricted if they are attempt to access and have betting activity from a territory which is restricted.</p>
            <p>Restricted territory countries are Algeria, Australia, Austria, Bulgaria, Canada, China, Cyprus, Czech Republic, Democratic People’s Republic of Korea, Denmark, Europe, France (and French territories), Gibraltar, Germany, Iran, Iraq, Ireland, Italy, Japan, Malta, New Zealand, Poland, Portugal, Qatar, Romania, Singapore, South Africa, Spain, Turkey, United Kingdom and United States of America (and US territories) </p>
            <p>We have the right to void all players winnings if they are deemed to come from a restricted territory.</p>
            <h3>1. Use and interpretation</h3>
            <p>The Exchange Rules and Regulations ("Exchange Rules") are part of terms and conditions.</p>
            <p>The Exchange Rules apply to all bets placed on Exchange markets. The Exchange Rules also apply to Exchange ’Multiples’ product (see the Multiples section below for further details). The Exchange Rules consist of the following:</p>
            <ul>
                <li>This INTRODUCTION section (Part A);</li>
                <li>The GENERAL RULES (set out in Part B below);</li>
                <li>The SPECIFIC SPORTS RULES (set out in Part C below – these apply to certain sports and to financial markets); and</li>
                <li>The MARKET INFORMATION (located on every market either under the tab entitled "Rules" or under the ‘Rules’).</li>
            </ul>
            <p>The General Rules apply to all bets unless stated otherwise in the Market Information or the Specific Sports Rules. If there is any inconsistency between the Specific Sports Rules and the General Rules, the Specific Sports Rules shall prevail. If there is any inconsistency between the Market Information and either the General Rules or the Specific Sports Rules, the Market Information shall prevail, except where the General Rules or Specific Sports Rules use the phrase 'regardless of what it says in the Market Information' or similar wording.</p>
            <p>For any category or market not referred to in the Specific Sports Rules (e.g. ’Special Bets’ or beach volleyball), the General Rules and Market Information will apply.</p>
            <h3>2. Market information</h3>
            <p>The Market Information is provided for information purposes as an at-a-glance guide on how to manage the market. The Market Information may also contain rules on market settlement, however it must always be read in conjunction with the General Rules and the relevant Specific Sports Rules and a link to this Rules and Regulations page will usually be provided in the Market Information for each market.</p>
            <p>Whilst the Market Information may give a guide to how markets will be managed, regardless of what it says in the Market Information, it reserves the right to suspend any market at any time at its sole discretion, including in order to carry out necessary administration and/or to take any necessary action to protect the customers.</p>
            <p>It shall not amend the Market Information after a market has been loaded except to correct obvious errors and/or to add wording to clarify the Market Information where appropriate.</p>
            <h3>3. Customer responsibility</h3>
            <p>The customers should make themselves aware of all the Exchange Rules affecting any market on which they wish to place a bet. Customers should not simply rely on the Market Information as the Market Information is unlikely to contain all of the applicable rules relating to a market.</p>
            <p>By their nature ‘Special Bets’ markets are unpredictable so should be treated with particular caution. Customers are responsible for managing their own positions at all times in such markets. Customers should pay particular attention to the Market Information on ‘Special Bets’ markets to ensure they understand the basis on which the market will be administered and settled.</p>
            <h3>4. Customer betting disputes &amp; IBAS</h3>
            <p>Any customer who has any concerns or questions regarding the Exchange Rules or regarding the settlement of any market should contact.</p>
            <p>If a customer is not satisfied with how a bet or a market has been settled then the customer should provide details of their grievance.</p>
            <h2>Part B - General rules</h2>
            <h3>1. Managing markets In-Play</h3>
            <h4>a) General</h4>
            <ul>
                <li>
                    For everything other than horseracing and greyhound racing, if a market is not scheduled to be turned in-play but fails to suspend the market at the relevant time, then:
                    <ul>
                        <li>if the event has a scheduled 'off' time, all bets matched after that scheduled off time will be void; and</li>
                        <li>if the event does not have a scheduled 'off' time, it will use its reasonable endeavours to ascertain the time of the actual 'off' and all bets after the time of the 'off' determined will be void.</li>
                    </ul>
                </li>
                <li>For horseracing and greyhound racing, if a market is not scheduled to be turned in-play but fails to suspend the market at the relevant time, then all bets matched after the official 'off' time will be void.</li>
                <li>It aims to use its reasonable endeavours to suspend in-play markets at the start of and at the end of the event. However, regardless of what it says in the Market Information, it does not guarantee that such markets will be suspended at the relevant time.</li>
                <li>It will not part-suspend outcomes/selections in an Exchange market that has been turned in-play.</li>
                <li>Customers are responsible for managing their in-play bets at all times.</li>
                <li>For the purposes of in-play betting, customers should be aware that transmissions described as "live" by some broadcasters may be delayed or pre-recorded. The extent of any delay may vary depending on the set-up through which they are receiving pictures or data.</li>
            </ul>
            <h4>b)  All markets other than soccer markets and Australian markets - not suspending at the time of the 'off'</h4>
            <ul>
                <li>In relation to markets which are scheduled to be turned in-play, aims to use its reasonable endeavours to turn such markets in-play at the time of the 'off'. The time of the 'off' for such markets should be set out in the Market Information. However, regardless of what it says in the Market Information, it does not guarantee that such markets will be suspended and turned in-play at the time of the 'off'.</li>
                <li>If a market is scheduled to be turned in-play but does not suspend the market and cancel unmatched bets at the time of the 'off' and the market is not turned in-play with unmatched bets cancelled at any time during the event, all bets matched after the scheduled time of the 'off' will be void (in the case of horseracing and greyhound racing, bets will be void from the official rather than the scheduled 'off' time). </li>
                <li>If the event does not have a scheduled 'off' time, it will use its reasonable endeavours to ascertain the time of the actual 'off' and all bets after the time of the 'off' determined will be void.</li>
                <li>If a market is scheduled to be turned in-play but does not suspend the market at the time of the 'off' (so unmatched bets are not cancelled at that time), but the market is intentionally turned in-play at a later time during the event, all bets matched after the time of the 'off' will stand</li>
            </ul>
            <h4>c) Soccer markets (with the exception of Australian soccer markets) - not suspending at kick-off or on the occurrence of a Material Event</h4>
            <h5>Not suspending at kick-off</h5>
            <ul>
                <li>In relation to soccer markets that are scheduled to be turned in-play, aims to use its reasonable endeavours to turn such markets in-play at kick-off and to suspend such markets on the occurrence of a Material Event (see definition of "Material Event" below).</li>
                <li>The relevant scheduled kick-off time should be set out in the Market Information. However, regardless of what it says in the Market Information, it does not guarantee that such markets will be suspended and turned in-play at kick-off.</li>
                <li>If a market is scheduled to be turned in-play but not suspend the market at kick-off and the market is not turned in-play at any time during the match, all bets matched after the scheduled time of the kick-off will be void.</li>
                <li>If a market is scheduled to be turned in-play but not suspend the market at kick-off (so unmatched bets are not cancelled at that time), but the market is turned in-play at a later time during the match, all bets matched after the scheduled time of the kick-off and before the first "Material Event" will stand. However, if there has been one or more "Material Events", any bets matched between the first "Material Event" and the market being turned in-play will be void.</li>
            </ul>
            <h5>Not suspending on the occurrence of a Material Event</h5>
            <ul>
                <li>If it does not suspend a market on time for the occurrence of a Material Event, it reserves the right to void bets unfairly matched after the Material Event has occurred. Voiding of these bets may take place during the event or retrospectively once a game is completed.</li>
            </ul>
            <h5>Definition of “Material Event”</h5>
            <ul>
                <li>For the purpose of these Exchange Rules, a "Material Event" shall mean a goal being scored, a penalty being awarded or a player being sent off.</li>
            </ul>
            <h4>d) In-play Australian markets</h4>
            <ul>
                <li>Notwithstanding the other in-play rules described above, in relation specifically to any Australian market that is scheduled to be turned in-play, if it fails to suspend the market at the 'off' then all bets matched after the scheduled time of the 'off' and before the market is turned in-play will be void (in the case of horseracing and greyhound racing, bets will be void from the official rather than the scheduled ‘off’ time). If the event does not have a scheduled 'off' time, it will use its reasonable endeavours to ascertain the time of the actual 'off' and all bets after the time of the 'off' as determined will be void.</li>
            </ul>
            <h3>2. Results and market settlement</h3>
            <h4>a) General</h4>
            <ul>
                <li>Markets will be settled as set out in the Market Information and/or the Specific Sports Rules.</li>
                <li>Where the Market Information or Specific Sports Rules do not specify how and on what basis a market will be settled, markets will be settled on the official result of the relevant governing body regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>If no official result of a relevant governing body is available, the result will be determined by using information from independent sources. In such cases, if any new information comes into the public domain within 48 hours of settlement, then it shall (acting reasonably) determine either: (i) whether the market should be reinstated or resettled in light of this new information; or (ii) whether or not to wait for further information before deciding whether to reinstate or resettle the market. Except it has announced that it is waiting for further information, any information that comes into the public domain more than 48 hours after a market has been settled shall not be considered (regardless of whether or not such information may have led to a different result).</li>
                <li>In the event of any uncertainty about any result or potential result, it reserves the right to suspend settlement of any market for an unlimited period until the uncertainty can be resolved to the reasonable satisfaction. It reserves the right to void any market if the uncertainty regarding settlement cannot be resolved to reasonable satisfaction.</li>
            </ul>
            <h4>b) Resettlements</h4>
            <ul>
                <li>Markets are generally settled shortly after the end of the event in question. It may settle (or part-settle) some markets before the official result is declared (or may increase a customer's 'available to bet' balance by the minimum potential winnings of that customer on a given market) purely as a customer service benefit. However, it reserves the right to amend the settlement of the market if: (i) the official result is different to the result on which initially settled the market (for example, a horseracing result being changed by the relevant governing body shortly after a race, but before the result is official); or (ii) if the whole market is eventually voided (e.g. for an abandoned event).</li>
                <li>It reserves the right to reverse the settlement of a market if a market is settled in error (for example, a human or technical error).</li>
                <li>If resettles a market, this may lead to amendments being made to a customer's balance to reflect changes in market settlement.</li>
            </ul>
            <h4>c) Non-runners, withdrawals and disqualifications</h4>
            <ul>
                <li>Subject always right to void bets under its terms and conditions or for any exception under the Exchange Rules, if a market contains a statement that says, "All bets stand, run or not" (or something similar) in the Market Information, then all bets on a team or competitor will stand regardless of whether or not the team or competitor starts the event or takes any part in the event.</li>
                <li>Where the Market Information does not stipulate that all bets will stand regardless of participation, customers should refer to the relevant Specific Sports Rules.</li>
                <li>If a team or competitor is disqualified, withdraws or forfeits after starting an event they will be deemed a loser providing at least one other team or competitor completes the event. If no team or competitor completes an event (having started) then all bets will be void except for bets on any markets which have been unconditionally determined.</li>
            </ul>
            <h4>d) Winner with [named selection]' markets</h4>
            <ul>
                <li>It may from time to time offer markets that are dependent on the participation of a particular competitor. If the competitor named either in a 'Winner with …' market title or in the Market Information does not participate in the tournament or event then all bets on the market will be void. For example, if there was a "Winner with Federer" tennis market, all bets on the market would be void if Federer did not participate in the tournament. However, if any other competitor did not participate, then bets would stand.</li>
                <li>A team or competitor will be deemed to have participated if they have taken part to the extent necessary to record an official result or classification (including any disqualification but excluding any "did not start" or equivalent classification).</li>
            </ul>
            <h3>3. Abandonments, Cancellations, Postponements</h3>
            <ul>
                <li>Some markets have different rules and these are listed in the Specific Sports Rules and/or the Market Information. However, where a market has no rules in the Specific Sports Rules or the Market Information in relation to an abandonment, cancellation and/or postponement the following shall apply.</li>
                <li>In relation to any match, fixture, game, individual event, race or similar: If the event is not completed within three days after the scheduled completion date, then all bets on markets for this event will be void, except for bets on any markets that have been unconditionally determined.</li>
                <li>In relation to any tournament, competition or similar: If the event is not completed within three days after the scheduled completion date, then any markets relating to the event will be settled in accordance with the official ruling of the relevant governing body, providing such a decision is given within 90 days after the scheduled completion date. If no official ruling is announced in this 90-day period, then bets on any market relating to this event will be void, except for bets on any markets which have been unconditionally determined. If a market is to be voided but has been part-settled as a courtesy to customers, then such part-settled bets will be reversed and all bets on the market will be void.</li>
                <li>It will decide (acting reasonably) whether a market relates to a match (or similar) or a tournament (or similar). However, by way of example, the following shall apply: (i) Europa League outright = tournament; (ii) Champions’ League Group outright = tournament; (iii) Top Premiership goal scorer = tournament; (iv) 72-hole Golf Match bet = match; (v) Ryder Cup outright = tournament; (vi) Golf tournament outright = tournament; (vii) Tennis Tournament outright = tournament;(viii) 5-day Cricket Test Match = match; (ix) Ashes Series outright winner = tournament; (x) Motor Race (e.g. Grand Prix) = match.</li>
            </ul>
            <h3>4. Change of venue</h3>
            <ul>
                <li>Some markets have different rules and these are listed in the Specific Sports Rules and/or the Market Information. However, if change of venue is not dealt with in the Specific Sports Rules and/or the Market Information then the following shall apply:</li>
                <li>For any team sport: if the scheduled venue is changed after the market is loaded, all bets will be void only if the new venue is a home ground of the original away team</li>
                <li>For all categories or markets other than team sports: if the scheduled venue is changed after the market is loaded, all bets will stand.</li>
                <li>If there is a change in the type of scheduled surface (e.g. a hockey match switching from grass to Astor-turf) after the market has been loaded, all bets will stand.</li>
            </ul>
            <h3>5. Periods of time</h3>
            <ul>
                <li>Some markets have different rules and these are listed in the Specific Sports Rules and/or the Market Information. However, if not dealt with in the Specific Sports Rules or the Market Information then the following shall apply.</li>
                <li>If the scheduled duration of an event is changed after the market has been loaded but before the start of the event, then all bets will be void.</li>
                <li>Some markets refer to the length of time until an occurrence in the event (e.g. time of first goal). If an event happens in stoppage or injury time after any regular time period then it will be deemed to have occurred at the end of the regular time period. For example, if a goal is scored in first half stoppage-time in a soccer match it will be deemed to have occurred on 45 minutes.</li>
                <li>All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included.</li>
                <li>References within these Rules and Regulations to a particular number of 'days' shall mean the end of the day local time after the expiry of the specified number of days. For example, if a rugby match is scheduled for the 1st of December, then the rule that allows the match to be completed within three days after the scheduled completion date (see Paragraph 3 above) would mean that the deadline for completion of that match would be 23.59:59 on the 4th of December.</li>
            </ul>
            <h3>6. Match bets</h3>
            <ul>
                <li>Some markets have different rules and these are listed in the Specific Sports Rules and/or the Market Information. For example, for match bets on Golf markets the rules are set out in the Specific Sports Rules. However, if not dealt with in the Specific Sports Rules or the Market Information then the following shall apply.</li>
                <li>'Match Bets' for one-off events are determined by the competitor or team with the best score, time or finishing position in the event. If none of the competitors or teams involved in the match bet complete the event or register a score, time or finishing position then bets are void unless specified otherwise in the Specific Sports Rules and/or the Market Information. Any competitor or team not completing an event or registering a score, time or finishing position having taken part in the match bet event will be settled as a loser providing at least one other team or competitor completes that event or registers a score, time or finishing position.</li>
                <li>'Match Bets' for progress in a competition or event with multiple heats or rounds are determined by the competitor or team which qualifies to the furthest round (whether it takes part in the further round or not) or with the best score, time or finishing position in the final or same heat of that competition or event. If the relevant competitors or teams fail to qualify in the same round of the competition but in different heats then dead-heat rules will apply, irrespective of the finishing positions in their respective heats. Markets will be part-settled after the end of each round and any subsequent disqualifications, penalties or amendments to results or qualifications will not have any effect on the market. Where one or more of the competitors or teams are disqualified, for the purposes of settlement, the disqualified competitor or team will be deemed to have progressed further in the competition or event than all those eliminated from the competition or event prior to the disqualification, and will be deemed to have finished last (or joint last if there is more than one disqualification) of those still competing in the competition or event. Disqualification will be considered to have taken place at the time of the competitor's or team's removal from the competition or event by the relevant governing body, rather than at the time of the event which caused disqualification.</li>
                <li>If one of the competitors or teams does not take any part in the event, then all relevant match bets will be void.</li>
                <li>If an event or tournament is abandoned or reduced in duration such that any competitor or team fails to complete the match bet, event or tournament for any reason other than withdrawal or disqualification then all bets will be void except for those on markets which have been unconditionally determined.</li>
            </ul>
            <h3>7. "To qualify" markets</h3>
            <ul>
                <li>Some markets have different rules and these are listed in the Specific Sports Rules and/or the Market Information. However, if not dealt with in the Specific Sports Rules or the Market Information then the following shall apply.</li>
                <li>Any 'to qualify' market (e.g. "to reach the final" markets) will be determined by the competitor or team that qualifies under the terms set out in Market Information, whether or not they take part in the next round or event for which they have qualified. Markets will be settled after the qualifying stage and any subsequent disqualification or amendment to the result will not count.</li>
            </ul>
            <h3>8. Dead heats</h3>
            <ul>
                <li>Unless stated otherwise in the Specific Sports Rules and/or the Market Information the Dead Heat Rule applies to bets on a market where there are more winners than expected (as set out in the Market Information).</li>
                <li>For each bet matched on a relevant winning selection, the stake money is first reduced in proportion by multiplying it by the sum of the number of winners expected (as set out in the Market Information), divided by the number of actual winners (i.e. stake multiplied by (number of winners expected/number of actual winners)). The winnings are then paid to the successful backers on this 'reduced stake' (reduced stake multiplied by traded price) and the remaining stake money is paid to the appropriate layers.</li>
                <li>For example, assume there is a dead heat for first place between three horses. 'Client A' has backed one of the winners for a stake of 300 at the traded price of 4.0 and 'Client B' has taken the other side of this bet. When the event is settled, the stake (300) is multiplied by 1/3 (i.e. the number of expected winners (1) divided by the number of actual winners (3)) to calculate the reduced stake (100) and the remainder given to the layer (200). The backer then receives the traded price matched (4.0) multiplied by the reduced stake (4 x 100 = 400). In this example, Client A's net winnings are 100 (400 payout minus the original 300 stake), and Client B's net losses are 100. Alternatively, this can be viewed as the full amount paid to the traded price divided by the number of dead-heaters. In the above example, this would be 300 at the traded price of 4.0 (1200) divided by 3; making 400 payouts and 100 net winnings.</li>
                <li>By way of another example, assume there is an outright winner in a golf tournament but 7 players tie for 2nd place. In the "top 5 finish" market, after settling on the outright winner in the top 5 market there would be 4 others designated winner’s places available. 'Client A' has backed one of the winners for 300 at the traded price of 4.0 and 'Client B' has taken the other side of this bet. When the event is settled, the stake (300) is multiplied by 4/7 (i.e. the number of expected winners (4) divided by the number of actual winners (7)) to calculate the reduced stake (171.43) and the remainder given to the layer (128.57). The backer then receives the traded price matched (4.0) multiplied by the reduced stake (4 x 171.43 = 685.72). In this example, Client A's net winnings are 385.72 (685.72 payout minus the original 300 stake), and Client B's net losses are 385.72.</li>
                <li>In relation to customers whose betting counterparty, for the purposes of this section of the Exchange Rules dealing with dead heats, the terms 'Client A', 'Client B' and 'appropriate layers' should, where relevant, be read</li>
            </ul>
            <h3>9. Exchange Multiples</h3>
            <p>The Exchange Rules only apply to Exchange Multiples. </p>
            <ul>
                <li>Customers placing a multiple bet will be betting with each other will act as the counterparty to the bet.</li>
                <li>A multiple bet consists of a number of legs. A leg is defined as one or more chosen selections in any individual event market.</li>
                <li>It reserves the right in its sole discretion not to accept certain multiple bets or to scale back stakes in certain circumstances.</li>
                <li>All multiple bets placed are subject to the Exchange Rules that apply to each individual sport that relates to any leg of any multiple bet.</li>
                <li>The maximum payout limit for Exchange Multiples is £1,000,000.</li>
                <li>Customers can place an Exchange Multiple bet using back, or where available lay or a mixture of back and lay selections. However, customers cannot have a combination of back and lay selections in any one leg.</li>
                <li>If customers choose more than one selection in one leg the odds for this leg will be "dutched" which means they will be combined to reflect the chances of any of the selections within the leg winning (if backed) or all of the selections losing (if laid). If customers wish to place a cross-multiple bet (i.e. more than one selection in any event market but not using "dutched" prices) they will need to enter each Exchange Multiple bet separately.</li>
                <li>Other than Exchange Multiples bets struck at Starting Price ("BSP"), the odds available via the Exchange Multiples product will be broadly based on the relevant singles markets on the Exchange and any such winning multiple bet will be subject to commission as set out in the Charges' section of the website.</li>
                <li>Any winnings from Exchange Multiples bets struck at BSP will not be subject to commission, but the odds returned on each leg in such a multiple will be subject to a 5% deduction from the BSP.</li>
                <li>A BSP each way Exchange Multiple bet is a bet for selections in the multiple to win and a bet on the same selections to place. For example, a £2 each way double represents a £2 bet on both selections to win and a £2 bet on both selections to be placed, with a total stake for the bet of £4. The number of places for each event in each way multiples bet are shown on the multiples win Market Information and do not change. Should the number of runners be equal to or less than the number of places available, the relevant place leg of any multiples bet will be void</li>
                <li>The minimum total stake for any Exchange Multiple bet is £2. For example, a 20p "Yankee" (11 bet combinations) representing a total stake of £2.20 is permitted. However, a 1p "Heinz" (57 bet combinations) representing a total stake of £0.57 or a £1 double (1 bet combination) representing a total stake of £1 will not be permitted. For customers betting in currencies other than English Sterling, the minimum total stake which applies to an Exchange Multiple bet will not necessarily be the equivalent of £2, though it will be calculated as described in the example above.</li>
                <li>If, in its sole discretion, will determine the markets that are available for Exchange Multiples. Events available for Exchange Multiples will be those listed within any individual Exchange Multiple group (e.g. UK football fixtures for any given day). Not all markets will be available via the Exchange Multiples product.</li>
                <li>With the exception of the rule directly below, if any selection in any leg is a non-runner or otherwise void under the Exchange Rules (e.g. an abandoned match) then all bets on that individual leg will be void and the Exchange Multiple bet shall be adjusted accordingly. For example, a treble including one void leg will become a double. This means that if customers have more than one selection in any leg (i.e. in cases of 'ditching') and one of those selections is a non-runner, the whole leg will be void. In the event that voided legs mean that an individual bet within a multiple becomes a single bet, then this single bet will stand.</li>
                <li>Notwithstanding the rule directly above, for Exchange Multiples bets struck at BSP, if customers have more than one selection in any leg and one of those selections is a non-runner the leg will stand. For example, if customers back both Desert Orchid and Red Rum in an individual horse race and Red Rum is a non-runner, the leg will become a back of just Desert Orchid.</li>
                <li>It reserves the right not to accept certain combinations of Exchange Multiples, such as those including related contingencies (i.e. where the outcome of one event is likely to affect the odds on the outcome of another event). This may happen automatically at the bet placement stage. Alternatively, if such bet is taken in error, it may void the individual bet combinations which include two or more of the related contingency selections.</li>
                <li>It may offer special markets on related events from time to time, including within its normal markets (i.e. outside of the Exchange Multiples product), for example a market on Chelsea to win the English domestic double (i.e. Premiership and FA Cup).</li>
                <li>When placing any Exchange Multiple bet, the prices shown only give an indicative guide as to the price available for each leg and the overall multiple. Other than Exchange Multiples bets struck at BSP, the price of each leg and the overall multiple price that customers get will be fixed when the multiple bet is actually placed and customers will then be able to see these prices. For more details on this customer should refer to the 'help' files.</li>
                <li>Horseracing Exchange Multiples are based on ‘day of the race’ markets (and not ante-post markets). Horseracing ante-post rules do not therefore apply in relation to horseracing Exchange Multiples.</li>
            </ul>
            <h3>10. Rules for Starting Price</h3>
            <ul>
                <li>Starting Price ('SP') is available on the Exchange. Exchange SP bets (‘SP bets’) are therefore a type of Exchange bet. The SP is calculated as, by balancing all SP bets and other Exchange bets when the market is suspended at the 'off' of the relevant event. The details of this calculation are provided directly below.</li>
                <li>Please note that for all customers, an SP bet cannot be cancelled once it has been placed.</li>
                <li>A bet at SP is a fixed odd bet, with the odds on each selection being calculated and bets matched when the event starts. The odds are calculated by matching SP backers and other Exchange backers against SP layers and other Exchange layers. The inclusion of other Exchange bets in the SP reconciliation is necessary to ensure that: SP backers get the benefit of unmatched Exchange offers to lay if those offers could increase the SP; and SP layers get the benefit of unmatched Exchange offers to back if those offers could reduce the SP. Including unmatched other Exchange in the SP reconciliation also ensures that the bets of other Exchange backers and layers, which would otherwise lapse, are matched where possible.</li>
                <li>The minimum liability for a bet placed at SP is £10 for a lay bet and £2 for a back bet (or the currency equivalent in each case).</li>
                <li>Example 1: on selection A there is £1,000 of backers' stakes and £6,000 of layers' liabilities at SP and there are £500 of unmatched other Exchange back bets available to lay at an average of 5.0. If we ignored the Exchange market in this case the SP would be 7.0. However, this would leave other Exchange back bets, that could also have been matched at their requested price against SP layers, unmatched. Therefore, the SP will be 5.0 and all SP backers and SP layers will be matched at that price. The £500 of Exchange backers' stakes will also be matched at their requested price of 5.0 against the SP layers.</li>
                <li>Example 2: on selection B there are £831 of backers' stakes and £4,428 of layers' liabilities at SP and the following unmatched other Exchange lay bets, available to back: £20 at 6.8, £31.13 at 6.6 and £100 at 6.4. In this case the SP will be 6.68. This is calculated by including the £20 available to back at 6.8 and the £31.13 available to back at 6.6 and balancing those amounts against the SP backers' stakes and SP layers' liabilities. The £100 available to back on the Exchange at 6.4 remains unmatched as to include any of this amount would lead to an imbalance between SP backers and SP layers.</li>
                <li>The SP is calculated to six decimal places for each selection, though it may be displayed in the relevant market view (or in any form/results data) to two decimal places, rounded up or down as applicable. After reconciliation, the full SP on each selection is available by clicking on the relevant individual runner graph.</li>
                <li>If for whatever reason the site is unavailable when an event starts or the SP cannot otherwise be reconciled at the 'off’, it will determine the SP using all available information. For the avoidance of doubt this information will not be limited to betting activity on the relevant market(s). Also in these circumstances, a licensed betting operator within the group may act as risk counterparty to SP bets if necessary to ensure a fair SP. The personnel involved in determining the SP in such circumstances will have no undeclared personal or other interest in the SP in question.</li>
                <li>If the SP reconciliation process is undertaken prematurely (for example if a horserace is turned in-play in error), then it will endeavor to reverse the reconciliation so that the SP is determined when the event starts. There may however be circumstances in which this is not possible, in which case the SP will be based on the initial reconciliation.</li>
                <li>In cases where an SP reconciliation is reversed: SP bets (including SP limit bets), 'At In-play: Take SP' bets and 'At In-play: Keep' bets will all revert to their status before the reconciliation; and 'At In-play: Cancel' bets will either remain cancelled if not matched as part of the reconciliation process, or if matched as part of the reconciliation process, will revert to their unmatched status before the reconciliation.</li>
                <li>If the SP reconciliation process is undertaken later than scheduled (i.e. after the event has started) and determines that a material event has occurred (this will generally mean that the event is not turned in-play), the SP will be determined based solely on SP bets (and ‘At In-play: Take SP’ bets which are unmatched when suspends the relevant market), placed before the 'off'. This means that ‘At In-play: Take SP’ bets matched after the off, will be made void and not included in the reconciliation process. In addition, SP bets placed after the 'off' will be voided. However, if the SP reconciliation process is undertaken later than scheduled but determines that no material event has occurred, all bets will stand.</li>
                <li>If a SP 'each way' option is offered this will be processed as two separate bets: a win bet at SP and a 'to be placed' bet at SP.</li>
                <li>If for any reason unmatched bets are cancelled prior to a market going in-play, then any unmatched bets that have been selected to 'At In-play: Take SP' will be converted to SP bets. Once those bets are converted, they cannot be cancelled.</li>
            </ul>
            <h4>➢ Party against whom you are betting at SP</h4>
            <ul>
                <li>When you place a bet at SP you are betting against other customers. However, during the reconciliation of the SP, it acts as counterparty in order to balance liabilities between SP bets and other Exchange bets</li>
            </ul>
            <h4>➢ Placing a SP bet</h4>
            <ul>
                <li>Selecting the 'SP' button in the market view gives customers two different ways to request an SP bet. These are as follows:</li>
                <li>The first way to request a bet at SP is by leaving the 'Set SP odds limit' box unticked at the top right of the bet manager. For a back bet you are required to enter the stake you wish to bet on the selection. For a lay bet, you are required to enter the liability you are prepared to risk against the selection – in other words the amount that you are prepared to lose, should the selection win (or be placed as applicable).</li>
                <li>The second way to request a bet at SP is to select the 'Set SP odds limit' option. Using this you can request a bet at SP conditional upon minimum SP odds in the case of a back bet, or maximum SP odds in the case of a lay bet. If SP is shorter than the minimum price requested by a backer or is longer than the maximum price requested by a layer, then the relevant bet will lapse when the event starts. If SP is longer than the minimum price requested by a backer or is shorter than the maximum price requested by a layer, the bet will be matched at the SP. Where an SP is equal to the price limit specified by customers, those bets will be included based on the time they were submitted, on a first come, first served basis as is the existing convention for other Exchange bets. This means that such bets may be unmatched or partially matched.</li>
                <li>Please note that if a non-runner cannot be removed from the relevant market until after the completion of the event, the application to all bets matched at or before the "off" (including SP bets) of any reduction factor, may mean that the matched price for an SP back bet with an odds limit requested, is adjusted to a price below the lower limit requested. It may also mean that an SP lay bet request with maximum odds requested, may not be matched even though the odds on the selection, after any post-race adjustment for the late non-runner, is below the maximum odds requested.</li>
                <li>If an SP bet with an odds limit is requested, the minimum/maximum odds requested can be shortened in the case of a back bet or a lengthened in the case of a lay bet at any time before the event starts. However, as mentioned above, an SP bet request cannot actually be cancelled by a customer once it has been placed. Details of the impact of non-runners on the SP re provided below.</li>
            </ul>
            <h4>➢ Exchange bets which are unmatched at the 'off'</h4>
            <ul>
                <li>A normal Exchange bet is placed by choosing the odds of your selection from the market view (as opposed to clicking on the 'SP' of your selection). When such an Exchange bet is fully or partially unmatched it can be adjusted and cancelled in the normal course. Previously, unmatched bets were automatically cancelled when a market was suspended at the 'off' of the relevant event. You can now choose to either have your unmatched Exchange bet convert to an SP bet when the market suspends at the start of the event, or to have the bet 'persist' when the event goes in-play (see the 'keep' option below).</li>
                <li>In order to convert your unmatched Exchange bet to an SP bet when the market suspends you should select the 'At In-Play: Take SP' option in the bet manager. If there is a non-runner in a win market with a reduction factor of at least 2.5%, or a non-runner in a place market with a reduction factor of at least 4%, it is policy to cancel unmatched lay bets on all other runners in the market. In the case of any such non-runner, instead of being cancelled a lay bet for which the 'At In-Play: Take SP' option has been selected, will automatically convert to an SP bet. After this conversion, the bet cannot be cancelled. Otherwise, unlike an SP bet, you can choose to cancel an Exchange bet once it has been placed, even if you have chosen for the bet to persist or to convert to an SP bet at the start of the event.</li>
                <li>If you choose to convert an unmatched Exchange lay bet to an SP bet, the liability of your Exchange lay bet will be converted into a liability for the SP bet. Your liability for that SP bet will never be more than the liability for the Exchange bet you had specified. However, the amount you can win on the SP lay bet may differ from the amount you would have won had the Exchange bet been matched, depending on the final SP.</li>
            </ul>
            <h4>➢ Adjustments to SP bets for non-runners</h4>
            <ul>
                <li>For SP back bets, will at no point amend either the stake or the odds requested by a customer, despite any non-runners or withdrawals. However, customers who have selected the SP limit option can reduce the minimum SP odds they are prepared to accept on a selection.</li>
                <li>For SP lay bets on win markets, it will reduce a customer's liability based on the reduction factor(s) of any non-runner(s) and the reduction factor of the runner on which the customer's bet has been placed. This is to ensure that the balance between the backers' stakes and the layers' liability reflects the revised market after the runner has been removed. For example, if a horse with a 50% reduction factor becomes a non-runner, then another horse in the same market priced at about 5 (i.e. a reduction factor of 20%) will change to a price of about 2.5. Therefore, the liability on a £200 lay bet on that runner will need to change to a liability of about £75 to ensure that a balancing back bet will have the same £50 stake. This is done by multiplying the liability by 37.5% (i.e. 100%-(50%/ (100%-20%))).</li>
                <li>Where an SP lay bet in a win market has a maximum odds limit specified, this limit will be reduced by the reduction factor of any non-runner, if the non-runner has a reduction factor of at least 2.5%.</li>
                <li>For SP lay bets on place markets, it will still reduce a customer's liability based on the reduction factor(s) of any non-runner(s) but the calculation will be slightly different, in line with the application of place market reduction factors. The liability will be reduced by the reduction factor of the removed runner. Where the lay bet on a place market has a maximum odds limit specified, the potential winnings on the bet (i.e. the odds – 1) will be reduced by the reduction factor of the removed runner.</li>
                <li>Where an SP lay bet in a place market has a maximum odds limit specified this limit will be reduced by the reduction factor of any non-runner.</li>
                <li>SP lay bets will not be cancelled when there is a non-runner.</li>
            </ul>
            <h4>➢ Specific conditions for SP bets for non-runners in greyhound racing markets</h4>
            <ul>
                <li>ALL SP bets will stand so long as your trap selection is not vacant or after your bet placement a reserve runner is not subsequently entered to run from your trap selection.</li>
                <li>ALL unmatched ‘Convert to SP’ bets will be cancelled when a reserve is added or a vacant trap removed.</li>
            </ul>
            <h3>11. 'Keep' bets option</h3>
            <ul>
                <li>For markets that are scheduled to be turned in-play at the 'off', a customer can request that an unmatched Exchange bet should not be cancelled when the market is turned in-play. This is done by selecting the 'At In-Play: Keep' option in the bet manager (and confirming that request) and means that the unmatched bet persists when other unmatched bets are cancelled at the start of the event.</li>
                <li>As described above, when a non-runner is removed from a horseracing market (apart for late withdrawals as described in the next bullet point below), it is policy to cancel unmatched offers to lay all other horses in the market if the non-runner has a reduction factor of 2.5% or greater for win markets, or 4.0% or greater for place markets. In these circumstances offers to lay a horse with the 'At In-Play: Keep' option selected will not be cancelled. Instead the lay odds offered in place markets will be reduced in proportion with the reduction factors of any non-runner(s) and the same will apply in win markets providing the relevant non-runner has a reduction factor of at least 2.5%.</li>
                <li>When there is a late withdrawal, may not have time to remove the non-runner from the market before turning it in-play. In such cases if can determine that the late withdrawal is a material runner (i.e. a selection with a reduction factor of approx. 20% or greater in the win market), it reserves the right to cancel ALL lay 'keep' bets (in both the win and ‘to be placed’ markets) before turning the market in-play. If it does not cancel lay 'keep' bets in the case of a late withdrawal (for example, it may not be possible to know which horse is withdrawn, at the time of the off), any such bets placed before the off and matched in-play will remain at the original selected price. This means that those lay 'keep' bets will not be subject to any reduction factor which as a result of the late withdrawal, will be applied after the completion of the race to bets matched at or before the 'off'.</li>
                <li>Otherwise, in exceptional cases reserves the right to cancel 'keep' bets to protect customers but, unless stipulated in the market rules or the market information, the general principle is that at no point will a 'keep' bet be cancelled by unless it remains unmatched when the market is closed (for the final time) at the end of the event.</li>
                <li>For the avoidance of doubt this means that (for example) when a Material Event occurs in a soccer match and other unmatched bets are cancelled before the market is reopened, a keep bet will not be cancelled.</li>
            </ul>
            <h3>12. Rules for Tote betting</h3>
            <ul>
                <li>When you place a Tote bet on the platform (for example a UK or South African Tote bet), you are betting against as counterparty to your bet. I will then strike a corresponding bet into the relevant Tote pool as applicable.</li>
                <li>A link to the rules which apply to Tote bets struck as counterparty is provided within each of the relevant. If there is any conflict between those rules and either: the equivalent rules available on the relevant Tote website (for example on the UK Tote website in the case of UK Tote bets); or the equivalent rules applied by the relevant host racetrack, the rules on the relevant Tote website or applied by the relevant host racetrack, will prevail.</li>
            </ul>
            <h3>13. Miscellaneous</h3>
            <ul>
                <li>All references to time periods in the Exchange Rules relate to the time zone in which the event takes place. For example, a reference to the start time of a football match, relates to the local kick-off time.</li>
                <li>All information is done so in good faith. However, it cannot accept liability for any errors or omissions in respect of any information, such as the posting of prices, runners, times, scores, results or general statistics. </li>
                <li>It reserves the right to correct any obvious errors and shall take all reasonable steps to ensure markets are administered with integrity and transparency.</li>
                <li>If an incorrect team or competitor name is displayed (excluding minor spelling mistakes) or the incorrect number of teams, competitors or outcomes is displayed in any complete market or a market is otherwise loaded using incorrect information (for example the application of an incorrect exposure algorithm or a cross matching tool utilising an incorrect algorithm) or includes any obvious error such as the incorrect deployment of the cross matching tool, then It reserves the right to suspend the market and (providing it acts reasonably) to void all bets matched on the market.</li>
                <li>Customers are responsible for ensuring that they satisfy themselves that the selection on which they place a bet is their intended selection. For example, in the case of a competitor bearing the same name as another individual not competing in the relevant event, the onus is on the customer to ensure that they know which competitor It has loaded into the relevant market and to ensure that they are placing their bet on their chosen competitor.</li>
                <li>It reserves the right at any time in its sole and absolute discretion to suspend an Exchange market.</li>
                <li>It may, in its sole and absolute discretion, decide to suspend betting on a market at any time (even if such suspension is earlier than anticipated by the Exchange Rules). In the interests of maintaining integrity and fairness in the markets, it may also void certain bets in a market or void a whole market in its entirety.</li>
                <li>It reserves the right to amend the Exchange Rules at any time. </li>
                <li>It reserves the right to cancel unmatched bets to protect customers at any time.</li>
                <li>
                    On the settlement of any market, amounts relating to
                    <ul>
                        <li>winnings/losses on bets; and</li>
                        <li>commission charges</li>
                    </ul>
                    will be rounded up or down to the nearest two decimal places (with the exception that rounding will always be down (and never up) in respect of amounts relating to winnings/losses in respect of BSP bets). By way of example, £3.333, will be settled as £3.33, whereas £3.335, will be settled as £3.34 (except that it will be settled as £3.33 in respect of an amount relating to winnings/losses in respect of BSP bets).
                </li>
                <li>The Exchange Rules have been prepared in various languages other than English for reference only. In the event of any differences between the English version and the non-English version, the English version shall prevail.</li>
            </ul>
            <h2>Part C - Specific sports rules</h2>
            <h3>1. American Football</h3>
            <ul>
                <li>All markets will be settled on the result including overtime unless stated otherwise in these American Football Rules or in the Market Information (e.g. the half time/full time market) or where the market has been unconditionally determined. If the game results in a tie after overtime all bets on the outright match winner market will be void.</li>
                <li>If a match does not start on the scheduled starting date and is not completed within three days of the scheduled completion date, all bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>If a match starts but is later abandoned or postponed, then within three days of the scheduled start date:(a) at least 55 minutes of play must have elapsed; or (b) an official result must be 'called' by the relevant governing body; otherwise all bets will be void, except for those on markets which have been unconditionally determined. In those instances, if the scores are tied then all bets on the outright match winner market will be void.</li>
                <li>For 'Individual player' markets, bets shall be void on any player who does not start in any down during the match.</li>
            </ul>
            <h3>2. Athletics</h3>
            <ul>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>Unless stated otherwise in the Market Information, if a track or field event is abandoned, cancelled or postponed and not completed within 7 days of the scheduled completion date, all bets will be void except for those on markets which have been unconditionally determined.</li>
            </ul>
            <h3>3. Australian Rules Football</h3>
            <ul>
                <li>Bets apply to regular time excluding overtime. However, bets on "Finals" matches apply to regular time including overtime except for those on markets which have been unconditionally determined or as stated otherwise in the Market Information (e.g. half-time/full-time markets). If the game results in a tie, dead-heat rules apply to bets on the match odds market.</li>
                <li>If a match does not start on the scheduled starting date and is not completed within three days of the scheduled start date, all bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>If a match starts but is abandoned or postponed before half-time and not completed within three days of the scheduled start date then the match shall be deemed to be a tie and settled on dead-heat rules. If a match starts but is abandoned or postponed in the second half and not completed within three days of the scheduled start date then the match shall be settled on the official result of the relevant governing body. In both instances, bets on the match odds market will be settled as set out above and all other bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>All bets relating to any individual player will stand if they are listed in any 22-player squad on the match day, irrespective of whether they take any part in the match or not.</li>
            </ul>
            <h3>4. Baseball</h3>
            <ul>
                <li>These Baseball Rules apply to Major League Baseball (MLB), World Baseball Classic and Japanese Baseball only. All other baseball, including Finnish Baseball for example, will be governed by the rules set out in the General Rules and the Market Information.</li>
                <li>If a match does not start on the scheduled starting date, all bets will be void.</li>
                <li>If an MLB match starts but is later abandoned or postponed and an official result is not declared within three days of the scheduled start date, all bets will be void, except for those on markets which have been unconditionally determined.</li>
                <li>If a Japanese Baseball match starts but is later abandoned or postponed and the match is not completed within three days of the scheduled start date, all bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>All bets will include extra innings, unless otherwise stated in the Market Information.</li>
                <li>All bets on 'Total runs' or 'Run line' markets will be void unless there have been at least 9 innings completed (8 1/2 if the home team is ahead) except those on market which have been unconditionally determined.</li>
                <li>In "Listed" markets, bets will be void unless both named pitchers listed in the market start as pitchers in the first fielding innings for their respective teams.</li>
            </ul>
            <h3>5. Basketball</h3>
            <ul>
                <li>Bets will be settled on the result including overtime unless stated otherwise in the Market Information or where the market has been unconditionally determined or as set out in these Basketball rules.</li>
                <li>If a match does not start on the scheduled start date then all bets will be void.</li>
                <li>If a match starts but is later abandoned or postponed then, within three days of the scheduled start date, (a) at least 43 minutes of play must have elapsed in any NBA match or 35 minutes of play must have elapsed in any other match; or (b) an official result must be 'called' by the relevant governing body; otherwise all bets will be void, except for those on markets which have been unconditionally determined. In these instances, if the scores are tied then dead heat rules will apply to bets on the outright match winner market.</li>
            </ul>
            <h3>6. Boxing and Mixed Martial Arts</h3>
            <ul>
                <li>All individual bout markets will be settled according to the official result of the relevant governing body immediately after the end of the fight, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in tallying the points or in order to correct an error in reporting the result).</li>
                <li>If a fight is abandoned, cancelled or postponed and not completed within three days of the scheduled start date, or a boxer is withdrawn or substituted before the first-round bell, all bets will be void.</li>
                <li>If a boxer retires before the start of a round, fails to answer the bell, or is disqualified for any reason between rounds, the fight will be deemed to have finished at the end of the previous round.</li>
                <li>If one of the boxers is disqualified they will be deemed a loser.</li>
                <li>If the scheduled duration of the fight is altered in time or number of rounds after the loading of the relevant markets, then all bets will be void.</li>
                <li>If the scheduled venue is changed after the market has been loaded so that the new venue is changed to a venue in a different country then all bets will be void. For all other changes to the scheduled venue bets will stand.</li>
                <li>In mixed martial arts, if a fight is declared a 'no contest', a draw or a technical draw then all bets will be void.</li>
            </ul>
            <h3>7. Cricket</h3>
            <h4>➢ General</h4>
            <ul>
                <li>If a ball is not bowled during a competition, series or match then all bets will be void except for those on any market that has been unconditionally determined (e.g. in the 'Completed Match' market).</li>
                <li>If a match is shortened by weather, all bets will be settled according to the official result (including for limited overs matches, the result determined by the Duckworth Lewis method).</li>
                <li>In the event of a match being decided by a bowl-off or toss of the coin, all bets will be void except for those on markets that have been unconditionally determined.</li>
            </ul>
            <h4>➢ Test matches</h4>
            <ul>
                <li>If a match starts but is later abandoned for any reason other than weather (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch vandalism; strike or boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of God), It reserves the right to void all bets, except for those on markets that have been unconditionally determined.</li>
                <li>If the match is not scheduled to be completed within five days after the original scheduled completion date, then all bets on markets for this event will be void, except for bets on any markets that have been unconditionally determined.</li>
            </ul>
            <h4>➢ Limited Over matches</h4>
            <ul>
                <li>If a match is declared "No Result'', bets will be void on all markets for the event except for those markets which have been unconditionally determined or where the minimum number of overs have been bowled as laid out in the market specific information.</li>
                <li>In the event of a new toss taking place on a scheduled reserve day for a limited over match all bets that were placed after 30 minutes before the original scheduled start of play on the first day will be made void. This rule relates to all markets except those that have been unconditionally determined (e.g. in the win the toss and toss combination markets).</li>
            </ul>
            <h4>➢ Super Over rule</h4>
            <ul>
                <li>Which team will win this Super Over? This market will be suspended on site and activated once Betfair are aware a Super Over is to be played. The market will be turned in-play at the start of the Super Over. This market will not be actively managed therefore it is the responsibility of all customers to manage their positions.  This market will be settled based on the number of runs scored by each team in the initial Super Over. For the avoidance of doubt, if scores are tied at the completion of both innings in the Super Over then the market will be settled as a Dead Heat except in cases where more than one Super Over is played, where it will be settled on the winner of the final over. Any tie breaker that may be used to determine a winner including but not limited to higher number of boundaries, higher number of sixes, losing fewer wickets, coin toss etc. do not count for the purposes of this market.</li>
            </ul>
            <h3>8. Cycling</h3>
            <ul>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
            </ul>
            <h3>9. Darts</h3>
            <ul>
                <li>If a match is not completed for any reason then bets on the match odds market will be void.</li>
                <li>If a match is not completed for any reason then bets on 'any correct score' or 'next leg/game/set' market will be void unless the market has been unconditionally determined.</li>
                <li>If a match is not completed for any reason then bets on any handicap market will be void unless the market has been unconditionally determined.</li>
            </ul>
            <h3>10. Financial markets</h3>
            <h4>➢ Indices</h4>
            <ul>
                <li>Intraday, Midday and PM markets will be settled on the first Bloomberg price stamp past the market closure time regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>All Daily markets, weekly markets and end of day Intraday markets will be settled on the official close price following any relevant auction period regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
            </ul>
            <h4>➢ Equities</h4>
            <ul>
                <li>All equity markets will be settled on the official close price given by Bloomberg regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>Should a share split be announced, the market will be determined by the net change in value of the new share price at the close of the day’s trading.</li>
            </ul>
            <h4>➢ Interest Rates</h4>
            <ul>
                <li>Interest rate markets will be settled once the result is regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
            </ul>
            <h3>11. House Price</h3>
            <ul>
                <li>For settlement purposes, the Standardized Average Price (seasonally adjusted) provided by HBOS will be used and markets will be settled once the result is regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>It will only use prices provided by HBOS. If the HBOS figures are discontinued or not available for any relevant period then all bets will be void unless stated otherwise in the Market Information.</li>
                <li>All Quarterly markets may be incomplete markets and therefore additional "runners" may be added at any time to reflect the movements in house prices.</li>
            </ul>
            <h4>➢ Currency markets</h4>
            <ul>
                <li>All Currency markets will be settled using the first Spot price given by Bloomberg past the market closure time regardless of any subsequent amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
            </ul>
            <h3>12. Golf</h3>
            <h4>➢ General</h4>
            <ul>
                <li>Tournament bets will only be settled if the minimum number of holes stipulated in the Market Information has been completed.</li>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>If a player does not start a tournament then all bets on that player will be void.</li>
                <li>Any player starting a tournament but withdrawing or being disqualified before the end of the tournament will be settled as a loser.</li>
                <li>If a tournament is shortened and settles the tournament markets then all bets matched after the last completed round will be void.</li>
                <li>In any 'to qualify' market for any tournament the winners are the number of golfers that qualify for the tournament under the terms set out in the Market Information, whether they compete in the tournament or not. Markets will be settled after the qualifying stage and any subsequent disqualification or amendment to results will not count.</li>
                <li>If a Tournament/Round is restarted from the beginning, all bets placed after the official off time will be void, except on markets which have been unconditionally determined, which will stand. Bets on 2 or 3 balls will only be void if matched after the tee time of the relevant 2 or 3 balls.</li>
                <li>If it does not suspend a tournament market and cancel unmatched bets at the time stated in the market rules but the market is turned in-play at a later time, all bets matched between the start of a day’s play and the time the market is turned in-play will be void.</li>
            </ul>
            <h4>➢ Tournament match betting (i.e. 72-hole match bets)</h4>
            <ul>
                <li>If a player withdraws without playing a stroke all bets on the relevant market will be void.</li>
                <li>If all players fail to complete a particular round for any reason the winner is the player with the lowest total score after the previous round.</li>
                <li>If a player is disqualified or withdraws during any round they will be settled as a loser providing at least one other player completes that round.</li>
                <li>Should all players fail to complete the first round then all bets will be void.</li>
            </ul>
            <h4>➢ Round betting markets (e.g. 2 balls, 3 balls etc.)</h4>
            <ul>
                <li>If a player withdraws without playing a stroke in that round all bets on the relevant market will be void.</li>
                <li>If one player fails to complete a round for any reason other than a withdrawal or disqualification then all bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>Any player withdrawing or being disqualified having played a stroke in that round will be settled as a loser providing at least one other player completes that round.</li>
                <li>Should all players fail to complete the round then all bets will be void.</li>
            </ul>
            <h4>➢ Stroke play hole-by-hole markets (i.e. performance of a named player on a given hole)</h4>
            <ul>
                <li>Should a hole not be completed for any reason all bets on that hole will be void unless the market has been unconditionally determined.</li>
                <li>Markets are settled on completion of the hole and any subsequent penalties or disqualification will not be taken into account.</li>
            </ul>
            <h4>➢ Match play hole-by-hole markets (i.e. performance of players against each other on a given hole)</h4>
            <ul>
                <li>With the exception of a player or team conceding a hole (where they are deemed a loser), if a hole is not completed by any player or team (other than for withdrawal or disqualification) all bets on that hole will be void unless the market has been unconditionally determined.</li>
                <li>Any player or team withdrawing or being disqualified having played a stroke on that hole will be settled as a loser providing at least one other player completes that hole.</li>
                <li>If any player or team does not play a stroke on a hole all bets will be void.</li>
                <li>Markets are settled on completion of the hole and any subsequent penalties or disqualification will not be taken into account.</li>
            </ul>
            <h3>13. Greyhound racing</h3>
            <h4>➢ General</h4>
            <ul>
                <li>All bets (excluding those struck on ante-post and Australian licensed markets) are placed on trap numbers. Greyhound names are displayed for information purposes only.</li>
                <li>Markets will be determined according to the official result at the time the track gives the result green light status, either in the form of an announcement or by display. Subsequent disqualifications, appeals or amendments to the result will be disregarded.</li>
                <li>If a non-runner or reserve runner is declared, then all bets prior to the update of the market on It will be void and all unmatched bets including ‘Take SP’ and ‘keep’ bets will be cancelled (except for certain SP bets as set out in Paragraph 10.5 of Part B above).</li>
                <li>If there are no finishers in any race or any race is declared void before the official result is declared then all bets will be void.</li>
                <li>If the scheduled venue is changed after the market has been loaded by It, all bets will be void.</li>
                <li>In ante-post markets, all bets on individual greyhounds stand whether the greyhound runs or not. All ante-post bets will be void if the competition is abandoned or the venue is changed.</li>
            </ul>
            <h4>➢ Trap Challenge</h4>
            <ul>
                <li>Bets on 'Trap challenge' markets are based on which trap results in the most winners during a race meeting, unless stated otherwise in the Market Information.</li>
                <li>These markets are unaffected by any changes due to non-runners or reserve runners.</li>
                <li>If a race results in a dead heat each trap will receive a half-win, with a 3-way dead heat giving a one third-win, and so on.</li>
                <li>Standard dead heat rules apply if two or more traps have an equal number of winners at the end of both meetings.</li>
                <li>If a whole meeting is abandoned all bets are void. However, if at least one race has been completed then bets on these markets will stand.</li>
            </ul>
            <h4>➢ Multi-trap or Multiplied Trap Numbers</h4>
            <ul>
                <li>'Multi-trap' or 'multiplied trap numbers' bets are on the cumulative sum of the winning trap multiplied by the second trap for each race during a race meeting, unless stated otherwise in the Market Information. If the cumulative sum is not a round number then that sum shall be rounded up to give the applicable cumulative sum.</li>
                <li>These markets are unaffected by any changes due to non-runners or reserve runners.</li>
                <li>If a race is cancelled, void or abandoned or if there is only one finisher in a race, an eight runner-greyhound races will be allocated 20 points and all other races will be allocated 12 points.</li>
                <li>If a whole meeting is abandoned all bets are void however if at least one race has been completed then bets on these markets will stand with the remaining races allocated 20 points for eight runner greyhound races and 12 points for all other races.</li>
                <li>Re-run races will be treated as cancelled races.</li>
                <li>If there is a dead heat for the winner between two greyhounds then to calculate the applicable multi-trap number for that race the trap numbers for the greyhounds involved in the dead heat will be multiplied by each other to give the applicable multi-trap number.</li>
                <li>If there is a dead heat for the winner between three or more greyhounds then to calculate the applicable multi-trap number for that race the trap numbers for the greyhounds involved in the dead heat will be added up and divided by the number of greyhounds involved in the dead heat and the resulting number will be multiplied by itself to give the applicable multi-trap number (even if not a round number). By way of example: traps 1, 3 and 6 dead heat for first. Add these numbers up gives 10; divide this number by the number of greyhounds in the dead heat (3) which gives a resulting number of 3.33; multiply 3.33 by itself to give 11.11.</li>
                <li>If there is a dead heat for the runner up between two or more greyhounds then to calculate the applicable multi-trap number for that race the trap numbers for the greyhounds involved in the dead heat will be added up and divided by the number of greyhounds involved in the dead heat and the resulting number will be multiplied by the number of the winner to give the applicable multi-trap number (even if not a round number). By way of example: trap 1 wins and traps 3 and 6 dead heat for second. Adding the numbers of the dead heating runners up gives 9; divide this number by the number of greyhounds in the dead heat (2) which gives a resulting number of 4.5; multiple 4.5 by the winning number (1) to give 4.5.</li>
            </ul>
            <h4>➢ Winning Distances</h4>
            <ul>
                <li>'Winning distances' bets are on the sum of the winning distances for all races during a race meeting, unless otherwise stated in the Market Information.</li>
                <li>The maximum winning distance in any race is 10 lengths.</li>
                <li>If there is only one finisher in any race the winning distance will be deemed to be 10 lengths.</li>
                <li>If a race is cancelled, void or abandoned the winning distance will be deemed to be 2 lengths.</li>
                <li>Re-run races will be treated as cancelled races.</li>
                <li>For distances below half a length, the following scale will be applied: Short-head 0.1; Head 0.2; Neck 0.3.</li>
                <li>The sum of all the winning distances will be rounded to the nearest whole number at the end of the meeting (rounded up, if half) and bets will be settled on this result.</li>
                <li>If a whole meeting is abandoned all bets are void. However, if at least one race has been completed then the remaining races are allocated 2 lengths each and bets will stand.</li>
            </ul>
            <h4>➢ Match Bets</h4>
            <ul>
                <li>'Match Bets' for a race are determined by the greyhound with the highest finishing position in that race. If neither greyhound finishes the race bets are void. If only one of the greyhounds finishes the race, that greyhound will be deemed the winner. If a non-runner or reserve runner is declared then all bets will be void.</li>
                <li>'Match Bets' for progress in a competition are determined by the greyhound which qualifies to the furthest round (whether it runs in the further round or not). If the greyhounds concerned fail to qualify in the same round of the competition then the market will be settled as a dead heat, irrespective of their finishing positions in their individual heats.</li>
            </ul>
            <h4>➢ Reverse Forecasts</h4>
            <ul>
                <li>'Reverse Forecast' markets are determined by those greyhounds placed first and second in a race.</li>
                <li>If a dead heat affects the reverse forecast then dead heat rules apply.</li>
            </ul>
            <h4>➢ Place markets</h4>
            <ul>
                <li>The number of winners in 'to be placed' markets is as set out in the Market Information and is determined with reference to the number of runners when the market is loaded.</li>
                <li>Once opened, the number of winners in 'to be placed' markets (as set out in the Market Information) will not be affected by further non-runners. If the number of potential winners is equal to or is greater than the number of runners, all bets in this market will be void.</li>
            </ul>
            <h4>➢ To Reach the Final and To Qualify markets</h4>
            <ul>
                <li>'Reach the Final' markets shall be determined by the first six greyhounds who qualify from the Semi Finals of the competition and be settled as such regardless of whether they go on to run in the final or not.</li>
                <li>'To qualify' markets shall be determined by the greyhounds that qualify in the relevant heats irrespective of whether they run in the next round or not.</li>
                <li>The 'reach the final' or 'to qualify' markets will be settled after the qualifying stages and any subsequent disqualification or amendment to results will not count.</li>
            </ul>
            <h4>➢ Ante-post</h4>
            <ul>
                <li>If a greyhound listed in an ante-post market dies, it will use the available information to determine the time of the greyhound's death. It will then, acting reasonably, determine whether the greyhound was a 'material runner' in the market. In determining whether a greyhound is a material runner, it will look at the general price of the greyhound immediately before the greyhound's death, in the market and in the wider betting market. Broadly, it will consider a greyhound to be a material runner if it is deemed to have an approximate chance of winning of 8-10 per cent or better.</li>
                <li>If It determines that the greyhound was a 'material runner', then all bets struck in the market (on all selections) between the time of death and the suspension of the market will be void and all unmatched bets will be cancelled before the market is reopened.</li>
                <li>If It determines that the greyhound was not a 'material runner', then just bets struck on the greyhound in question between the time of death and the suspension of the market will be void and unmatched bets will not be cancelled before the market is reopened.</li>
                <li>Bets matched on greyhounds after they have forfeited their entry at a particular entry stage or where they have not qualified by the applicable qualifying date will be voided unless the race in question has a subsequent supplementary entry stage.</li>
                <li>Where an event is postponed or rescheduled to another day at the same venue then ante-post bets will stand unless entries are reopened in which case all bets will be void.</li>
            </ul>
            <h4>➢ Australian Specific Non-Runner Rules</h4>
            <ul>
                <li>Notwithstanding any of the above, the following rules apply to declared non-runners in Australian greyhound markets.</li>
                <li>If a greyhound becomes a notified non-runner after the market is loaded but prior to the commencement of the race it will be removed and all bets on the market, matched prior to the update of the market will be voided.</li>
                <li>If, following the completion of a race, the stewards declare a greyhound a non-runner, it will resettle the market and will void all bets that were placed on that runner only. It will then apply a reduction factor to all bets placed on the winner (or place getters in the case of place markets) based on that runner’s weighted average price.</li>
            </ul>
            <h3>14. Horseracing</h3>
            <h4>➢ General</h4>
            <ul>
                <li>All individual race markets will be determined according to the official result at the time of the 'weigh-in' announcement (or equivalent). Subsequent disqualifications, appeals or amendments to the result will be disregarded.</li>
                <li>If a race is abandoned or otherwise declared void, or in the event of a walkover, all bets on that race will be void.</li>
                <li>If the scheduled venue is changed after the market has been loaded, all bets will be void.</li>
                <li>Where a race does not take part on its scheduled day, all bets will be void.</li>
                <li>If a scheduled surface type is changed (e.g. turf to dirt) all bets will stand.</li>
                <li>Horseracing Exchange Multiples are based on ‘day of the race’ markets (and not ante-post markets). Horseracing ante-post rules do not therefore apply in relation to horseracing Exchange Multiples.</li>
            </ul>
            <h4>➢ Ante-post</h4>
            <ul>
                <li>Subject to the points below, in ante-post markets, all bets on an individual horse stand whether the horse runs or not.</li>
                <li>For GB and Irish racing: if a horse is balloted out, all bets on that horse will be void.</li>
                <li>If a horse listed in an ante-post market dies, it will use the available information to determine the time of the horse's death. It will be acting reasonably, determine whether the horse was a 'material runner' in the market. It will undertake a similar determination in cases where it is advised through official channels that a runner has been formally scratched from an ante-post race. In determining whether a horse is a material runner, it will consider the general price of the horse immediately before the horse's death (or immediately before being advised it has been scratched), in the market and in the wider betting market. Broadly, it will consider a horse to be a material runner if it is deemed to have an approximate chance of winning of 8-10 per cent or better.</li>
                <li>If it determines that the horse was a 'material runner', then all bets struck in the market (on all selections) between the time of death (or the time that the formal scratching notification was created) and the suspension of the market will be void and all unmatched bets will be cancelled before the market is reopened.</li>
                <li>If it determines that the horse was not a 'material runner', then just bets struck on the horse in question between the time of death (or the time that the formal scratching notification was created) and the suspension of the market will be void and unmatched bets will not be cancelled before the market is reopened.</li>
                <li>Bets matched on horses after they have forfeited their entry at a particular entry stage or where they have not qualified by the applicable qualifying date will be voided unless the race in question has a subsequent supplementary entry stage.</li>
                <li>Please be aware that runners who have not been entered at the various entry stages may be removed from relevant race markets and all matched customer bet prices set to 1.0 even if there are later supplementary stages. Should it appear likely that a specific runner may actually be supplemented into the race this runner will be reinstated with all matched customer bets set back to the original prices.</li>
                <li>No Non-Runner Rule' reductions are made to ante-post bets.</li>
                <li>All relevant bets will be void where an event is: abandoned and not rescheduled; or postponed and rescheduled to another venue; or postponed and rescheduled to another day at the same venue with entries for the race being reopened or reverting back to a previous entry or declaration stage provided any additional horses are entered or re-entered and such entries or declarations are considered, in absolute discretion, material to the betting on the race. For the avoidance of doubt, where a race is postponed and rescheduled to another day at the same venue, relevant bets will stand where entries/declarations at the time of postponement remain unaltered or include any non-material addition(s) prior to the rescheduled race.</li>
            </ul>
            <h4>➢ Place Terms for Exchange markets</h4>
            <ul>
                <li>The number of winners in 'to be placed' markets is as set out in the Market Information and is determined with reference to the number of runners when the market is loaded.</li>
                <li>Once opened, the number of winners in 'to be placed' markets will not be affected by further non-runners.</li>
                <li>If the number of winners stated in the Market Information is equal to or is greater than the number of runners, all bets in this market will be void.</li>
                <li>If the number of placed horses is less than the number of potential winners listed in the Market Information, the winners will only be the placed horse or horses.</li>
            </ul>
            <h4>➢ EW markets</h4>
            <ul>
                <li>The place portion of any "Each Way" bet will be settled according to the number of 'places' and at the fraction of the win portion profit stated in the market information.</li>
                <li>For example: “EW Terms: 1/5th odds, 3 places”. That “1/5th” is applied to the traditional or fractional odds, which are 1 less than decimal odds, so the calculation of the Place odds corresponding to Win odds of 8.0 in this market is ((8.0 – 1) / 5) + 1 = 2.4.</li>
                <li>Once opened, the number of places in 'EW” markets will not be affected by further non-runners unless the number of ‘places’ which are offered on the ‘Each Way’ market is equal to or exceeds the number of runners in which case, the place portion of any EW bet will be voided at settlement.</li>
            </ul>
            <h4>➢ Winning Distance Bets</h4>
            <ul>
                <li>Unless stated otherwise in the Market Information, winning distances' bets are on the sum of the winning distances from each race on one particular day at a race meeting.</li>
                <li>The winning distance in a race will be the officially declared distance between the first two horses past the post. However, if either the first or second horse past the post is disqualified because: (i) of an incorrect weight carried; (ii) they have taken the wrong course; (iii) the jockey of either horse fails to weigh in, or weighs in light after the race; then in each case the winning distance shall be that between the first and second horse under the official result.</li>
                <li>
                    For the purpose of this bet, the maximum distance on any individual race will be 12 lengths for Flat races and 30 lengths for National Hunt races (which includes National Hunt Flat Races) and these distances will be applied where only one horse finishes a race. For distances below half a length, the following scale will be applied:
                    <ul>
                        <li>Nose: 0.05 length</li>
                        <li>Short-head: 0.1</li>
                        <li>Head: 0.2</li>
                        <li>Short-neck: 0.25</li>
                        <li>Neck: 0.3</li>
                    </ul>
                </li>
                <li>If a meeting has three or more races abandoned or declared void, then all 'winning distance' bets will be void, unless the entire market has been unconditionally determined.</li>
                <li>When only one or two races are abandoned or declared void, then 'winning distance' bets will be settled with a default distance used for each abandoned or void race. The default distances are 2 lengths for Flat races and 10 lengths for National Hunt races.</li>
                <li>
                    In the case of a walkover the following distances will be applied:
                    <ul>
                        <li>Flat: 5 lengths</li>
                        <li>National Hunt: 12 lengths</li>
                    </ul>
                </li>
            </ul>
            <h4>➢ Non-Runner Rule</h4>
            <ul>
                <li>Non-runner rule relates to the adjustment of odds on bets already matched when a horse in a race is declared a non-runner. In order to make the adjustment applies a reduction factor to the remaining runners. The reduction factor allocated to a non-runner is a calculation (the details of which are described below) of that horse's chances of winning (or being placed, etc. as appropriate) and is applied to bets already matched on the other runners in the relevant market or markets.</li>
                <li>Any horse listed when the relevant market is loaded which does not subsequently come under starter's orders is deemed to be a non-runner.</li>
                <li>When the market is loaded each horse is given a 'reduction factor', based on a forecast price, which is expressed as a percentage. These reduction factors may be updated periodically at the discretion based on trading in the market, but after approximately 15 minutes (approximately 5 minutes for Australian and US markets) from the scheduled 'off' time of a given race, they will be updated only in exceptional circumstances.</li>
                <li>Reductions will be made to both win and place markets but applied differently (as described below), and horses will have a different reduction factor for each market.</li>
                <li>As soon as becomes aware that a horse is an official non-runner or a highly likely non-runner, following a statement to the press from connections, the following will happen:</li>
                <li>All matched bets on that horse will be void and the horse will be removed from the market.</li>
                <li>In the win market: if the reduction factor of the non-runner is 2.5% or greater, the traded price of all the matched bets on the remaining horses will be reduced by an amount equal to the non-runner's final reduction factor and all the unmatched offers to lay will be cancelled. If the non-runner's reduction factor is less than 2.5%, reductions will not be applied and unmatched bets will not be cancelled.</li>
                <li>In the place market, the reduction factor of all non-runners will be applied (even if less than 2.5%) and the potential winnings in relation to matched bets on the remaining horses will be reduced by an amount equal to the non-runner's final reduction factor. Only if the non-runner's reduction factor is 4.0% or greater will all the unmatched offers to lay be cancelled.</li>
                <li>All the reduction factors on the remaining horses will be adjusted to reflect their improved chance of winning.</li>
                <li>Reduction factors are not applied to bets which are struck in-play. However, if a market is turned in-play prematurely by error (or, for example, there is a false start), all bets matched during this time will be subject to any later reduction factor, provided the market is turned out of play before the race commences. In the event of a late withdrawal, it reserves the right to remove the runner after completion of the race. In this case only those bets matched prior to the off will be affected by a reduction factor.</li>
                <li>In the event of a non-runner being removed from a race in error or following incorrect information regarding a runner’s participation, it will reinstate both the runner and all previously matched bets associated with that runner. All bets made between the time of withdrawal and reinstatement will be void in both the place market and the win market. The reduction factor applied to matched bets at the time of withdrawal will be reversed and the original prices will become valid.</li>
                <li>Any non-runners will be removed from the relevant markets in the order in which they are brought to attention. If It becomes aware of more than one non-runner at the same time, it will remove the non-runners from the relevant markets in race card order.</li>
                <li>If a runner is not included in a market because of an error or because of incorrect information regarding a runner’s participation, It reserve the right to introduce the missing runner into the market at any time prior to settlement (even after the race has been run), provided that It has determined that the missing runner is not a material runner (i.e. a selection with a reduction factor of approx. 2.5% or less in the win market). In such circumstances, all pre-play unmatched and matched bets will stand, however if the runner is not introduced before the start of the race, all in-play bets will be void. However, if the missing runner is deemed to be a material runner, then the malformed market will be void and a new market will be loaded where possible.</li>
            </ul>
            <h4>➢ How the Reductions are applied for Exchange markets</h4>
            <ul>
                <li>In the win market, reductions will be made on the traded price.</li>
                <li>For example: if the non-runner's final reduction factor is 25% the traded price on all previously matched bets on other horses will be reduced by 25% - traded price of 8.0 would become 6.0 etc. And these might be further reduced if another horse is subsequently declared a non-runner.</li>
                <li>In the EW Market, reductions will be made on the traded win price. The advertised place terms will then apply to the revised win prices.</li>
                <li>For example: if the non-runner's final reduction factor is 25% the traded price on all previously matched bets on other horses will be reduced by 25% - - traded price of 8.0 would become 6.0. If each Way terms were 1/5th odds for 3 places, the corresponding price for the Place portion of the bet would reduce from 2.4 to 2.0.</li>
                <li>In the place market, reductions will be made to the potential winnings on the bet only, and not the traded price.</li>
                <li>For example: if the non-runner's final reduction factor is 25% the potential winnings on all previously matched bets on the other horses will be reduced by 25% - a traded price of 8.0 would become 6.25. For example, a £10 bet on a horse to be placed at a traded price of 8.0 would provide winnings of £70. If there is a non-runner with a reduction factor of 25% in the race, that factor will be applied to the £70 of potential winnings leaving potential winnings of £52.50. Therefore, the revised traded price will be 6.25.</li>
                <li>The traded price may be further reduced if any other horse(s) is subsequently declared a non-runner, however odds cannot be reduced below 1.01.</li>
                <li>Reserves: A reserve runner may appear in the relevant markets but will have a non-applicable reduction factor until It has received confirmation that it is a confirmed runner, in which case an applicable reduction factor may apply to it.</li>
                <li>For the avoidance of doubt, any reduction factor applicable to a non-runner replaced by a reserve, will be applied to all bets struck on the relevant markets, prior to the removal from those markets of such non-runner. Likewise, should a reserve runner become a confirmed runner but subsequently become a non-runner, any reduction factor applicable to such non-runner will be applied to all bets struck on the relevant markets, prior to the removal from those markets of such non-runner.</li>
            </ul>
            <h4>➢ Additional rules</h4>
            <ul>
                <li>Card numbers are posted as a guide only: bets are placed on a named horse.</li>
                <li>Horses will not be coupled.</li>
                <li>Where any horse(s) runs for purse money only it is deemed a non-runner for betting purposes. Should this result in the number of possible winners stated in the relevant Market Information being equal to or greater than the number of runners in the relevant market, all bets in the market will be void.</li>
            </ul>
            <h3>15. Ice Hockey</h3>
            <ul>
                <li>All bets on "Regular Time" markets will be settled on the result at the end of regular time, excluding overtime. All bets on " Moneyline " markets will be settled at the result at the end of regular time including "overtime and any shootouts that may be played".</li>
                <li>'Puck Line' markets (i.e. handicap markets for NHL (National Hockey League) matches) will be settled on the final result including any overtime and any shootouts that may be played. All 'handicap' markets on other matches will be settled on the result at the end of regular time, excluding overtime.</li>
                <li>If a match does not start on the scheduled start date and is not completed within three days of the scheduled start date, all bets will be void except for those on markets which have been unconditionally determined.</li>
                <li>If a match starts but is later abandoned or postponed then, within three days of the scheduled start date, (a) at least 55 minutes of play must have elapsed in any match; or (b) an official result must be 'called' by the relevant governing body; otherwise all bets will be void, except for those which have been unconditionally determined. In these instances, if the scores are tied then for " Moneyline " matches (where no tie is offered) dead heat rules will apply to bets on the outright match winner market.</li>
            </ul>
            <h3>16. Rowing</h3>
            <ul>
                <li>If a crew or individual starts a race but does not complete it then they will be deemed a loser providing at least one other crew or individual completes the race. If no crew or individual completes a race then all bets will be void.</li>
                <li>If a regatta is cancelled for any reason, all bets will be void, except those on markets which have been unconditionally determined.</li>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
            </ul>
            <h3>17. Rugby Union and Rugby League</h3>
            <ul>
                <li>'Tournament points' and 'tournament tries' bets will apply to all playing time, including any extra-time in any match where an official result is declared.</li>
                <li>For 'time of first try' bets, if the try is scored in the second half, the first half is deemed to have lasted 40 minutes, regardless of stoppage-time. If no try is scored, or the try is scored in second half stoppage-time or extra-time, the result is 80.</li>
                <li>For the purpose of markets involving tries, penalty tries will count with the exception of 'first individual try scorer' markets where penalty tries will not count.</li>
                <li>If a match starts but is abandoned before its completion, all bets will be void unless an official result is declared by the applicable governing body. Where an official result has been declared by the official governing body, that official result will govern match and handicap market settlement but all other markets will be void unless their result has already been determined at the point of abandonment (i.e. at the point of abandonment, it would not have been possible for the outcome of the bet to change had the match continued to its natural conclusion). By way of example, if 37 points have been scored at the time a match is abandoned: (i) a bet placed on 35 points or more to be scored would be settled as a winning bet, (ii) a bet placed on 30-35 points to be scored would be settled as a losing bet and (iii) a bet placed on 40-45 points to be scored would be void. In such circumstances, bets on last try scorer / team to score last / team to score last try / last scoring play would all be void.</li>
            </ul>
            <h3>18. Snooker and Pool</h3>
            <ul>
                <li>In the event of a match starting but not being completed, the player progressing to the next round will be deemed the winner (or in the case of the final the player declared the winner).</li>
                <li>If a match is not completed for any reason then bets on 'any correct score' or 'next frame' market will be void.</li>
                <li>If a match is not completed for any reason, bets on any handicap market will be void unless the market has been unconditionally determined.</li>
            </ul>
            <h3>19. Soccer</h3>
            <ul>
                <li>If it does not suspend a market on time for the occurrence of a Material Event, it reserves the right to void bets unfairly matched after the Material Event has occurred. Voiding of these bets may take place during the event or retrospectively once a game is completed.</li>
                <li>If a match has not started (or believes that a match will not have started) by 23:59 (local time) on its scheduled start date, then all bets will be void unless it has knowledge that the match has been rescheduled to be played within three days of its original start date.</li>
                <li>If a match starts but is later abandoned or postponed and believes that the match will not have been completed by 23:59 (local time) on its scheduled start date, then all markets, with the exception of any unconditionally determined markets, will be void unless it has knowledge that the match has been rescheduled to be played within three days of its original start date. If it does have knowledge that the game will be played within three days and the game is played within three days, then all bets will stand except if the match is restarted from the beginning. If the match is restarted from the beginning then all bets matched before the market went in-play will stand, but any bets placed in-play will be void, except for any bets placed in-play on markets which have been unconditionally determined, which will stand.</li>
                <li>For Friendly matches, all bets apply to the full duration of play according to the match officials, plus any stoppage time. If a friendly match starts but is later abandoned or postponed and is not completed (i.e. the full duration of play according to match officials, plus any stoppage time) within three days of the scheduled start date, all bets will be void except for those on markets which have been unconditionally determined. In the case of ambiguity over the official result from match officials, the outcome will be determined (acting reasonably) using information from independent sources.</li>
                <li>If an official fixture lists different team details to those listed (for example, the team name, reserves, age group, gender, etc.), then all bets matched on the affected markets will be void. In all other cases, bets will stand (including instances where a team name is listed without specifying the term 'XI' in the name). If an official fixture is shown on the website under an incorrect competition name, then it reserves the right to void all bets matched on the affected markets.</li>
                <li>If a team is relegated from a league because, at the end of a season, it has finished within the relegation positions which are relevant to that league (i.e. usually any of the bottom three league positions), bets on that team to be relegated will be settled as winning bets. If a team is otherwise disqualified, thrown out or removed from a league (i.e. in circumstances other than those where it has finished the season within the relevant relegation positions): (i) if such team is disqualified, thrown out or removed from the league before the relevant season has started, all bets on the affected market will be void (and a new market will subsequently be loaded) and (ii) if such team is disqualified, thrown out or removed from the league after the relevant season has started (or a determination is made by the applicable governing body, during the season, that the team will be thrown out or removed from the league following the season’s conclusion), all bets on the affected team will be void. For the avoidance of doubt, if a points deduction is imposed on a team such that it finishes the season within the relegation places which are relevant to the applicable league, bets on that team to be relegated will be settled as winning bets.</li>
                <li>The relevant season will be deemed to have started once the first league game has been played. For the purposes of this rule, markets relating to individual matches will not be deemed to be "affected markets".</li>
                <li>'Shirt numbers' bets will refer to the shirt number allocated at the start of the match. 'Shirt numbers' bets will include own-goal scorers. Any player whose shirt bears no number will be allocated the number 12.</li>
                <li>For 'time of first goal' bets (i.e. "First Goal Odds" markets), the first half is deemed to last 45 minutes, regardless of stoppage time. Also for these markets, please note that the "0 - 10 Minutes" selection covers the first 10 minutes of the match. In other words, it runs from 0:00 until just before the clock hits 10:00. The "11 – 20 Minutes" selection runs from 10:00 until just before the clock hits 20:00. The same principle applies to each of the other selections in this market.</li>
                <li>For 'top goal scorer' markets only the goals scored in the league or competition stated in the Market Information count. For example, if a player joins a club mid-season any goals scored in a different league will not count, however goals scored for a different club in the same league will count. Own goals will not count.</li>
                <li>In markets which relate to the number of incidents to occur, such as 'number of corners', these will be determined on the basis of the number taken, rather than awarded.</li>
                <li>For markets that relate to the number of bookings given, the number of corners taken, any goal scorer or the time of a particular goal, the result will be determined (acting reasonably) using information from independent sources. In such cases, if any new information comes into the public domain within 48 hours of settlement, then it shall (acting reasonably) determine either: (i) whether the market should be reinstated or resettled in light of this new information; or (ii) to wait for further information before deciding whether to reinstate or resettle the market. Except it has announced that it is waiting for further information, any information that comes into the public domain more than 48 hours after a market has been settled shall not be considered (regardless of whether or not such information may have led to a different result).</li>
            </ul>
            <h3>20. Swimming</h3>
            <ul>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result)</li>
            </ul>
            <h3>21. Tennis</h3>
            <ul>
                <li>If a player or pairing retires or is disqualified in any match, the player or pairing progressing to the next round (or winning the tournament in the case of a final) will be deemed the winner. However, if less than one set has been completed at the time of the retirement or disqualification then all bets relating to that individual match will be void.</li>
                <li>All bets relating to the number of occurrences of certain events within a tournament will be void if the tournament is reduced in length, postponed or cancelled, except for those on markets which have been unconditionally determined.</li>
                <li>All bets will stand regardless of changes to scheduled venues, including any changes to a different type of surface.</li>
                <li>If the scheduled duration of a match is reduced or increased in the number of games/sets required to win, all bets will be void except for those on markets which have been unconditionally determined. Please note that this does not apply to ‘Match Odds’ or ‘Set Winner’ markets on Davis Cup matches or ‘dead rubber’ matches that have been shortened from five sets to three sets after the market has been loaded, provided that the match has been shortened in accordance with the competition’s rules.</li>
                <li>Where markets are offered on individual games or sets within a match, a retirement or disqualification during a game or set will render bets on that game or set market and all individual game or set markets void except those on markets which have been unconditionally determined.</li>
            </ul>
            <h3>22. Winter sports (which may include, amongst others, Alpine Skiing, Biathlon, Cross-Country Skiing and Ski-Jumping)</h3>
            <ul>
                <li>Where there is a presentation ceremony, markets will be settled on the official result of the relevant governing body at the time of the ceremony, regardless of any subsequent disqualification or amendment to the result.</li>
                <li>If there is no presentation ceremony, outcomes will be determined in accordance with the official result of the relevant governing body, regardless of any subsequent disqualification or amendment to the result (except if an amendment is announced within 24 hours of the initial settlement of the relevant market in order to correct an error in reporting the result).</li>
                <li>Unless stated otherwise in the Market Information, if an event is abandoned, postponed or cancelled, all bets will be void unless (a) the event is completed at the same venue within 7 days of the official scheduled completion date; or (b) a result is 'called' by the relevant governing body.</li>
                <li>The General Rules will apply for 'match bets'. However, in respect of "Nordic Combined" if all competitors involved in the match bet do not start both sections of the event (ski jumping and cross country) then bets will be void.</li>
            </ul>
        </div>
    }
    return false;
}

export default TermsTemplate;