import '../assets/css/style.css';
import React, { useRef, useContext, useState } from 'react';
import BetSlip from '../includes/BetSlip';
import LeftSideBar from '../includes/LeftSideBar';
import { toast } from "react-toastify";
// import { SocketContext } from "../../context/socket";
import { Modal, Tooltip, Fade, IconButton, Backdrop, ClickAwayListener, Grid, makeStyles, Table, TableRow, TableCell, TableHead, TableBody, Link, Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
//IMAGES
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import background from '../assets/images/bg-purple.png';
import transparent from '../assets/images/transparent.gif';
import Time from '../assets/images/clock-green.png';
import fancyicon from '../assets/images/fancy.svg';
import BtnDelete from '../assets/images/btn-delete.svg';
import { useParams, useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Appconfig from "../config/config"
import axios from 'axios';
import Loader from '../assets/images/loading40.gif';
import { useQuery, useQueryClient } from 'react-query';
import { postErrorToslack } from '../config/common';

function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
        height: "100%",
        marginBottom: "48px"
    },
    tableHeight: {
        height: 'calc(100vh - 120px)',
    },
}));
const MultimarketComponent = (props) => {
    const params = useParams();
    const queryClient = useQueryClient();
    const storedList = JSON.parse(window.localStorage.getItem(`eventDetailsL_${props.event_id}`));
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView();
    const history = useHistory();
    // const socket = useContext(SocketContext);
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [postionFancyList, setPostionFancyList] = React.useState([]);
    const [positionOpen, setPositionOpen] = React.useState(false);
    const eventId = props.event_id;
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedB: false,
    });
    const [isBetPlaced, setIsBetPlaced] = React.useState(false);
    const [bookButton, setBookButton] = React.useState([]);
    const [betShow, setBetShow] = React.useState(false);
    const [EventData, setEventData] = React.useState([]);
    const [StakeValue, setStakeValue] = React.useState(0);
    const [placing, setPlacing] = React.useState(false);
    const [lossValue, setLossValue] = React.useState(0);
    const [toolFancytipOpen, setFancytooltipOpen] = React.useState(null);
    const [defaultStake, setDefaultStake] = React.useState(0);
    const [BetPlaceData, setBetPlaceData] = React.useState({
        event_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
    });
    const [ProfitValue, setProfitValue] = React.useState(0);
    function handleFancyTooltipOpen(index) {
        // alert(index);
        setFancytooltipOpen(toolFancytipOpen === index ? null : index);
    };
    function pinmarket(event_id, market_id, type) {
        loader_default();
        var data = JSON.stringify({
            event_id: event_id,
            market_id: document.querySelector(`#${market_id}`).value,
            user_id: userInfo._id,
            type: type
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}favorite/add&removeFavorite`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    refetch();
                    loader_remove();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const placeStakeValue = (stake) => {
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };
    const handleBetInputChange = (e) => {
        setStakeValue(e.target.value);
    }
    const rows = [
        createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '1 - 1 ', 'maxime', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('king', '3 - 1 ', 'cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring', '2 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Forge', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
    ];
    const positionClose = () => {
        setPositionOpen(false);
    }
    const [tvUrl, setTvUrl] = React.useState("");
    const [scoreUrl, setScoreUrl] = React.useState("");
    const [iframeHeight, setScoreIframeHeight] = React.useState("");
    const [chipList, setchipList] = React.useState([]);
    function getDefaultStakes() {
        var data = JSON.stringify({
            user_id: userInfo._id,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getDefaultStakes`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result == 0) {
                    } else {
                        setDefaultStake(response.data.resultData.default_stake)
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    React.useEffect(() => {
        if (userInfo) {
            getChips();
            getDefaultStakes()
        }
        window.addEventListener("message", (event) => {
            if (event.origin == "https://central.satsport247.com'")
                console.log("dont remove", "socre width")
            setScoreIframeHeight(event.data.scoreWidgetHeight)
        }, false);
        // console.log("profit,loss", [ProfitValue, lossValue])
        // socket.on('fancy_update', function (data) {
        //     // console.log('FANCY UPDATE:', data);
        //     // console.log(data);
        //     fancyHtml(data);
        // });
        // socket.on("market_update", (data) => {
        //     // return false;
        //     //events   
        //     // console.log('MARKET UPDATE:', data);
        //     var matchId = eventId;
        //     // if (MarketId) {
        //     // var market = data.marketodds[matchId]
        //     if (data.marketodds.length > 0) {
        //         var market = data.marketodds.find(o => o.event_id == matchId);
        //         // console.log('market',market);
        //         if (market.hasOwnProperty("market_types")) {
        //             if (market.market_types.length > 0) {
        //                 market.market_types.map((market_type, index) => {
        //                     market_type.hasOwnProperty("runners") && market_type.runners.length > 0 && market_type.runners.map((runner, index) => {
        //                         if (market_type.status == 'OPEN') {
        //                             // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeOut();
        //                         } else {
        //                             // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeIn();
        //                             // document.getElementsByClassName('status_matchBoxs_' + market_type.market_id.replace('.', '')).text(market_type.status);
        //                         }
        //                         // console.log('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id[0]);
        //                         var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)[0];
        //                         if (runner.status == 'SUSPENDED') {
        //                             if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                                 if (match_odd_row) {
        //                                     match_odd_row.parentElement.setAttribute('data-title', "SUSPENDED");
        //                                     match_odd_row.parentElement.classList.add("suspended");
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 } else {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 } else {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 }
        //                             }
        //                         } else {
        //                             if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                                 if (match_odd_row) {
        //                                     match_odd_row.parentElement.setAttribute('data-title', "");
        //                                     match_odd_row.parentElement.classList.remove("suspended");
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
        //                                 } else {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
        //                                 } else {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
        //                                 }
        //                             }
        //                             // if (parseFloat(document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_price)) {
        //                             //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;
        //                             // } else {
        //                             //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;
        //                             // }
        //                             // if (parseFloat(document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_price)) {
        //                             //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;
        //                             // } else {
        //                             //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;
        //                             // }
        //                         }
        //                         /************************Size */
        //                         // if (parseFloat(document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_size)) {
        //                         //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                         // if (parseFloat(document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_size)) {
        //                         //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                         if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                             if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
        //                                 document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                             } else {
        //                                 document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
        //                                 //  document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                             }
        //                             if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
        //                                 document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                             } else {
        //                                 document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                             }
        //                         }
        //                         // if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
        //                         //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                         // if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
        //                         //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                     });
        //                 });
        //             }
        //         }
        //     }
        // });
        if (queryClient.getQueryData(`eventDetails_${props.event_id}`)) {
            setEventData(queryClient.getQueryData(`eventDetails_${props.event_id}`).data.resultData);
        }
        // getEventData()
    }, [props.event_id])
    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;
            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);
                    } else {
                        curr = winloss + ((priceVal * stake) - stake);
                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                    } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = Math.round(curr);
            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
            }
            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).setAttribute('data-value', currV)
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML = Math.abs(currV);
                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)
                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
            }
        }
    }
    function fancyHtml(data) {
        // console.log("kaka", data);
        var superiors = ["270", "259", "185", "177", "30"];
        var matchId = eventId;
        if (document.getElementById('fancyAPI')) {
            // document.getElementById('fancyAPI').innerHTML = `<tr class="MuiTableRow-root yelloBG"><td class="MuiTableCell-root MuiTableCell-body table_first_row colorDanger"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body bg_odd MuiTableCell-alignRight">Back</td><td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight">Lay</td></tr>`;
        }
        if (matchId) {
            if (data.fantacy.length > 0) {
                var fancys = data.fantacy.find(o => o.event_id == matchId);
                if (fancys) {
                    fancys = fancys.fancy_data;
                    if (fancys.length) {
                        for (var j = 0; j < fancys.length; j++) {
                            if (fancys[j].runner_name.includes('ball')) {
                                continue;
                            }
                            if (fancys[j].cron_disable == 'Yes') {
                            } else {
                                if (fancys[j].is_active == "No") {
                                    document.getElementsByClassName('fancy_' + fancys[j].selection_id).remove();
                                }
                                if (fancys[j]) {
                                    var block_market_fancys = fancys[j].block_market;
                                    var block_all_market_fancys = fancys[j].block_all_market;
                                    var find_fancy_all_block = block_all_market_fancys.filter(element => {
                                        return superiors.includes(element.user_id.toString())
                                    });
                                    if (find_fancy_all_block.length > 0) {
                                    } else {
                                        var find_fancy_block = block_market_fancys.filter(element => {
                                            return superiors.includes(element.user_id.toString())
                                        });
                                        if (find_fancy_block.length > 0) {
                                        } else {
                                            var fancyHtml = '';
                                            if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {
                                                fancyHtml = `<div class="all custom-container-fancy fancy_${fancys[j].selection_id} ">
<div id="fancyBetTag">
    <div id="" class="bets-wrap fancy-bet">
        <h5 p="1">
            <span id="marketName">${fancys[j].runner_name}</span>
              <a href="#" id="open-fancy_info" class="btn-fancy_info">fancybet info</a>
            <div id="fancy_popup2" class="fancy_info-popup">
                <dl>	
                    <dt>Min / Max</dt>
                    <dd id="minMax">1.00 / 800.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName">Rebate</dt>
                    <dd id="rebate"></dd>
                </dl>
                <a href="#" id="close-fancy_info" class="close">Close</a>
            </div>
        </h5>
        <dl p="1" class="odd_even_clr odd_even_clr_${fancys[j].selection_id} bets-selections fancy_${fancys[j].selection_id} " >
            <dt class="line_market-selection">
                <dl class="tips">
                    <dt><span id="before" class="win"></span><span id="after" class="to-lose"></span></dt>
                    <dd id="remarkFirstRow"></dd>
                    <dd id="remarkSecondRow"></dd>
                </dl>
            </dt>
            <dd id="lay_1">
                <a class="lay-1 blue-core" onclick=
                handleOpen(
                  ${eventId},
                  "",
                  false,
                  ${fancys[j].lay_price1},
                  true,
                  ${fancys[j].selection_id},
                  ${fancys[j].runner_name},
                  fancy_lay_price_${fancys[j].selection_id}
                ) href="#">
                    <div class="widthhundredfancy" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div>
                    <span class="widthhundredfancy" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                </a>
            </dd>
            <dd id="back_1">
                <a class="back-1 pink-core" onclick=
                handleOpen(
                  ${eventId},
                  "",
                  true,
                  ${fancys[j].back_price1},
                  true,
                  ${fancys[j].selection_id},
                  ${fancys[j].runner_name},
                  fancy_lay_price_${fancys[j].selection_id}
                ) href="#">
                    <div class="widthhundredfancy" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)}</div>
                    <span class="widthhundredfancy" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                </a>
            </dd>
        </dl>
    </div>
</div>
<div p="1" class="pbb place-fancybet-block_${fancys[j].market_id}_${fancys[j].selection_id}">
    <div class="xs-bet-slip lcolor" colspan="7">
        <div class="bet-list">
            <div>
                <p>&nbsp;</p>
                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained typed" tabindex="0" type="button"><span class="MuiButton-label"></span><span class="MuiTouchRipple-root"></span></button>
            </div>
            <div class="input-num">
                <p>Min Bet</p>
                <div>
                    <a href="#" style="border-top-left-radius: 1.6vw; border-bottom-left-radius: 1.6vw;">
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5v-2h14v2z"></path></svg>
                    </a>
                    <span class="typeing"></span>
                    <a href="#" style="border-top-right-radius: 1.6vw; border-bottom-right-radius: 1.6vw;">
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="coin-list">
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10</a>
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">20</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5000</a>
            <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10000</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">25000</a>
        </div>
        <div class="keyboard-wrap">
            <div class="btn-tel">
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">1</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">2</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">3</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">4</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">6</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">7</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">8</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">9</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">0</a>
                <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">00</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">.</a>
            </div>
            <a href="#"><img src="/static/media/btn-delete.263a4b8e.svg" /></a>
        </div>
        <div class="btn-list"><a class="cancelbtn" href="#">Cancel</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover placebtn disable MuiTypography-colorPrimary" to="/#">Place Bet</a></div>
        <div class="acceptodds l-dark-color">
            <label><input type="checkbox" /><a>Accept Any Odds</a></label>
        </div>
    </div>
</div>
</div>
`;
                                                //                         fancyHtml += `<tr class="MuiTableRow-root fancy_${fancys[j].selection_id}" >`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body table_first_row">`;
                                                //                         fancyHtml += `<div class="text_left">
                                                //     <button onclick=getFancyPosition(${fancys[j].selection_id}) className="book-fancy">Book</button>
                                                //     <p >${fancys[j].runner_name}</p></div>`;
                                                //                         fancyHtml += `</td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight" 
                                                //     onclick=
                                                // handleOpen(
                                                //   ${eventId},
                                                //   "",
                                                //   false,
                                                //   ${fancys[j].lay_price1},
                                                //   true,
                                                //   ${fancys[j].selection_id},
                                                //   ${fancys[j].runner_name},
                                                //   fancy_lay_price_${fancys[j].match_id}_${fancys[j].selection_id}
                                                // )>`;
                                                //                         fancyHtml += `<div class="fancy-td-cell"><div class="fancy_lay_price_${fancys[j].selection_id}" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div><p class="eventP fancy_lay_size_${fancys[j].selection_id}" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</p></div>`;
                                                //                         fancyHtml += `</td >`;
                                                //                         fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_odd sus-parent MuiTableCell-alignRight" 
                                                //     onclick=
                                                // handleOpen(
                                                //   ${eventId},
                                                //   "",
                                                //   true,
                                                //   ${fancys[j].back_price1},
                                                //   true,
                                                //   ${fancys[j].selection_id},
                                                //   ${fancys[j].runner_name},
                                                //   fancy_lay_price_${fancys[j].selection_id}
                                                // )>`;
                                                //                         fancyHtml += `<div class="fancy-td-cell"><div class="fancy_back_price_${fancys[j].selection_id}" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)} </div> <p class="eventP fancy_back_size_${fancys[j].selection_id}" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].back_size1)}</p></div></td>`;
                                                //                         fancyHtml += `</tr >`;
                                                // console.log('fancy html', fancyHtml);
                                                if (document.getElementById('fancyAPI')) {
                                                    document.getElementById('fancyAPI').innerHTML += fancyHtml;
                                                }
                                            }
                                            var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                                            var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];
                                            if (document.getElementById('fancy_lay_price_' + fancys[j].selection_id)) {
                                                if (fancys[j].game_status == 'Ball Running') {
                                                    newdiv.setAttribute('data-title', "BALL RUNNING");
                                                    newtr.classList.add("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                                                } else if (fancys[j].game_status == 'SUSPENDED') {
                                                    newdiv.setAttribute('data-title', "SUSPENDED");
                                                    newtr.classList.add("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                                                } else if (fancys[j].back_price1 == 0) {
                                                    newtr.classList.remove("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = '-';
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                                                } else {
                                                    newtr.classList.remove("suspended");
                                                    document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                                                    document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                                                    document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                                                    document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    var fancyHtml = '';
                                    if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {
                                        fancyHtml = `<div class="all custom-container-fancy fancy_${fancys[j].selection_id} ">
                                        <div id="fancyBetTag">
                                            <div id="" class="bets-wrap fancy-bet">
                                                <h5 p="1">
                                                    <span id="marketName">${fancys[j].runner_name}</span>
                                                      <a href="#" id="open-fancy_info" class="btn-fancy_info">fancybet info</a>
                                                    <div id="fancy_popup2" class="fancy_info-popup">
                                                        <dl>	
                                                            <dt>Min / Max</dt>
                                                            <dd id="minMax">1.00 / 800.00</dd>
                                                        </dl>
                                                        <dl>
                                                            <dt id="rebateName">Rebate</dt>
                                                            <dd id="rebate"></dd>
                                                        </dl>
                                                        <a href="#" id="close-fancy_info" class="close">Close</a>
                                                    </div>
                                                </h5>
                                                <dl p="1" class="odd_even_clr odd_even_clr_${fancys[j].selection_id} bets-selections fancy_${fancys[j].selection_id} " >
                                                    <dt class="line_market-selection">
                                                        <dl class="tips">
                                                            <dt><span id="before" class="win"></span><span id="after" class="to-lose"></span></dt>
                                                            <dd id="remarkFirstRow"></dd>
                                                            <dd id="remarkSecondRow"></dd>
                                                        </dl>
                                                    </dt>
                                                    <dd id="lay_1">
                                                        <a class="lay-1 blue-core" onclick=
                                                        handleOpen(
                                                          ${eventId},
                                                          "",
                                                          false,
                                                          ${fancys[j].lay_price1},
                                                          true,
                                                          ${fancys[j].selection_id},
                                                          ${fancys[j].runner_name},
                                                          fancy_lay_price_${fancys[j].selection_id}
                                                        ) href="#">
                                                            <div class="widthhundredfancy" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div>
                                                            <span class="widthhundredfancy" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                                                        </a>
                                                    </dd>
                                                    <dd id="back_1">
                                                        <a class="back-1 pink-core" onclick=
                                                        handleOpen(
                                                          ${eventId},
                                                          "",
                                                          true,
                                                          ${fancys[j].back_price1},
                                                          true,
                                                          ${fancys[j].selection_id},
                                                          ${fancys[j].runner_name},
                                                          fancy_lay_price_${fancys[j].selection_id}
                                                        ) href="#">
                                                            <div class="widthhundredfancy" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)}</div>
                                                            <span class="widthhundredfancy" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</span>
                                                        </a>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div p="1" class="pbb place-fancybet-block_${fancys[j].market_id}_${fancys[j].selection_id}">
                                            <div class="xs-bet-slip lcolor" colspan="7">
                                                <div class="bet-list">
                                                    <div>
                                                        <p>&nbsp;</p>
                                                        <button class="MuiButtonBase-root MuiButton-root MuiButton-contained typed" tabindex="0" type="button"><span class="MuiButton-label"></span><span class="MuiTouchRipple-root"></span></button>
                                                    </div>
                                                    <div class="input-num">
                                                        <p>Min Bet</p>
                                                        <div>
                                                            <a href="#" style="border-top-left-radius: 1.6vw; border-bottom-left-radius: 1.6vw;">
                                                                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13H5v-2h14v2z"></path></svg>
                                                            </a>
                                                            <span class="typeing"></span>
                                                            <a href="#" style="border-top-right-radius: 1.6vw; border-bottom-right-radius: 1.6vw;">
                                                                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="coin-list">
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10</a>
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">20</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">5000</a>
                                                    <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">10000</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="#">25000</a>
                                                </div>
                                                <div class="keyboard-wrap">
                                                    <div class="btn-tel">
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">1</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">2</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">3</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">4</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">5</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">6</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">7</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">8</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">9</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">0</a>
                                                        <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">00</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="/#">.</a>
                                                    </div>
                                                    <a href="#"><img src="/static/media/btn-delete.263a4b8e.svg" /></a>
                                                </div>
                                                <div class="btn-list"><a class="cancelbtn" href="#">Cancel</a><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover placebtn disable MuiTypography-colorPrimary" to="/#">Place Bet</a></div>
                                                <div class="acceptodds l-dark-color">
                                                    <label><input type="checkbox" /><a>Accept Any Odds</a></label>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        `;
                                        if (document.getElementById('fancyAPI')) {
                                            document.getElementById('fancyAPI').innerHTML += fancyHtml;
                                        }
                                    }
                                    var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                                    var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];
                                    if (fancys[j].game_status == 'Ball Running') {
                                        newdiv.setAttribute('data-title', "BALL RUNNING");
                                        newtr.classList.add("suspended");
                                        document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                                        document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                                    } else {
                                        newtr.classList.remove("suspended");
                                        document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                                        document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                                        document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                                        document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleBetClick = () => {
        setBetShow(!betShow);
    }
    const [value, setValue] = React.useState(0);
    const fancyChange = (event, newValue) => {
        setValue(newValue);
    };
    function getEventData() {
        loader_default();
        const event_id = props.event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: event_id,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: event_id,
            });
        }
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setEventData(response.data.resultData);
                    loader_remove();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const getDashboardDataNew = () => {
        const event_id = props.event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: event_id,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: event_id,
            });
        }
        return axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        try {
            if (data) {
                setEventData(data.data.resultData);
                window.localStorage.setItem(`eventDetailsL_${props.event_id}`, JSON.stringify(data.data.resultData));
                // renderEventData(data.data.resultData)
            }
        } catch (e) {
            postErrorToslack(e.message);
        }
    }
    const { isLoading, isError, error, data, refetch } = useQuery(`eventDetails_${props.event_id}`, getDashboardDataNew, {
        onSuccess
    })
    !storedList && isLoading ? loader_default() : loader_remove();
    // console.log("setEventData", setEventData);
    // Stake Dynamic Start
    function getChips() {
        var data = JSON.stringify({
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    let tmpChipList = response.data;
                    const newChipList = tmpChipList.map((chipList, index) => {
                        tmpChipList[index]["id"] = index + 1;
                    });
                    loader_remove();
                    setchipList(tmpChipList);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    // Stake Dynamic end
    // function betPlace() {
    // Book Button In Fancy Market 
    function getFancyPosition(selectionId) {
        var data = JSON.stringify({
            user_id: userInfo._id,
            event_id: eventId,
            fancy_id: selectionId
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancyPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setPostionFancyList(response.data.resultData);
                    setPositionOpen(true);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
        // console.log(postionFancyList)
    }
    // Stake Dynamic end
    function betPlace(ishow) {
        if (userInfo) {
            if (userInfo.user_type == "User") {
                setPlacing(true);
                var data = JSON.stringify({
                    user_id: userInfo._id,
                    match_id: BetPlaceData.event_id,
                    selection_id: BetPlaceData.selection_id,
                    is_back: BetPlaceData.is_back,
                    stake: StakeValue,
                    price_val: BetPlaceData.price,
                    market_id: BetPlaceData.market_id,
                    is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
                    market_name: "Match odds",
                    profit: ProfitValue,
                    loss: lossValue,
                    pm_fancy: BetPlaceData.PM_FANCY
                });
                // console.log("betting for fancy", data);
                var config = {
                    method: "post",
                    url: `${Appconfig.apiUrl}betting/addBetting`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                // alert("success");
                axios(config)
                    .then(function (response) {
                        try {
                            setBetPlaceData({
                                event_id: "",
                                market_id: "",
                                is_back: "",
                                price: "",
                                is_fancy: "",
                                selection_id: "",
                                runner_name: "",
                            });
                            setProfitValue(0);
                            setStakeValue(0);
                            hideAllBetBox()
                            if (response.data.result == 0) {
                                document.getElementById("msgBox").classList.remove("success");
                                document.getElementById("msgBox").classList.add("error");
                                document.getElementById("sideType").innerHTML = "Error";
                                document.getElementById("selectionName").innerHTML = response.data.resultMessage;
                                document.getElementById("stake_notify").innerHTML = "";
                                document.getElementById("price_notify_value").innerHTML = "";
                                var element = document.getElementById("sideType");
                                element.classList.add("red-type");
                            } else {
                                document.getElementById("msgBox").classList.add("success");
                                document.getElementById("msgBox").classList.remove("error");
                                setBookButton(ishow);
                                if (response.data.resultData[0].is_back == 1) {
                                    document.getElementById("sideType").innerHTML = "Yes";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("blue-type");
                                }
                                if (response.data.resultData[0].is_back == 0) {
                                    document.getElementById("sideType").innerHTML = "No";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("pink-type");
                                }
                            }
                            setPlacing(false);
                            document.getElementById("msgBox").style.display = "flex";
                            setTimeout(() => {
                                document.getElementById("msgBox").style.display = "none";
                            }, 3000);
                            refetch();
                            setIsBetPlaced(!isBetPlaced);
                        } catch (e) {
                            postErrorToslack(e.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        postErrorToslack(error.message);
                    });
            }
        }
        else {
            history.push("/login")
        }
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success",
            });
        }
    }
    const hideAllBetBox = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
        const boxes = document.getElementsByClassName("pbb");
        const boxesWin = document.getElementsByClassName("to-win");
        for (var box of boxes) {
            box.style.setProperty("display", "none", "important")
        }
        for (var box of boxesWin) {
            box.style.setProperty("display", "none", "important")
        }
    }
    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {
            // if (gameType == 'market') {
            //    if (MatchMarketTypes == 'M') {
            // var pl = Math.round((priceVal * t_stake) / 100);
            //    } else {
            var pl = Math.round((priceVal * t_stake) - t_stake);
            //    }
            // } else {
            // var pl = Math.round((priceVal * t_stake) / 100);
            // }
            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        } else {
            if (document.getElementById('fancy_lay_size_' + selection_id)) {
                var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
                var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
                var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
                var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);
            }

            pl = parseFloat(t_stake);
            if (inputno == inputyes) {
                if (isback) {
                    setLossValue(pl.toFixed(2))
                    setProfitValue((YesValume * pl / 100).toFixed(2))
                } else {
                    setLossValue((NoValume * pl / 100).toFixed(2))
                    setProfitValue(pl.toFixed(2))
                }
            } else {
                setLossValue(pl.toFixed(2))
                setProfitValue(pl.toFixed(2))
            }
        }
    }
    const handleOpen = (
        event_id,
        market_id,
        is_back,
        price,
        is_fancy,
        selection_id,
        runner_name,
        htmlId
    ) => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
        if (!userInfo) {
            return false;
        }
        hideAllBetBox();
        if (!is_fancy) {
            document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        } else {
            document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        }
        setProfitValue(0);
        setStakeValue(0)
        // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
        var priceNew = document.getElementById(htmlId).innerHTML;
        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "_BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }
        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        // console.log(runners);
        console.log('pricenew', {
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
        });
        // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
        var priceNew = document.getElementById(htmlId).innerHTML;
        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "_BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }
        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        // console.log(runners);
        setBetPlaceData({
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
        });
        setTimeout(function () {
            SetPosition(0, priceNew, market_id, is_back, selection_id)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
        executeScroll();
        // console.log("Bet Place Data", BetPlaceData);
    };
    // const handleOpen = (
    //     event_id,
    //     market_id,
    //     is_back,
    //     price,
    //     is_fancy,
    //     selection_id,
    //     runner_name,
    //     htmlId
    // ) => {
    //     //alert(htmlId);
    //     hideAllBetBox();
    //     const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    //     // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
    //     const priceNew = document.getElementById(htmlId).innerHTML;
    //     // console.log(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)
    //     // document.getElementsByClassName("pbb").style.setProperty("display", "none", "important");
    //     if (!is_fancy) {
    //         document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
    //     } else {
    //         document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
    //     }
    //     setBetPlaceData({
    //         event_id: event_id,
    //         market_id: market_id,
    //         is_back: is_back ? 1 : 0,
    //         price: priceNew,
    //         is_fancy: is_fancy,
    //         selection_id: selection_id,
    //         runner_name: runner_name,
    //     });
    //     // 
    //     // console.log("Bet Place Data", BetPlaceData);
    // };
    const counter = 6;
    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;
            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);
                    } else {
                        curr = winloss + ((priceVal * stake) - stake);
                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                    } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = Math.round(curr);
            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
            }
            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).style.display = "inherit";
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).setAttribute('data-value', currV)
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).innerHTML = Math.abs(currV);
                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)
                // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
            }
        }
    }
    const [bookmakerPin, SetBookmakerPin] = useState(false);
    const [matchOddsPin, SetMatchOddsPin] = useState(false);
    const [fancyPin, SetFancyPin] = useState(false);
    function updatePin(type, value) {
        if (type == "bookmaker") {
            if (document.querySelector("#bookmaker_pin")) {
                if (value == "Yes") {
                    document.querySelector("#bookmaker_pin").classList.remove("pin-off");
                    document.querySelector("#bookmaker_pin").classList.add("pin-on")
                }
                else {
                    document.querySelector("#bookmaker_pin").classList.remove("pin-on");
                    document.querySelector("#bookmaker_pin").classList.add("pin-off")
                }
            }
        }
        if (type == "match_odds") {
            if (document.querySelector("#match_odds_pin")) {
                if (value == "Yes") {
                    document.querySelector("#match_odds_pin").classList.add("select")
                }
                else {
                    document.querySelector("#match_odds_pin").classList.remove("select")
                }
            }
        }
        if (type == "fancy") {
        }
    }
    return (
        <>
            <div>
                <Grid style={{ backgroundColor: '#F0ECE1' }}>
                    {isLoggedIn ? null :
                        <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                    }
                    {placing ?
                        <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div> : ""
                    }
                    {isMobile ?
                        <div id="msgBox" className="message-wrap-full success to-open_bets" style={{ display: 'none' }}>
                            <div className="message">
                                <h4 id="header">Bet Matched</h4>
                                <p id="info">
                                    <span id="sideType" className="back_notify">Yes</span>
                                    <strong id="selectionName">Match 1st Over</strong> <strong id="stake_notify"> 100.00</strong> at odds <strong id="price_notify_value">7/100</strong>
                                </p>
                            </div>
                            <Link id="close" className="close ui-link" href="#">Close</Link>
                        </div> : null
                    }
                    <Grid item lg={2} className="xs-none">
                        <LeftSideBar />
                    </Grid>
                    <Grid item lg={7} xs={12} className={`cricket-section`} style={{ overflow: "hidden" }}>
                        <Grid container id="ctitle" spacing={2} style={{ padding: '8px' }} >
                            <Grid item lg={12} className="p-0" xs={12} ref={myRef}>
                                {props.match_type == "MatchOdds" ?
                                    <>
                                        <div className="match-block-header" style={{ width: "100vw" }}>
                                            {EventData.length > 0 && EventData[0].event_type == 4 ?
                                                <h5>Cricket</h5>
                                                :
                                                EventData.length > 0 && EventData[0].event_type == 1 ?
                                                    <h5>Soccer</h5>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 2 ?
                                                        <h5>Tennis</h5>
                                                        :
                                                        null
                                            }
                                            <span>
                                                {EventData.length > 0 && EventData[0].event_type == 4 && EventData[0].is_inplay == "True" ?
                                                    <span><img src={Time} /> In-Play</span>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 1 && EventData[0].is_inplay == "True" ?
                                                        <span><img src={Time} /> In-Play</span>
                                                        :
                                                        EventData.length > 0 && EventData[0].event_type == 2 && EventData[0].is_inplay == "True" ?
                                                            <span><img src={Time} /> In-Play</span>
                                                            :
                                                            null
                                                }
                                            </span>
                                        </div>
                                        <Table className={classes.table}>
                                            <TableHead className="multi-head">
                                                <TableRow className="tbl_head">
                                                    <TableCell colSpan="1" className="md-none ipl-header">
                                                        <a id="match_odds_pin" onClick={() => pinmarket(props.event_id, "bookmaker_id_preserve", "Match")} class="pin-off" href="#">Pin to Muilti Markets</a>
                                                        <span className="ipl-header-text">{EventData && EventData.length > 0 ? EventData[0].event_name : ""}</span>
                                                    </TableCell>
                                                    <TableCell style={{ maxWidth: '18px', borderRight: '1px solid #394f5c', textAlign: 'center', paddingBottom: '4px' }}>
                                                        <ChevronRight className="right-arrow" onClick={() => history.push(`/full-market/${props.event_id}`)} />
                                                    </TableCell>
                                                    <TableCell style={{ maxWidth: '18px', textAlign: 'center', paddingBottom: '4px' }}>
                                                        <ReplayIcon />
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                        <div id="marketBetsWrap" class="bets-wrap">
                                            <dl id="betsHead" class="bets-selections-head">
                                                <dt>
                                                    <a class="a-depth" href="#" id="marketDepthBtn">Markets Depth</a>
                                                    <p>
                                                        <span>Match Odds</span>
                                                    </p>
                                                </dt>
                                                <dd class="mode-land"></dd>
                                                <dd class="mode-land"></dd>
                                                <dd>Back
                                                </dd>
                                                <dd>Lay
                                                </dd>
                                                <dd class="mode-land"></dd>
                                                <dd class="mode-land"></dd>
                                            </dl>
                                            {EventData.length > 0 && EventData.map((event, index) =>
                                                event.hasOwnProperty("marketTypes") && event.marketTypes.map((market, index) =>
                                                    market.hasOwnProperty("marketRunners") && market.marketRunners.length > 0 && market.market_name == "Match Odds" ?
                                                        market.marketRunners.map((runner, i) => (
                                                            <>
                                                                {updatePin("match_odds", market.is_pin)}
                                                                <input type="hidden" id="matchodds_id_preserve" value={market.market_id} />
                                                                <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                                                <dl data-title={runner.status === "suspended" ? "SUSPENDED" : ""} id="selection_4708889" class="bets-selections" style={{ display: 'flex' }} key={i * 14} className={`odd_even_clr bets-selections table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} button>
                                                                    <dt>
                                                                        <div id="horseInfo" class="horse-info" style={{ display: 'none' }}>
                                                                            <ul id="horseBox" class="horse-box">
                                                                                <li id="clothNumberAlpha"></li>
                                                                                <li id="stallDraw"></li>
                                                                            </ul>
                                                                            <div id="uniform" class="uniform"><img src="" /></div>
                                                                            <ul id="horseName" class="horse-name">
                                                                                <li id="runnerName">{runner.runner_name}</li>
                                                                                <li id="jockyName"></li>
                                                                            </ul>
                                                                        </div>
                                                                        <h4 id="runnerName">{runner.runner_name}</h4>
                                                                        <ul id="winLoss">
                                                                            <li id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} class="win market-exposure" data-value={runner.exposure} style={{ display: 'block' }}>{Math.abs(runner.exposure.toFixed(2))}</li>
                                                                            <li id="lossWithoutBet" class="win" style={{ display: 'none', marginRight: "1.06667vw" }}></li>
                                                                            <li id={runner.selection_id + "_maxprofit_list_loss_runner_next_" + market.market_id.replace('.', '')} class="to-win market-exposure" style={{ display: 'none', marginLeft: "1.06667vw" }}>0</li>
                                                                            <li id="lossWithBet" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroProfit" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroLiability" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroProfitWithBet" class="win" style={{ display: 'none' }}></li>
                                                                            <li id="zeroLiabilityWithBet" class="win" style={{ display: 'none' }}>
                                                                                {/* <p  class="market-exposure" ></p> */}
                                                                            </li>
                                                                        </ul>
                                                                    </dt>
                                                                    <dd id="suspend" class="suspend" style={{ display: 'none' }}><p>Suspend
                                                                    </p></dd>
                                                                    <dd id="closed" class="suspend" style={{ display: 'none' }}><p>Closed
                                                                    </p></dd>
                                                                    <dd id="nonRunner" class="suspend" style={{ display: 'none' }}><p>Non Runner
                                                                    </p></dd>
                                                                    <dd id="back_1">
                                                                        <Link to="#" onClick={() => handleOpen(
                                                                            event.event_id,
                                                                            market.market_id,
                                                                            true,
                                                                            runner.back_1_price,
                                                                            false,
                                                                            runner.selection_id,
                                                                            runner.runner_name,
                                                                            `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                        )
                                                                        } className={`back-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} ><span className="backprice-match" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_price)}</span><span id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}> {parseFloat(runner.back_1_size)}</span></Link>
                                                                    </dd>
                                                                    <dd id="lay_1">
                                                                        <Link to="#" onClick={() => handleOpen(
                                                                            event.event_id,
                                                                            market.market_id,
                                                                            false,
                                                                            runner.lay_1_price,
                                                                            false,
                                                                            runner.selection_id,
                                                                            runner.runner_name,
                                                                            `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                        )
                                                                        } className={`lay-1 ${runner.status === "SUSPENDED" ? "suspended" : ""}`} fullmarketodds={parseFloat(runner.lay_1_price)}><span className="layprice-match" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_price)}</span><span id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}> {parseFloat(runner.lay_1_size)}</span></Link>
                                                                    </dd>
                                                                </dl>
                                                                <div p={1} key="pbb_bet1" className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                    <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                        <div className="bet-list" >
                                                                            <div>
                                                                                <p>&nbsp;</p>
                                                                                <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                            </div>
                                                                            <div className="input-num">
                                                                                <p>Min Bet</p>
                                                                                <div>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                        <RemoveIcon />
                                                                                    </a>
                                                                                    <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                    <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                        <AddIcon />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="coin-list" >
                                                                            {chipList.slice(0, 6).map((row, cindex) => (
                                                                                <Link to="#" key={"chipList" + cindex} onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                            ))}
                                                                        </div>
                                                                        <div className="keyboard-wrap">
                                                                            <div className="btn-tel">
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                                <Link to="#">.</Link>
                                                                            </div>
                                                                            <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                        </div>
                                                                        <div className="btn-list">
                                                                            <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                            <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => placing ? console.log('Placing....') : betPlace()}>{placing ? "Placing...." : "Place Bet"}</Link>
                                                                            {/* <Button
                variant="contained"
                className="eventSingleSuccessBtn"
                onClick={() => betPlace()}
                disabled={placing}
                endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                >
                {placing ? "PLACING...." : "PLACE BET"}
            </Button> */}
                                                                        </div>
                                                                        <div id="toscroll" className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                            <label>
                                                                                <input type="checkbox" />
                                                                                <a>Accept Any Odds</a>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)) : ""))}
                                        </div>
                                    </>
                                    : ""}
                                {/* Match Odds Custom Section End */}
                                {/* Book Marker Custom Section Start */}
                                {props.match_type == "Bookmaker" ?
                                    <div id="bookMakerMarket_30998640_130561" class="bets-wrap bets-bookmaker" style={{ display: 'block', marginTop: 10 }}>
                                        <div className="match-block-header" style={{ width: "100vw" }}>
                                            {EventData.length > 0 && EventData[0].event_type == 4 ?
                                                <h5>Cricket</h5>
                                                :
                                                EventData.length > 0 && EventData[0].event_type == 1 ?
                                                    <h5>Soccer</h5>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 2 ?
                                                        <h5>Tennis</h5>
                                                        :
                                                        null
                                            }
                                            <span>
                                                {EventData.length > 0 && EventData[0].event_type == 4 && EventData[0].is_inplay == "True" ?
                                                    <span><img src={Time} /> In-Play</span>
                                                    :
                                                    EventData.length > 0 && EventData[0].event_type == 1 && EventData[0].is_inplay == "True" ?
                                                        <span><img src={Time} /> In-Play</span>
                                                        :
                                                        EventData.length > 0 && EventData[0].event_type == 2 && EventData[0].is_inplay == "True" ?
                                                            <span><img src={Time} /> In-Play</span>
                                                            :
                                                            null
                                                }
                                            </span>
                                        </div>
                                        <Table className={classes.table}>
                                            <TableHead className="multi-head">
                                                <TableRow className="tbl_head">
                                                    <TableCell colSpan="1" className="md-none ipl-header">
                                                        <a id="bookmaker_pin" onClick={() => pinmarket(props.event_id, "bookmaker_id_preserve", "Match")} class="pin-off" href="#">Pin to Muilti Markets</a>
                                                        <span className="ipl-header-text">{EventData && EventData.length > 0 ? EventData[0].event_name : ""}</span>
                                                    </TableCell>
                                                    <TableCell style={{ maxWidth: '18px', borderRight: '1px solid #394f5c', textAlign: 'center', paddingBottom: '4px' }}>
                                                        <ChevronRight className="right-arrow" onClick={() => history.push(`/full-market/${props.event_id}`)} />
                                                    </TableCell>
                                                    <TableCell style={{ maxWidth: '18px', textAlign: 'center', paddingBottom: '4px' }}>
                                                        <ReplayIcon />
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                        <Grid item lg={12} className="p-0" xs={12} ref={myRef}>
                                            <div id="marketBetsWrap" class="bets-wrap">
                                                <dl id="betsHead" class="bets-selections-head">
                                                    <dt>
                                                        <a class="a-depth" href="#" id="marketDepthBtn">Markets Depth</a>
                                                        <p>
                                                            <span>Bookmaker</span>
                                                        </p>
                                                    </dt>
                                                    <dd class="mode-land"></dd>
                                                    <dd class="mode-land"></dd>
                                                    <dd>Back
                                                    </dd>
                                                    <dd>Lay
                                                    </dd>
                                                    <dd class="mode-land"></dd>
                                                    <dd class="mode-land"></dd>
                                                </dl>
                                                {EventData.length > 0 && EventData.map((event, index) =>
                                                    event.marketTypes.map((market, index) =>
                                                        market.marketRunners.length > 0 && market.market_name == "Bookmaker" ? (
                                                            <div className="sectionbookmaker">
                                                                {market.marketRunners.map((runner, i) => (
                                                                    <>
                                                                        {updatePin("bookmaker", market.is_pin)}
                                                                        <input type="hidden" id="bookmaker_id_preserve" value={market.market_id} />
                                                                        <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                                                        <dl id="bookMakerSelection_30998640_130561_385354" class="bets-selections" style={{ display: 'flex' }}>
                                                                            <dt>
                                                                                <h4 id="runnerName">{runner.runner_name}</h4>
                                                                                <ul id="winLoss">
                                                                                    <li id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')} class="win market-exposure" data-value={runner.exposure} style={{ display: 'block' }}>{Math.abs(runner.exposure.toFixed(2))}</li>
                                                                                    <li id="lossWithoutBet" class="win" style={{ display: 'none', marginRight: "1.06667vw" }}></li>
                                                                                    <li id={runner.selection_id + "_maxprofit_list_loss_runner_next_" + market.market_id.replace('.', '')} class="to-win market-exposure" style={{ display: 'none', marginLeft: "1.06667vw" }}>0</li>
                                                                                    <li id="lossWithBet" class="win" style={{ display: 'none' }}></li>
                                                                                    <li id="zeroProfit" class="win" style={{ display: 'none' }}></li>
                                                                                    <li id="zeroLiability" class="win" style={{ display: 'none' }}></li>
                                                                                    <li id="zeroProfitWithBet" class="win" style={{ display: 'none' }}></li>
                                                                                    <li id="zeroLiabilityWithBet" class="win" style={{ display: 'none' }}>
                                                                                        {/* <p  class="market-exposure" ></p> */}
                                                                                    </li>
                                                                                </ul>
                                                                            </dt>
                                                                            <dd id="back_1" className={`bookmaker-back-1 table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`}>
                                                                                <Link to="#" className="back-1 pink-core" onClick={() =>
                                                                                    handleOpen(
                                                                                        event.event_id,
                                                                                        market.market_id,
                                                                                        true,
                                                                                        runner.back_1_price,
                                                                                        false,
                                                                                        runner.selection_id,
                                                                                        runner.runner_name,
                                                                                        `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                                    )
                                                                                }><div className="back-box" id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_1_price}</div>
                                                                                    <span className="bookmakerSize" id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.back_1_size}</span>
                                                                                </Link>
                                                                            </dd>
                                                                            <dd id="lay_1" className="bookmaker-lay-1">
                                                                                <Link to="#" className="lay-1 blue-core" onClick={() =>
                                                                                    handleOpen(
                                                                                        event.event_id,
                                                                                        market.market_id,
                                                                                        false,
                                                                                        runner.lay_1_price,
                                                                                        false,
                                                                                        runner.selection_id,
                                                                                        runner.runner_name,
                                                                                        `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                                                                    )
                                                                                }><div className="lay-box" id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_1_price}</div>
                                                                                    <span className="bookmakerSize" id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{runner.lay_1_size}</span>
                                                                                </Link>
                                                                            </dd>
                                                                        </dl>
                                                                        <div p={1} key="pbb_bet2" className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                                                                            <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                                <div className="bet-list" >
                                                                                    <div>
                                                                                        <p>&nbsp;</p>
                                                                                        <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                                    </div>
                                                                                    <div className="input-num">
                                                                                        <p>Min Bet</p>
                                                                                        <div>
                                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                                <RemoveIcon />
                                                                                            </a>
                                                                                            <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                            <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                                <AddIcon />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="coin-list" >
                                                                                    {chipList.slice(0, 6).map((row, cindex) => (
                                                                                        <Link to="#" key={"chipListNew" + cindex} onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="keyboard-wrap">
                                                                                    <div className="btn-tel">
                                                                                        <Link to="#" key={"placeStakeValueTest1"} onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest2"} onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest3"} onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest4"} onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest5"} onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest6"} onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest7"} onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest8"} onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest9"} onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest0"} onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                                        <Link to="#" key={"placeStakeValueTest11"} onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                                        <Link to="#">.</Link>
                                                                                    </div>
                                                                                    <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                                </div>
                                                                                <div className="btn-list">
                                                                                    <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                                    <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => placing ? console.log('Placing....') : betPlace()}>{placing ? "Placing...." : "Place Bet"}</Link>
                                                                                    {/* <Button
                    variant="contained"
                    className="eventSingleSuccessBtn"
                    onClick={() => betPlace()}
                    disabled={placing}
                    endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                    >
                    {placing ? "PLACING...." : "PLACE BET"}
                </Button> */}
                                                                                </div>
                                                                                <div id="toscroll" className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                                    <label>
                                                                                        <input type="checkbox" />
                                                                                        <a>Accept Any Odds</a>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))} </div>) : ""))}
                                            </div>
                                        </Grid>
                                    </div>
                                    : ""}
                                {/* Book Marker Custom Section End */}
                                {/* Book Marker Section Table */}
                            </Grid>
                            <Grid item lg={12} className="xs-none">
                                <Grid container spacing={2}>
                                    <Grid item lg={6} className="xs-none">
                                        <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                                <Link to="#" className="bookmark-last-icon-table"></Link>
                                                <Typography>Over/Under 2.5 Goals</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1">Matched: INR 0</TableCell>
                                                            <TableCell align="center">Back</TableCell>
                                                            <TableCell align="center">Lay</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="bookmark-full-market">
                                                        {rows.map((row, index) => (
                                                            <TableRowLink to={row.link} p={1} key={index} className="odd_even_clr" button>
                                                                <TableCell className="table_first_row" colSpan="1">
                                                                    <div className="text_left-in-bookmark">
                                                                        <img className="icon-predict" alt="chart" src={transparent} />
                                                                        {row.name1}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#72BBEF' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.calories}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#FAA9BA' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.fat}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRowLink>
                                                        ))}
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1"></TableCell>
                                                            <TableCell colSpan="2" align="right"><Link to="#">View Full Market</Link></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item lg={6} className="xs-none">
                                        <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                                <Link to="#" className="bookmark-last-icon-table"></Link>
                                                <Typography>Over/Under 2.5 Goals</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1">Matched: INR 0</TableCell>
                                                            <TableCell align="center">Back</TableCell>
                                                            <TableCell align="center">Lay</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="bookmark-full-market">
                                                        {rows.map((row, index) => (
                                                            <TableRowLink to={row.link} p={1} key={index * 11} className="odd_even_clr" button>
                                                                <TableCell className="table_first_row" colSpan="1">
                                                                    <div className="text_left-in-bookmark">
                                                                        <img className="icon-predict" alt="chart" src={transparent} />
                                                                        {row.name1}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#72BBEF' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.calories}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#FAA9BA' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.fat}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRowLink>
                                                        ))}
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1"></TableCell>
                                                            <TableCell colSpan="2" align="right"><Link to="#">View Full Market</Link></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item lg={6} className="xs-none">
                                        <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                                <Link to="#" className="bookmark-last-icon-table"></Link>
                                                <Typography>Over/Under 2.5 Goals</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1">Matched: INR 0</TableCell>
                                                            <TableCell align="center">Back</TableCell>
                                                            <TableCell align="center">Lay</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="bookmark-full-market">
                                                        {rows.map((row, index) => (
                                                            <TableRowLink to={row.link} p={1} key={index * 12} className="odd_even_clr" button>
                                                                <TableCell className="table_first_row" colSpan="1">
                                                                    <div className="text_left-in-bookmark">
                                                                        <img className="icon-predict" alt="chart" src={transparent} />
                                                                        {row.name1}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#72BBEF' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.calories}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className="xs-ipl-block" style={{ backgroundColor: '#FAA9BA' }}>
                                                                    <div style={{ display: 'block' }}>
                                                                        <Typography variant='span'>{row.fat}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRowLink>
                                                        ))}
                                                        <TableRow className="tbl_head xs-none">
                                                            <TableCell colSpan="1"></TableCell>
                                                            <TableCell colSpan="2" align="right"><Link to="#">View Full Market</Link></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Child Grid item End */}
                        </Grid>
                        {/* Child Grid Container End */}
                        {props.match_type == "Fancy" ?
                            <>
                                <div className="match-block-header" style={{ width: "100vw" }}>
                                    {EventData.length > 0 && EventData[0].event_type == 4 ?
                                        <h5>Cricket</h5>
                                        :
                                        EventData.length > 0 && EventData[0].event_type == 1 ?
                                            <h5>Soccer</h5>
                                            :
                                            EventData.length > 0 && EventData[0].event_type == 2 ?
                                                <h5>Tennis</h5>
                                                :
                                                null
                                    }
                                    <span>
                                        {EventData.length > 0 && EventData[0].event_type == 4 && EventData[0].is_inplay == "True" ?
                                            <span><img src={Time} /> In-Play</span>
                                            :
                                            EventData.length > 0 && EventData[0].event_type == 1 && EventData[0].is_inplay == "True" ?
                                                <span><img src={Time} /> In-Play</span>
                                                :
                                                EventData.length > 0 && EventData[0].event_type == 2 && EventData[0].is_inplay == "True" ?
                                                    <span><img src={Time} /> In-Play</span>
                                                    :
                                                    null
                                        }
                                    </span>
                                </div>
                                <Table className={classes.table}>
                                    <TableHead className="multi-head">
                                        <TableRow className="tbl_head">
                                            <TableCell colSpan="1" className="md-none ipl-header">
                                                <a id="match_odds_pin" onClick={() => pinmarket(props.event_id, "bookmaker_id_preserve", "Match")} class="pin-off" href="#">Pin to Muilti Markets</a>
                                                <span className="ipl-header-text"><img src={Time} style={{ verticalAlign: "middle" }} /><img src={fancyicon} className='fancy-icon' />{EventData && EventData.length > 0 ? EventData[0].event_name : ""}</span>
                                            </TableCell>
                                            <TableCell style={{ maxWidth: '18px', borderRight: '1px solid #394f5c', textAlign: 'center', paddingBottom: '4px' }}>
                                                <ChevronRight className="right-arrow" onClick={() => history.push(`/full-market/${props.event_id}`)} />
                                            </TableCell>
                                            <TableCell style={{ maxWidth: '18px', textAlign: 'center', paddingBottom: '4px' }}>
                                                <ReplayIcon />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                {EventData.length > 0 && EventData[0].fancy.length > 0 ?
                                    <Grid container spacing={2} style={{ padding: '10px' }}>
                                        <Grid item lg={12} className="p-0" xs={12}>
                                            <div value={value} index={0} className="fancy-tab-content fancybet-data">
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow className="special_bet">
                                                            {/* <TableCell colSpan="8" className="lg-none">
                                                    <Box display='flex'>
                                                        <Link className="bookmark-last-icon"></Link>
                                                        <span style={{ fontWeight: "bold", fontSize: "15px" }}>Fancy Bet</span>
                                                    </Box>
                                                </TableCell> */}
                                                            <TableCell colSpan="8" className="xs-none">
                                                                <Box display='flex' justifyContent="space-between">
                                                                    <Box display='flex'>
                                                                        <Link to="#" className="bookmark-last-icon"></Link>
                                                                        <Typography variant="span" style={{ fontWeight: "bold", fontSize: "15px" }}>Fancy Bet</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="financial_tbl_body" >
                                                        <TableRow className="">
                                                            <TableCell align="left" colSpan="4"></TableCell>
                                                            <TableCell align="center" colSpan=""><b>No</b></TableCell>
                                                            <TableCell align="center" colSpan=""><b>Yes</b></TableCell>
                                                            <TableCell align="left" colSpan="2" className="xs-none"></TableCell>
                                                        </TableRow>
                                                        <TableRow className="hegintnone">
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="left" colSpan="2" className="xs-none"></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                {EventData.length && EventData.map((event, index) =>
                                                    event.fancy.map((fancy, i) => (
                                                        <div className={`all custom-container-fancy fancy_${fancy.selection_id}`}>
                                                            <input type="hidden" id="fancy_id_preserve" value={fancy.market_id} />
                                                            <div id="fancyBetTag">
                                                                <div id="fancyBetMarket_332715" class="bets-wrap fancy-bet">
                                                                    <h5 p={1} key={i * 66}>
                                                                        <span id="marketName">{fancy.runner_name}</span>
                                                                        <ClickAwayListener onClickAway={() => handleFancyTooltipOpen(null)}>
                                                                            <HtmlTooltip title={
                                                                                <React.Fragment>
                                                                                    <div id="minMaxBox" className="limit_info-popup" style={{ "display": "flex" }}>
                                                                                        <a className="close" onClick={() => handleFancyTooltipOpen(null)}>Close</a>
                                                                                        <dl>
                                                                                            <dt id="minMaxDt" >{"Min / Max"}</dt>
                                                                                            <dt id="maxDt" style={{ "display": "none" }}>{"Max"}</dt>
                                                                                            <dd id="minMaxInfo">{"100 / 150000"}</dd>
                                                                                        </dl>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                                placement="right"
                                                                                PopperProps={{
                                                                                    disablePortal: true,
                                                                                }}
                                                                                onClose={() => handleFancyTooltipOpen(null)}
                                                                                open={toolFancytipOpen === index}
                                                                                disableFocusListener
                                                                                disableHoverListener
                                                                                disableTouchListener
                                                                            >
                                                                                <a href="#" onClick={() => handleFancyTooltipOpen(index)} id="open-fancy_info" class="btn-fancy_info"></a>
                                                                            </HtmlTooltip>
                                                                        </ClickAwayListener>
                                                                        {/* <div id="fancy_popup2" class="fancy_info-popup">
                                                                    <dl>
                                                                        <dt>Min / Max</dt>
                                                                        <dd id="minMax"> 1.00 /  800.00</dd>
                                                                    </dl>
                                                                    <dl>
                                                                        <dt id="rebateName">Rebate</dt>
                                                                        <dd id="rebate"></dd>
                                                                    </dl>
                                                                    <a href="#" id="close-fancy_info" class="close">Close</a>
                                                                </div> */}
                                                                    </h5>
                                                                    <dl p={1} key={i * 66} className={`odd_even_clr odd_even_clr_${fancy.selection_id} bets-selections fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""}`} data-title={fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""} button>
                                                                        <dt class="line_market-selection">
                                                                            <dl class="tips">
                                                                                <dt>
                                                                                    <span id="before" class="win"></span>
                                                                                    <span id="after" class="to-lose"></span>
                                                                                </dt>
                                                                                {bookButton == "fancyBetBookBtn" + i || fancy.is_bet_open == true ?
                                                                                    <Link to="#" id={`fancyBetBookBtn${i}`} className={`btn-book `} onClick={() => getFancyPosition(fancy.selection_id)}>Book</Link>
                                                                                    : null}
                                                                                <dd id="remarkFirstRow"></dd>
                                                                                <dd id="remarkSecondRow"></dd>
                                                                            </dl>
                                                                            {/* <a id="fancyBetBookBtn" href="#" class="btn-book">Book</a> */}
                                                                        </dt>
                                                                        {/* <dd id="suspend eded" className={`suspend-fancy odd_even_clr fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""} ${fancy.game_status === "Ball Running" ? "suspended" : ""}`} style={{ display: 'none' }} data-title={`${fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""}${fancy.game_status === "Ball Running" ? "ballrunning-sus" : ""}`}><p id="info">{fancy.game_status === "SUSPENDED" ? "Suspend" : ""}{fancy.game_status === "Ball Running" ? "Ball Running" : ""}</p></dd> */}
                                                                        {/* <dd id="delayBetting" class="suspend-fancy" style={{ display: 'none' }}><p id="info"></p></dd> */}
                                                                        <dd id="lay_1">
                                                                            <a className="lay-1 blue-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    false,
                                                                                    fancy.lay_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_lay_price_${fancy.selection_id}`
                                                                                )
                                                                            }>
                                                                                <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}>{parseFloat(fancy.lay_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        <dd id="back_1">
                                                                            <a className="back-1 pink-core" href="#" onClick={() =>
                                                                                handleOpen(
                                                                                    event.event_id,
                                                                                    "",
                                                                                    true,
                                                                                    fancy.back_price1,
                                                                                    true,
                                                                                    fancy.selection_id,
                                                                                    fancy.runner_name,
                                                                                    `fancy_back_price_${fancy.selection_id}`
                                                                                )
                                                                            }>
                                                                                <div className="widthhundredfancy" id={`fancy_back_price_${fancy.selection_id}`}>{parseFloat(fancy.back_price1)}</div>
                                                                                <span className="widthhundredfancy" id={`fancy_back_size_${fancy.selection_id}`}>{parseFloat(fancy.back_size1)}</span>
                                                                            </a>
                                                                        </dd>
                                                                        {/* <div>
                                                    <div className="widthhundredfancy" id={`fancy_lay_price_${fancy.selection_id}`}> {parseFloat(fancy.lay_price1)}</div>
                                                    <span className="widthhundredfancy" id={`fancy_lay_size_${fancy.selection_id}`}>{parseFloat(fancy.lay_size1)}</span>
                                                </div> */}
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div p={1} key={"mxod" + fancy.market_id} className={`pbb place-fancybet-block_${fancy.match_id}_${fancy.selection_id}`} button>
                                                                <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                                                    <div className="bet-list" >
                                                                        <div>
                                                                            <p>&nbsp;</p>
                                                                            <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                                                        </div>
                                                                        <div className="input-num">
                                                                            <p>Min Bet</p>
                                                                            <div>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                                                    <RemoveIcon />
                                                                                </a>
                                                                                <span className={!StakeValue ? "typeing" : ""}>{StakeValue ? StakeValue : ""}</span>
                                                                                <a href="#" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                                                    <AddIcon />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="coin-list" >
                                                                        {chipList.slice(0, 6).map((row, index) => (
                                                                            <Link to="#" key={"cpls" + index} onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                                                        ))}
                                                                    </div>
                                                                    <div className="keyboard-wrap">
                                                                        <div className="btn-tel">
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                                            <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                                            <Link to="#">.</Link>
                                                                        </div>
                                                                        <a href="#" onClick={() => placeStakeValue(String(StakeValue).substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                                                    </div>
                                                                    <div className="btn-list fancyyyyybutttn">
                                                                        <a className="cancelbtn" href="#" onClick={hideAllBetBox}>Cancel</a>
                                                                        <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => betPlace(`fancyBetBookBtn_"${fancy.match_id}_${fancy.selection_id}`)}>Place Bet</Link>
                                                                        {/* <Button
                                                                            variant="contained"
                                                                            className="eventSingleSuccessBtn"
                                                                            onClick={() => betPlace()}
                                                                            disabled={placing}
                                                                            endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                                                                            >
                                                                            {placing ? "PLACING...." : "PLACE BET"}
                                                                        </Button> */}
                                                                    </div>
                                                                    <div id="toscroll" className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                                                        <label>
                                                                            <input type="checkbox" />
                                                                            <a>Accept Any Odds</a>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)))}
                                            </div>
                                        </Grid>
                                        {/* Fancy Cricket Grid item End */}
                                    </Grid>
                                    : null}
                            </>
                            : ""}
                    </Grid>
                    {/* End Main Item Grid */}
                    <Grid item lg={3} xs={12} className="betSlipGrid xs-none">
                        <BetSlip />
                    </Grid>
                </Grid>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={positionOpen}
                onClose={positionClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ height: "100%" }}
            >
                <Fade in={positionOpen}>
                    <div className="user_modal" >
                        <Grid container spacing={2}>
                            <Grid item lg={11} xs={10}>
                                <h2 id="transition-modal-title">Fancy Positions</h2>
                            </Grid>
                            <Grid item lg={1} xs={1}>
                                <IconButton aria-label="delete" onClick={positionClose} color="secondary" className={classes.margin}>
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className="tblhead darktblhead">
                                    <TableCell align="center" >Score </TableCell>
                                    <TableCell align="center" >Ammount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tbl_body ">
                                {postionFancyList.map((item, index) => {
                                    if (item < 0)
                                        return <>
                                            <TableRow className="">
                                                <TableCell align="center" >{index}</TableCell>
                                                <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                                            </TableRow>
                                        </>
                                    if (item >= 0 && item != null)
                                        return <>
                                            <TableRow className="">
                                                <TableCell align="center" >{index}</TableCell>
                                                <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                                            </TableRow>
                                        </>
                                })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
export default MultimarketComponent;
