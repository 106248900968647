import '../assets/css/style.css';
import React from 'react';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip';
import LeftSideBar from '../includes/LeftSideBar';

import PropTypes from 'prop-types';
import { Grid,makeStyles,Table,TableRow,TableCell,TableHead,TableBody,Link,AppBar,Tabs,Tab,Box,Typography} from '@material-ui/core';

//IMAGES
import time from '../assets/images/time.png';
import transparent from '../assets/images/transparent.gif';
import shirt from '../assets/images/1104581.png';

import ReplayIcon from '@material-ui/icons/Replay';

function createData(name1, value,name2,play,link,calories,fat,carbs,protein) {
    return { name1, value, name2, play,link,calories,fat,carbs,protein};
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    root:{
        height:'100%',
    },
    tableHeight:{
        height:'calc(100vh - 120px)',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`} {...other} >
        {value === index && ( <Box p={3}> <Table>{children}</Table> </Box> )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const HorseSingle = () => {

    const classes = useStyles();
    const rows = [
        createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play','link', 237, 9.0, 37, 4.3),
        createData('Daring king', '1 - 1 ', 'maxime', 'In play','link', 237, 9.0, 37, 4.3),
        createData('king', '3 - 1 ', 'cressy', 'In play','link', 237, 9.0, 37, 4.3),
        createData('Daring', '2 - 1 ', 'maxime cressy', 'In play','link', 237, 9.0, 37, 4.3),
        createData('Forge', '0 - 1 ', 'maxime cressy', 'In play','link', 237, 9.0, 37, 4.3),
    ];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
    <>
      <Header/>
      <div className={classes.root}>
      <Grid container  style={{ backgroundColor: '#F0ECE1'}}>
        <Grid item lg={2}  className="xs-none" style={{ backgroundColor: '#fff'}}>
            <LeftSideBar />
        </Grid>
        <Grid item lg={7} xs={12}  className={`horse-section ${classes.tableHeight}`}>
            <Grid container spacing={2} style={{padding:'10px' }} className="ipl-xs-section">
                <Grid item lg={12} className="ipl-banner xs-none">
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className="tbl_head">
                                <TableCell colSpan="1" className="xs-none pin-icon font-xs">
                                    <Link to="#"></Link>
                                    <span>Broome (AUS) 10th Aug </span> 
                                    <span className="winner">R6 1300m Hcap</span>
                                    <span className="refresh-icon"><ReplayIcon/></span>
                                </TableCell>
                                <TableCell colSpan="1" className="md-none ipl-header">
                                    <Link  to="#" className="pin-icon">
                                        <Link to="#"></Link>
                                    </Link>
                                    <span className="ipl-header-text">Broome (AUS) 10th Aug - R6 1300m Hcap</span>
                                    <span className="refresh-icon"><ReplayIcon/></span>
                                </TableCell>
                            </TableRow>
                            <TableRow className="table_tabbing">
                                <TableCell colSpan="2" className="horse-tabbing">
                                    <AppBar position="static" classame="horse-tabbing-header">
                                        <Tabs value={value} onChange={handleChange}>
                                            <Tab label="Item One" {...a11yProps(0)} />
                                            <Tab label="Item Two" {...a11yProps(1)} />
                                        </Tabs>
                                    </AppBar>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell align="center" className="ipl-table-space-bet xs-none">
                                <div className="game-info">
                                    <img width="15px" src={time} alt="Live" style={{marginBottom:"-2px",marginRight:"8px"}}/>
                                    <span>Live</span>
                                </div>
                                <div className="fancy-info">Max 1600</div>
                                <div className="margin-match">
                                    <span>Matched</span>
                                    <span className="game-matched-bold">INR 1,504,230</span>
                                    <span className="game-matched">Low Liquidity</span>
                                </div>
                            </TableCell>
                        </TableBody>
                    </Table>
                    <TabPanel value={value} index={0} className="horse-single-table">
                        <Table className={`${classes.table}`} aria-label="simple table">
                            <TableHead>
                                <TableRow className="ipl-tbl-head xs-none">
                                    <TableCell colSpan="1">
                                    <span>8 selections </span>
                                    </TableCell>
                                    <TableCell align="left" colSpan="2">
                                        <span>200.8%</span>
                                    </TableCell>
                                    <TableCell align="center" colSpan="1" className="back-all">
                                        <Typography className="text-black">Back all</Typography>
                                    </TableCell>
                                    <TableCell align="center" colSpan="1" className="lay-all">
                                        <Typography className="text-black">Lay all</Typography>
                                    </TableCell>
                                    <TableCell align="right" colSpan="2"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="ipl_tbl_body">
                                {rows.map((row,index) => (
                                    <TableRowLink to={row.link} p={1} key={row.name1} className="odd_even_clr" button>
                                        <TableCell className="table_first_row" colSpan="1">
                                            <Box display="flex" flexDirection="row" alignItems="center" p={2}>
                                                <Box className="flex horse-table-flex" align="center">
                                                    <img className="icon-predict" alt="chart" src={transparent}/>
                                                </Box>
                                                <Box className="flex horse-table-flex" align="center">
                                                    <Box alignItems="center" variant="span" className="text_left-in-ipl">{index+1}</Box>
                                                    <Box variant="span">({index+11})</Box>
                                                </Box>
                                                <Box className="flex horse-table-flex" align="center">
                                                    <img className="" alt="chart" src={shirt}/>
                                                </Box>
                                                <Box className="flex horse-table-flex" align="left">
                                                    <Box alignItems="center" variant="span" className="text_left-in-ipl">{index+1}.{row.name1}</Box>
                                                    <Box variant="span">{row.name1}</Box>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" className="disabled_td xs-none" style={{backgroundColor:'#D7E8F4'}}>
                                            <div style={{display:'block'}}>
                                                <div>{row.calories}</div>
                                                <span>72</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className="disabled_td xs-none" style={{backgroundColor:'#B7D5EB'}}>
                                            <div style={{display:'block'}}>
                                                <div>{row.calories}</div>
                                                <span>72</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className="disabled_td xs-ipl-block" style={{backgroundColor:'#72BBEF'}}>
                                            <div style={{display:'block'}}>
                                                <div>{row.calories}</div>
                                                <span>72</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className="">
                                        </TableCell>
                                        <TableCell align="center" className="">
                                        </TableCell>
                                        <TableCell align="center" className="">
                                        </TableCell>
                                    </TableRowLink>
                                ))}
                            </TableBody>    
                        </Table>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                 </Grid>
            </Grid>
        </Grid>
        <Grid item lg={3} className="betSlipGrid xs-none">
           <BetSlip/>
        </Grid>
    </Grid>
    </div>
    </>
    
)}
export default HorseSingle;

