import React from 'react';
import { makeStyles, Grid, List, TextField, ListItem, InputLabel, ListItemText, Select, FormControl, NativeSelect, Button, MenuItem, } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';


import moment from 'moment';
import { useParams } from "react-router-dom";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PropTypes from 'prop-types';
import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AppBar, Link } from '@material-ui/core';
import Appconfig from "../config/config";
import axios from "axios";

import { isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import { postErrorToslack } from '../config/common';

const gridType = isMobile ? 10 : 8;



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Bethistory() {
    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/account-statement') {
        segment = 2;
    }
    const [value, setValue] = React.useState(segment);

    const params = useParams();
    const userId = params.user_id;
    const eventId = params.event_id;
    const selectionId = params.selection_id;
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [pageCount, setPageCount] = React.useState(10);
    const [startStopLoader, setStartStopLoader] = React.useState(false);
    const [formData, setFormData] = React.useState({
        "event_type": 4,
        "user_id": userInfo._id,
        'status': "Settled",
        'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
        'to_date': moment().format("YYYY-MM-DD"),
    })
    const [betHistoryList, SetBetHistoryList] = React.useState([]);
    React.useEffect(() => {
        window.scroll(0, 0);
        formData.from_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
        formData.to_date = moment().format("YYYY-MM-DD");
        setFormData({
            ...formData
        });
        getBetHistory(formData.event_type);
    }, [eventId, userId]);
    const [searched, setSearched] = React.useState("");
    const [FilterList, setFilterList] = React.useState([]);

    const handleFormDataChange = (event) => {

        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }
    const classes = useStyles();


    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }



    const [state, setState] = React.useState(" ");

    const handleFilterSubmit = () => {
        getBetHistory(formData.event_type);
    }
    const handleChange = (event) => {
        const name = event.target.name;
        setState(event.target.value);
    };

    function getBetHistory(event_type) {
        let tmpAccountList;
        setStartStopLoader(true)

        if (eventId == 0) {
            formData.event_type = event_type;
            formData.from_date = moment(formData.from_date).format('YYYY-MM-DD');
            formData.to_date = moment(formData.to_date).format('YYYY-MM-DD')
        }
        else {
            formData.event_type = "";
            formData.from_date = "";
            formData.to_date = "";
            formData.status = "Settled";
        }

        formData.matchUnmatch = state;
        formData.event_id = eventId;
        formData.selection_id = selectionId;

        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        formData.user_id = id
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/betHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    SetBetHistoryList(response.data.resultData);
                    tmpAccountList = response.data.resultData;
                    const newChipList = tmpAccountList.map((betHistoryList, index) => {
                        tmpAccountList[index]["id"] = index + 1;
                    });
                    SetBetHistoryList(tmpAccountList);
                    setFilterList(tmpAccountList);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            }).then(() => {
                setStartStopLoader(false);
            });
    }

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        // console.log("searchedVal",searchedVal);
        const filteredRows = betHistoryList.filter((row) => {
            return row.user_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.event_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.runner_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilterList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            <Grid className="account-stat" container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />
                <Grid item lg={gridType} xs={12} spacing={2}>


                    <Box fontWeight="fontWeightBold" fontSize={14}>
                        <Typography variant="span">Bet History</Typography>
                    </Box>
                    <Table className={`statement-table ${classes.table}`}>
                        <TableHead className="table_head">
                            <TableRow>
                                <TableCell align="right">Date/Time</TableCell>
                                <TableCell align="right">Description</TableCell>
                                <TableCell align="right">Selection</TableCell>

                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Odds</TableCell>
                                <TableCell align="right">Stake</TableCell>
                                <TableCell align="right">P_L</TableCell>
                                <TableCell align="right">MY PL</TableCell>
                                <TableCell align="right">Profit</TableCell>
                                <TableCell align="right">Loss</TableCell>
                                <TableCell align="right">Result</TableCell>
                                <TableCell align="right">Bet Type</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">IP</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody className="table_body">
                            {FilterList.map((datas) => (
                                <TableRow>
                                    <TableCell align="right" component="th" scope="row">
                                        {moment(datas.createdAt).format('DD-MM-YYYY  hh:mm ')}
                                    </TableCell>
                                    <TableCell align="right" >
                                        {
                                            datas.event_name
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            datas.runner_name
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.is_back ? "Back" : "Lay"

                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.price_val
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            datas.stake
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.p_l
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.my_pl
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.profit
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.loss
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {
                                            datas.bet_result
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.betting_type
                                        }
                                    </TableCell>

                                    <TableCell align="right">
                                        {
                                            datas.status
                                        }
                                    </TableCell>


                                    <TableCell align="right">
                                        {
                                            datas.ip_address?.replace("::ffff:", "")
                                        }
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </Grid>
            </Grid>
        </>
    );
}