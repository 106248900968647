import React from 'react';
import Grid from '@material-ui/core/Grid';

import Facebook from '@material-ui/icons/Facebook';

import WhatsApp from '@material-ui/icons/WhatsApp';
export default function SignUp() {

    return (
        <>
            {/* <Header/> */}
            <Grid container className="">
                <div className="signuphead">
                    <a href='#'  onClick={() => window.open("https://wa.me/919002363070?text,=hi", "_blank")}   ><WhatsApp /></a>
                    <a href='#' onClick={() => window.open("https://www.facebook.com/", "_blank")} >
                        <Facebook />
                    </a>
                </div>
                <div>
                    <p className="signup-div">
                    কিভাবে একাউন্ট খুলবেনঃ
                    <a href="https://betbuzz247.bet/" target="_blank">betbuzz247.bet </a>     এ একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে লেনদেন করবেন তার বুঝতে হলে আপনার নিম্মের তথ্য গুলো পড়া জরুরী।

                    এজেন্ট লিস্টঃ
                    একাউন্ট খুলতে নিম্মের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। এজেন্ট লিষ্ট এর এজেন্টদের সাথে ইউজারদের শুধু মাত্র হোয়াটসঅ্যাপ,ফেইসবুক এবং মেসেঞ্জার এর মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসঅ্যাপ,ফেইসবুক এবং মেসেঞ্জার ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। এজেন্টদের সাথে যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসঅ্যাপ,ফেইসবুক এবং মেসেঞ্জার আইকন উপরে ক্লিক করুন অথবা ফোন নাম্বারটি মোবাইলে সেভ করে তাকে হোয়াটসঅ্যাপ এ মেসেজ পাঠাতে পারবেন। হোয়াটসঅ্যাপ এপটি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে স্টোর থেকে ইন্সটল করে নিন। অনলাইন মাষ্টার এজেন্ট লিস্টঃ

                    এজেন্ট কয় প্রকারঃ
                    অনলাইন সুপার এজেন্ট লিস্টঃ সুপার এজেন্টরা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে পারেন। কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে জানাতে হবে। উপরে মেনুতে এডমিন লিষ্ট দেয়া আছে।

                    অনলাইন মাষ্টার এজেন্ট লিস্টঃ অনলাইন এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি সুপার এজেন্ট এর কাছে অভিযোগ করতে হবে।

                    লোকাল মাষ্টার এজেন্ট লিস্টঃ লোকাল মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। কিন্তু তাদের সাথে লেনদেন প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না।

                    লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা পরিচিত দের সাথে লেনদেন করে । যারা আগে বাজি ধরিয়ে দিত, তাদের কেই মুলত লোকাল এজেন্ট দেয়া হয়েছে। লোকাল এজেন্ট রা অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন রাখতে চায়। লোকাল এজেন্ট দের সাথে অনলাইনে কোন ভাবেই লেনদেন করবেন না, আর করে থাকলে তার দায়ভার পুরোটাই আপনার।
                    </p>
                </div>




            </Grid>

        </>
    );
}
