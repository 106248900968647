// import React from 'react';
import '../assets/css/style.css';
import background from '../assets/images/bg-purple.png';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { useHistory } from 'react-router';
import Appconfig from "../config/config";
import React from 'react';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles, Grid, Typography, Accordion, Table, TableHead, TableRow, TableCell, TableBody, AccordionSummary, AccordionDetails, AppBar, Tab, Tabs, Button, Box, Paper, Divider, List, ListItem } from '@material-ui/core'
import InPlayImg from '../assets/images/play.svg'
import BookmakerImg from '../assets/images/bookmaker.svg'
import PremiumImg from '../assets/images/premium.svg'
import clockImg from '../assets/images/clock-green.png'
import FancyImg from '../assets/images/fancy.svg'
import Loader from '../assets/images/loading40.gif';
import bookmarkwithtime from '../assets/images/bookmarkwithtime.png';
import cricketE from '../assets/images/cricket-e.png';
import soccerE from '../assets/images/soccer-e.png';
import tennisE from '../assets/images/tennis-e.png';
import PinOff from '../assets/images/pin-off.svg';
import GreenPinOff from '../assets/images/green-pin-off.svg';
import axios from 'axios';
import moment from 'moment';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from 'react-query';
import { postErrorToslack } from '../config/common';

function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }
}
function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 0,
        marginLeft: 20,
        marginRight: 20,
        height: '100%',
        overflow: 'hidden',
        // width: '75%',
        // maxWidth: "70%",
        backgroundColor: theme.palette.background.paper,
    },
    paperStyle: {
        height: 'calc(100% - 104px)',
        // height:'80%',
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    heightSet: {
        height: 'calc(100% - 105px)',
    },
    time: {
        width: '70px',
        fontWeight: 'bold',
        fontSize: '0.8rem',
    },
    textSize: {
        fontSize: '0.8rem',
        verticalAlign: 'middle',
    },
    listStyle: {
        paddingBottom: '4px',
        paddingTop: '4px',
    },
    fromto: {
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    betSlipBar: {
        marginRight: '20px',
        backgroundColor: 'pink',
    },
    betSlipGrid: {
        backgroundColor: 'red',
    },
}));
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}
function value(val) {
    return ({ time: val[0], type: val[1], game: val[2], from: val[3], to: val[4], link: val[5] });
}
function createData(name1, value, name2, play, link, calories, fat, carbs, protein, time) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein, time };
}
function createData1(
    sport,
    event_id,
    name,
    value,
    action,
    backFirst,
    layFirst,
    backSecond,
    laySecond,
    selection1Id,
    selection2Id,
    test,
    is_fancy,
    is_bookmaker,
    tv_link
) {
    return {
        sport, event_id, name, value, action, backFirst, layFirst, backSecond, laySecond, selection1Id, selection2Id, is_fancy, is_bookmaker, tv_link
    };
}
const Inplay = (props) => {
    const queryClient = useQueryClient()
    const classes = useStyles();
    const [value, setValue] = React.useState(1);
    const [inplayMatchList, setInplayMatchList] = React.useState({
        cricket: false,
        tennis: false,
        soccer: false,
    });
    const [todayMatchList, setTodayMatchList] = React.useState({
        cricket: false,
        tennis: false,
        soccer: false,
    });
    const [tmrwMatchList, setTmrwMatchList] = React.useState({
        cricket: false,
        tennis: false,
        soccer: false,
    });
    const stotedList = JSON.parse(window.localStorage.getItem("eventListByUser"));
    const [eventListData, setEventListData] = React.useState(stotedList ? stotedList : []);
    const history = useHistory();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const getDashboardDataNew = () => {
          var data = JSON.stringify({
            user_id: userInfo ? userInfo._id : "",
            event_type_id: 0,
        });
        let url = "get-dashboard-data-with-user-id";
        if (!userInfo) {
            url = "get-dashboard-data-without-user-id";
        }
        return axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        try {
            if (data) {
                renderEventData(data.data.resultData);
                loader_remove();
            }
        } catch (e) {
            loader_remove();
            postErrorToslack(e.message);
        }
    }
    const onCache = (data) => {
        try {
            if (data) {

                renderEventData(data);
                loader_remove();
            }
        }
        catch (e) {
            loader_remove();
        }
    }
    const { isLoading, isError, error, data, refetch } = useQuery('eventLists', getDashboardDataNew, {
        onSuccess
    })
    // console.log("eventLists",isError);
    // isLoading ? loader_default() : loader_remove();
    React.useEffect(() => {
        // loader_remove();
        if (queryClient.getQueryData(`eventLists`)) {
            onCache(queryClient.getQueryData(`eventLists`).data.resultData);
        }
        // getDashboardData()
    }, [])
    const handleChange = (event, newValue) => {
        if (newValue === 3) {
            history.push('/result');
        }
        setValue(newValue);
    };

     function pinmarket(event_id, market_id) {
        if (userInfo) {
            var data = JSON.stringify({
                event_id: event_id,
                market_id: market_id,
                user_id: userInfo._id,
                type: "Match"
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}favorite/add&removeFavorite`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        refetch();
                    } catch (e) {
                        postErrorToslack(e.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    postErrorToslack(error.message);
                });
        }
        else {
            history.push("/login")
        }
    }

    const renderEventData = (eventData) => {
        let iPlayL = {
            cricket: false,
            tennis: false,
            soccer: false,
        };
        let todayPlayL = {
            cricket: false,
            tennis: false,
            soccer: false,
        };
        let tmrwPlayL = {
            cricket: false,
            tennis: false,
            soccer: false,
        };

        let tempData = [];
        eventData.map((Crick, index) => {
            Crick.competitions.map((competition, index) => {
                competition.events.map((event, index) => {
                    // if (event.marketTypes.length) {
                        // console.log('test for empty',event.marketTypes[0].marketRunners.length)
                        let sport_type = '';
                        // console.log(event);
                        if (event.event_type == 4) {
                            sport_type = 'Cricket';
                            if (event.is_inplay == 'True') {
                                iPlayL.cricket = true;
                            } else {
                                todayPlayL.cricket = true;
                                tmrwPlayL.cricket = true;
                            }
                        } else if (event.event_type == 2) {
                            sport_type = 'Tennis';
                            if (event.is_inplay == 'True') {
                                iPlayL.tennis = true;
                            } else {
                                todayPlayL.tennis = true;
                                tmrwPlayL.tennis = true;
                            }
                        }
                        else if (event.event_type == 1) {
                            sport_type = 'Soccer';
                            if (event.is_inplay == 'True') {
                                iPlayL.soccer = true;
                            } else {
                                todayPlayL.soccer = true;
                                tmrwPlayL.soccer = true;
                            }
                        }
                        let eventDetail = {
                            sport: sport_type,
                            event_id: event.event_id,
                            name: event.event_name,
                            // time: moment(event.open_date).format("hh:mm"),
                            time: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("HH:mm"),
                            date: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("YYYY-MM-DD"),
                            // date: moment(event.open_date).format("YYYY-MM-DD"),
                            is_inplay: event.is_inplay == 'True' ? "Inplay" : "Going Inplay",
                            backFirst: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].back_1_price : 0,
                            layFirst: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].lay_1_price : 0,
                            backSecond: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].back_1_price : 0,
                            laySecond: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].lay_1_price : 0,
                            selection1Id: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].selection_id : 0,
                            selection2Id: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketType[0].marketType[0].marketRunners && event.marketType[0].marketType[0].marketRunners.length && event.marketType[0].marketType[0].marketRunners[0]) ? event.marketType[0].marketRunners[0].selection_id : 0,
                            is_fancy: event.is_fancy,
                            is_bookmaker: event.is_bookmaker,
                            market_id: event.marketTypes && event.marketTypes.length && event.marketTypes[0].market_name == "Match Odds" ? event.marketTypes[0].market_id : "",
                            is_pin: event.marketTypes && event.marketTypes.length && event.marketTypes[0].is_pin == "Yes" ? true : false,
                            is_virtual: event.is_virtual == "Yes" ? true : false,
                            channel_id: event.channel_id,
                            tv_link: event.is_inplay == 'True' ? event.tv_link : ""
                        }



                        // console.log('eventDetail',eventDetail);
                        tempData.push(eventDetail);
                    // }
                    // else {
                    //     // console.log('its empty',event.marketTypes[0].marketRunners.length)
                    // }
                });
            });
        });
        setInplayMatchList(iPlayL);
        setTodayMatchList(todayPlayL);
        setTmrwMatchList(tmrwPlayL);
        setEventListData(tempData);
        window.localStorage.setItem("eventListByUser", JSON.stringify(tempData));
    };
    // console.log('eventListData', eventListData);
    const root = classes.root + " inplay-parent";
    const today = moment().format("YYYY-MM-DD");
    // console.log('today',today);
    const tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
    return (
        <>
            <Header eventList={eventListData} />
            <div className={root}>
                {isLoggedIn ? null :
                    <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                }
                <Grid container spacing={1} style={{ backgroundColor: '#F0ECE1', marginTop: userInfo ? 0 : 60 }}>
                    <Grid item lg={9} xs={12} className="mini-games-grid">
                        <AppBar position="static" className="tab headerInplay">
                            <Tabs className="main_tabbing_border" value={value} variant="fullWidth" onChange={handleChange}>
                                <Tab label="In-Play" {...a11yProps(0)} />
                                <Tab label="Today" {...a11yProps(1)} />
                                <Tab label="Tomorrow" {...a11yProps(2)} />
                                {/* <Tab label="Result" onClick={() => userInfo ? { ...a11yProps(3) } : history.push("/login")} /> */}
                            </Tabs>
                        </AppBar>
                        <TabPanel className="inplay-tabs" value={value} index={0}>
                            {inplayMatchList.cricket ? <Accordion defaultExpanded={true} style={{ marginTop: 4 }}>
                                <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                    <Typography>Cricket</Typography>
                                </AccordionSummary>

                                <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className="tbl_head xs-none">
                                                <TableCell colSpan="5"></TableCell>
                                                <TableCell align="right" colSpan="2">1</TableCell>
                                                <TableCell align="right" colSpan="2">x</TableCell>
                                                <TableCell align="right" colSpan="2">2</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="tbl_body">
                                            {eventListData.map((row, index) => (
                                                row.is_inplay == "Inplay" && row.sport == "Cricket" ?
                                                    <TableRow p={1} key={"inplaycrick" + index} className="odd_even_clr" button>
                                                        <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none" />
                                                                <img src={InPlayImg} alt="skyexchange" />
                                                                <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                {row.is_virtual && <SportsEsportsIcon className="vrIcon" />}
                                                                {
                                                                    row.is_fancy == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size  v-none game-fancy" />
                                                                            <img src={clockImg} className="clockImg" alt="skyexchange" /><img src={FancyImg} alt="skyexchange" className="fancy-f-icon fancyImgall" />
                                                                        </> : null
                                                                }
                                                                {
                                                                    row.is_bookmaker == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src={clockImg} className="clockImg" alt="skyexchange" /><img src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                        </> : null
                                                                }

                                                                <span className="time green">In-Play</span>
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                <Link to="#" style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        {
                                                                            row.name.length > 36 ?
                                                                                `${row.name.substring(0, 36)}...` :
                                                                                row.name
                                                                        }
                                                                        {/* <span> {row.name.split("v")[0]} </span>
                                                                        <span className="in-play"> v </span>
                                                                        <span>{row.name.split("v")[1]}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                        <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={cricketE} alt="cricket" /></span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}>

                                                            <label className="pin-toggle">
                                                                <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                            </label>
                                                        </TableCell>
                                                    </TableRow>
                                                    : ""
                                            ))}
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion> : ""}
                            {inplayMatchList.soccer ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                    <Typography>Soccer</Typography>
                                </AccordionSummary>
                                <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className="tbl_head xs-none">
                                                <TableCell colSpan="5"></TableCell>
                                                <TableCell align="right" colSpan="2">1</TableCell>
                                                <TableCell align="right" colSpan="2">x</TableCell>
                                                <TableCell align="right" colSpan="2">2</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="tbl_body">
                                            {eventListData.map((row, index) => (
                                                row.is_inplay == "Inplay" && row.sport == "Soccer" ?
                                                    <TableRow p={1} key={"inplaysocc" + index} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row hello" colSpan="5" onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })}>
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none" />
                                                                <img src={InPlayImg} alt="skyexchange" />
                                                                <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                <span className="">{row.action}</span>
                                                                {/* <span className="game-name xs-game-name"><img src={soccerE} alt="cricket" /></span> */}
                                                                <span className="time green">In-Play</span>
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                <Link to='#' style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        {
                                                                            row.name.length > 36 ?
                                                                                `${row.name.substring(0, 36)}...` :
                                                                                row.name
                                                                        }
                                                                        {/* <span> {row.name.split("v")[0]} </span>
                                                                        <span className="in-play"> v </span>
                                                                        <span>{row.name.split("v")[1]}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                                <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                        <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={soccerE} alt="cricket" /></span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><label className="pin-toggle">
                                                            <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                            <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                        </label></TableCell>
                                                    </TableRow>
                                                    : ""
                                            ))}
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion> : ""}
                            {inplayMatchList.tennis ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                    <Typography>Tennis</Typography>
                                </AccordionSummary>
                                <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className="tbl_head xs-none">
                                                <TableCell colSpan="5"></TableCell>
                                                <TableCell align="right" colSpan="2">1</TableCell>
                                                <TableCell align="right" colSpan="2">x</TableCell>
                                                <TableCell align="right" colSpan="2">2</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="tbl_body">
                                            {eventListData.map((row, index) => (
                                                row.is_inplay == "Inplay" && row.sport == "Tennis" ?
                                                    <TableRow p={1} key={"inplaytennis" + index} className="odd_even_clr" button>
                                                        <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none" />
                                                                <img src={InPlayImg} alt="skyexchange" />
                                                                <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                <span className="">{row.action}</span>
                                                                {/* <span className="game-name xs-game-name"><img src={tennisE} alt="cricket" /></span> */}
                                                                <span className="time green">In-Play</span>

                                                            </div>
                                                            <div className="text_left-in-play">
                                                                <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                <Link to='#' style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        {
                                                                            row.name.length > 36 ?
                                                                                `${row.name.substring(0, 36)}...` :
                                                                                row.name
                                                                        }
                                                                        {/* <span> {row.name.split("v")[0]} </span>
                                                                        <span className="in-play"> v </span>
                                                                        <span>{row.name.split("v")[1]}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                                <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                        <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={tennisE} alt="cricket" /></span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><label className="pin-toggle">
                                                            <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                            <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                        </label></TableCell>
                                                    </TableRow>
                                                    : ""
                                            ))}
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion> : ""}
                        </TabPanel>
                        <TabPanel value={value} index={1} className="in-play-table">
                            <div className="in-play-box xs-none">
                                <Box display="flex" p={1} bgcolor="background.paper">
                                    <p varient="h5"><b>Sport Filter:</b></p>
                                    <p p={2}>
                                        <span className="paddingX-1">
                                            <span className="paddingX-1">Cricket</span>
                                            <FiberManualRecordIcon className="circle-size" />
                                            <span className="paddingX-1">Soccer</span>
                                            <FiberManualRecordIcon className="circle-size" />
                                            <span className="paddingX-1">Tennis</span>
                                        </span>
                                    </p>
                                    <Button variant="outlined" style={{ height: 30, backgroundColor: '#fff', }}>Filter</Button>
                                </Box>
                            </div>
                            <BrowserView>
                                <div className={classes.heightSet}>
                                    <Paper className={classes.paperStyle}>
                                        <List component="p" aria-label="secondary mailbox folders" className={classes.listStyle}>
                                            {eventListData.map((item, index) => (
                                                1 == 1
                                                    ?
                                                    <div key={"bw" + index}>
                                                        <ListItemLink className="xs-none" href={item.link} p={1}>
                                                            <Typography className={classes.time}>{item.value}</Typography>
                                                            <Typography className={classes.textSize}>
                                                                <span>{item.sport}</span>
                                                                <PlayArrowIcon color="disabled" className={classes.textSize} />
                                                                <span>{item.name}</span>
                                                                <PlayArrowIcon color="disabled" className={classes.textSize} />
                                                                <Link to='/full-market' style={{ color: '#2789CE' }}>
                                                                    <b>{item.name}</b>
                                                                </Link>
                                                            </Typography>
                                                        </ListItemLink>
                                                        <div className="iplay-now-head md-none">
                                                            <img src={InPlayImg} alt="skyexchange" />
                                                            <span className="">{item.time}</span>
                                                        </div>
                                                        <div className="inplay-now-xsblock md-none">
                                                            <FiberManualRecordIcon className="circle-size" /> &nbsp;
                                                            <Link to='/full-market' style={{ color: '#2789CE' }}>
                                                                <b>{item.from} v {item.to}</b>
                                                            </Link>
                                                        </div>
                                                        <Divider />
                                                    </div>
                                                    : ""
                                            ))}
                                        </List>
                                    </Paper>
                                </div>
                            </BrowserView>
                            <MobileView>
                            {todayMatchList.cricket ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                   <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Crickets</Typography>
                                    </AccordionSummary> 
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">x</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body">
                                                {eventListData.map((row, index) => (
                                                    row.sport == "Cricket" && String(row.date) == String(today) || row.sport == "Cricket" && String(row.date) < String(today) || row.sport == "Cricket" && row.is_inplay == "Inplay" ?
                                                        <TableRow p={1} key={"todaycric" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    <img src={InPlayImg} alt="skyexchange" />
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    {row.is_virtual && <SportsEsportsIcon className="vrIcon" />}
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img src={BookmakerImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {/* <img src={InPlayImg} alt="skyexchange" /> */}
                                                                    {row.is_inplay == "Inplay" ? <> <img src={clockImg} className="clockImg" alt="skyexchange" /> <span className="time green">In-Play </span></> : <span className="time">{row.time}</span>}
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    {
                                                                        row.is_inplay == "Inplay" ? (
                                                                            <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        ) : (
                                                                            <FiberManualRecordIcon className="going-inplay-cricle" />
                                                                        )
                                                                    }
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={cricketE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>: ""}
                                {todayMatchList.soccer ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                    <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Soccer</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body">
                                                {eventListData.map((row, index) => (
                                                    row.sport == "Soccer" && String(row.date) == String(today) || row.sport == "Soccer" && String(row.date) < String(today) || row.sport == "Soccer" && row.is_inplay == "Inplay" ?
                                                        <TableRow p={1} key={"todaysocc" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    <img src={InPlayImg} alt="skyexchange" />
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img src={BookmakerImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {/* <img src={InPlayImg} alt="skyexchange" /> */}
                                                                    {(row.is_inplay == "Inplay" || row.is_inplay == "True") ? <> <img src={clockImg} className="clockImg" alt="skyexchange" /> <span className="time green">In-Play </span></> : <span className="time">{row.time}</span>}
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    {
                                                                        row.is_inplay == "Inplay" ? (
                                                                            <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        ) : (
                                                                            <FiberManualRecordIcon className="going-inplay-cricle" />
                                                                        )
                                                                    }
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={soccerE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion> : ""}
                                {todayMatchList.tennis ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                    <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Tennis</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body">
                                                {eventListData.map((row, index) => (
                                                    row.sport == "Tennis" && String(row.date) == String(today) || row.sport == "Tennis" && String(row.date) < String(today) || row.sport == "Tennis" && row.is_inplay == "Inplay" ?
                                                        <TableRow p={1} key={"todaytennis" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    <img src={InPlayImg} alt="skyexchange" />
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <>
                                                                                <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                                <img src={BookmakerImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    {/* <img src={InPlayImg} alt="skyexchange" /> */}
                                                                    {(row.is_inplay == "Inplay" || row.is_inplay == "True") ? <> <img src={clockImg} className="clockImg" alt="skyexchange" /> <span className="time green">In-Play </span></> : <span className="time">{row.time}</span>}
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    {
                                                                        row.is_inplay == "Inplay" ? (
                                                                            <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        ) : (
                                                                            <FiberManualRecordIcon className="going-inplay-cricle" />
                                                                        )
                                                                    }
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={tennisE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion> : ""}
                            </MobileView>
                        </TabPanel>
                        <TabPanel value={value} index={2} className='in-play-table'>
                            <div className="in-play-box xs-none">
                                <Box display="flex" p={1} bgcolor="background.paper">
                                    <p varient="h5"><b>Sport Filter:</b></p>
                                    <p p={2}>
                                        <span className="paddingX-1">
                                            <span className="paddingX-1">Cricket</span>
                                            <FiberManualRecordIcon className="circle-size" />
                                            <span className="paddingX-1">Soccer</span>
                                            <FiberManualRecordIcon className="circle-size" />
                                            <span className="paddingX-1">Tennis</span>
                                        </span>
                                    </p>
                                    <Button variant="outlined" style={{ height: 30, backgroundColor: '#fff', }}>Filter</Button>
                                </Box>
                            </div>
                            <BrowserView>
                                <div className={classes.heightSet}>
                                    <Paper className={classes.paperStyle}>
                                        <List component="p" aria-label="secondary mailbox folders" className={classes.listStyle}>
                                            {eventListData.map((row, index) => (
                                                row.sport == "Cricket" && row.action == today ?
                                                    <TableRow p={1} key={"today1cric" + index} className="odd_even_clr" button>
                                                        <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none" />
                                                                <img src={InPlayImg} alt="skyexchange" />
                                                                {row.is_virtual && <SportsEsportsIcon className="vrIcon" />}
                                                                {
                                                                    row.is_fancy == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                        </> : null
                                                                }
                                                                {
                                                                    row.is_bookmaker == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src={BookmakerImg} alt="skyexchange" />
                                                                        </> : null
                                                                }
                                                                {/* <img src={InPlayImg} alt="skyexchange" /> */}
                                                                <span className="">{row.time}</span>
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                <FiberManualRecordIcon className=" going-inplay-cricle" />
                                                                <Link to="#" style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        {
                                                                            row.name.length > 36 ?
                                                                                `${row.name.substring(0, 36)}...` :
                                                                                row.name
                                                                        }
                                                                        {/* <span> {row.name.split("v")[0]} </span>
                                                                        <span className="in-play"> v </span>
                                                                        <span>{row.name.split("v")[1]}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                        <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={cricketE} alt="cricket" /></span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                            <label className="pin-toggle">
                                                                <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                            </label>
                                                        </TableCell>
                                                    </TableRow>
                                                    : ""
                                            ))}
                                        </List>
                                    </Paper>
                                </div>
                            </BrowserView>
                            <MobileView>
                            {tmrwMatchList.cricket ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                   <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Crickets</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">x</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body">
                                                {eventListData.map((row, index) => (
                                                    // row.is_inplay == "Going Inplay" && 
                                                    row.sport == "Cricket" && row.date == tomorrow || row.sport == "Cricket" && row.date > tomorrow ?
                                                        <TableRow p={1} key={"today2cric" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row hellll" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <>
                                                                                <img className="bookImgall" src={BookmakerImg} alt="skyexchange" />
                                                                            </> : null
                                                                    }
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    <span className="time">{`Tomorrow ${row.time}`}</span>
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    <FiberManualRecordIcon className=" going-inplay-cricle" />
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {/* {row.name} */}
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 30)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={cricketE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion> : ""}
                                {tmrwMatchList.soccer ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                    <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Soccer</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body">
                                                {eventListData.map((row, index) => (
                                                    // row.is_inplay == "Going Inplay" && 
                                                    row.sport == "Soccer" && row.date == tomorrow || row.sport == "Soccer" && row.date > tomorrow ?
                                                        <TableRow p={1} key={"today2cric" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row hellll" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <img className="bookImgall" src={BookmakerImg} alt="skyexchange" />
                                                                            : null
                                                                    }
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    <span className="time">{`Tomorrow ${row.time}`}</span>
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    <FiberManualRecordIcon className=" going-inplay-cricle" />
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {/* {row.name} */}
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 30)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={soccerE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion> : ""}
                                {tmrwMatchList.tennis ? <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                                    <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar" style={{ background: `linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)` }}>
                                        <Typography>Tennis</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow className="tbl_head xs-none">
                                                    <TableCell colSpan="5"></TableCell>
                                                    <TableCell align="right" colSpan="2">1</TableCell>
                                                    <TableCell align="right" colSpan="2">2</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="tbl_body ">
                                                {eventListData.map((row, index) => (
                                                    // row.is_inplay == "Going Inplay" && 
                                                    row.sport == "Tennis" && row.date == tomorrow || row.sport == "Tennis" && row.date > tomorrow ?
                                                        <TableRow p={1} key={"today2cric" + index} className="odd_even_clr" button>
                                                            <TableCell onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} className="table_first_row hellll" colSpan="5">
                                                                <div className="md-none inplay-xs-title">
                                                                    <FiberManualRecordIcon className="circle-size v-none" />
                                                                    {
                                                                        row.is_fancy == "True" ?
                                                                            <img className="fancyImgall" src={FancyImg} alt="skyexchange" />
                                                                            : null
                                                                    }
                                                                    {
                                                                        row.is_bookmaker == "True" ?
                                                                            <img className="bookImgall" src={BookmakerImg} alt="skyexchange" />
                                                                            : null
                                                                    }
                                                                    <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                    <span className="time">{`Tomorrow ${row.time}`}</span>
                                                                </div>
                                                                <div className="text_left-in-play">
                                                                    <FiberManualRecordIcon className=" going-inplay-cricle" />
                                                                    <Link to="#" style={{ color: '#2789CE' }}>
                                                                        <Box display="contents">
                                                                            {/* {row.name} */}
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 30)}...` :
                                                                                    row.name
                                                                            }
                                                                            {/* <span> {row.name.split("v")[0]} </span>
                                                                            <span className="in-play"> v </span>
                                                                            <span>{row.name.split("v")[1]}</span> */}
                                                                        </Box>
                                                                    </Link>
                                                                    <span className="in-play xs-none"> {row.action} <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                                    {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span> */}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.layFirst}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{""}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.backSecond}</span></TableCell>
                                                            <TableCell className="xs-none" align="right"><span>{row.laySecond}</span></TableCell>
                                                            <TableCell className="pin-icon"><span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={tennisE} alt="cricket" /></span></TableCell>
                                                            <TableCell className="pin-icon" style={{ width: "30px" }}>
                                                                <label className="pin-toggle">
                                                                    <input type="checkbox" onChange={() => console.log("")} checked={row.is_pin} />
                                                                    <a className="inplay-last-icon" onClick={() => pinmarket(row.event_id, row.market_id)}></a>
                                                                </label>
                                                            </TableCell>
                                                        </TableRow>
                                                        : ""
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion> : ""}
                            </MobileView>
                        </TabPanel>
                    </Grid>
                    <Grid item lg={3} className="betSlipGrid xs-none">
                        <BetSlip />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default React.memo(Inplay)