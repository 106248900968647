import React from 'react'
import { List, ListItemText, ListItem } from '@material-ui/core'
import { ArrowForwardIos, MoreVert } from '@material-ui/icons';

const LeftSideBar = () => {
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    return (
        <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="first-item">
                <ListItemText primary={<MoreVert />} />
                <span className="first-list-icon">Sports</span>
            </ListItemLink>
            <ListItemLink href="#simple-list" className="active">
                <ListItemText primary="Sports" />
                <span className="list-icon"><ArrowForwardIos /></span>
            </ListItemLink>
            <ListItemLink href="#simple-list">
                <ListItemText primary="In-Play" />
                <span className="list-icon"><ArrowForwardIos /></span>
            </ListItemLink>
            <ListItemLink href="#simple-list">
                <ListItemText primary="Cricket" />
                <span className="list-icon"><ArrowForwardIos /></span>
            </ListItemLink>
        </List>
    )
}

export default LeftSideBar;