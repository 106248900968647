import React, { useState, useEffect } from 'react';
import noInernetimg from '../src/skyexchange/assets/images/nointernet.webp';
const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state 
  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });

  window.addEventListener('offline', () => {
    setOnline(false)
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return (
      props.children
    )
  } else {
    return (<div className='nointernet'><img src={noInernetimg} alt="No Internet!" /></div>)
  }
}
export default NoInternetConnection