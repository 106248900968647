import '../assets/css/style.css';
import React, { useContext, useEffect, useState } from 'react';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip';
import LeftSideBar from '../includes/LeftSideBar';
import { toast } from "react-toastify";
// import { SocketContext } from "../../context/socket";


import { WebSocketContext } from "../../context/websocket";

import { Modal, Tooltip, Fade, IconButton, Backdrop, ClickAwayListener, Grid, makeStyles, Table, TableRow, TableCell, TableHead, TableBody, Link, Box, Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, Button, AppBar, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
//IMAGES
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Replay from '@material-ui/icons/Replay';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import background from '../assets/images/bg-purple.png';
import ground from '../assets/images/ground.png';
import transparent from '../assets/images/transparent.gif';
import Time from '../assets/images/clock-green.png';
import SimplePin from '../assets/images/simple-pin.svg';
import BtnDelete from '../assets/images/btn-delete.svg';
import { useParams, useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Appconfig from "../config/config"
import axios from 'axios';
import Loader from '../assets/images/loading40.gif';
import { useQuery, useQueryClient } from 'react-query';
import VirtualList from 'react-virtual-list';
import { postErrorToslack } from '../config/common';

import { useLocation } from 'react-router-dom';


const match_odds_bet_limit = 20;
function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
function TableRowLink(props) {
    return <TableRow component="Link" {...props} />;
}
function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }
}
function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
        height: "100%",
    },
    tableHeight: {
        height: "100%",
    },
}));
const PmFancy = ({ bettingboxRef, row, StakeValue, hideAllBetBox, chipList, handleOpen, placeStakeValue, betPlace, BetPlaceData, params, placing }) => {
    return (
        <div>
            <Accordion defaultExpanded={false} key={`premium_ac_site_id_${row.id}`} id={`premium_ac_site_id_${row.id}`} style={{ margin: 0, marginBottom: 1 }}>
                <AccordionSummary aria-controls="panel1a-content" className="bet-slip-bar bet-slip-bar-pr">
                    <a id="bookmaker_pin" className="pin-off" href="javascript:void(0)">Pin to Muilti Markets</a>
                    <Typography className="prrunnername">{row.marketName}</Typography>
                </AccordionSummary>
                <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                    <div className="sectionbookmaker">
                        {row.hasOwnProperty('sportsBookSelection') && row.sportsBookSelection.map((runner, i) => (
                            <div key={"acspSelc" + i}>
                                <input type="hidden" id="bookmaker_id_preserve" value={runner.marketId} />
                                <input type="hidden" className={`position_${runner.marketId.replace('.', '')}`} data-id={runner.id} value={0} />
                                <dl id="" className="bets-selections bets-selections-pr" style={{ display: 'flex' }}>
                                    <dt>
                                        <h4 id="runnerName ">{runner.selectionName}</h4>
                                        <ul id="winLoss">
                                            <li id={runner.id + "_maxprofit_list_loss_runner_prev_" + runner.marketId.replace('.', '')} className="win market-exposure" data-value={0} style={{ display: 'block' }}>{Math.abs(0)}</li>
                                            <li id="lossWithoutBet" className="win" style={{ display: 'none', marginRight: "1.06667vw" }}></li>
                                            <li id={runner.id + "_maxprofit_list_loss_runner_next_" + runner.marketId.replace('.', '')} className="to-win market-exposure" style={{ display: 'none', marginLeft: "1.06667vw" }}>0</li>
                                            <li id="lossWithBet" className="win" style={{ display: 'none' }}></li>
                                            <li id="zeroProfit" className="win" style={{ display: 'none' }}></li>
                                            <li id="zeroLiability" className="win" style={{ display: 'none' }}></li>
                                            <li id="zeroProfitWithBet" className="win" style={{ display: 'none' }}></li>
                                            <li id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }}>
                                                {/* <p  class="market-exposure" ></p> */}
                                            </li>
                                        </ul>
                                    </dt>
                                    <dd id="back_1" className={`bookmaker-back-1 table_row_${runner.marketId.replace('.', '')}_${runner.id} ${runner.status === "suspended" ? "suspended" : ""}`}>
                                        <div className="back-1 back-1-pr pink-core" onClick={() =>
                                            handleOpen(
                                                params.event_id,
                                                runner.marketId,
                                                true,
                                                runner.odds,
                                                false,
                                                runner.id,
                                                runner.selectionName,
                                                `availableToBack1_price_${runner.marketId.replace('.', '')}_${runner.id}`,
                                                true
                                            )
                                        }>
                                            <div className="back-box" id={`availableToBack1_price_${runner.marketId.replace('.', '')}_${runner.id}`}>{runner.odds}</div>
                                        </div>
                                    </dd>
                                </dl>
                                <div ref={bettingboxRef} p={1} key={"mxov" + runner.marketId} className={`pbb place-bet-block_${runner.marketId.replace('.', '')}_${runner.id}`} button>
                                    <div className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                        <div className="bet-list" >
                                            <div>
                                                <p>&nbsp;</p>
                                                <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                                            </div>
                                            <div className="input-num">
                                                <p>Min Bet</p>
                                                <div>
                                                    <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue > 0 ? StakeValue - 1 : console.log("negative"))} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                                        <RemoveIcon />
                                                    </a>
                                                    <span className={!StakeValue ? "typeing" : ""} >{StakeValue ? StakeValue : ""}</span>
                                                    <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                                        <AddIcon />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="coin-list" >
                                            {chipList.slice(0, 6).map((row, index) => (
                                                <Link to="#" key={"fullmarketChilList" + index} onClick={() => placeStakeValue(row.chip_value)}>{row.chip_value}</Link>
                                            ))}
                                        </div>
                                        <div className="keyboard-wrap">
                                            <div className="btn-tel">
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("1"))}>1</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("2"))}>2</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("3"))}>3</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("4"))}>4</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("5"))}>5</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("6"))}>6</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("7"))}>7</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("8"))}>8</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("9"))}>9</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("0"))}>0</Link>
                                                <Link to="#" onClick={() => placeStakeValue(String(StakeValue).concat("00"))}>00</Link>
                                                <Link to="#">.</Link>
                                            </div>
                                            <a href="javascript:void(0)" onClick={() => placeStakeValue(String(StakeValue)?.substring(0, String(StakeValue).length - 1))}><img src={BtnDelete} /></a>
                                        </div>
                                        <div className="btn-list">
                                            <a className="cancelbtn" href="javascript:void(0)" onClick={hideAllBetBox}>Cancel</a>
                                            <Link className={!StakeValue ? "placebtn disable" : "placebtn"} to="/#" onClick={() => placing ? console.log('Placing....') : betPlace()}>{placing ? "Placing...." : "Place Bet"}</Link>
                                        </div>
                                        <div id="toscroll" className={`acceptodds ${BetPlaceData.is_back ? "b-dark-color" : "l-dark-color"}`}>
                                            <label>
                                                <input type="checkbox" />
                                                <a>Accept Any Odds</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
const MyList = ({
    virtual,
    itemHeight,
    params, placing, bettingboxRef, chipList, handleOpen, placeStakeValue, StakeValue, hideAllBetBox, betPlace, BetPlaceData
}) => (
    <div >
        {virtual.items.map(item => (
            <div key={`item_${item.id}`}>
                <MemoRenderName bettingboxRef={bettingboxRef} chipList={chipList} handleOpen={handleOpen} placeStakeValue={placeStakeValue} StakeValue={StakeValue} hideAllBetBox={hideAllBetBox} betPlace={betPlace} BetPlaceData={BetPlaceData} key={`premium_site_id_new_${item.id}`} id={`premium_site_id_new_${item.id}`} row={item} params={params} placing={placing} />
            </div>
        ))}
    </div>
);
const MemoRenderName = React.memo(PmFancy, (prev, next) => {
    const valEqual = prev.id !== next.id;
    return valEqual;
});

const checkHighestMarketPrice = (runner) => {
    let price_exceed_by_limit = false;
    // await Promise.all(
    if (runner.market_id.toString().includes('BM')) {

        if (runner.back_1_price > 0) {
            if (parseFloat((runner.back_1_price / 100) + 1) > match_odds_bet_limit) {
                price_exceed_by_limit = true;
            }

        }

        if (runner.lay_1_price > 0) {
            if (parseFloat((runner.lay_1_price / 100) + 1) > match_odds_bet_limit) {
                price_exceed_by_limit = true;
            }

        }

        // if (parseFloat((runner.back_1_price/100) + 1) > match_odds_bet_limit || parseFloat((runner.lay_1_price/100) + 1) > match_odds_bet_limit) {
        //     price_exceed_by_limit = true;
        // }

        if (runner.back_1_price <= 0) {
            // if (parseFloat((runner.back_1_price/100) + 1) <= 0) {
            price_exceed_by_limit = true;
            // }

        }


        if (runner.lay_1_price <= 0) {
            // if ( parseFloat((runner.lay_1_price/100) + 1) <= 0) {
            // price_exceed_by_limit = true;
            // }

        }



        // if (parseFloat((runner.back_1_price/100) + 1) <= 0 && parseFloat((runner.lay_1_price/100) + 1) <= 0) {
        //     price_exceed_by_limit = true;
        // }
    }
    else {
        if (parseFloat(runner.back_1_price) > match_odds_bet_limit || parseFloat(runner.lay_1_price) > match_odds_bet_limit) {
            price_exceed_by_limit = true;
        }


        if (parseFloat(runner.back_1_price) <= 0 && parseFloat(runner.lay_1_price) <= 0) {
            price_exceed_by_limit = true;
        }
    }


    // )


    return price_exceed_by_limit;
}

const MyVirtualList = VirtualList()(MyList);
const CasinoMarket = () => {
    const { state } = useLocation();

     const bettingboxRef = React.useRef(null);
    const queryClient = useQueryClient()
    const history = useHistory();
    // const socket = useContext(SocketContext);
    const websocket = useContext(WebSocketContext);

    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const params = useParams();
    const [postionFancyList, setPostionFancyList] = React.useState([]);
    const [positionOpen, setPositionOpen] = React.useState(false);
    const eventId = params.event_id;
    const classes = useStyles();
    // const [state, setState] = React.useState({
    //     checkedB: false,
    // });
    const [isBetPlaced, setIsBetPlaced] = React.useState(false);
    const [bookButton, setBookButton] = React.useState([]);
    const [betShow, setBetShow] = React.useState(false);
    const storedList = JSON.parse(window.localStorage.getItem(`eventDetailsL_${params.event_id}`));
    const [EventData, setEventData] = React.useState(storedList ? storedList : []);
    const [StakeValue, setStakeValue] = React.useState(0);
    const [lastShowedBox, setLastShowedBox] = React.useState();
    const [placing, setPlacing] = React.useState(false);
    const [lossValue, setLossValue] = React.useState(0);
    const [tooltipOpen, settooltipOpen] = React.useState(false);
    const [toolBooktipOpen, setBooktooltipOpen] = React.useState(false);
    const [toolFancytipOpen, setFancytooltipOpen] = React.useState(null);
    const [defaultStake, setDefaultStake] = React.useState(0);
    const [BetPlaceData, setBetPlaceData] = React.useState({
        event_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        PM_FANCY: false
    });

    const [casinoLink, setCasinoLink] = React.useState('');


    const [tmpFancy, setTmpFancy] = React.useState(null);

    const [isReady, setIsReady] = useState(false);
    const [ProfitValue, setProfitValue] = React.useState(0);
    const [premiumFArr, setPremiumFArr] = React.useState([]);
    const handleShowPremium = (val) => {
        setShowPremium(val);
    }


    const getFancyData = async () => {
        const event_id = params.event_id;

        var data;
        let url;

        url = "getEventFancy"
        data = JSON.stringify({
            event_id: event_id,
            user_id: userInfo ? userInfo._id : ''
        });

        let fancyData = await axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });


        if (fancyData.status == 200) {
            fancyData = fancyData.data

            if (fancyData.result == 1) {
                fancyData = fancyData.data;
                // console.log('fancyData',fancyData);
                fancyHtml(fancyData)

                // console.log('fancyData',fancyData);
            }

        }



    }

    console.log('casinoLink',casinoLink)
    React.useEffect(() => {
        // const data = this.props.
    const receivedData = state?.casino_path ? state?.casino_path : null;

        setCasinoLink(receivedData);

         // console.log('data',data);
        // const intervalId =   setInterval(function () {
            getFancyData()
        // }, 750)

        // return () => clearInterval(intervalId);

    }, [])


    const getDashboardDataNew = () => {
        const event_id = params.event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: event_id,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: event_id,
            });
        }
        return axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        try {
            if (data) {
                setEventData(data.data.resultData);
                if (data.data.resultData.length > 0) {
                    let event_detail = data.data.resultData[0];
                    if (event_detail.tv_link) {
                        setTvUrl(event_detail.tv_link);

                    }
                }


                if (userInfo) {
                    getFancysExposure();
                }
                setRefetchData(false)
                window.localStorage.setItem(`eventDetailsL_${params.event_id}`, JSON.stringify(data.data.resultData));
            }
        } catch (e) {
            postErrorToslack(e.message);
        }
    }
    const { isLoading, isError, error, data, refetch } = useQuery(`eventDetails_${params.event_id}`, getDashboardDataNew, {
        onSuccess
    })
    !storedList && isLoading ? loader_default() : loader_remove();

    const handleTooltipClose = () => {
        settooltipOpen(false);
    };
    const handleTooltipOpen = () => {
        settooltipOpen(true);
    };
    const handleBookTooltipClose = () => {
        setBooktooltipOpen(false);
    };
    const handleBookTooltipOpen = () => {
        setBooktooltipOpen(true);
    };

    function handleFancyTooltipOpen(index) {
        // alert(index);
        setFancytooltipOpen(toolFancytipOpen === index ? null : index);
    };
    function pinmarket(event_id, market_id, type) {
        if (userInfo) {
            loader_default();
            var data = JSON.stringify({
                event_id: event_id,
                market_id: document.querySelector(`#${market_id}`) ? document.querySelector(`#${market_id}`).value : 0,
                user_id: userInfo._id,
                type: type
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}favorite/add&removeFavorite`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        refetch();
                        loader_remove();
                    } catch (e) {
                        postErrorToslack(e.message);
                        loader_remove();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    postErrorToslack(error.message);
                });
        }
        else {
            history.push("/login")
        }
    }
    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };
    const handlePriceValue = (priceVal) => {
        if (String(priceVal).startsWith("NaN")) {
            priceVal = String(priceVal).replace("NaN", "");
        }
        priceVal = parseFloat(priceVal).toFixed(2);
        setBetPlaceData({
            ...BetPlaceData,
            price: parseFloat(priceVal).toFixed(2)
        });
        calc(StakeValue, priceVal, BetPlaceData.selection_id)
        SetPosition(StakeValue, priceVal, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
    };

    function getFancysExposure() {
        var data = JSON.stringify({
            event_id: params.event_id,
            user_id: userInfo._id,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancysExposure`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    // console.log('response.data',response.data);
                    const boxesWin = document.getElementsByClassName("fancywin");
                    for (var box of boxesWin) {
                        box.style.setProperty("display", "none")
                    }
                    if (response.data.resultData.length > 0) {
                        response.data.resultData.map((row, index) => {
                            if (document.querySelector(`.win_${params.event_id}_${row.selection_id.replace('.', '___')}`)) {
                                document.querySelector(`.win_${params.event_id}_${row.selection_id.replace('.', '___')}`).style.display = "block";
                                document.querySelector(`.win_${params.event_id}_${row.selection_id.replace('.', '___')}`).setAttribute("data-value", row.min);
                                document.querySelector(`.win_${params.event_id}_${row.selection_id.replace('.', '___')}`).textContent = Math.abs(row.min);
                            }
                        })
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const rows = [
        createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '1 - 1 ', 'maxime', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('king', '3 - 1 ', 'cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring', '2 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        createData('Forge', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
    ];
    const positionClose = () => {
        setPositionOpen(false);
    }
    const [showPremium, setShowPremium] = React.useState(false);
    const [premiumMarketList, setPremiumMarketList] = React.useState([]);
    const [tvUrl, setTvUrl] = React.useState();
    const [scoreUrl, setScoreUrl] = React.useState("");
    const [iframeHeight, setScoreIframeHeight] = React.useState("");
    const [chipList, setchipList] = React.useState([]);
    const [showScoreIfram, setShowScoreIfram] = React.useState(false);
    const [fetchData, setRefetchData] = React.useState(false);
    const [fancyOddsData,setFancyOddsData] = React.useState([]);


    let flag = true;
    async function createPremiumMarket(data) {
        let filterdData = data.fancy_data.filter((row) => row.event_id == params.event_id);

        if (filterdData.length > 0) {
            if (filterdData[0].hasOwnProperty("fancys")) {
                filterdData = filterdData[0].fancys;
            }

            let fullArr = [];
            await Promise.all(filterdData.map(async (row, index) => {
                if (row.apiSiteStatus == 'ACTIVE') {
                    let exist = document.querySelector(`#premium_site_id_new_${row.id}`);
                    if (!exist) {
                        fullArr.push(row);
                    }
                    if (row.hasOwnProperty('sportsBookSelection')) {
                        row.sportsBookSelection.map((runner, i) => {
                            var match_odd_row = document.getElementsByClassName('table_row_' + runner.marketId.replace('.', '') + '_' + runner.id)[0];
                            if (!runner.isActive) {
                                if (document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id)) {
                                    if (match_odd_row) {
                                        match_odd_row.parentElement.setAttribute('data-title', "Ball Running");
                                        match_odd_row.parentElement.classList.add("suspended-pr");
                                    }
                                    if (parseFloat(document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML) != parseFloat(runner.odds)) {
                                        document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML = 0;
                                    } else {
                                        document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML = 0;
                                    }
                                }
                            } else {
                                if (document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id)) {
                                    if (match_odd_row) {
                                        match_odd_row.parentElement.setAttribute('data-title', "");
                                        match_odd_row.parentElement.classList.remove("suspended-pr");
                                    }
                                    if (runner.odds == 0) {
                                        if (match_odd_row) {
                                            match_odd_row.parentElement.setAttribute('data-title', "Suspended");
                                            match_odd_row.parentElement.classList.add("suspended-pr");
                                        }
                                    } else {
                                        if (match_odd_row) {
                                            match_odd_row.parentElement.setAttribute('data-title', "");
                                            match_odd_row.parentElement.classList.remove("suspended-pr");
                                        }
                                        if (parseFloat(document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML) != parseFloat(runner.odds)) {
                                            document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML = parseFloat(runner.odds);
                                        } else {
                                            document.getElementById('availableToBack1_price_' + runner.marketId.replace('.', '') + '_' + runner.id).innerHTML = parseFloat(runner.odds);
                                        }
                                    }
                                }
                            }
                        })
                    }
                }

                if (row.apiSiteStatus === 'DEACTIVED') {
                    // console.log("tempArr",tempArr);
                    if (document.querySelector(`#premium_site_id_new_${row.id}`)) {
                        let newRow = await Promise.all(
                            premiumFArr.map((nrow, index) => {
                                if (nrow.id !== row.id) {
                                    return nrow;
                                }
                            }));
                        if (premiumFArr !== newRow) {
                            if (newRow > 0) {
                                setTimeout(function () { setPremiumFArr(newRow); }, 15000)
                            }
                        }
                    }

                    // document.querySelector(`#premium_site_id_new_${row.id}`).remove();
                    // console.log("deleted");

                }
            }))
            let newfullArr = [];
            if (flag) {
                setPremiumFArr(fullArr);
                flag = false;
            } else {
                let tempArr = premiumFArr.map((row) => row.id);
                await Promise.all(fullArr.map((frow, index) => {
                    if (!tempArr.includes(frow.id)) {
                        newfullArr.push(frow);
                    }
                }));
                if (newfullArr.length) {
                    setTimeout(function () { setPremiumFArr(premiumFArr.concat(newfullArr)) }, 20000);
                }
            }
        }
    }

    React.useEffect(() => {
        // document.querySelector(".score-board").onload = () => {
        //     setTimeout(function () {
        //         setShowScoreIfram(true);
        //     }, 1000)
        // };
    })



    window.onbeforeunload = function () {
        window.sessionStorage.setItem("refresh", 'true');
    }

    React.useEffect(() => {

        if (userInfo) {
            if (params.inplay !== "Inplay") {
                document.querySelector("#ctitle").style.marginTop = "7px"
            }
            getChips();
            getFancysExposure();
        }
        let userName = userInfo ? userInfo.user_name : "demo";
        let room = eventId;
        //send event that user has joined room
        // socket.emit("join_room", {
        //     username: userName,
        //     roomName: room
        // });
        // socket.on('fancy_update', function (data) {
        //     // console.log('FANCY UPDATE:', data);
        //     // console.log(data);
        //     fancyHtml(data);
        // });
        // socket.on('premium_fancy_update', function (data) {
        //     createPremiumMarket(data);
        // });

        // let disable_market = false;
        // socket.on("market_update", (data) => {
        //     // return false;

        //     //events   
        //     // console.log('MARKET UPDATE:', data);
        //     var matchId = eventId;
        //     // if (MarketId) {
        //     // var market = data.marketodds[matchId]
        //     if (data.marketodds.length > 0) {

        //         var market = data.marketodds.find(o => o.event_id == matchId);
        //         // console.log('market',market);
        //         if (market) {

        //             if (market.market_types.length > 0) {
        //                 // console.log('runner',market.market_types);
        //                 market.market_types.map((market_type, index) => {
        //                     market_type.hasOwnProperty("runners") && market_type.runners.map((runner, index) => {


        //                         if (market_type.status == 'OPEN') {
        //                             // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeOut();
        //                         } else {
        //                             // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeIn();
        //                             // document.getElementsByClassName('status_matchBoxs_' + market_type.market_id.replace('.', '')).text(market_type.status);
        //                         }
        //                         // console.log('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id[0]);


        //                         if(runner.market_id == '4.1680554980-BM')
        //                         {
        //                             // console.log('runner',JSON.stringify(runner));
        //                         }
        //                         if (checkHighestMarketPrice(runner)) {

        //                             const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.replace('.', '') + '_' + runner.selection_id);
        //                             // console.log('.market_runners_' + runner.market_id.replace('.', '')+'_'+ runner.selection_id);
        //                             for (const box of boxes) {
        //                                 box.classList.add('disabled');
        //                             }
        //                         }
        //                         else {
        //                             const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.replace('.', '') + '_' + runner.selection_id);

        //                             for (const box of boxes) {
        //                                 box.classList.remove('disabled');
        //                             }
        //                         }



        //                         var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)[0];

        //                         if (runner.status == 'SUSPENDED' || runner.status == 'CLOSE') {


        //                             if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                                 if (match_odd_row) {
        //                                     match_odd_row.parentElement.setAttribute('data-title', runner.status);
        //                                     match_odd_row.parentElement.classList.add("suspended");
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 } else {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 } else {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
        //                                 }
        //                             }
        //                         } else {
        //                             if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                                 if (match_odd_row) {
        //                                     match_odd_row.parentElement.setAttribute('data-title', "");
        //                                     match_odd_row.parentElement.classList.remove("suspended");
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
        //                                 } else {
        //                                     document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
        //                                 }
        //                                 if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
        //                                 } else {
        //                                     document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
        //                                 }
        //                             }

        //                         }
        //                         /************************Size */


        //                         if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
        //                             document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
        //                             document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
        //                             if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
        //                                 document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
        //                                 setTimeout(function () {
        //                                     if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
        //                                         document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
        //                                 },
        //                                     700);
        //                                 document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                             } else {
        //                                 document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
        //                                 //  document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                             }
        //                             if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
        //                                 document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
        //                                 document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
        //                                 setTimeout(function () {
        //                                     if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
        //                                         document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
        //                                 }
        //                                     , 700);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                             } else {
        //                                 document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
        //                                 // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                             }
        //                         }
        //                         // if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
        //                         //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                         // if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
        //                         //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
        //                         // } else {
        //                         //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
        //                         // }
        //                     });
        //                 });
        //             }
        //         }
        //     }
        // });
        if (queryClient.getQueryData(`eventDetails_${params.event_id}`)) {
            setEventData(queryClient.getQueryData(`eventDetails_${params.event_id}`).data.resultData);
        }

        getTvUrl(eventId)
        getScoreUrl(eventId)
        // getEventData()
    }, [eventId])

    React.useEffect(() => {
        console.log('websocket', websocket);
        if (websocket) {
            websocket.onopen = () => {
                console.log('Websocket is open ', new Date());
                setIsReady(true)
                // websocket.send(JSON.stringify(
                // {
                //     "action":"JOIN",
                //     "data":{
                //         "username":"suthard444",
                //         "event_id":eventId
                //     }
                // }
                // ));
            };




            if (websocket.readyState) {

                console.log('WESOCKET JOIN REQUEST SENT', {
                    "action": "JOIN",
                    "data": {
                        "username": "suthard444",
                        "event_id": eventId
                    }
                });

                websocket.send(JSON.stringify(
                    {
                        "action": "JOIN",
                        "data": {
                            "username": "suthard444",
                            "event_id": eventId
                        }
                    }))
            }


        }
    }, [websocket, eventId, isReady]);

    React.useEffect(() => {
        if (websocket) {
            websocket.onmessage = (evt) => {
                // console.log('event message recieve', evt)
                const data = JSON.parse(evt.data);
                if (data.action == "MARKET_UPDATE") {
                    console.log("MARKET SOCKET RESPONDING");

                    let market = data.data;
                    if (market) {

                        if (market.market_types.length > 0) {
                            market.market_types.map((market_type, index) => {
                                market_type.hasOwnProperty("runners") && market_type.runners.map((runner, index) => {

                                    if (market_type.status == 'OPEN') {
                                        // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeOut();
                                    } else {
                                        // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeIn();
                                        // document.getElementsByClassName('status_matchBoxs_' + market_type.market_id.replace('.', '')).text(market_type.status);
                                    }
                                    // console.log('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id[0]);


                                    if (runner.market_id == '4.1680554980-BM') {
                                        // console.log('runner',JSON.stringify(runner));
                                    }
                                    if (checkHighestMarketPrice(runner)) {

                                        const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.replace('.', '') + '_' + runner.selection_id);
                                        // console.log('.market_runners_' + runner.market_id.replace('.', '')+'_'+ runner.selection_id);
                                        for (const box of boxes) {
                                            box.classList.add('disabled');
                                        }
                                    }
                                    else {
                                        const boxes = document.querySelectorAll('.market_runners_' + runner.market_id.toString().replace('.', '') + '_' + runner.selection_id);
                                        for (const box of boxes) {
                                            box.classList.remove('disabled');
                                        }
                                    }



                             

                                    var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.toString().replace('.', '') + '_' + runner.selection_id)[0];


                                    if (runner.status == 'SUSPENDED' || runner.status == 'CLOSE') {


                                        if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                            if (match_odd_row) {

                                                match_odd_row.setAttribute('data-title', runner.status);
                                                match_odd_row.parentElement.classList.add("suspended");
                                            }
                                            if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                                                // document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                            } else {
                                                // document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                            }
                                            if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                                                // document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                            } else {
                                                // document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                                            }
                                        }
                                    } else {
                                        if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                            if (match_odd_row) {
                                                match_odd_row.parentElement.setAttribute('data-title', "");
                                                match_odd_row.parentElement.classList.remove("suspended");
                                            }
                                            if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                                                document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                                            } else {
                                                document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                                            }
                                            if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                                                document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                                            } else {
                                                document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                                            }
                                        }

                                    }
                                    /************************Size */


                                    if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                                        document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                                        document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                                        if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                                            document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                                            setTimeout(function () {
                                                if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                                                    document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                                            },
                                                700);
                                            document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                                            // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
                                        } else {
                                            document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                                            //  document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        }
                                        if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                                            document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add("spark");
                                            document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                                            setTimeout(function () {
                                                if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id))
                                                    document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove("spark");
                                            }
                                                , 700);
                                            // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
                                        } else {
                                            document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                                            // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                        }
                                    }
                                    // if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
                                    //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
                                    // } else {
                                    //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                    // }
                                    // if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
                                    //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');
                                    // } else {
                                    //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                                    // }
                                });
                            });
                        }
                    }
                }

                if (data.action == "FANCY_UPDATE") {
                    let market = data.data;
                    // console.log("FANCY SOCKET RESPONDING",market?.fancy_data);
                    if (market) {
                             fancyHtml(market?.fancy_data)

                        // fancyHtml(market)
                    }
                }

            };
        }
    }, [websocket, eventId]);

    function hideDiv(id, type) {
        document.querySelector(`#${id}`).style.display = type;
        return true;
    }
    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        //  var MatchMarketTypes = 'M';
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        // console.log("tempRunnersv", runners);
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;
            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);
                    } else {
                        curr = winloss + ((priceVal * stake) - stake);
                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                    } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;
            // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss.toFixed(2))
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss.toFixed(2));
            }
            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).setAttribute('data-value', currV.toFixed(2))
                document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML = Math.abs(currV.toFixed(2));

            }
        }
    }
    // async function OldfancyHtml(data) {




    //     // console.log("kaka", data);
    //     var superiors = ["270", "259", "185", "177", "30"];
    //     var matchId = eventId;
    //     if (document.getElementById('fancyAPI')) {
    //         // document.getElementById('fancyAPI').innerHTML = `<tr class="MuiTableRow-root yelloBG"><td class="MuiTableCell-root MuiTableCell-body table_first_row colorDanger"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body bg_odd MuiTableCell-alignRight">Back</td><td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight">Lay</td></tr>`;
    //     }

    //     if (matchId) {


    //         if (matchId == data.event_id) {
    //             let fancys = data


    //             if (fancys) {

    //                 let check_new_fields_added = false;
    //                 fancys = fancys.fancy_data;

    //                 if (EventData && EventData.length > 0) {
    //                     // console.log('setTmpFancy',EventData[0].fancy);
    //                     if (EventData[0].fancy != fancys) {

    //                         await Promise.all(fancys.map(async (row, index) => {
    //                             let findFancy = EventData[0].fancy.find((item) => item.selection_id == row.selection_id)


    //                             if (!findFancy) {
    //                                 check_new_fields_added = true;
    //                             }

    //                         }))

    //                     }
    //                 }



    //                 let check_old_fancy_exists = false;

    //                 if (EventData && EventData.length > 0) {
    //                     // console.log('setTmpFancy',EventData[0].fancy);
    //                     if (EventData[0].fancy != fancys) {

    //                         await Promise.all(EventData[0].fancy.map(async (row, index) => {
    //                             let findFancy = fancys.find((item) => item.selection_id == row.selection_id)


    //                             if (!findFancy) {
    //                                 check_old_fancy_exists = true;
    //                             }

    //                         }))

    //                     }
    //                 }



    //                 if (EventData && EventData.length > 0) {
    //                     if (check_new_fields_added) {

    //                         // EventData[0].fancy = fancys
    //                         // setEventData(EventData)
    //                         // refetch();

    //                         console.log('NEW FANCY ADDED IN MATCH', new Date());
    //                     }


    //                     if (check_old_fancy_exists) {

    //                         // EventData[0].fancy = fancys
    //                         // setEventData(EventData)
    //                         // refetch();

    //                         console.log('OLD FANCY REMOVE FROM MATCH ', new Date());


    //                     }

    //                     // setTmpFancy(fancys)

    //                 }




    //                 if (fancys.length) {
    //                     for (var j = 0; j < fancys.length; j++) {
    //                         if (fancys[j].runner_name.includes('ball')) {
    //                             continue;
    //                         }
    //                         if (fancys[j].cron_disable == 'Yes') {
    //                         } else {
    //                             if (fancys[j].is_active == "No") {
    //                                 document.getElementsByClassName('fancy_' + fancys[j].selection_id).remove();
    //                             }
    //                             if (fancys[j]) {
    //                                 var block_market_fancys = fancys[j].block_market;
    //                                 var block_all_market_fancys = fancys[j].block_all_market;
    //                                 var find_fancy_all_block = block_all_market_fancys.filter(element => {
    //                                     return superiors.includes(element.user_id.toString())
    //                                 });
    //                                 if (find_fancy_all_block.length > 0) {
    //                                 } else {
    //                                     var find_fancy_block = block_market_fancys.filter(element => {
    //                                         return superiors.includes(element.user_id.toString())
    //                                     });
    //                                     if (find_fancy_block.length > 0) {
    //                                     } else {
    //                                         var fancyHtml = '';

    //                                         var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
    //                                         var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];

    //                                         console.log(fancys[j].game_status + '   fancy   ' + fancys[j].runner_name)
    //                                         if (document.getElementById('fancy_lay_price_' + fancys[j].selection_id)) {

    //                                             if (fancys[j].game_status == 'Ball Running') {
    //                                                 newdiv.setAttribute('data-title', "BALL RUNNING");
    //                                                 newtr.classList.add("suspended");
    //                                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
    //                                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
    //                                             } else if (fancys[j].game_status == 'SUSPENDED') {
    //                                                 newdiv.setAttribute('data-title', "SUSPENDED");
    //                                                 newtr.classList.add("suspended");
    //                                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
    //                                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
    //                                             } else if (fancys[j].back_price1 == 0) {
    //                                                 newdiv.setAttribute('data-title', "SUSPENDED");
    //                                                 newtr.classList.add("suspended");
    //                                                 // newtr.classList.remove("suspended");
    //                                                 // document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = '-';
    //                                                 // document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
    //                                             } else {
    //                                                 newdiv.setAttribute('data-title', "");
    //                                                 newtr.classList.remove("suspended");
    //                                                 if (document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].lay_size1)) {
    //                                                     document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                                 }
    //                                                 else {
    //                                                     document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                                 }
    //                                                 if (document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].back_size1)) {
    //                                                     document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                                 }
    //                                                 else {
    //                                                     document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                                 }
    //                                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
    //                                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
    //                                                 document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
    //                                                 document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             } else {
    //                                 var fancyHtml = '';
    //                                 if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {


    //                                 }
    //                                 var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
    //                                 var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];
    //                                 if (fancys[j].game_status == 'Ball Running') {
    //                                     newdiv.setAttribute('data-title', "BALL RUNNING");
    //                                     newtr.classList.add("suspended");
    //                                     document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
    //                                     document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
    //                                 } else {
    //                                     newtr.classList.remove("suspended");
    //                                     if (document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].lay_size1)) {
    //                                         document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                     }
    //                                     else {
    //                                         document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.remove("spark");
    //                                     }
    //                                     if (document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].back_size1)) {
    //                                         document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
    //                                     }
    //                                     else {
    //                                         document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.remove("spark");
    //                                     }
    //                                     document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
    //                                     document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
    //                                     document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
    //                                     document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }

    //         }



    //     }
    // }



    async function fancyHtml(fancys) {
        var superiors = ["270", "259", "185", "177", "30"];
        var matchId = eventId;

        if (matchId) {

            if (fancys) {

                let check_new_fields_added = false;
 
 

                setFancyOddsData(fancys)

                // if (EventData && EventData.length > 0) {
                //     // console.log('setTmpFancy',EventData[0].fancy);
                //     if (EventData[0].fancy != fancys) {

                //         await Promise.all(fancys.map(async (row, index) => {
                //             let findFancy = EventData[0].fancy.find((item) => item.selection_id == row.selection_id)


                //             if (!findFancy) {
                //                 check_new_fields_added = true;
                //             }

                //         }))

                //     }
                // }



                // let check_old_fancy_exists = false;

                // if (EventData && EventData.length > 0) {
                //     // console.log('setTmpFancy',EventData[0].fancy);
                //     if (EventData[0].fancy != fancys) {

                //         await Promise.all(EventData[0].fancy.map(async (row, index) => {
                //             let findFancy = fancys.find((item) => item.selection_id == row.selection_id)


                //             if (!findFancy) {
                //                 check_old_fancy_exists = true;
                //             }

                //         }))

                //     }
                // }



                // if (EventData && EventData.length > 0) {
                //     if (check_new_fields_added) {

                //         // EventData[0].fancy = fancys
                //         // setEventData(EventData)
                //         // refetch();

                //         console.log('NEW FANCY ADDED IN MATCH', new Date());
                //     }


                //     if (check_old_fancy_exists) {

                //         // EventData[0].fancy = fancys
                //         // setEventData(EventData)
                //         // refetch();

                //         console.log('OLD FANCY REMOVE FROM MATCH ', new Date());


                //     }

                //     // setTmpFancy(fancys)

                // }




                // if (fancys.length) {
                //     for (var j = 0; j < fancys.length; j++) {
                //         if (fancys[j].runner_name.includes('ball')) {
                //             continue;
                //         }
                //         if (fancys[j].cron_disable == 'Yes') {
                //         } else {
                //             if (fancys[j].is_active == "No") {
                //                 document.getElementsByClassName('fancy_' + fancys[j].selection_id).remove();
                //             }
                //             if (fancys[j]) {
                //                 var block_market_fancys = fancys[j].block_market;
                //                 var block_all_market_fancys = fancys[j].block_all_market;
                //                 var find_fancy_all_block = block_all_market_fancys.filter(element => {
                //                     return superiors.includes(element.user_id.toString())
                //                 });
                //                 if (find_fancy_all_block.length > 0) {
                //                 } else {
                //                     var find_fancy_block = block_market_fancys.filter(element => {
                //                         return superiors.includes(element.user_id.toString())
                //                     });
                //                     if (find_fancy_block.length > 0) {
                //                     } else {
                //                         var fancyHtml = '';

                //                         var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                //                         var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];

                //                         console.log(fancys[j].game_status + '   fancy   ' + fancys[j].runner_name)
                //                         if (document.getElementById('fancy_lay_price_' + fancys[j].selection_id)) {

                //                             if (fancys[j].game_status == 'Ball Running') {
                //                                 newdiv.setAttribute('data-title', "BALL RUNNING");
                //                                 newtr.classList.add("suspended");
                //                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                //                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                //                             } else if (fancys[j].game_status == 'SUSPENDED') {
                //                                 newdiv.setAttribute('data-title', "SUSPENDED");
                //                                 newtr.classList.add("suspended");
                //                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                //                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                //                             } else if (fancys[j].back_price1 == 0) {
                //                                 newdiv.setAttribute('data-title', "SUSPENDED");
                //                                 newtr.classList.add("suspended");
                //                                 // newtr.classList.remove("suspended");
                //                                 // document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = '-';
                //                                 // document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                //                             } else {
                //                                 newdiv.setAttribute('data-title', "");
                //                                 newtr.classList.remove("suspended");
                //                                 if (document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].lay_size1)) {
                //                                     document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                                 }
                //                                 else {
                //                                     document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                                 }
                //                                 if (document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].back_size1)) {
                //                                     document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                                 }
                //                                 else {
                //                                     document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                                 }
                //                                 document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                //                                 document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                //                                 document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                //                                 document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                //                             }
                //                         }
                //                     }
                //                 }
                //             } else {
                //                 var fancyHtml = '';
                //                 if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {


                //                 }
                //                 var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                //                 var newdiv = document.getElementsByClassName('odd_even_clr_' + fancys[j].selection_id)[0];
                //                 if (fancys[j].game_status == 'Ball Running') {
                //                     newdiv.setAttribute('data-title', "BALL RUNNING");
                //                     newtr.classList.add("suspended");
                //                     document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                //                     document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                //                 } else {
                //                     newtr.classList.remove("suspended");
                //                     if (document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].lay_size1)) {
                //                         document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                     }
                //                     else {
                //                         document.getElementById('fancy_lay_size_' + fancys[j].selection_id).parentElement.classList.remove("spark");
                //                     }
                //                     if (document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML != parseFloat(fancys[j].back_size1)) {
                //                         document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.add("spark");
                //                     }
                //                     else {
                //                         document.getElementById('fancy_back_size_' + fancys[j].selection_id).parentElement.classList.remove("spark");
                //                     }
                //                     document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                //                     document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                //                     document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                //                     document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                //                 }
                //             }
                //         }
                //     }
                // }
            }





        }
    }
    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleBetClick = () => {
        setBetShow(!betShow);
    }
    const [value, setValue] = React.useState(0);
    const fancyChange = (event, newValue) => {
        setValue(newValue);
    };
    function getEventData() {
        loader_default();
        const event_id = params.event_id;
        var data;
        let url;
        if (userInfo) {
            url = "getDashboardDataByeventId"
            data = JSON.stringify({
                user_id: userInfo._id,
                event_id: event_id,
            });
        } else {
            url = "getDashboardDataByEventIdWithoutUserID"
            data = JSON.stringify({
                event_id: event_id,
            });
        }
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setEventData(response.data.resultData);
                    loader_remove();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    // console.log("setEventData", setEventData);
    // Stake Dynamic Start
    function getChips() {
        var data = JSON.stringify({
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    loader_remove();
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    // Stake Dynamic end
    // function betPlace() {
    // Book Button In Fancy Market 
    function getFancyPosition(selectionId) {
        var data = JSON.stringify({
            user_id: userInfo._id,
            event_id: eventId,
            fancy_id: selectionId
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getFancyPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setPostionFancyList(response.data.resultData);
                    setPositionOpen(true);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
        // console.log(postionFancyList)
    }
    // Stake Dynamic end
    function betPlace(ishow) {
        if (userInfo) {
            if (userInfo.user_type == "User") {
                setPlacing(true);
                var data = JSON.stringify({
                    user_id: userInfo._id,
                    match_id: BetPlaceData.event_id,
                    selection_id: BetPlaceData.selection_id,
                    is_back: BetPlaceData.is_back,
                    stake: StakeValue,
                    price_val: BetPlaceData.price,
                    market_id: BetPlaceData.market_id,
                    is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
                    market_name: "Match odds",
                    profit: ProfitValue,
                    loss: lossValue,
                    pm_fancy: BetPlaceData.PM_FANCY
                });
                // console.log("betting for fancy", data);
                var config = {
                    method: "post",
                    url: `${Appconfig.apiUrl}betting/addBetting`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };
                // alert("success");
                axios(config)
                    .then(function (response) {
                        try {
                            setProfitValue(0);
                            setStakeValue(0);
                            hideAllBetBox();


                        


                            if (response.data.result == 0) {
                                document.getElementById("msgBox").classList.remove("success");
                                document.getElementById("msgBox").classList.add("error");
                                document.getElementById("sideType").innerHTML = "Error";
                                document.getElementById("selectionName").innerHTML = response.data.resultMessage;
                                document.getElementById("stake_notify").innerHTML = "";
                                document.getElementById("price_notify_value").innerHTML = "";
                                var element = document.getElementById("sideType");
                                element.classList.add("red-type");
                            } else {
                                document.getElementById("msgBox").classList.add("success");
                                document.getElementById("msgBox").classList.remove("error");
                                setBookButton(ishow);
                                if (response.data.resultData[0].is_back == 1) {
                                    document.getElementById("sideType").innerHTML = "Yes";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("blue-type");
                                }
                                if (response.data.resultData[0].is_back == 0) {
                                    document.getElementById("sideType").innerHTML = "No";
                                    document.getElementById("selectionName").innerHTML = response.data.resultData[0].place_name;
                                    document.getElementById("stake_notify").innerHTML = response.data.resultData[0].stake;
                                    document.getElementById("price_notify_value").innerHTML = response.data.resultData[0].price_val;
                                    var element = document.getElementById("sideType");
                                    element.classList.add("pink-type");
                                }

                                refetch();
                                setIsBetPlaced(!isBetPlaced);

                            }

                            setPlacing(false);
                            if (document.getElementById("msgBox")) {
                                document.getElementById("msgBox").style.display = "flex";
                            }
                            setTimeout(() => {
                                if (document.getElementById("msgBox")) {
                                    document.getElementById("msgBox").style.display = "none";
                                }
                            }, 3000);
                            setBetPlaceData({
                                event_id: "",
                                market_id: "",
                                is_back: "",
                                price: "",
                                is_fancy: "",
                                selection_id: "",
                                runner_name: "",
                            });
                        } catch (e) {
                            postErrorToslack(e.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        postErrorToslack(error.message);
                    });
            }
        }
        else {
            history.push("/login")
        }
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success",
            });
        }
    }
    function getTvUrl(eventId) {
        return false;
        // setTvUrl('https://dotlivetv.qnsports.live/livetv.php?eventId='+eventId);
        var data = JSON.stringify({
            event_id: eventId,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getTv`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setTvUrl(response.data.resultData.livetv);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    function getScoreUrl(eventId) {

        // if(isLoggedIn)
        // {


        var data = JSON.stringify({
            event_id: eventId,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getScore`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    // alert("Hello");
                    setScoreUrl(response.data.resultData.animation);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
        // }
    }
    const hideAllBetBox = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
        const boxes = document.getElementsByClassName("pbb");
        const boxesWin = document.getElementsByClassName("to-win");

        for (var box of boxes) {
            box.style.setProperty("display", "none", "important")
        }
    }
    function calc(t_stake, priceVal, selection_id) {
        var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        if (!isfancy) {

            var pl = ((priceVal * t_stake) - t_stake);

            pl = parseFloat(pl.toFixed(2));
            if (isback) {
                setProfitValue(pl)
                setLossValue(t_stake)
            } else {
                setLossValue(pl)
                setProfitValue(t_stake)
            }
            // SetPosition(priceVal);
        } else {
            if (document.getElementById('fancy_lay_size_' + selection_id)) {
                var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
                var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
                var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
                var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);
            }


            pl = parseFloat(t_stake);

            if (inputno == inputyes) {
                if (isback) {
                    setLossValue(pl.toFixed(2))
                    setProfitValue((YesValume * pl / 100).toFixed(2))

                } else {
                    setLossValue((NoValume * pl / 100).toFixed(2))
                    setProfitValue(pl.toFixed(2))

                }
            } else {
                setLossValue(pl.toFixed(2))
                setProfitValue(pl.toFixed(2))
            }

        }
    }
    function scrollTo(id) {
        const element = document.getElementById(id);
        // element.scrollIntoView({ behavior: 'smooth', inline: 'end' })
    }
    const handleOpen = (
        event_id,
        market_id,
        is_back,
        price,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null
    ) => {
        if (!userInfo) {
            return false;
        }
        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);
        if (selectElements.length > 0) {
            let check_class_exists = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id)[0].classList.contains('disabled');
            if (check_class_exists) {
                return false;
            }

        }

   


        hideAllBetBox();
        if (!is_fancy) {
            // setLastShowedBox(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`);
            document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        } else {
            // setLastShowedBox(`place-fancybet-block_${event_id}_${selection_id}`);
            document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "block", "important");
        }
        setProfitValue(0);
        setStakeValue(0)
        var priceNew = document.getElementById(htmlId).innerHTML;
        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "-BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }
        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        var priceNew = document.getElementById(htmlId).innerHTML;
        var lastFive = market_id.substr(market_id.length - 3);
        if (lastFive == "-BM") {
            priceNew = ((priceNew / 100) + 1).toFixed(2);
        }
        var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
        var tempRunners = "";
        setBetPlaceData({
            event_id: event_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            PM_FANCY: PM_FANCY
        });

        setTimeout(function () {
            SetPosition(0, priceNew, market_id, is_back, selection_id)
            calc(0, priceNew, selection_id)
            placeStakeValue(defaultStake);
        }, 800);
        // scrollTo(htmlId);
    };

    function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
        // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId.replace('.', '');
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("position_" + MId);
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = parseFloat(item.value);
            var curr = 0;
            if (selectionId == selecid) {
                if (isback) {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + ((priceVal * stake) / 100);
                    } else {
                        curr = winloss + ((priceVal * stake) - stake);
                    }
                } else {
                    if (MatchMarketTypes == 'M') {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                    } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                    }
                }
            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;
            if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss.toFixed(2))
                document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss.toFixed(2));
            }
            if (document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId)) {
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).style.display = "inherit";
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).setAttribute('data-value', currV.toFixed(2))
                document.getElementById(selecid + "_maxprofit_list_loss_runner_next_" + MId).innerHTML = Math.abs(currV.toFixed(2));
            }
        }
    }
    const [bookmakerPin, SetBookmakerPin] = useState(false);
    const [matchOddsPin, SetMatchOddsPin] = useState(false);
    const [fancyPin, SetFancyPin] = useState(false);

    function updatePin(type, value) {
        if (type == "bookmaker") {
            if (document.querySelector("#bookmaker_pin")) {
                if (value == "Yes") {
                    document.querySelector("#bookmaker_pin").classList.remove("pin-off");
                    document.querySelector("#bookmaker_pin").classList.add("pin-on")
                }
                else {
                    document.querySelector("#bookmaker_pin").classList.remove("pin-on");
                    document.querySelector("#bookmaker_pin").classList.add("pin-off")
                }
            }
        }
        if (type == "match_odds") {
            if (document.querySelector("#match_odds_pin")) {
                if (value == "Yes") {
                    document.querySelector("#match_odds_pin").classList.add("select")
                }
                else {
                    document.querySelector("#match_odds_pin").classList.remove("select")
                }
            }
        }
        if (type == "fancy") {
        }
    }
    const handleRefetch = () => {
        setRefetchData(true);
        refetch();
    }
    return (
        <>
            <Header tvLink={tvUrl} isBetPlaced={isBetPlaced} is_virtual={EventData.length > 0 && EventData[0].hasOwnProperty("is_virtual_game") ? EventData[0].is_virtual_game : "No"} channel_id={EventData.length > 0 ? EventData[0].channel_id : "No"} eventTypeChk={EventData.length > 0 ? EventData[0].event_type : 0} />
            <div className={classes.root}>
                <div container style={{ backgroundColor: '#F0ECE1' }}>
                   
                   
                    <Grid item lg={7} xs={12} className={`cricket-section `}>
                        <Grid container id="ctitle" spacing={2} style={{ padding: '8px', marginTop: userInfo ? "13.5rem" : userInfo ? 5 : 56 }}>
                            <Grid item lg={12} xs={12} className="md-none xp-0">
                                 
                            </Grid>
                            
                            
                            {/* Child Grid item End */}
                        </Grid>
                      
                    </Grid>
                    {/* End Main Item Grid */}
                    
                </div>

                <iframe  src={casinoLink} style={{width:"100%",minHeight:600}} />

            </div>
          
            <div style={{ marginBottom: 150 }}></div>
        </>
    )
}
export default React.memo(CasinoMarket);
