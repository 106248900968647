import React from 'react';
import { useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItem, Tabs, Button } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Toolbar,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    ButtonGroup,
    Menu,
    Backdrop,
    Fade,
    TextField,
} from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';

import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar, Modal, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ArrowForwardIos, MoreVert } from '@material-ui/icons';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { BrowserView, MobileView, isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import Loader from '../assets/images/loading40.gif';
import Appconfig from "../config/config";
import axios from "axios";
import { postErrorToslack } from '../config/common';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

function createData(name, value) {
    return { name, value };
}
function activity(time, status, ip, isp, city, type) {
    return { time, status, ip, isp, city, type };
}
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}

function loader_remove() {
    var pokerid = document.getElementById("poker_loading");
    if (pokerid) {
        pokerid.style.display = "none";
    }
}

export default function VerticalTabs() {

    var rows = [];
    var address = [];
    var contact_details = [];
    var currency_sec = [];
    var commission_char = [];
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    if (userInfo) {
        rows = [
            createData('First Name', userInfo.name ? userInfo.name : ''),
            createData('Last Name', '--'),
            createData('Birthday', userInfo.birthday ? userInfo.birthday : ''),
            createData('Email', userInfo.email ? userInfo.email : ''),
            createData('Password', userInfo.password ? userInfo.password : ''),
        ];
    }
    if (userInfo) {

        address = [
            createData('Address', userInfo.address ? userInfo.address : '--'),
            createData('Town/City', userInfo.city ? userInfo.city : '--'),
            createData('Country', '--'),
            createData('Country/State', userInfo.state ? userInfo.state : '--'),
            createData('Postcode', userInfo.pin_code ? userInfo.pin_code : '--'),
            createData('Timezone', userInfo.timezone ? userInfo.timezone : '--'),
        ];

        contact_details = [
            createData('Primary Number', userInfo.phone ? userInfo.phone : '--'),
        ];
        currency_sec = [
            createData('Currency', 'PBU'),
            createData('Odds Format', userInfo.odds_format ? userInfo.odds_format : '--'),
        ];
        commission_char = [
            createData('Comm charged', userInfo.commission ? userInfo.commission : '--'),
        ];
    }

    const activity_log = [
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
        activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    ];


    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/balance') {
        segment = 1;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(0);
    const [valuen, setnValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [opens, setOpens] = React.useState(false);
    const [validationError, SetValidationError] = useState("Password Changed Successfully");
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const [passwordData, setPasswordData] = React.useState({

    });
    const handlePasswordChange = (event) => {
        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value

        })
        console.log(passwordData);
    }
    function checkBothPasss() {
        var newconfirmpass = passwordData.new_confirm_password;
        var newpassfirst = passwordData.new_password;
        if (newpassfirst != newconfirmpass) {
            document.getElementById("validateConfirmPass").innerHTML = "Password Did Not Match";
            return false
        }
        if (newpassfirst == newconfirmpass) {
            document.getElementById("validateConfirmPass").innerHTML = "";
            return true
        }
    }

    function notify(message, type) {
        if (type === 'error') {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const handlePasswordSubmit = (e) => {

        e.preventDefault();
        var data = JSON.stringify({
            "old_password": passwordData.old_password,
            "new_password": passwordData.new_password,
            "user_id": userInfo._id
        });
        if (checkBothPasss()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/changePassword`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    try {
                        if (response.data.result == 1) {
                            document.querySelector(".message-wrap-new").style.backgroundColor = "green";
                            document.querySelector("#message").style.display = "block";
                            document.querySelector("#message p").textContent = response.data.message;
                        }
                        else {
                            let temp = "";
                            document.querySelector("#message p").textContent = response.data.message;
                            document.querySelector(".message-wrap-new").style.backgroundColor = "red";
                            document.querySelector("#message").style.display = "block";
                        }
                        handleClose();
                        setPasswordData({
                            old_password: '',
                            new_password: '',
                        })
                        setTimeout(function () {
                            closeMessage();
                        }, 3000);
                    } catch (e) {
                        postErrorToslack(e.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    postErrorToslack(error.message);
                });
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handlexChange = (event, newValue) => {
        setxValue(newValue);
    };
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
    };
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const handleChangeval = (event) => {
        setAge(event.target.value);
    };
    const handleClose = () => {
        setOpens(false);
    };

    const handleOpen = () => {
        setOpens(true);
    };

    function closeMessage() {
        document.getElementById("message").style.display = "none";
    }
    const gridType = isMobile ? 10 : 8;
    React.useEffect(() => {
        setTimeout(() => {
            loader_remove();
        }, 2000);
    }, []);
    const modalOpen = () => {
        setOpens(true);
    };

    // var passwordast = document.getElementById("id_pass").innerHTML;
    // passwordast = passwordast.replace(/[^a-zA-Z0-9]/g,'*');
    //   alert(passwordast);
    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            {isLoggedIn ? null :
                <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
            }
            <Grid container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />

                <Grid item lg={gridType} xs={12} >
                    <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                        <div id="message" className="message-wrap-new">
                            <Link to="#" onClick={closeMessage} className="btn-close close-btn-right">Close</Link>
                            <p>{validationError}</p>
                        </div>
                    </div>
                    {/* Account Details */}
                    <TabPanel value={value} index={0}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Account Details</Typography>
                        </Box>
                        <Grid container spacing={2} style={{ padding: 0 }}>
                            <Grid item lg={6} xs={12}>
                                <Table className={`account-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell colSpan={2}>About You</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                        {rows.map((row) => (
                                            <><div>
                                                {console.log('AllData', row)}
                                            </div><TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell id={`id_${row.name == `Password` ? `pass` : null}`} align="left">{row.name == `Password` ? `****` : row.value}{row.name == `Password` ?
                                                        <div className="edit-password">
                                                            <Link to="#" onClick={handleOpen}>Edit</Link>
                                                        </div>
                                                        : ""}</TableCell>
                                                    {/* <div>{row.value.password}</div> */}
                                                </TableRow></>
                                        ))}
                                        {/* <TableCell component="th" scope="row">
                                            edit
                                        </TableCell>
                                        <TableCell align="left">ok</TableCell> */}
                                    </TableBody>
                                </Table>
                                <Table className={`account-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell colSpan={5}>Address</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                        {address.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Table className={`account-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell colSpan={2}>Contact Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                        {contact_details.map((rowscontact) => (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {rowscontact.name}
                                                </TableCell>
                                                <TableCell align="left">{rowscontact.value}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Table className={`account-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell colSpan={2}>Setting</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                        {currency_sec.map((rowscontact) => (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {rowscontact.name}
                                                </TableCell>
                                                <TableCell align="left">{rowscontact.value}</TableCell>
                                            </TableRow>

                                        ))}
                                        {/* <TableRow>
                                            <TableCell component="th" scope="row">
                                                Odds Format
                                            </TableCell>
                                            <TableCell align="left">--</TableCell>
                                        </TableRow> */}
                                    </TableBody>
                                </Table>
                                <Table className={`account-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell colSpan={2}>Commission</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                        {commission_char.map((rowscontact) => (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {rowscontact.name}
                                                </TableCell>
                                                <TableCell align="left">{userInfo.value ? `${userInfo.value}%` : "--"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* Summary */}
                    <TabPanel value={value} index={1}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Summary</Typography>
                        </Box>
                        <Box className="balance-overview">
                            <Grid container>
                                <Grid item lg={4} xs={12} className="balance-section">
                                    <Typography className="balance">Your balance</Typography>
                                    <Typography className="balance-amount">
                                        12.0
                                        <Typography className="balance-currency">PBU</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item lg={8} xs={12} className="welcome-section">
                                    <Typography className="welcome-text">Welcome,</Typography>
                                    <Typography className="welcome-content">View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container>
                            <Grid lg={12} xs={12}>
                                <Table className={`balance-table ${classes.table}`}>
                                    <TableHead className="table_head">
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell align="right">Transaction No</TableCell>
                                            <TableCell align="right">Debits</TableCell>
                                            <TableCell align="right">Credits</TableCell>
                                            <TableCell align="right">Balance</TableCell>
                                            <TableCell align="right">Remarks</TableCell>
                                            <TableCell align="right">From/To</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="table_body">
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* Account Statement */}
                    <TabPanel value={value} index={2}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Account Statement</Typography>
                        </Box>
                        <Table className={`statement-table ${classes.table}`}>
                            <TableHead className="table_head">
                                <TableRow>
                                    <TableCell>Date/Time</TableCell>
                                    <TableCell align="right">Deposite</TableCell>
                                    <TableCell align="right">Withdraw</TableCell>
                                    <TableCell align="right">Balance</TableCell>
                                    <TableCell align="right">Remarks</TableCell>
                                    <TableCell align="right">From/To</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        No Data
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TabPanel>
                    {/* My Bets */}
                    <TabPanel value={value} index={3}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">My Bets</Typography>
                        </Box>
                        <Grid container spacing={1} className="my-bets">
                            <Grid item lg={12} xs={12} className="mini-games-grid">
                                <AppBar position="static" className="tab">
                                    <Tabs value={valuex} variant="fullWidth" onChange={handlexChange}>
                                        <Tab label="Current Bets" {...a11yProps(0)} />
                                        <Tab label="Bets History" {...a11yProps(1)} />
                                        <Tab label="Profit &amp; Loss" {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel className="current-bet" value={valuex} index={0} >
                                    <AppBar position="static" className="tab">
                                        <Tabs value={valuen} variant="fullWidth" onChange={handlenChange}>
                                            <Tab label="Exchange" {...a11yProps(0)} />
                                            <Tab label="FancyBet" {...a11yProps(1)} />
                                            <Tab label="Sportsbook" {...a11yProps(2)} />
                                            <Tab label="BookMaker" {...a11yProps(3)} />
                                            <Tab label="Binary" {...a11yProps(4)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel className="current-bet" value={valuen} index={0}>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-filter">
                                            <Box className="input-list">
                                                <Typography variant="span">Bet Status</Typography>
                                                <select id="betStatus" className="bet-status">
                                                    <option value="">All</option>
                                                    <option value="Unmatched">Unmatched</option>
                                                    <option value="Matched">Matched</option>
                                                </select>
                                                <Typography variant="span">Order By</Typography>
                                                <input type="checkbox" name="betplace" />
                                                <Typography variant="span" className="ml-0">BetPlaced</Typography>
                                                <input type="checkbox" name="market" />
                                                <Typography variant="span" className="ml-0">Market</Typography>
                                            </Box>
                                        </Box>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Unmatched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection	Type</TableCell>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Unmatched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Box fontWeight="fontWeightBold" fontSize={14} className="bets-box">
                                            <Typography variant="span">Matched</Typography>
                                        </Box>
                                        <Table className={`bets-table ${classes.table}`}>
                                            <TableHead className="table_head">
                                                <TableRow>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell align="left">Selection	Type</TableCell>
                                                    <TableCell align="left">Bet ID</TableCell>
                                                    <TableCell align="right">Bet placed</TableCell>
                                                    <TableCell align="right">Odds req.</TableCell>
                                                    <TableCell align="right">Matched</TableCell>
                                                    <TableCell align="right">Unmatched</TableCell>
                                                    <TableCell align="right">Date matched</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="table_body">
                                                <TableRow>
                                                    <TableCell component="th" scope="row">You have no bets in this time period.</TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TabPanel>
                                    <TabPanel className="current-bet" value={valuen} index={1} >
                                        Two
                                    </TabPanel>
                                    <TabPanel className="current-bet" value={valuen} index={2} >
                                        Three
                                    </TabPanel>
                                    <TabPanel className="current-bet" value={valuen} index={3} >
                                        Four
                                    </TabPanel>
                                    <TabPanel className="current-bet" value={valuen} index={4} >
                                        Five
                                    </TabPanel>
                                </TabPanel>
                                <TabPanel className="" value={valuex} index={1}>
                                    jsnjnj
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* Activity Log */}
                    <TabPanel value={value} index={4}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Activity Log</Typography>
                        </Box>
                        <Table className={`statement-table ${classes.table}`}>
                            <TableHead className="table_head">
                                <TableRow>
                                    <TableCell>Login Date &amp; Time</TableCell>
                                    <TableCell align="right">Login Status</TableCell>
                                    <TableCell align="right">IP Address</TableCell>
                                    <TableCell align="right">ISP</TableCell>
                                    <TableCell align="right">City/State/Country</TableCell>
                                    <TableCell align="right">User Agent Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                                {activity_log.map((row) => (
                                    <TableRow key={row.time}>
                                        <TableCell component="th" scope="row">{row.time}</TableCell>
                                        <TableCell align="right" className="green">{row.status}</TableCell>
                                        <TableCell align="right">{row.ip}</TableCell>
                                        <TableCell align="right">{row.isp}</TableCell>
                                        <TableCell align="right">{row.city}</TableCell>
                                        <TableCell align="right">{row.type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={opens}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={opens}>
                            <div className="change_password_model custom-sky-passwordChange">
                                <h2 id="transition-modal-title">Change Password</h2>
                                <a className="close_pop" href="#" onClick={handleClose}>close_pop</a>
                                <ValidatorForm

                                    onSubmit={handlePasswordSubmit}
                                    autoComplete="off"
                                >
                                    <Grid container spacing={2}>
                                        <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                            New Password
                                        </Grid>
                                        <Grid className="form-input-changepas" item lg={5} xs={5}>
                                            <TextValidator size="small"
                                                value={passwordData.new_password}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handlePasswordChange}
                                                id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="new_password" variant="outlined" />
                                        </Grid>
                                        <Grid className="star-must" item lg={2} xs={2}>
                                            <span className="must">＊</span>
                                        </Grid>
                                        <Grid className="form-input-changepas" item lg={5} xs={5}>
                                            New Password Confirm
                                        </Grid>
                                        <Grid item lg={5} xs={5} className="form-input-changepas">
                                            <TextValidator size="small"
                                                value={passwordData.new_confirm_password}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handlePasswordChange}
                                                onKeyUp={checkBothPasss}
                                                id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="new_confirm_password" variant="outlined" />
                                            <div id="validateConfirmPass"></div>
                                        </Grid>
                                        <Grid className="star-must" item lg={2} xs={2}>
                                            <span className="must">＊</span>
                                        </Grid>
                                        <Grid className="form-lable-changepass" item lg={5} xs={5}>
                                            Your Password
                                        </Grid>
                                        <Grid item lg={5} xs={5} className="form-input-changepas">
                                            <TextValidator size="small"
                                                value={passwordData.old_password}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handlePasswordChange}
                                                id="outlined-basic2" className="inputtext-pass" type="password" placeholder="Enter" name="old_password" variant="outlined" />
                                        </Grid>
                                        <Grid className="star-must" item lg={2} xs={2}>
                                            <span className="must">＊</span>
                                        </Grid>
                                        <Grid className="form-lable-changepass" item lg={5} xs={5}>

                                        </Grid>
                                        <Grid item lg={5} xs={5} className="form-input-changepas">
                                            <Button variant="contained" className="chaPassSubmit" type="submit">
                                                Change
                                            </Button>
                                            {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button> */}
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </div>
                        </Fade>
                    </Modal>

                </Grid>
            </Grid>
        </>
    );
}
