import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  makeStyles,
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Appconfig from "../config/config";
import axios from 'axios';
import moment from "moment";
import Footer from "../includes/Footer";
import Header from "../includes/Header";
import Casino from "../includes/Casino";
import { useQuery, useQueryClient } from 'react-query';
// Import Images
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { postErrorToslack } from "../config/common";

// import { useHistory } from 'react-router';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dashboardWrap: {
    width: "75%",
    margin: "auto",
    marginTop: "2px",
  },
  AlignCenter: {
    alignItems: "center",
  },
  borderRight: {
    borderRight: "1px solid #999",
    height: "100%",
  },
  marginBottomLast: {
    marginBottom: "24px !important",
  },
}));


function loginAlert() {
  alert("Please Login");
}
function loader_remove() {
  if (document.getElementById("poker_loading")) {
    document.getElementById("poker_loading").style.display = "none";
  }
}


const Dashboard = () => {

  const history = useHistory();

  const queryClient = useQueryClient()
  let sportsCount = {
    "cricket": 0,
    "tennis": 0,
    "soccer": 0
  };

  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [bal, setBal] = React.useState(false);
  const [casinoApiLink, setCasinoApiLink] = React.useState('http://silver.marketsarket.in/casino/');
  const myStoredBal = JSON.parse(window.localStorage.getItem("getBalanceByUser"));
  const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
  const [eventListData, setEventListData] = React.useState([]);
  const [bannerList, setBannerList] = React.useState(userInfo ? userInfo.banners : []);
  const [inplaycount, setInplaycount] = React.useState({});
  const [isBannerLoaded, setIsBannerLoaded] = React.useState(false);

  const Item = (props) => {
    return <img alt="skyexchange" onLoad={() => setIsBannerLoaded(true)} fetchpriority="low" src={Appconfig.apiUrl + 'banner/' + props.item.image_name} style={{ height: 73, width: "100vw" }} />;
  }

  const getInplayCountNew = () => {
    let config = JSON.stringify({
      user_id: userInfo ? userInfo._id : "",
    });
    return axios.post(`${Appconfig.apiUrl}eventsDashboard/inplayCount`, config, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  const onSuccess = (data) => {
    try {
      if (data) {
        data.data.resultData.map((val, index) => {
          if (val._id == 4) {
            sportsCount.cricket = val.myCount;
          }
          if (val._id == 1) {
            sportsCount.soccer = val.myCount;
          }
          if (val._id == 2) {
            sportsCount.tennis = val.myCount;
          }
        })
      }
      loader_remove();

      setInplaycount(sportsCount);
    } catch (e) {
      postErrorToslack(e.message);
    }
  }
  const onCache = (data) => {
    try {
      if (data) {
        data && data.length && data.map((val, index) => {
          if (val._id == 4) {
            sportsCount.cricket = val.myCount;
          }
          if (val._id == 1) {
            sportsCount.soccer = val.myCount;
          }
          if (val._id == 2) {
            sportsCount.tennis = val.myCount;
          }
        })
      }
      setInplaycount(sportsCount);
    } catch (e) {
      postErrorToslack(e.message);
    }
  }
  const { isLoading, isError, error, data, refetch } = useQuery('inplayCount', getInplayCountNew, {
    onSuccess
  })

  React.useEffect(() => {

    // return false;
    // loader_remove();
    // getInplayCount();
    if (queryClient.getQueryData(`inplayCount`)) {
      onCache(queryClient.getQueryData(`inplayCount`).data.resultData);
    }
    // getDashboardData();
    getBanner();
  }, []);
  const awcsport = (placeFormName, gameCode = null) => {
    let data = JSON.stringify({
      'platform': placeFormName,
      'user_name': userInfo.user_name,
      'externalURL': "https://skyexchangeart.in/",
      "gameCode": gameCode
    })
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}awc/awc-login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then(function (response) {


        try {
          if (response.data.result) {
            window.location.href = response.data.resultData.url
          }
        } catch (e) {
          postErrorToslack(e.message);
        }
      }).catch(function (error) {
        console.log(error);
        postErrorToslack(error.message);
      });
  }


  const diamondCasinoLogin = (gameCode = null) => {
    let data = JSON.stringify({
      'username': userInfo._id,
      'sitename': 'https://skyexchangeart.in/',
      'game': gameCode,
    })
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}diamond-casino/get-casino-link`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          if (response.data.casino_link) {
            // history.push('/casino-market', { casino_path:response.data.casino_link });
            window.location.href = response.data.casino_link
          }
        } catch (e) {
          postErrorToslack(e.message);
        }
      }).catch(function (error) {
        console.log(error);
        postErrorToslack(error.message);
      });
  }

  const CreateJwtAndLaunchCasino = (casinoGameName) => {
    let data = JSON.stringify({
      'username': userInfo._id,
      'balance': myStoredBal.balance,
      'sitename': "https://betbuzz247.bet/",
      'is_react': "Yes"
    })
    var config = {
      method: "post",
      url: `https://nginx.operator.games:8080/gettoken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          if (response) {
            response = JSON.parse(JSON.stringify(response));
            if (response) {
              var jwtToken = response.data.token;
              var link = casinoApiLink + casinoGameName + "/" + jwtToken;

              window.location.href = link;
            }
          }
        } catch (e) {
          postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        postErrorToslack(error.message);
      });
  }

  function getBanner() {
    var data = JSON.stringify({
      site_code: Appconfig.sitecodes,
    });
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}banner/getDashboardBanner`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        try {
          if (response.data.result == 0) {
          } else {
            setBannerList(response.data.resultData);
          }
        } catch (e) {
          postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        postErrorToslack(error.message);
      });
  }
  const getDashboardDataNew = () => {
    var data = JSON.stringify({
      user_id: userInfo ? userInfo._id : "",
      event_type_id: 0,
    });
    let url = "get-dashboard-data-with-user-id";
    if (!userInfo) {
      url = "get-dashboard-data-without-user-id";
    }
    return axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
  const onSuccessNew = (data) => {
    try {
      if (data) {
        renderEventData(data.data.resultData);
        loader_remove();
      }
    } catch (e) {
      loader_remove();
      postErrorToslack(e.message);
    }
  }
  const onCacheNew = (data) => {
    try {
      if (data) {

        renderEventData(data);
        loader_remove();
      }
    }
    catch (e) {
      loader_remove();
    }
  }
  const { isLoadingNew, isErrorNew, errorNew, dataNew, refetchNew } = useQuery('eventLists', getDashboardDataNew, {
    onSuccessNew
  })


  React.useEffect(() => {
    // loader_remove();
    if (queryClient.getQueryData(`eventLists`)) {
      onCacheNew(queryClient.getQueryData(`eventLists`).data.resultData);
    }
    // getDashboardData()
  }, [])
  const renderEventData = (eventData) => {
    let tempData = [];
    let inplayArr = [];
    let outPlayArr = [];
    eventData.map((Crick, index) => {
      Crick.competitions.map((competition, index) => {
        competition.events.map((event, index) => {
          if (event.marketTypes.length) {
            if (event.marketTypes[0].marketRunners.length) {
              // console.log('check for market runnner',event.marketTypes[0].marketRunners[0])
              if (event.marketTypes[0].marketRunners[0].selection_id) {
                // console.log('test for empty',event.marketTypes[0].marketRunners.length)
                let sport_type = '';
                // console.log(event);
                if (event.event_type == 4) {
                  sport_type = 'Cricket';
                } else if (event.event_type == 2) {
                  sport_type = 'Tennis';
                }
                else if (event.event_type == 1) {
                  sport_type = 'Soccer';
                }
                // console.log('event.marketTypes[0].marketRunners[0].open_date',event);
                let eventDetail = {
                  sport: sport_type,
                  event_id: event.event_id,
                  name: event.event_name,
                  // time: moment(event.open_date).format("hh:mm"),
                  time: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("HH:mm"),
                  date: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("YYYY-MM-DD"),
                  // date: moment(event.open_date).format("YYYY-MM-DD"),
                  is_inplay: event.is_inplay == 'True' ? "Inplay" : "Going Inplay",
                  backFirst: event.marketTypes[0].marketRunners[0].back_1_price,
                  layFirst: event.marketTypes[0].marketRunners[0].lay_1_price,
                  backSecond: event.marketTypes[0].marketRunners[1].back_1_price,
                  laySecond: event.marketTypes[0].marketRunners[1].lay_1_price,
                  selection1Id: event.marketTypes[0].marketRunners[0].selection_id,
                  selection2Id: event.marketTypes[0].marketRunners[1].selection_id,
                  is_fancy: event.is_fancy,
                  is_bookmaker: event.is_bookmaker,
                  market_id: event.marketTypes[0].market_name == "Match Odds" ? event.marketTypes[0].market_id : "",
                  is_pin: event.marketTypes[0].is_pin == "Yes" ? true : false,
                }
                // console.log('eventDetail',eventDetail);
                if (event.is_inplay == 'True') {
                  inplayArr.push(eventDetail)
                } else {
                  outPlayArr.push(eventDetail)
                }
              }
            }
            else {
              // console.log('its empty',event.marketTypes[0].marketRunners.length)
            }
          }
        });
      });
    });
    tempData = inplayArr.concat(outPlayArr);
    setEventListData(tempData);
    window.localStorage.setItem("eventListByUser", JSON.stringify(tempData));
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <Header eventList={eventListData} />
      {isLoggedIn ? null :
        <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
      }
      {bal && <Casino isOpen={true} />}
      <div className="dashboard">
        <div className={`dashborad-slider ${classes.dashboardWrap}`} style={{ marginTop: userInfo ? 85 : 64, width: "100vw" }}>



          <>
            <Slider {...settings} style={{ height: 87, display: !isBannerLoaded ? 'none' : 'block' }}>
              {bannerList && bannerList.length && bannerList.map((item, i) => (
                <Item key={i} item={item} />
              ))}
            </Slider>

            {
              !isBannerLoaded ?
                <div style={{
                  height: 77, backgroundColor: "#000", marginBottom: 15, justifyContent: "center", display: "flex",
                  justifyContent: "center"
                }}>
                  <img alt="loader" className="loader" src="/images/loading.gif" style={{ height: 70, width: 90 }} />
                </div> : null
            }

          </>




        </div>
        <div className={classes.dashboardWrap}>
          <div className="sport-blocks">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div className="sport-block">
                  <Link to={{
                    pathname: "/in-play",
                    state: eventListData
                  }}>
                    <div className="live-count">
                      <div className="live">
                        <span>
                          <img alt="skyexchange" fetchpriority="low" src="/images/live.svg" />
                        </span>
                        LIVE
                      </div>
                      <div className="sport-count">
                        <span>Cricket</span>
                        <span className="show-number">{inplaycount.cricket}</span>
                      </div>
                      <div className="sport-count">
                        <span>Soccer</span>
                        <span className="show-number">{inplaycount.soccer}</span>
                      </div>
                      <div className="sport-count">
                        <span>Tennis</span>
                        <span className="show-number">{inplaycount.tennis}</span>
                      </div>
                    </div>
                    <img alt="skyexchange" fetchpriority="low" src="/images/banner_sports.png" />
                    <div className="bottom-title">
                      <div className="title">Sports</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>

              </Grid>
              <Grid item md={6} xs={12}>
                <div className="sport-block" onClick={() => userInfo ? console.log('Hello') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="/images/horse.64c056fc.webp" />
                    <div className="bottom-title">
                      <div className="title">Sports</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div></Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('teenpatti/t20') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="/images/teen20.jpg" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Teenpatti T20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('dt20') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/dt20.jpg" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">1 Day Dragon Tiger</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('lucky7') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/lucky7.jpg" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Lucky 7A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('teenpatti/oneday') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/teen.jpg" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Teenpatti One Day</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('ab20') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="/images/ab20.webp" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Andarbar</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('poker/6player') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/poker6.webp" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Poker 6 Player</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('poker/t20') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="/images/poker20.webp" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Poker T20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('ddb') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/bollywood.webp" style={{ height: 115,width:200 }} />
                    <div className="bottom-title">
                      <div className="title">Bollywood Casino</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('card32a') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/card32.jpg" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Card 32A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('aaa') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src="images/aaa.webp" style={{ height: 115 }} />
                    <div className="bottom-title">
                      <div className="title">Amar Akbar Anthony</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>














              {/*               
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-029') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={bannerevo} />
                    <div className="bottom-title">
                      <div className="title">Bia Buu</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

              {/* <Grid item md={6} xs={12}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER') : loginAlert()} >
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={kingmakercasino} />
                    <div className="bottom-title">
                      <div className="title">Kingmaker games</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}


              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('VENUS') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={venus} />
                    <div className="bottom-title">
                      <div className="title">Venus</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

              {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-048') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={banner_horsebook} />
                    <div className="bottom-title">
                      <div className="title">HORSEBOOK</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}










              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/t20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={teenpattihalf} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}


              {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-020') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={baicao} />
                    <div className="bottom-title">
                      <div className="title">Bai Cao</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-035') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={bolagolek} />
                    <div className="bottom-title">
                      <div className="title">Bola Golek</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
               */}

              {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-043') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={dice} />
                    <div className="bottom-title">
                      <div className="title">Dice</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-037') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={hl} />
                    <div className="bottom-title">
                      <div className="title">HL</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-030') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={munda} />
                    <div className="bottom-title">
                      <div className="title">Munda</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-017') : loginAlert()} >
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={taixiu} />
                    <div className="bottom-title">
                      <div className="title">Taixiu</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-009') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={thai} />
                    <div className="bottom-title">
                      <div className="title">Thai</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={mojo7} />
                    <div className="bottom-title">
                      <div className="title">7Mojo</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid item md={6} xs={12}>
                <div className="sport-block">
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={slotgames} />
                    <div className="bottom-title">
                      <div className="title">Slot Games</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/t20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti T20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/oneday') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti Oneday</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/test') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti Test</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('lucky7') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_lucky7a} />
                    <div className="bottom-title">
                      <div className="title">Lucky 7 A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('lucky7eu') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_lucky7b} />
                    <div className="bottom-title">
                      <div className="title">Lucky 7 B</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt6} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger 2020</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt202') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt202} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger 2020 2</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt6') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt6} />
                    <div className="bottom-title">
                      <div className="title">OneDay Dragon Tiger</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
              {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dtl20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dtl20} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger Lion 20-20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('card32a') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_card32a} />
                    <div className="bottom-title">
                      <div className="title">32 Cards A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('card32b') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_card32b} />
                    <div className="bottom-title">
                      <div className="title">32 Cards B</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('aaa') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_aaa} />
                    <div className="bottom-title">
                      <div className="title">Amar Akbar Anthony</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('ddb') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_Bollywood_casino} />
                    <div className="bottom-title">
                      <div className="title">Bollywood Casino</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('war') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_war} />
                    <div className="bottom-title">
                      <div className="title">Casino War</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

            </Grid>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default React.memo(Dashboard);
