import React from 'react'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, Typography, Table, TableRow, TableCell, CardContent, Card, TableBody, Slide, InputBase, Dialog, IconButton, Button, BottomNavigation, BottomNavigationAction, Modal, Backdrop, Fade, Grid, Menu, MenuItem, List, ListItem, ListItemText, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import Marquee from "react-fast-marquee";
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import Person from '@material-ui/icons/Person';
import Setting from '@material-ui/icons/Settings';
import Replay from '@material-ui/icons/Replay';
import CheckBox from '@material-ui/icons/CheckBoxOutlineBlank';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useQuery, useQueryClient } from 'react-query';
// Import Images
// import LOGO from '../assets/images/lce.webp';
import LOGO from '../assets/images/logo.png';


import Verify from '../assets/images/verifycode.gr';
import Live from '../assets/images/live.svg';
import Account from '../assets/images/account.svg';
import Multi from '../assets/images/multi.svg';
import HomeIcon from '../assets/images/home.svg';
import Clock from '../assets/images/clock.svg';
import Sport from '../assets/images/sport-trophy.svg';
import LoginBg from '../assets/images/bg-login.jpg';
import ltv from '../assets/images/ltv.webp';
import livetv from '../assets/images/livetv.gif';

import ltvone from '../assets/images/ltvone.webp';
import tv from '../assets/images/tv.webp';


import Transparent from '../assets/images/transparent.gif';
import Loader from '../assets/images/loading40.gif';
import Mic from '../assets/images/mic.svg';
import Appconfig from "../config/config";
import axios from "axios";
import { postErrorToslack } from '../config/common';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
        width: "inherit"
    },
    search: {
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '270px',
        },
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
    searchIcon: {
        padding: theme.spacing(0, .5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        color: '#000000',
        fontSize: '14px !important'
    },
    inputRoot: {
        color: '#000000',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(.5, .5, .5, 0),
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '14px'
    },
    sectionDesktop: {
        display: 'flex',
    },
    navButton: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '2px 16px',
        textTransform: 'none',
        marginLeft: '8px',
    },
    navBtnImg: {
        marginLeft: '6px'
    },
    navInput: {
        border: 'none',
        borderRadius: '4px',
        marginLeft: '6px',
        fontSize: '12px',
        paddingLeft: '8px',
        width: '122px',
    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
function displayStakeForm() {
    document.getElementById("open-stakeform").style.display = "block";
    document.getElementById("hide-stakebuttons").style.display = "none";
}
function hideStakeForm() {
    document.getElementById("hide-stakebuttons").style.display = "block";
    document.getElementById("open-stakeform").style.display = "none";
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionSetting = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}
function showDetailListing(block_id) {
    document.getElementById("open-bets-table").style.display = "none";
    document.getElementById(block_id).style.display = "block";
}
function closeBackDetail(closeblock) {
    document.getElementById(closeblock).style.display = "none";
    document.getElementById("open-bets-table").style.display = "block";
}

const Header = (props) => {
    const queryClient = useQueryClient()
    const params = useParams();
    const classes = useStyles();
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const [eventListData, setEventListData] = React.useState(props.eventList);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [betopen, betsetOpen] = React.useState(false);
    const [betSlipList, setBetSlipList] = React.useState([]);
    const [openList, setOpenList] = React.useState([]);
    const [tvUrl, setTvUrl] = React.useState("");
    const [showtv, setShowtv] = React.useState(false);
    const [showLtv, setShowLtv] = React.useState(false);
    // console.log('showLtv',showLtv);
    const pathname = window.location.pathname;
    const [News, setNews] = React.useState([]);
    const [formData, setformData] = React.useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        validation_checker: ''
    });
    const [validationError, SetValidationError] = React.useState("");
    function getNews() {
        var data = JSON.stringify({
            site_id: "61b2052095c67ecfc7a00656" + Appconfig.sitecodes,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}news/getNews`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    let messageArr = [];
                    response.data.resultData.map((news, index) => {
                        messageArr.push(news.message);
                    })
                    setNews(messageArr);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    const handleUserChange = (event) => {
        return false;
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
        console.log(formData);
    }

    const setChangePModal = () => {

        var data = JSON.stringify({
            "user_id": userInfo._id,
            "UpdateField": { "cpmodal": "Yes" }
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/updateUserInfo`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result) {
                        console.log("response.data", response.data);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const addCpLog = () => {

        var data = JSON.stringify({
            "user_id": userInfo._id,
            "master_id": userInfo.master_id,
            "remark": "Password Changed By Self"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}passwordHistory/add_password_history`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result) {
                        console.log("response.data", response.data);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    const [cpformData, setcpformData] = React.useState({
        opassword: '',
        npassword: '',
        cpassword: '',
    });
    const handleCpChange = (event) => {
        setcpformData({
            ...cpformData,
            [event.target.name]: event.target.value
        });
    }
    const handleCpSumit = (e) => {
        e.preventDefault();
        if (cpformData.opassword != userInfo.password) {
            notify("Old password did not match", "error");
            return false;
        }
        if (cpformData.npassword != cpformData.cpassword) {
            notify("confirm password did not match", "error");
            return false;
        }
        var data = JSON.stringify({
            "user_id": userInfo._id,
            "UpdateField": { "password": cpformData.npassword }
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/updateUserInfo`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };



        axios(config)
            .then(function (response) {
                try {
                    if (response.data.resultData.n) {
                        userInfo.password = cpformData.npassword;
                        userInfo.cpmodal = "Yes";
                        window.sessionStorage.setItem("userData", JSON.stringify(userInfo));
                        psModalClose();
                        notify("Password changed Successfully", "success");
                        setChangePModal();
                        addCpLog();
                    } else {
                        notify(response.data.resultData.resultMessage, "error");
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }

            })
            .catch(function (error) {
                notify(error, "error");
                postErrorToslack(error.message);
                console.log(error);
            });
    }

    const handleBetClickOpen = () => {
        betsetOpen(!betopen);
    };
    const handleBetClose = () => {
        betsetOpen(false);
    };
    const [settingXSOpen, settingsetOpen] = React.useState(false);
    const handleSettingClickOpen = () => {
        settingsetOpen(!settingXSOpen);
    };
    const handleSettingClose = () => {
        settingsetOpen(false);
    };
    const lastSegment = window.location.pathname;
    let segValue = 2;
    let menuName = "";
    if (lastSegment === '/cricket' || lastSegment === '/soccer' || lastSegment === '/tennis') {
        segValue = 0;
    } else if (lastSegment === '/in-play') {
        segValue = 1;
        menuName = 'in-play';
    } else if (lastSegment === '/multi-market') {
        segValue = 3;
        menuName = 'multi-market';
    }
    if (lastSegment === '/cricket') {
        menuName = 'cricket';
    } else if (lastSegment === '/soccer') {
        menuName = 'soccer';
    } else if (lastSegment === '/tennis') {
        menuName = 'tennis';
    } else if (lastSegment === '/indian-premier-league') {
        menuName = 'indian-premier-league';
    } else if (lastSegment === '/financial-market') {
        menuName = 'financial-market';
    } else if (lastSegment === '/horse-racing') {
        menuName = 'horse-racing';
    } else if (lastSegment === '/result') {
        menuName = 'result';
    }
    const [value, setValue] = React.useState(segValue);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [login, setLogin] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const history = useHistory();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen1 = () => {
        setOpen1(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const menuClose = () => {
        setAnchorEl(null);
    };
    const menuClose2 = () => {
        setAnchorEl2(null);
        sessionStorage.clear();
        sessionStorage.setItem("loggedIn", false);
        history.push("/");
    };
    const menuClose3 = () => {
        setAnchorEl3(null);
    };
    const accountClick = (event) => {
        setAnchorEl2(event.currentTarget);
    }
    const settingOpen = (event) => {
        setAnchorEl3(event.currentTarget);
    }
    const navInput = classes.navInput + " xs-none";
    const searchClass = classes.search + " xs-none";
    const loginBtn = classes.navButton + " red-btn login-index";
    const signupBtn = classes.navButton + " dark-btn btn-signup";
    const bottomPage = (urlSegment) => {
        history.push(`/${urlSegment}`);
    }
    const openAccount = (URL, search = '') => {
        if (URL === 'current-bets') {
            history.push({
                pathname: '/' + URL,
                search: search,
            });
        } else {
            history.push(`/${URL}`);
        }
    }
    const displayType = isLoggedIn && isMobile ? 'inline-block' : 'flex';
    const myStoredBal = JSON.parse(window.localStorage.getItem("getBalanceByUser"));
    const [balLoader, setBalLoader] = React.useState(myStoredBal ? false : true);
    const [MainBalance, setMainBalance] = React.useState(myStoredBal?.balance);
    const [exposure, setExposure] = React.useState(myStoredBal?.exposure);
    const [defaultStake, setDefaultStake] = React.useState(0);
    const [psModalOpen, setPsModalOpen] = React.useState(false);

    let pthh = MainBalance - Math.abs(exposure);
    const [showBetInfo, setShowBetInfo] = React.useState(false);

    React.useEffect(() => {
        // if (window.sessionStorage.getItem("refresh") == 'true' && userInfo) {
        //     console.log(window.sessionStorage.getItem("refresh"))
        //     setTimeout(function () { window.sessionStorage.setItem("refresh", false) }, 3000);
        //     history.push("/");
        // }
    }, [window.sessionStorage.getItem("refresh")])

    React.useEffect(() => {

        const chkLog = window.setInterval(function () {
            if (userInfo) {
                checkNewLogin();
            }
        }, 5000);
        return () => {
            clearInterval(chkLog)
        }
    })
    const handleShowimg = () => {
        ;
    }
    React.useEffect(() => {
        if (params.inplay == "Inplay") {
            document.querySelector("#ctitle").style.marginTop = "5px"

            // setShowtv(true);
            if (props.tvLink) {
                // setTimeout(function () { setShowLtv(true) }, 1500);
            } else {
                setShowLtv(false);
            }
        }
    }, [props.tvLink])
    React.useEffect(() => {
        (function () {
            getNews();
            if (userInfo) {
                chkAndUpdateFirstLogin();
                if (!window.localStorage.getItem("getBalanceByUser")) {
                    setBalLoader(true);
                }
                if (queryClient.getQueryData(`userBalance`)) {
                    onCache(queryClient.getQueryData(`userBalance`).data.resultData);
                    refetch()
                }
                getBetSlip();
                getChips();
                getOpenBets();
                getDefaultStakes()
            } else {
                if (!isMobile) {
                    loadCaptchaEnginge(4, 'white', 'black', 'numbers');
                }
            }
        })()
    }, [props.isBetPlaced]);
    const [chipList, setchipList] = React.useState([]);
    const checkNewLogin = () => {
        var data = JSON.stringify({
            "login_token": userInfo.login_token,
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}userToken/getToken`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result) {
                    }
                    else {
                        sessionStorage.clear();
                        sessionStorage.setItem("loggedIn", false);
                        window.location.href = "/";
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    const handleDefaultStakesInputChange = (event) => {
        setDefaultStake(event.target.value == '' ? 0 : event.target.value);
    }
    const psModalClose = () => {
        setPsModalOpen(false);
    }
    const chkAndUpdateFirstLogin = () => {
        if (userInfo.cpmodal == "No") {
            // setPsModalOpen(true);
        }
    }
    const handleInputChange = (event) => {
        let index = event.target.getAttribute('data-id');
        console.clear();
        console.log('index', index)
        // const newChipList = chipList.map((chip, index) => {
        //     if(index == tempIndex)
        //     {
        //         console.log('chipList1',chip);
        //         chipList[index]["chip_value"] =  parseFloat(event.target.value)
        //     }
        // });
        console.log('before', chipList[index])
        chipList[index]["chip_value"] = parseFloat(event.target.value == '' ? 0 : event.target.value);
        console.log('after', chipList[index])
        setchipList([
            ...chipList,
        ]);
        console.log('chipList', chipList);
    }
    function closeAllInterVals() {
        if (window.location.href.indexOf("full-market") > -1) {
        } else {
            clearInterval(window.sessionStorage.getItem("fancyExpInterval"))
        }
        if (window.location.href.indexOf("casino-bets") > -1) {
        } else {
            clearInterval(window.sessionStorage.getItem("clockInterval"))
        }
    }
    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo._id,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                setBalLoader(false);
                try {
                    if (response.data.result == 0) {
                    } else {
                        console.log(response.data.resultData);
                        setExposure(response.data.resultData.exposure);
                        setMainBalance(response.data.resultData.balance);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                setBalLoader(false);
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function getDefaultStakes() {
        var data = JSON.stringify({
            user_id: userInfo._id,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getDefaultStakes`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result == 0) {
                    } else {
                        setDefaultStake(response.data.resultData.default_stake)
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    const updateDefaultStakes = (event) => {
        let default_stake = event.target.value == '' ? 0 : event.target.value;
        var data = JSON.stringify({
            user_id: userInfo._id,
            default_stake: default_stake
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/updateDefaultStakes`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                getDefaultStakes()
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function getBetSlip() {
        var data = JSON.stringify({
            user_id: userInfo._id,
            event_id: props.eventId,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getBettingsByUserAndEventId`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setBetSlipList(response.data.resultData);
                }
                catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function getChips() {
        var data = JSON.stringify({
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    let tmpChipList = response.data;
                    const newChipList = tmpChipList.map((chipList, index) => {
                        tmpChipList[index]["id"] = index + 1;
                    });
                    loader_remove();
                    setchipList(tmpChipList);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function updateChips(chipList) {
        let newdata = [];
        for (var i = 0; i < chipList.length; i++) {
            newdata[i] = { "_id": chipList[i]._id, "chip_value": document.getElementById('stakeEdit_8' + chipList[i]._id).value };
        }
        var data = JSON.stringify({
            "data": newdata
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/updateMultipleChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.ok) {
                        hideStakeForm();
                        getChips();
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    let expocheck = Math.sign(exposure);
    // console.log('chipList',chipList);
    function getOpenBets() {
        var data = JSON.stringify({
            "user_id": userInfo._id
        });
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/openBets`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    setOpenList(response.data.resultData);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function filterBackBets(bettings) {
        // console.log('bettings', bettings);
        let backBets = bettings.filter(element => element.is_back == "1");
        // console.log('backBets', backBets);
        return backBets
    }
    function filterLayBets(bettings) {
        // console.log('bettings', bettings);
        let backBets = bettings.filter(element => element.is_back == "0");
        // console.log('backBets', backBets);
        return backBets
    }
    function getTvUrl(eventId) {
        var data = JSON.stringify({
            event_id: eventId,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getTv`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                try {
                    setTvUrl(response.data.resultData.livetv);
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }
    function storeUserinfo(userdata) {
        window.sessionStorage.setItem("loggedIn", true);
        window.sessionStorage.setItem("userData", JSON.stringify(userdata));
        history.push('/dashboard');
    }
    function handlesetShowtv(val) {
        setShowtv(val);
        if (val) {
            document.querySelector("#ctitle").style.marginTop = "13.5rem"
        }
        else {
            document.querySelector("#ctitle").style.marginTop = "5px"
        }
    }
    function checkValidation() {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let errorText;
        if (formData.user_name == "") {
            errorText = "Username is empty."
            SetValidationError(errorText);
            return false;
        } else if (formData.password == "") {
            errorText = "Password is empty"
            SetValidationError(errorText);
            return false;
        } else if (formData.validation_checker == "") {
            errorText = "Validation code is empty"
            SetValidationError(errorText);
            return false;
        } else if (format.test(formData.user_name) == true) {
            errorText = "Username is only allow a-z and 0-9."
            SetValidationError(errorText);
            return false;
        } else if (doSubmit() == false) {
            errorText = "Invalid validation code!"
            SetValidationError(errorText);
            return false;
        } else {
            errorText = ""
            SetValidationError(errorText);
            return true;
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkValidation()) {
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/userAuthenticate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(formData)
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.data.result) {
                            storeUserinfo(response.data.resultData);
                        }
                        else {
                            notify(response.data.resultMessage, 'error')
                        }
                    } catch (e) {
                        postErrorToslack(e.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    postErrorToslack(error.message);
                });
        }
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    function doSubmit() {
        let user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha) == true) {
            return true;
        }
        else {
            loadCaptchaEnginge(4, 'white', 'black', 'numbers');
            return false;
        }
    };
    const getBalanceNew = () => {
        if (!userInfo) {
            return false;
        }
        var data = JSON.stringify({
            user_id: userInfo._id,
        });
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        setBalLoader(false);
        return axios.post(`${Appconfig.apiUrl}ledger/getUserBalance`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        try {
            if (data) {
                window.localStorage.setItem("getBalanceByUser", JSON.stringify(data.data.resultData));
                setExposure(data.data.resultData.exposure);
                setBalLoader(false);
                setMainBalance(data.data.resultData.balance);
            }
        } catch (e) {
            postErrorToslack(e.message);
        }
    }
    const onCache = (data) => {
        try {
            if (data) {
                setExposure(data.exposure);
                setMainBalance(data.balance);
            }
        } catch (e) {
            postErrorToslack(e.message);
        }
    }
    const { isLoading, isError, error, data, refetch } = useQuery('userBalance', getBalanceNew, {
        onSuccess
    })

    const refreshBalance = () => {
        setBalLoader(true);
        setTimeout(function () { refetch() }, 2000);

    }
    return (
        <>
            {window.location.href.indexOf("full-market") > -1 && showtv && userInfo ? <div id="streamingBox" className="tv-fix">
                {/* <img useMap="#image-map" fetchpriority="high" src={ltv} alt="livetv" style={{ height: "13rem", position: "absolute", width: "100%", display: showLtv ? "none" : "" }} /> */}
                {/* <img useMap="#image-map" fetchpriority="high" src={ltvone} alt="livetv" style={{ height: "3rem", position: "absolute", width: "100%", display: showLtv ? "" : "none" }} /> */}
                <img useMap="#image-map" fetchpriority="high" src={tv} alt="livetv" style={{ height: "100%", position: "absolute", width: "100%", display: showLtv ? "none" : "" }} />

                {/* <img  fetchpriority="high" src={livetv} alt="livetv" style={{ width: "100vw", height: "13.3rem", display: showLtv ? "none" : "" }} /> */}

                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" contenteditable="false" frameborder="0" scrolling="yes" id="tvframe" onLoad={() => setShowLtv(true)} referrerpolicy="no-referrer"  src={props.tvLink} style={{ width: "100vw", height: "12.5rem", display: showLtv ? "" : "none" }}></iframe>

            </div> : ""}


            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {isLoggedIn ?
                <div className="header logged-inn" >
                    <map name="image-map">
                        <area target="" alt="" title="" onClick={() => handlesetShowtv(!showtv)} href="#" coords="332,5,388,51" shape="rect" />
                    </map>
                    <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                    <AppBar position="static">
                        <Toolbar style={{ 'display': displayType, justifyContent: 'space-between' }}>
                            <div style={{ 'display': 'flex', alignItems: 'center', width: "44%" }}>
                                {isMobile && isLoggedIn ? null :
                                    <Typography className={classes.title} variant="h6">
                                        <Link to="/">
                                            <img alt="skyexchange" className="logo" src={LOGO} />
                                        </Link>
                                    </Typography>
                                }
                                <div className={searchClass}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon className="searchInput" />
                                    </div>
                                    <InputBase
                                        placeholder="Search Events"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                            </div>
                            {isLoggedIn ?
                                <>
                                    <MobileView>
                                        <div className="xs-header main-balance-btn">
                                            {
                                                window.location.href.indexOf("full-market") > -1 && params.inplay == "Inplay" ? <div className="xs-bets-tv" onClick={() => handlesetShowtv(!showtv)}>
                                                    <Link className="a-open_tv ui-link" to="#" >
                                                        <img src={Transparent} alt="bet" />
                                                    </Link>
                                                </div> : ""}
                                            <div className="xs-bets" onClick={handleBetClickOpen}>
                                                <Link to="#">
                                                    <img src={Transparent} alt="bet" />
                                                    Bets
                                                </Link>
                                            </div>
                                            <div>
                                                <Button aria-controls="simple-menu" className="expo-btn xs-expo-btn" aria-haspopup="true" >
                                                    <div style={{ display: balLoader ? "none" : "" }}>
                                                        <div>
                                                            <span>Main</span> <strong>INR {pthh.toFixed(2)}</strong>
                                                        </div>
                                                        <div>
                                                            <span>Exposure</span>
                                                            {
                                                                expocheck == '-1' ? <strong className="negative-exposure">({Math.abs(parseFloat(exposure).toFixed(2))})</strong> : <strong className="positive-exposure">({Math.abs(parseFloat(exposure).toFixed(2))})</strong>
                                                            }
                                                            {/* <strong>({Math.abs(parseFloat(exposure).toFixed(2))})</strong> */}
                                                        </div>
                                                        {/* <div className="nums">
                                                            +4
                                                        </div> */}
                                                    </div>
                                                    <p className="loading-bar" id="menuRefreshLoading" style={{ display: balLoader ? "flex" : "none" }}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </p>
                                                </Button>
                                                <Button aria-controls="simple-menu" onClick={refreshBalance} className="refresh-btn" aria-haspopup="true">
                                                    <Replay />
                                                </Button>
                                            </div>
                                            <div className="xs-sett">
                                                <div className="xs-setting" onClick={handleSettingClickOpen}>
                                                    <Setting />
                                                </div>
                                            </div>
                                        </div>
                                    </MobileView>
                                    <BrowserView>
                                        <div style={{ 'display': 'flex', alignItems: 'center' }}>
                                            <div className="main-balance-btn">
                                                <Button aria-controls="simple-menu" className="expo-btn" aria-haspopup="true" onClick={handleClick}>
                                                    Main <span className="xs-none">Balance</span> <strong>INR {pthh.toFixed(2)}</strong> <span className="md-none"><br /></span> Exposure <strong>{Math.abs(parseFloat(exposure).toFixed(2))}</strong> <span className="num">+4</span>
                                                </Button>
                                                <Button aria-controls="simple-menu" className="refresh-btn" aria-haspopup="true">
                                                    <Replay />
                                                </Button>
                                            </div>
                                            <div className="account-btn">
                                                <Button aria-controls="account-menu" aria-haspopup="true" onClick={accountClick}>
                                                    <Person /> My Account <ArrowDropDown />
                                                </Button>
                                                <Menu
                                                    id="account-menu"
                                                    anchorEl={anchorEl2}
                                                    keepMounted
                                                    open={Boolean(anchorEl2)}
                                                    onClose={menuClose2}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                    }}
                                                >
                                                    <MenuItem className="top-menu-account" onClick={menuClose2}><span>CCC251</span><span className="b-left">GMT +5:30</span></MenuItem>
                                                    <MenuItem onClick={() => openAccount('account')}><Link to="/account">My Profile</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('summary')}><Link to="/summary">Balance Overview</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('account-statement')}><Link to="/account-statement">Account Statement</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('current-bets', 'current')}><Link to="/">My Bets</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('current-bets', 'history')}><Link to="/">Bets History</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('current-bets', 'PandL')}><Link to="/">Profit & Loss</Link></MenuItem>
                                                    <MenuItem onClick={() => openAccount('activity-log')}><Link to="/activity-log">Activity Log</Link></MenuItem>
                                                    <MenuItem onClick={menuClose2}>Logout</MenuItem>
                                                    <Button variant="contained" className="logout-dark-btn">Logout <ExitToApp /></Button>
                                                </Menu>
                                            </div>
                                        </div>
                                    </BrowserView>
                                </>
                                :
                                <div className={classes.sectionDesktop}  >
                                    <Person className="theme-yellow xs-none" />
                                    <input type="text" placeholder="Username" className={navInput} />
                                    <input type="password" placeholder="Password" className={navInput} />
                                    <div className="validation-input">
                                        <input type="text" placeholder="Validation" maxLength="4" className={navInput} />
                                        <img alt="skyexchange" src={Verify} />
                                    </div>
                                    {/* <Link to="#">
                                        <Button variant="contained" className={signupBtn}>
                                            Sign up
                                        </Button>
                                    </Link> */}
                                    <Button onClick={() => history.push("/login")} variant="contained" color="secondary" id="sdfsdfsdfs" className={loginBtn}>Login <img alt="skyexchange" src={Transparent} className={classes.navBtnImg} /></Button>
                                </div>
                            }
                        </Toolbar>
                    </AppBar>
                    <MobileView>
                        <div className="news-section">
                            <h4> <img src={Mic} />News</h4>
                            <div onClick={handleOpen1} style={{ width: "100vw" }}>
                                <Marquee>
                                    {
                                        News.map((row, index) => {
                                            return <span className="newss">{row}</span>
                                        })}
                                </Marquee>
                            </div>
                        </div>
                    </MobileView>
                </div>
                :
                pathname != "/login" ?
                    <div className="header logged-out" >
                        <AppBar position="static"  >
                            <Toolbar style={{ 'display': displayType, justifyContent: 'space-between' }}>
                                <div style={{ 'display': 'flex', alignItems: 'center' }}>
                                    {isMobile && isLoggedIn ? null :
                                        <Typography className={classes.title} variant="h6">
                                            <Link to="/">
                                                <img alt="skyexchange" className="logo" src={LOGO} />
                                            </Link>
                                        </Typography>
                                    }
                                    <div className={searchClass}>
                                        <div className={classes.searchIcon} >
                                            <SearchIcon className="searchInput" />
                                        </div>
                                        <InputBase
                                            placeholder="Search Events"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </div>
                                {isLoggedIn ?
                                    <>
                                        <MobileView>
                                            <div className="xs-header main-balance-btn">
                                                <div className="xs-bets" onClick={handleBetClickOpen}>
                                                    <Link to="#">
                                                        <img src={Transparent} alt="bet" />
                                                        tv
                                                    </Link>
                                                </div>
                                                <div className="xs-bets" onClick={handleBetClickOpen}>
                                                    <Link to="#">
                                                        <img src={Transparent} alt="bet" />
                                                        Bets
                                                    </Link>
                                                </div>
                                                <div >
                                                    <Button aria-controls="simple-menu" className="expo-btn xs-expo-btn" aria-haspopup="true" onClick={handleClick}>
                                                        <div >
                                                            <span>Main</span> <strong>INR {pthh.toFixed(2)}</strong>
                                                        </div>
                                                        <div >
                                                            <span>Exposure</span> <strong>{exposure}</strong>
                                                        </div>
                                                        <div className="nums">
                                                            +4
                                                        </div>
                                                    </Button>
                                                    <Button aria-controls="simple-menu" className="refresh-btn" aria-haspopup="true">
                                                        <Replay />
                                                    </Button>
                                                </div>
                                                <div className="xs-sett">
                                                    <div className="xs-setting" onClick={handleSettingClickOpen}>
                                                        <Setting />
                                                    </div>
                                                </div>
                                            </div>
                                        </MobileView>
                                        <BrowserView>
                                            <div style={{ 'display': 'flex', alignItems: 'center' }}>
                                                <div className="main-balance-btn">
                                                    <Button aria-controls="simple-menu" className="expo-btn" aria-haspopup="true" onClick={handleClick}>
                                                        Main <span className="xs-none">Balance</span> <strong>INR 0.53</strong> <span className="md-none"><br /></span> Exposure <strong>0.00</strong> <span className="num">+4</span>
                                                    </Button>
                                                    <Button aria-controls="simple-menu" className="refresh-btn" aria-haspopup="true">
                                                        <Replay />
                                                    </Button>
                                                </div>
                                                <div className="account-btn">
                                                    <Button aria-controls="account-menu" aria-haspopup="true" onClick={accountClick}>
                                                        <Person /> My Account <ArrowDropDown />
                                                    </Button>
                                                    <Menu
                                                        id="account-menu"
                                                        anchorEl={anchorEl2}
                                                        keepMounted
                                                        open={Boolean(anchorEl2)}
                                                        onClose={menuClose2}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                        }}
                                                    >
                                                        <MenuItem className="top-menu-account" onClick={menuClose2}><span>CCC251</span><span className="b-left">GMT +5:30</span></MenuItem>
                                                        <MenuItem onClick={() => openAccount('account')}><Link to="/account">My Profile</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('summary')}><Link to="/summary">Balance Overview</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('account-statement')}><Link to="/account-statement">Account Statement</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('current-bets', 'current')}><Link to="/">My Bets</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('current-bets', 'history')}><Link to="/">Bets History</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('current-bets', 'PandL')}><Link to="/">Profit & Loss</Link></MenuItem>
                                                        <MenuItem onClick={() => openAccount('activity-log')}><Link to="/activity-log">Activity Log</Link></MenuItem>
                                                        <MenuItem onClick={menuClose2}>Logout</MenuItem>
                                                        <Button variant="contained" className="logout-dark-btn">Logout <ExitToApp /></Button>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </BrowserView>
                                    </>
                                    :
                                    <div className={`login-whole-desktop ${classes.sectionDesktop}`}  >
                                        <Person className="theme-yellow xs-none" />
                                        <div className="username-field">
                                            <input type="text" placeholder="Username" name="user_name" onChange={handleUserChange} className={navInput} />
                                            <span>{validationError} </span>
                                        </div>
                                        <div className="username-field">
                                            <input type="password" placeholder="Password" name="password" onChange={handleUserChange} className={navInput} />
                                        </div>
                                        {/* DeskTop Login */}
                                        <div className="custom-captcha">
                                            {/* <CaptchaTest /> */}
                                        </div>
                                        <div className="validation-input" id="valcodeid">
                                            <input type="number" placeholder="Validation Code" id="user_captcha_input" value={formData.validation_checker} name="validation_checker" maxLength="4" className={classes.navInput} onChange={handleUserChange} />
                                            {/* <img alt="skyexchange" src={Verify} /> */}
                                            <div className="canvas-captcha"> <LoadCanvasTemplate /></div>
                                        </div>
                                        {/* Desktop Login */}
                                        {/* <div className="validation-input">
                                        <input type="text" placeholder="Validation" maxLength="4" className={navInput} />
                                        <img alt="skyexchange" src={Verify} />
                                    </div> */}
                                        {/* <Link to="/signup">
                                            <Button variant="contained" className={signupBtn}>
                                                Sign up
                                            </Button>
                                        </Link> */}
                                        {isMobile ?
                                            <Button onClick={() => history.push("/login")} variant="contained" id="mobileLogin" color="secondary" className={loginBtn}>Login <img alt="skyexchange" src={Transparent} className={classes.navBtnImg} /></Button>
                                            :
                                            <Button onClick={handleSubmit} variant="contained" id="deskTopLogin" color="secondary" className={loginBtn}>Login <img alt="skyexchange" src={Transparent} className={classes.navBtnImg} /></Button>
                                        }
                                    </div>
                                }
                            </Toolbar>
                        </AppBar>
                        {/* <MobileView>
                     <div className="news-section">
                         <h4> <img src={Mic} />News</h4>
                         <div onClick={handleOpen1}>
                             <Marquee>
                                 <Link> <span>11 Sep 2021</span> Match :- Guyana Amazon Warriors v St Lucia Kings ... Market :- SLK 6 Over Runs ' 41 - 42 , (IST 06:39:42 - 06:40:06) and SLK 10 Over Runs ' 69 - 71, (IST 06:39:45 - 06:40:09) Bets Voided Because of Wrong Commentary ... sorry for the Inconvenience Caused</Link>
                                 <Link> <span>11 Sep 2021</span> Match :- Guyana Amazon Warriors v St Lucia Kings ... Market :- SLK 6 Over Runs ' 41 - 42 , (IST 06:39:42 - 06:40:06) and SLK 10 Over Runs ' 69 - 71, (IST 06:39:45 - 06:40:09) Bets Voided Because of Wrong Commentary ... sorry for the Inconvenience Caused</Link>
                             </Marquee>
                         </div>
                     </div>
                 </MobileView> */}
                    </div> : null
            }
            <BrowserView >
                <div className="navbar onlynavbar">
                    <AppBar position="static">
                        <Toolbar variant="dense" className="navMenu">
                            <Typography className={menuName === "" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/">
                                    Home
                                </Link>
                            </Typography>
                            <Typography className={menuName === "in-play" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/in-play">
                                    In-Play
                                </Link>
                            </Typography>
                            <Typography className={menuName === "multi-market" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/multi-market">
                                    Multi Markets
                                </Link>
                            </Typography>
                            <Typography className={menuName === "cricket" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/cricket">
                                    Cricket
                                    <span className="live-number">
                                        <strong><img alt="skyexchange" src={Live} /></strong> 0
                                    </span>
                                </Link>
                            </Typography>
                            <Typography className={menuName === "soccer" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/soccer">
                                    Soccer
                                    <span className="live-number">
                                        <strong><img alt="skyexchange" src={Live} /></strong> 3
                                    </span>
                                </Link>
                            </Typography>
                            <Typography className={menuName === "tennis" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/tennis">
                                    Tennis
                                    <span className="live-number">
                                        <strong><img alt="skyexchange" src={Live} /></strong> 12
                                    </span>
                                </Link>
                            </Typography>
                            {/* <Typography className={menuName === "indian-premier-league" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/indian-premier-league">
                                    IPL Winner
                                </Link>
                            </Typography>
                            <Typography className={menuName === "horse-racing" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/horse-racing">
                                    Horse Racing
                                </Link>
                            </Typography>
                            <Typography className={menuName === "horse-racing" ? 'active dark-menu' : 'dark-menu'} variant="p" color="inherit">
                                <Link to="/horse-racing">
                                    Casino
                                </Link>
                            </Typography> */}
                            <Typography className={menuName === "result" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/result">
                                    Result
                                </Link>
                            </Typography>
                            {/* <Typography className={menuName === "financial-market" ? 'active' : null} variant="p" color="inherit">
                                <Link to="/financial-market">
                                    Binary
                                </Link>
                            </Typography> */}
                            <div className="margin-left-auto nav-right">
                                <Typography variant="p" color="inherit" className="time-zone">
                                    Time Zone : <strong>GMT +5:30</strong>
                                </Typography>
                                <Typography variant="p" color="inherit" className="one-click-bet">
                                    <Link to="#" onClick={handleOpen}>
                                        <CheckBox /> One Click Bet
                                    </Link>
                                </Typography>
                                <Typography variant="p" color="inherit">
                                    <Link onClick={settingOpen} to="#">
                                        Setting <Setting />
                                    </Link>
                                </Typography>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
            </BrowserView>
            <MobileView>
                <div className="bottom-nav">
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        showLabels
                        className={classes.root}
                    >
                        <BottomNavigationAction onClick={() => history.push({
                            pathname: "/cricket",
                            state: eventListData
                        })} className="sportlable" label="Sports" icon={<img alt="skyexchange" src={Sport} />} />
                        <BottomNavigationAction onClick={() => history.push({
                            pathname: "/in-play",
                            state: eventListData
                        })} label="In-Play" icon={<img alt="skyexchange" src={Clock} />} />
                        <BottomNavigationAction onClick={() => bottomPage('')} label="Home" className="home" icon={<img alt="skyexchange" src={HomeIcon} />} />
                        <BottomNavigationAction style={{ letterSpacing: '.4px' }} onClick={() => bottomPage('multi-market')} label="Multi M..." icon={<img alt="skyexchange" src={Multi} />} />
                        <BottomNavigationAction onClick={() => bottomPage(isLoggedIn == true ? 'profile' : 'login')} label="Account" icon={<img alt="skyexchange" src={Account} />} />
                    </BottomNavigation>
                </div>
            </MobileView>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="login-modal">
                        <Grid container>
                            <Grid item xs={6} >
                                <img className="login-modal-img" src={LoginBg} />
                            </Grid>
                            <Grid item xs={6} >
                                <div className="right-login">
                                    <h3>Please login to continue</h3>
                                    <div>
                                        <input placeholder="Username" />
                                    </div>
                                    <div>
                                        <input placeholder="Password" type="password" />
                                    </div>
                                    <div className="validation-input">
                                        <input type="text" placeholder="Validation Code" maxLength="4" className={classes.navInput} />
                                        <img alt="skyexchange" src={Verify} />
                                    </div>
                                    <Button variant="contained" className="w-100 popup-login-btn">Login<img alt="skyexchange" src={Transparent} className={classes.navBtnImg} /></Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open1}
                onClose={handleClose1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open1}>
                    <div className={`news-modal`}>
                        <div className="news-head" >
                            <h5>Announcement</h5>
                            <Link onClick={handleClose1} to="#">
                                <Clear />
                            </Link>
                        </div>
                        <div className="news-body" >
                            <div className="news-block">
                                <div className="news-date">
                                    <span >10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                            <div className="news-block">
                                <div className="news-date">
                                    <span>10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                            <div className="news-block">
                                <div className="news-date">
                                    <span>10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                        </div>
                        <div className="news-footer" >
                            <ul className="pages">
                                <li id="prev" >
                                    <a href="javascript:void(0);" className="ui-link disable">Prev</a>
                                </li>
                                <li id="pageNumber" >
                                    <a href="javascript:void(0);" className="ui-link select">1</a>
                                </li>
                                <li id="next" >
                                    <a href="javascript:void(0);" className="ui-link disable">Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Menu
                id="setting-list"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={menuClose3}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
            >
                <ListItem component="span" className="default-setting setting-first-list">
                    <ListItem component="span" Text primary="Default stake" secondary={
                        <>
                            <input value={defaultStake} />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting setting-stack">
                    <ListItem component="span" Text primary="Default stake" secondary={
                        <>
                            <Grid container spaceing={2}>
                                <Grid item md={9}>
                                    <Grid container spaceing={2}>
                                        {chipList.map((row, index) => (
                                            <Grid item md={3} >
                                                <a>{row.chip_value}</a>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item md={3}>
                                    <a className="edit-settings">Edit <Edit /></a>
                                </Grid>
                            </Grid>
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting odd-list">
                    <ListItem component="span" Text primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Highlight when odds change"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting odd-list">
                    <ListItem component="span" Text primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Odds"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting odd-list">
                    <ListItem component="span" Text primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Odds"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting odd-list">
                    <ListItem component="span" Text primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Price"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem component="span" className="default-setting setting-button">
                    <Button className="close-btn" variant="contained" onClick={menuClose3} >
                        Cancel
                    </Button>
                    <Button className="close-btn save-btn" variant="contained" onClick={menuClose3}>
                        Save
                    </Button>
                </ListItem>
                <Divider />
            </Menu>
            <Dialog fullScreen open={betopen} onClose={handleBetClose} TransitionComponent={Transition} className="bet-popup">
                <AppBar className='header'>
                    <Box className="xs-bets-popup" display="flex" justifyContent="space-between">
                        <Box className="header-name">
                            <img src={Transparent} alt="bets" />
                            Open Bets
                        </Box>
                        <Box >
                            <IconButton edge="start" color="inherit" onClick={handleBetClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </AppBar>
                <List className="bet-body-popup">
                    <Table className={classes.table} aria-label="simple table" style={{ marginTop: 37 }}>
                        <TableBody className="tbl_body">
                            <div id="open-bets-table">
                                {openList.length > 0 && openList.map((row, index) => (
                                    <TableRow to={row.link} p={1} className="odd_even_clr" button>
                                        <TableCell className="table_first_row" colSpan="5">
                                            <div className="text_left-in-play">
                                                <FiberManualRecordIcon className="circle-size" />
                                                <Link to="#" style={{ color: '#2789CE' }} onClick={() => showDetailListing('details-container-open' + (row.betting_type == `Fancy` ? row.match_id + `_` + row.selection_id : row.match_id + `_` + row.market_id))}>
                                                    <Box display="contents">
                                                        <span> {`${(row.remarks + ' - ' + (row.betting_type == 'Fancy' ? row.place_name : row.market_name))?.substring(0, 40)}...`} </span>
                                                        {/* <span className="in-play"> v </span>
                                                    <span>{row.name2}</span> */}
                                                    </Box>
                                                </Link>
                                            </div>
                                        </TableCell>
                                        <TableCell className="arrow-icon" style={{ width: "30px" }} onClick={() => showDetailListing('details-container-open' + (row.betting_type == `Fancy` ? row.match_id + `_` + row.selection_id : row.match_id + `_` + row.market_id))}>
                                            <Link to="#" className="inplay-last-icon"></Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </div>
                            <TableRow>
                                {openList.length > 0 && openList.map((row, index) => (
                                    <div className="details-container-open-bets" id={`details-container-open${(row.betting_type == `Fancy` ? row.match_id + `_` + row.selection_id : row.match_id + `_` + row.market_id)}`} style={{ display: 'none' }}>
                                        <div id="detailOpenHead" className="detail-open-head">
                                            <Link id="detailBack" className="detail-back" to="#" onClick={() => closeBackDetail('details-container-open' + (row.betting_type == `Fancy` ? row.match_id + `_` + row.selection_id : row.match_id + `_` + row.market_id))}></Link>
                                            <ul>
                                                <li id="eventName">{`${row.remarks?.substring(0, 36)}...`}</li>
                                            </ul>
                                        </div><h3 id="openheadHeader"> Matched </h3><div id="txnList" className="slip-list" style={{ 'display': 'block' }}>
                                            {
                                                filterBackBets(row.bettings).length > 0 ?
                                                    <>
                                                        <dl id="header_back" className="bets-head" style={{ 'display': 'block' }}>
                                                            <dt>
                                                                <h4>Back</h4>
                                                            </dt>
                                                            <dd className="col-odds"><span>Odds</span></dd>
                                                            <dd className="col-stake"><span>Stake</span></dd>
                                                            <dd className="col-profit"><span>Profit</span></dd>
                                                        </dl>
                                                        {filterBackBets(row.bettings).length > 0 && filterBackBets(row.bettings).map((rowinner, index) => (
                                                            rowinner.is_back == 1 ?
                                                                <><dd id="betInfo" className={`col-ref backk ${showBetInfo ? 'show-bet-info' : 'hide-bet-info'}`}>
                                                                    <ul>
                                                                        <li id="betId">Ref {rowinner._id.substr(rowinner._id.length - 6)}</li>
                                                                        <li id="placeDate">
                                                                            {moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}
                                                                        </li>
                                                                    </ul>
                                                                </dd>
                                                                    {/* {console.log('working', rowinner)} */}
                                                                    <dl id="txn_692282101" className="bets-back" style={{ 'display': 'block' }}>
                                                                        <dt>
                                                                            <span id="sideType" className="back">Yes</span>
                                                                            <h4 id="selectionName">{`${rowinner.place_name ? rowinner.place_name.substring(0, 14) : ''}...`}</h4>
                                                                        </dt>
                                                                        <dd id="odds" className="col-odds"><span>{rowinner.price_val}</span></dd>
                                                                        <dd id="stake" className="col-stake" title=" 100"><span> {rowinner.stake}</span></dd>
                                                                        <dd id="pl" className="col-profit"><span> {rowinner.profit.toFixed(2)}</span></dd>
                                                                        <div className="clear"></div>
                                                                    </dl></> : ''
                                                        ))}
                                                    </> : null
                                            }
                                            {
                                                filterLayBets(row.bettings).length > 0 ?
                                                    <>
                                                        <dl id="header_lay" className="bets-head" style={{ 'display': 'block' }}>
                                                            <dt>
                                                                <h4>Lay</h4>
                                                            </dt>
                                                            <dd className="col-odds"><span>Odds</span></dd>
                                                            <dd className="col-stake"><span>Stake</span></dd>
                                                            <dd className="col-profit"><span>Liability</span></dd>
                                                        </dl>
                                                        {filterLayBets(row.bettings).length > 0 && filterLayBets(row.bettings).map((rowinner, index) => (
                                                            rowinner.is_back == 0 ?
                                                                <div ><dd id="betInfo" className={`col-ref layy ${showBetInfo ? 'show-bet-info' : 'hide-bet-info'}`} >
                                                                    <ul>
                                                                        <li id="betId">Ref {rowinner._id.substr(rowinner._id.length - 6)}</li>
                                                                        <li id="placeDate">{moment(rowinner.createdAt).format('DD-MM-YYYY  hh:mm ')}</li>
                                                                    </ul>
                                                                </dd>
                                                                    <dl id="txn_692282101" className="bets-lay" style={{ 'display': 'block' }}>
                                                                        <dt>
                                                                            <span id="sideType" className="lay">No</span>
                                                                            <h4 id="selectionName">{`${rowinner.place_name?.substring(0, 14)}...`}</h4>
                                                                        </dt>
                                                                        <dd id="odds" className="col-odds"><span>{rowinner.price_val}</span></dd>
                                                                        <dd id="stake" className="col-stake" title=" 100"><span> {rowinner.stake}</span></dd>
                                                                        <dd id="pl" className="col-profit"><span> {rowinner.loss.toFixed(2)}</span></dd>
                                                                        <div className="clear"></div>
                                                                    </dl></div> : ''
                                                        ))}
                                                    </> : null
                                            }
                                        </div><ul id="openBetOption" className="check-list" style={{ 'display': 'flex' }}>
                                            <li ><Link id="showBetInfo" className="" to="#" onClick={() => {
                                                setShowBetInfo(!showBetInfo)
                                            }
                                            }>Bet Info</Link></li>
                                            <li name="txnOption" style={{ 'display': 'none' }}><Link id="averageOdds" className="" to="#">Average Odds</Link></li>
                                        </ul>
                                    </div>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </List>
            </Dialog>
            <Dialog fullScreen open={settingXSOpen} onClose={handleSettingClose} TransitionComponent={TransitionSetting} className="set-popup">
                <AppBar className='header'>
                    <Box className="xs-setting-popup" display="flex" justifyContent="space-between">
                        <Box className="header-name">
                            <Setting />Setting
                        </Box>
                        <Box >
                            <IconButton edge="start" color="inherit" onClick={handleSettingClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </AppBar>
                <List className="popup-setting-body" style={{ marginTop: 40 }}>
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Stack</Typography>
                    </Box>
                    <ListItem className="default-setting setting-first-list">
                        <ListItemText primary="Default stake" secondary={
                            <>
                                <input type="number" className="userCoin" onChange={handleDefaultStakesInputChange} value={defaultStake} onBlur={updateDefaultStakes} />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem className="default-setting setting-stack hello">
                        <ListItemText primary="Quick stakes" secondary={
                            <>
                                <Grid container spaceing={2} id="hide-stakebuttons">
                                    <Grid item md={9} xs={12}>
                                        <Grid container spaceing={2}>
                                            {chipList.map((row, index) => (
                                                <Grid item md={3} xs={3} >
                                                    <label>
                                                        <input type="checkbox" />
                                                        <a>{row.chip_value}</a>
                                                    </label>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <Link to="#" className="edit-settings" onClick={displayStakeForm}>Edit Stakes<Edit /></Link>
                                    </Grid>
                                </Grid>
                                <div className="grid-text-boxes" id="open-stakeform">
                                    <dl id="editCustomizeStakeList" class="setting-block stake-custom-edit stake-setting">
                                        <dt>Quick Stakes</dt>
                                        {chipList.map((row, index) => (
                                            <dd >
                                                <div class="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                                    <input id={`stakeEdit_8${row._id}`} type="text" onChange={handleInputChange} pattern="[0-9]*" data-id={index} value={row.chip_value} /></div>
                                            </dd>
                                        ))}
                                        <dd class="col-stake_edit"><Link id="ok" onClick={() => updateChips(chipList)} class="btn-send ui-link" to="#">OK</Link></dd>
                                    </dl>
                                </div>
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    {/* <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Odds</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <label className="switch">
                                    Highlight when odds change
                                    <input type="checkbox" checked />
                                    <div className="slider round">
                                        <span className="on"></span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">FancyBet</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <label className="switch">
                                    Accept Any Odds
                                    <input type="checkbox" />
                                    <div className="slider round">
                                        <span className="on"></span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Sportsbook</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <label className="switch">
                                    Accept Any Odds
                                    <input type="checkbox" />
                                    <div className="slider round">
                                        <span className="on"></span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Binary</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <label className="switch">
                                    Accept Any Price
                                    <input type="checkbox" />
                                    <div className="slider round">
                                        <span className="on"></span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </>
                        } />
                    </ListItem>
                    <Divider /> */}
                    <ListItem className="default-setting setting-button">
                        <Button className="close-btn" variant="contained" onClick={handleSettingClose} >
                            Cancel
                        </Button>
                        <Button className="close-btn save-btn" variant="contained" onClick={handleSettingClose}>
                            Save
                        </Button>
                    </ListItem>
                </List>
            </Dialog>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={menuClose}
            >
                <List className={classes.root}>
                    <ListItem component="span" className="main-balance balance-list">
                        <ListItem component="span" Text primary="Main Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">{pthh.toFixed(2)}</span>
                                <Divider />
                                <div className="main-footer">
                                    <span>Exposure</span>
                                    <span> {
                                        expocheck == '-1' ? <strong className="negative-exposure-popup">({Math.abs(parseFloat(exposure).toFixed(2))})</strong> : <strong className="positive-exposure-popup">({Math.abs(parseFloat(exposure).toFixed(2))})</strong>
                                    }</span>
                                </div>
                            </>
                        } />
                        <Divider />
                    </ListItem>
                </List>
                <List className={classes.root}>
                    <ListItem component="span" className="balance-list">
                        <ListItem component="span" Text primary="SABA Balance" secondary={
                            <>
                                <span cluassName="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem component="span" className="balance-list">
                        <ListItem component="span" Text primary="Casino Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem component="span" className="balance-list">
                        <ListItem component="span" Text primary="BPoker Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem component="span" className="balance-list">
                        <ListItem component="span" Text primary="&nbsp;" secondary={
                            <>
                                <span className="points"></span>
                            </>
                        } />
                        <Button className="recall-btn">Recall All</Button>
                    </ListItem>
                </List>
                <Button variant="contained" onClick={menuClose} className="close-btn">Close</Button>
            </Menu>

            {/* change password modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={psModalOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        psModalClose();
                    }
                }}

                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={psModalOpen}>

                    <Card variant="outlined" className='cpmodal' style={{ "width": "95%" }}>
                        <Grid container >
                            <Grid item xs={12}>
                                {/* <CardHeader title="Change Password" className='report-card-header' /> */}
                                <CardContent className='report-card-content' style={{ "background-color": "#FFB80C", "padding": "0px" }}>
                                    <div className='cpmodal-row' >
                                        <div className="cpmodal-col">
                                            <ul>
                                                <li>Password must contain 8 to 15 alphanumeric without white space</li>
                                                <li>Password cannot be same as username/nickname</li>
                                                <li>Must contain atleast 1 capital letter, 1 small letter and 1 number</li>
                                                <li>Password must not contain any special letter</li>
                                            </ul>
                                        </div>
                                        <div className='cpmodal-col'>
                                            <h3>Change Password</h3>
                                            <form onSubmit={handleCpSumit}>
                                                <div className="cpmodal-right-login">

                                                    <div>
                                                        <input placeholder="Old Password" onChange={handleCpChange} name="opassword" type="password" />
                                                    </div>
                                                    <div>
                                                        <input placeholder="New Password" onChange={handleCpChange} name="npassword" type="password" />
                                                    </div>
                                                    <div>
                                                        <input placeholder="Confirm Password" onChange={handleCpChange} name="cpassword" type="password" />
                                                    </div>

                                                    <button variant="contained" type='submit' className="cpmodal-button">Change</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='cpmodal-row' style={{ "background-color": "black", "padding": "18px" }}>
                                        <img alt="skyexchange" className="logo" src={LOGO} style={{ "margin-left": "32%" }} />
                                    </div>
                                </CardContent>

                            </Grid>
                        </Grid>
                    </Card>

                </Fade>
            </Modal>
        </>
    )
}
export default React.memo(Header);
