import React, { Suspense, lazy } from 'react';
import './skyexchange/assets/css/style.css'
import './skyexchange/assets/css/Responsive.css'
import { WebSocketContext, socket } from './context/websocket';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NoInternetConnection from './NoInternetConnection'
import { useParams, useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.min.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';
import { postErrorToslack } from './skyexchange/config/common';


import Dashboard   from "./skyexchange/pages/Dashboard";
import Inplay from "./skyexchange/pages/Inplay"
import FullMarket from "./skyexchange/pages/FullMarket"
import SportTab from "./skyexchange/includes/SportTab"
import Login from "./skyexchange/pages/Login"

import CasinoBets from "./skyexchange/includes/CasinoBets"
import Bethistory from "./skyexchange/pages/Bethistory"
import CasinoTabs from "./skyexchange/pages/CasinoTabs"
import MultiMarket from "./skyexchange/pages/MultiMarket"
import IndianIPL from "./skyexchange/pages/IndianIPL"

import AccountMobile from "./skyexchange/pages/AccountMobile"
import ActivityLog from "./skyexchange/pages/ActivityLog"
  import CurrentBets from "./skyexchange/pages/CurrentBets"
  import AccountStatement from "./skyexchange/pages/AccountStatement"

  import Balance from "./skyexchange/pages/Balance"
  import Account from "./skyexchange/pages/Account"
  import Content from "./skyexchange/pages/Content"
  import SignUp from "./skyexchange/pages/SignUp"

  import HorseSingle from "./skyexchange/pages/HorseSingle"
  import Result from "./skyexchange/pages/Result"
  import HorseRacing from "./skyexchange/pages/HorseRacing"
  import FinancialMarket from "./skyexchange/pages/FinancialMarket"

  import CasinoMarket from "./skyexchange/pages/CasinoMarket"


 const LoaderSk = () => {
  return (<SkeletonTheme baseColor="#d6cfc7" highlightColor="#999DA0">
    <Skeleton count={1} style={{ height: 60, marginBottom: 2 }} />
    <Skeleton count={1} style={{ height: 90, marginBottom: 2 }} />
    <Skeleton count={1} style={{ height: 125, marginBottom: 2 }} />
    <Skeleton count={1} style={{ height: 125, marginBottom: 2 }} />
    <Skeleton count={1} width="48%" style={{ height: 130, marginRight: 5, marginBottom: 2 }} inline={true} />
    <Skeleton count={1} width="50%" style={{ height: 130 }} inline={true} />
    <Skeleton count={1} width="48%" style={{ height: 130, marginRight: 5, marginBottom: 2 }} inline={true} />
    <Skeleton count={1} width="50%" style={{ height: 130 }} inline={true} />
    <Skeleton count={1} width="48%" style={{ height: 130, marginRight: 5, marginBottom: 2 }} inline={true} />
    <Skeleton count={1} width="50%" style={{ height: 130 }} inline={true} />
    <Skeleton count={1} width="48%" style={{ height: 130, marginRight: 5, marginBottom: 2 }} inline={true} />
    <Skeleton count={1} width="50%" style={{ height: 130 }} inline={true} />
  </SkeletonTheme>)
}


function App() {
  // const Dashboard = import("./skyexchange/pages/Dashboard");
  // const Inplay = import("./skyexchange/pages/Inplay");
  // const FullMarket = import("./skyexchange/pages/FullMarket");
  // const SportTab = import("./skyexchange/includes/SportTab");
  // const Login = import("./skyexchange/pages/Login");

  // const CasinoBets = import("./skyexchange/includes/CasinoBets");
  // const Bethistory = import("./skyexchange/pages/Bethistory");
  // const CasinoTabs = import("./skyexchange/pages/CasinoTabs");
  // const MultiMarket = import("./skyexchange/pages/MultiMarket");
  // const IndianIPL = import("./skyexchange/pages/IndianIPL");

  // const AccountMobile = import("./skyexchange/pages/AccountMobile");
  // const ActivityLog = import("./skyexchange/pages/ActivityLog");
  // const CurrentBets = import("./skyexchange/pages/CurrentBets");
  // const AccountStatement = import("./skyexchange/pages/AccountStatement");

  // const Balance = import("./skyexchange/pages/Balance");
  // const Account = import("./skyexchange/pages/Account");
  // const Content = import("./skyexchange/pages/Content");
  // const SignUp = import("./skyexchange/pages/SignUp");

  // const HorseSingle = import("./skyexchange/pages/HorseSingle");
  // const Result = import("./skyexchange/pages/Result");
  // const HorseRacing = import("./skyexchange/pages/HorseRacing");
  // const FinancialMarket = import("./skyexchange/pages/FinancialMarket");


  // const Dashboard   from "./skyexchange/pages/Dashboard";
  // const Inplay = import("./skyexchange/pages/Inplay");
  // const FullMarket = import("./skyexchange/pages/FullMarket");
  // const SportTab = import("./skyexchange/includes/SportTab");
  // const Login = import("./skyexchange/pages/Login");

  // const CasinoBets = import("./skyexchange/includes/CasinoBets");
  // const Bethistory = import("./skyexchange/pages/Bethistory");
  // const CasinoTabs = import("./skyexchange/pages/CasinoTabs");
  // const MultiMarket = import("./skyexchange/pages/MultiMarket");
  // const IndianIPL = import("./skyexchange/pages/IndianIPL");

  // const AccountMobile = import("./skyexchange/pages/AccountMobile");
  // const ActivityLog = import("./skyexchange/pages/ActivityLog");
  // const CurrentBets = import("./skyexchange/pages/CurrentBets");
  // const AccountStatement = import("./skyexchange/pages/AccountStatement");

  // const Balance = import("./skyexchange/pages/Balance");
  // const Account = import("./skyexchange/pages/Account");
  // const Content = import("./skyexchange/pages/Content");
  // const SignUp = import("./skyexchange/pages/SignUp");

  // const HorseSingle = import("./skyexchange/pages/HorseSingle");
  // const Result = import("./skyexchange/pages/Result");
  // const HorseRacing = import("./skyexchange/pages/HorseRacing");
  // const FinancialMarket = import("./skyexchange/pages/FinancialMarket");

  let loggedIn = window.sessionStorage.getItem("loggedIn") == null ? false : true;
  // alert(loggedIn);
  const history = useHistory();
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  function ErrorHandler({ error }) {
    const errorText = `${window.location} has some error please look for it in UI 
    ${error}`;
    postErrorToslack(errorText);
    window.location.href='/';
    
  }
  return (
    <div className="app">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <WebSocketContext.Provider value={socket}>
        <Router basename="/">
          {/* <Suspense fallback={<LoaderSk />}> */}
            <Switch>

              <NoInternetConnection>
                <ErrorBoundary FallbackComponent={ErrorHandler}>
                  <Route exact path="/" component={Dashboard} isLoggedin={false} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/in-play" component={Inplay} />
                  <Route exact path="/multi-market" component={MultiMarket} />
                  <Route exact path="/cricket" component={SportTab} />
                  <Route exact path="/casinotabs" component={CasinoTabs} />
                  <Route exact path="/full-market/:event_id/:inplay" component={FullMarket} />
                  <Route exact path="/indian-premier-league" component={IndianIPL} />
                  <Route exact path="/financial-market" component={FinancialMarket} />
                  <Route exact path="/horse-racing" component={HorseRacing} />
                  <Route exact path="/horse-single" component={HorseSingle} />
                  <Route exact path="/result" component={Result} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/content" component={Content} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/summary" component={Balance} />
                  <Route exact path="/account-statement" component={AccountStatement} />
                  <Route exact path="/current-bets" component={CurrentBets} />
                  <Route exact path="/activity-log" component={ActivityLog} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/profile" component={AccountMobile} />
                  <Route exact path="/casino-bets/:casino_type" component={CasinoBets} />
                  <Route exact path="/bethistory/:event_id/:selection_id/:user_id" component={Bethistory} />

                  <Route exact path="/casino-market" component={CasinoMarket} />


                </ErrorBoundary>
              </NoInternetConnection>

            </Switch>
          {/* </Suspense> */}
        </Router>
      </WebSocketContext.Provider>
    </div>
  );
}

export default App;
