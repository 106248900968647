import React from 'react';

import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'

import background from '../assets/images/bg-purple.png';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const BetSlip = () => {
    return (
        <>
            <Accordion defaultExpanded={true} style={{ marginTop: 10, marginRight: 23 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                    <Typography>Bet Slip</Typography>
                </AccordionSummary>
                <AccordionDetails className="d-none bet-slip-block" display="inline-block" style={{ height: "100%" }}>
                    <Typography className="bet-slip-content">Click on the odds to add selections to the betslip.</Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default BetSlip;