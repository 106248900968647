import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, makeStyles, Tabs, Tab, Box, Typography, LinearProgress } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import Email from '@material-ui/icons/Email';
import Telegram from '@material-ui/icons/Telegram';
import Facebook from '@material-ui/icons/Facebook';

import WhatsApp from '@material-ui/icons/WhatsApp';
import Instagram from '@material-ui/icons/Instagram';
import Clear from '@material-ui/icons/Clear';
import Skype from '../assets/images/skype.svg';

// Import Images
// import LOGO from '../assets/images/lce.webp';
import LOGO from '../assets/images/logo.png';

import Verify from '../assets/images/verifycode.gr';
import Transparent from '../assets/images/transparent.gif';
import BetFair from '../assets/images/betfair_white.png';
import ErrorEx from '../assets/images/error-ex.png';
import axios from 'axios';
import Appconfig from '../config/config';
import { toast } from 'react-toastify';
import { FormControl, Input, FormControlLabel, Checkbox } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Header from "../includes/Header";

import CaptchaTest from '../includes/captcha';
import TermsTemplate from '../pages/TermsTemplate';
import { postErrorToslack } from '../config/common';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
    },
    search: {
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '270px',
        },
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
    searchIcon: {
        padding: theme.spacing(0, .5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        color: '#000000',
        fontSize: '14px !important'
    },
    inputRoot: {
        color: '#000000',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(.5, .5, .5, 0),
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '14px'
    },
    sectionDesktop: {
        display: 'flex',
    },
    navButton: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '2px 16px',
        textTransform: 'none',
        marginLeft: '8px',
        color: '#ffffff'
    },
    navBtnImg: {
        marginLeft: '6px'
    },
    navInput: {
        border: 'none',
        borderRadius: '4px',
        marginLeft: '6px',
        fontSize: '12px',
        paddingLeft: '8px',
        width: '122px',

    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function capthcreturn() {
    return Math.floor(1000 + Math.random() * 9000);
}
const Login = (props) => {
    const classes = useStyles();
    const [captcha, setCaptcha] = React.useState(capthcreturn());
    const [loading, setLoading] = React.useState(false);

    const [value, setValue] = React.useState(0);
    const [modalTitle, setModalTitle] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (title) => {
        setModalTitle(title)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const history = useHistory();
    const [formData, setformData] = useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        validation_checker: ''
    });



    const [validationError, SetValidationError] = useState("");

    function checkValidation() {

        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let errorText;

        document.getElementById("user_name").style.background = "";
        document.getElementById("password").style.background = "";
        document.getElementById("user_captcha_input").style.background = "";
        document.getElementById("erroruserid").style.display = "none";
        document.getElementById("errorpassid").style.display = "none";

        if (formData.user_name == "") {

            document.getElementById("user_name").style.border = "#fbd7d3 solid 2px";
            document.getElementById("erroruserid").style.display = "block";
            errorText = "Username is empty."
            SetValidationError(errorText);
            return false;
        } else if (formData.password == "") {
            document.getElementById("password").style.background = "#fbd7d3";
            document.getElementById("errorpassid").style.display = "block";
            errorText = "Password is empty"
            SetValidationError(errorText);
            return false;
        } else if (formData.validation_checker == "") {
            document.getElementById("user_captcha_input").style.border = "#fbd7d3 solid 2px !important";
            errorText = "Validation code is empty"
            SetValidationError(errorText);
            return false;
        }
        //  else if (format.test(formData.user_name) == true) {

        //     errorText = "Username is only allow a-z and 0-9."
        //     SetValidationError(errorText);
        //     return false;
        // } 
        else if (doSubmit() == false) {
            errorText = "Invalid validation code!"
            SetValidationError(errorText);
            return false;

        } else {
            errorText = ""
            SetValidationError(errorText);
            return true;
        }


        //        return false;

    }

    React.useEffect(() => {
        /* loadCaptchaEnginge(4); */
        // loadCaptchaEnginge(4, 'white', 'black', 'numbers');
    }, [])
    const handleInputChange = (event) => {
        if (event.target.name === 'validation_checker') {
            let tval = event.target.value;
            if (tval.length == 4) {
                document.activeElement.blur();
            }
        }
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const handleSubmit = (event) => {
        event.preventDefault();


        if (checkValidation()) {
            setLoading(true);

            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/userAuthenticate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(formData)
            };
            axios(config)
                .then(function (response) {
                    try {
                        setLoading(false);
                        // console.log('response.data.result',response.data.result);
                        // console.log('response.data.resultData',response.data.resultData);

                        if (response.data.result && response.data.resultData.user_type == "User") {

                            notify(response.data.resultMessage, 'success')
                            storeUserinfo(response.data.resultData);
                        }
                        else {
                            if (response.data.result) {
                                notify("wrong username or password", 'error')
                            }
                            else {
                                notify(response.data.resultMessage, 'error')
                                setCaptcha(capthcreturn());
                            }
                        }
                    } catch (e) {
                        postErrorToslack(e.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    postErrorToslack(error.message);
                });

        }

    }
    // toast.configure({
    //     autoClose: 10000
    //   });
    function notify(message, type) {

        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    function storeUserinfo(userdata) {
        window.sessionStorage.setItem("loggedIn", true);
        window.sessionStorage.setItem("userData", JSON.stringify(userdata));
        history.push('/dashboard');
    }
    function doSubmit() {

        let user_captcha = document.getElementById('validation_code').value;
        if (formData.validation_checker == captcha) {
            return true;
        }
        else {
            return false;
        }
    };
    return (
        <>
            {/* <Header /> */}
            {
                loading ? <LinearProgress color="secondary" /> : null
            }


            <Grid container className="login-section">

                <Link to="/dashboard" className="login-close"><Clear /></Link>
                <Grid item xs={12}>
                    <div className="login-header">
                        <img src={LOGO} />
                        <div>
                            <p>Powered by</p>
                            <img src={BetFair} />
                        </div>
                    </div>
                </Grid>
                <Grid className="form-login-all" item xs={12}>
                    <ValidatorForm

                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="right-login login-form">
                            <div>
                                <input validators={['required']} autoComplete="off"
                                    value={formData.user_name} placeholder="Username" id="user_name" name="user_name" onChange={handleInputChange} />
                                <span className="error-ex-cont"><img id="erroruserid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <div>
                                <input validators={['required']} autoComplete="off"
                                    id="password" type="password" value={formData.password} placeholder="Password" name="password" onChange={handleInputChange} />
                                <span className="error-ex-cont"><img id="errorpassid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <div className="custom-captcha">
                                {/* <CaptchaTest /> */}



                            </div>
                            <div className="validation-input">
                                <input type="number" placeholder="Validation Code"
                                    inputMode='numeric' pattern="[0-9]*" id="user_captcha_input" value={formData.validation_checker} name="validation_checker" maxLength="4" className={classes.navInput} onChange={handleInputChange} />
                                {/* <img alt="skyexchange" src={Verify} /> */}
                                <div className="canvas-captcha">
                                    <span id="validation_code" style={{ fontSize: 25, color: "black", fontWeight: "bold", paddingTop: 0 }}>{captcha}</span>
                                </div>
                                <span className="error-ex-cont"><img id="errorid" src={ErrorEx} className="errow-ex" /></span>
                            </div>
                            <Button type="submit" variant="contained" className="w-100 popup-login-btn">Login</Button>
                            <span style={{ marginTop: 10, color: "#7B1818", padding: 4, fontSize: 13 }}>{validationError} </span>
                        </div>
                    </ValidatorForm>
                    <div className="footer-links">

                        <Link to="#" onClick={() => handleClickOpen('Privacy Policy')}>
                            Privacy Policy
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('Terms and Conditions')}>
                            Terms and Conditions
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('Rules and Regulations')}>
                            Rules and Regulations
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('KYC')}>
                            KYC
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('Responsible Gaming')}>
                            Responsible Gaming
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('About Us')}>
                            About Us
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('Self-exclusion Policy')}>
                            Self-exclusion Policy
                        </Link>

                        <Link to="#" onClick={() => handleClickOpen('Underage Policy')}>
                            Underage Policy
                        </Link>

                    </div>
                    <div className="social login-social">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            {/* <Tab onMouseEnter={() => setValue(0)} label={<Email />} /> */}
                            <Tab label={<WhatsApp />} />

                            {/* <Tab onClick={() => window.open("https://wa.me/919002363070?text,=hi", "_blank")}  label={<WhatsApp />} /> */}
                            <Tab onMouseEnter={() => setValue(1)} label={<Facebook />} />
                            {/* <Tab onMouseEnter={() => setValue(2)} label={<Telegram />} /> */}
                            {/* <Tab onMouseEnter={() => setValue(3)} label={<img alt="skyexchange" src={Skype} />} /> */}
                            {/* <Tab onMouseEnter={() => setValue(4)} label={<Instagram />} /> */}
                        </Tabs>
                        <TabPanel className="tab-description" value={value} index={0}>
                            <Link to="#">
                                info@skyexchangeart.in
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={1}>
                            <Link to="#">
                                info@skyexchangeart.in
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={2}>
                            <Link to="#">
                                www.t.me/officialskyexchangeart.in
                            </Link>
                            <Link to="#">
                                www.t.me/lucky7customersupport
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={3}>
                            <Link to="#">
                            lucky7official
                            </Link>
                        </TabPanel>
                        <TabPanel className="tab-description" value={value} index={4}>
                            <Link to="#">
                                officiallucky7
                            </Link>
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {modalTitle}
                </DialogTitle>
                <DialogContent dividers>

                    <TermsTemplate title={modalTitle} />

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Login;
