import '../assets/css/style.css';
import '../assets/css/Responsive.css';
import React from 'react';
import Header from '../includes/Header';
import LeftSideBar from '../includes/LeftSideBar';
import SportTab from '../includes/SportTab';
import Footer from '../includes/Footer';
import Appconfig from "../config/config";

import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import background from '../assets/images/bg-purple.png';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Grid, makeStyles, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableRow, TableCell, TableHead, TableBody, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

//IMAGES
import KV1 from '../assets/images/KV01.jpg';
import bookmarkwithtime from '../assets/images/bookmarkwithtime.png';
import InPlayImg from '../assets/images/play.svg'
import cricketE from '../assets/images/cricket-e.png';
import PinOff from '../assets/images/pin-off.svg';
import Slide1 from "../assets/images/slide1.jpg";
import Slide2 from "../assets/images/slide2.jpg";
import Carousel from "react-material-ui-carousel";
import axios from 'axios';
import moment from 'moment';

import BookmakerImg from '../assets/images/bookmaker.svg'
import FancyImg from '../assets/images/fancy.svg'
import { useHistory } from 'react-router';
import Loader from '../assets/images/loading40.gif';
import { postErrorToslack } from '../config/common';

function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}


const useStyles = makeStyles((theme) => ({

}));
var items = [
    {
        image: Slide1,
    },
    {
        image: Slide2,
    },
];
function Item(props) {
    return <img alt="skyexchange" src={props.item.image} />;
}

function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";

}


function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";

}

const CasinoTabs = () => {
    const [eventCount, setEventCount] = React.useState({
        "cricket": 0,
        "tennis": 0,
        "soccer": 0,
    });
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const classes = useStyles();
    const rows = [
        // createData('Daring king', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', '1 - 1 ', 'maxime', 'In play', 'link', 237, 9.0, 37, 4.3),
        // createData('king', '3 - 1 ', 'cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring', '2 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        // createData('Forge', '0 - 1 ', 'maxime cressy', 'In play', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),
        // createData('Daring king', 'v', 'maxime cressy', '7:00', 'link', 237, 9.0, 37, 4.3),

        // createData('Northern Knights v Munster Reds', '08 Jun 2021 17:30:0', 'IN-PLAY', 237, 9.0, 37, 4.3),
        // createData('England Women v India Women', '07 Jun 2021 17:30:0', 'GOING IN-PLAY', 262, 16.0, 24, 6.0),
        // createData('Birmingham Bears v Yorkshire', '10 Jun 2021 17:30:0', 'GOING IN-PLAY', 305, 3.7, 67, 4.3),
        // createData('Northamptonshire v Durham', '19 Jun 2021 17:30:0', 'GOING IN-PLAY', 356, 16.0, 49, 3.9),
    ];
    React.useEffect(() => {
        getCricketData()
    }, [])
    const [eventListData, setEventListData] = React.useState([]);
    const history = useHistory();

    function getCricketData() {
        var data = JSON.stringify({
            // user_id: userInfo._id,
            event_type_id: 0,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getDashboardData`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    renderEventData(response.data.resultData);
                    loader_remove();
                } catch (e) {
                    loader_remove();
                    postErrorToslack(e.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    const current = new Date();
    const today = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    const tomorrow = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate() + 1}`;

    const renderEventData = (eventData) => {
        let tempData = [];
        let cricketcount = 0;
        let soccercount = 0;
        let tenniscount = 0;
        eventData.map((Crick, index) => {
            Crick.competitions.map((competition, index) => {

                competition.events.map((event, index) => {
                    if (event.event_type == 2) {
                        if (event.event_type == 2 && event.is_inplay == "True") {
                            tenniscount += 1;
                        }

                        if (event.marketTypes.length) {
                            if (event.marketTypes[0].marketRunners.length) {
                                // console.log('check for market runnner',event.marketTypes[0].marketRunners[0])
                                if (event.marketTypes[0].marketRunners[0].selection_id) {
                                    // console.log('test for empty',event.marketTypes[0].marketRunners.length)
                                    let sport_type = '';
                                    console.log(event);
                                    if (event.event_type == 2) {
                                        sport_type = 'Tennis';
                                    }
                                    // } else if (event.event_type == 2) {
                                    //     sport_type = 'Tennis';

                                    // }
                                    // else if (event.event_type == 1) {
                                    //     sport_type = 'Soccer';
                                    // }

                                    console.log('event.marketTypes[0].marketRunners[0].open_date', event);

                                    let eventDetail = {
                                        sport: sport_type,
                                        event_id: event.event_id,
                                        name: event.event_name,
                                        // time: moment(event.open_date).format("hh:mm"),
                                        time: moment(event.open_date, "MM/DD/YYYY hh:mm:ss A").format("HH:mm"),
                                        date: moment(event.open_date, "MM/DD/YYYY hh:mm:ss A").format("YYYY-MM-DD"),

                                        // date: moment(event.open_date).format("YYYY-MM-DD"),
                                        is_inplay: event.is_inplay == 'True' ? "Inplay" : "Going Inplay",
                                        backFirst: event.marketTypes[0].marketRunners[0].back_1_price,
                                        layFirst: event.marketTypes[0].marketRunners[0].lay_1_price,
                                        backSecond: event.marketTypes[0].marketRunners[1].back_1_price,
                                        laySecond: event.marketTypes[0].marketRunners[1].lay_1_price,
                                        selection1Id: event.marketTypes[0].marketRunners[0].selection_id,
                                        selection2Id: event.marketTypes[0].marketRunners[1].selection_id,
                                        is_fancy: event.is_fancy,
                                        is_bookmaker: event.is_bookmaker,


                                    }

                                    console.log('eventDetail', eventDetail);

                                    tempData.push(eventDetail);
                                }
                            }
                            else {
                                // console.log('its empty',event.marketTypes[0].marketRunners.length)
                            }
                        }

                    }
                    if (event.event_type == 1 && event.is_inplay == "True") {
                        soccercount += 1;
                    }
                    if (event.event_type == 4 && event.is_inplay == "True") {
                        cricketcount += 1;
                    }
                });
            });
        });
        setEventListData(tempData);
        setEventCount({
            "cricket": cricketcount,
            "tennis": tenniscount,
            "soccer": soccercount,
        });
    };


    return (
        <>
            <Header />
            <Grid container style={{ backgroundColor: '#F0ECE1' }}>
                {isLoggedIn ? null :
                    <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                }
                <Grid item lg={2} xs={6} className="xs-none">
                    <LeftSideBar />
                </Grid>
                <Grid item lg={7} xs={12} className="cricket-section">
                    <div className="sportsSlider">
                        <Carousel animation={"slide"} indicatorIconButtonProps={{ style: { display: "none", }, }}>
                            {items.map((item, i) => (
                                <Item key={i} item={item} />
                            ))}
                        </Carousel>
                    </div>
                    <Grid container spacing={2} style={{ padding: '10px' }} className="xs-padding">
                        {/* <Grid item lg={12} className="cricket-banner sportsSlider">
                            {/* <img alt="cricket" src={KV1} /> 


                        </Grid> */}
                        <Grid item lg={12} className="pt-0">
                            <div className="xs-sport-tab tab-sportsname">
                                <SportTab eventCount={eventCount} />
                            </div>
                            <div className="tbl-top-head">Highlights</div>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tbl_head xs-none">
                                        <TableCell colSpan="5"></TableCell>
                                        <TableCell align="right" colSpan="2"></TableCell>
                                        <TableCell align="right" colSpan="2">1</TableCell>
                                        <TableCell align="right" colSpan="2">X</TableCell>
                                        <TableCell align="right" colSpan="2">2</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="cricket_tbl_body">

                                    {isMobile ?
                                        <>
                                            <TableRowLink onClick={() => history.push(`/casino-bets/t20`)} to="#" p={1} className="odd_even_clr" button>

                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none inplay-xs-title">
                                                        <FiberManualRecordIcon className="circle-size v-none circle-colorr" />

                                                        <img src={InPlayImg} alt="skyexchange" />
                                                        <span className="">Inplay</span>
                                                    </div>
                                                    <div className="text_left-in-play">
                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                        <Link to='#' style={{ color: '#2789CE' }}>
                                                            <Box display="contents">
                                                                <span>

                                                                    Teenpatti T20
                                                                </span>

                                                            </Box>
                                                        </Link>
                                                        <span className="in-play xs-none"> Teenpatti T20 <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                        <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                        <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                    </div>

                                                </TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img src={PinOff} /></Link></TableCell>
                                            </TableRowLink>



                                            <TableRowLink onClick={() => history.push(`/casino-bets/ltp`)} to="#" p={1} className="odd_even_clr" button>

                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none inplay-xs-title">
                                                        <FiberManualRecordIcon className="circle-size v-none circle-colorr" />

                                                        <img src={InPlayImg} alt="skyexchange" />
                                                        <span className="">Inplay</span>
                                                    </div>
                                                    <div className="text_left-in-play">
                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                        <Link to='#' style={{ color: '#2789CE' }}>
                                                            <Box display="contents">
                                                                <span>

                                                                    Teenpatti One Day
                                                                </span>

                                                            </Box>
                                                        </Link>
                                                        <span className="in-play xs-none"> Teenpatti T20 <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                        <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                        <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                    </div>

                                                </TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img src={PinOff} /></Link></TableCell>
                                            </TableRowLink>


                                            <TableRowLink onClick={() => history.push(`/casino-bets/aaa`)} to="#" p={1} className="odd_even_clr" button>

                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none inplay-xs-title">
                                                        <FiberManualRecordIcon className="circle-size v-none circle-colorr" />

                                                        <img src={InPlayImg} alt="skyexchange" />
                                                        <span className="">Inplay</span>
                                                    </div>
                                                    <div className="text_left-in-play">
                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                        <Link to='#' style={{ color: '#2789CE' }}>
                                                            <Box display="contents">
                                                                <span>

                                                                    Amar Akbar Anthony
                                                                </span>

                                                            </Box>
                                                        </Link>
                                                        <span className="in-play xs-none"> Teenpatti T20 <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                        <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                        <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                    </div>

                                                </TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img src={PinOff} /></Link></TableCell>
                                            </TableRowLink>

                                            <TableRowLink onClick={() => history.push(`/casino-bets/dt20`)} to="#" p={1} className="odd_even_clr" button>

                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none inplay-xs-title">
                                                        <FiberManualRecordIcon className="circle-size v-none circle-colorr" />

                                                        <img src={InPlayImg} alt="skyexchange" />
                                                        <span className="">Inplay</span>
                                                    </div>
                                                    <div className="text_left-in-play">
                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                        <Link to='#' style={{ color: '#2789CE' }}>
                                                            <Box display="contents">
                                                                <span>

                                                                    Dragon Tiger
                                                                </span>

                                                            </Box>
                                                        </Link>
                                                        <span className="in-play xs-none"> Teenpatti T20 <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                        <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                        <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                    </div>

                                                </TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img src={PinOff} /></Link></TableCell>
                                            </TableRowLink>

                                            <TableRowLink onClick={() => history.push(`/casino-bets/32c`)} to="#" p={1} className="odd_even_clr" button>

                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none inplay-xs-title">
                                                        <FiberManualRecordIcon className="circle-size v-none circle-colorr" />

                                                        <img src={InPlayImg} alt="skyexchange" />
                                                        <span className="">Inplay</span>
                                                    </div>
                                                    <div className="text_left-in-play">
                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                        <Link to='#' style={{ color: '#2789CE' }}>
                                                            <Box display="contents">
                                                                <span>

                                                                    32 Cards
                                                                </span>

                                                            </Box>
                                                        </Link>
                                                        <span className="in-play xs-none"> Teenpatti T20 <img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                        <span className="game-name xs-none"><img src={cricketE} alt="cricket" /></span>
                                                        <span className="in-play xs-none icon-on-right"><img alt="bookMark" src={bookmarkwithtime} /> </span>
                                                    </div>

                                                </TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img src={PinOff} /></Link></TableCell>
                                            </TableRowLink>
                                        </>

                                        :
                                        rows.map((row) => (
                                            <TableRowLink to={row.link} p={1} key={row.name1} className="odd_even_clr" button>
                                                <TableCell className="table_first_row" colSpan="5">
                                                    <div className="md-none tags">
                                                        <img alt="bookMark" src={bookmarkwithtime} />
                                                        <img alt="bookMark" src={bookmarkwithtime} />
                                                        <img alt="bookMark" src={bookmarkwithtime} />
                                                        <span> {row.play} </span>
                                                    </div>
                                                    <Link to="full-market">
                                                        <div className="text_left-in-play">
                                                            {row.play === 'In play' ?
                                                                <FiberManualRecordIcon className="circle-size" /> :
                                                                <FiberManualRecordIcon className="circle-size" style={{ color: '#ced5da' }} />
                                                            }
                                                            <span> {row.name1} </span>
                                                            {row.play === 'In play' ?
                                                                <span className="in-play"> {row.value} </span> :
                                                                <span className="in-play" style={{ color: "#ced5da", fontWeight: 'none' }}> {row.value} </span>
                                                            }
                                                            <span>{row.name2}</span>
                                                            {row.play === 'In play' ?
                                                                <span className="in-play xs-none"> {row.play} </span> :
                                                                <span className="in-play xs-none" style={{ color: '#ced5da' }}> {row.play} </span>
                                                            }
                                                        </div>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="" className="xs-none"><img alt="bookMark" src={bookmarkwithtime} /></TableCell>
                                                <TableCell align="" className="xs-none"><img alt="bookMark" src={bookmarkwithtime} /></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.calories}</span></Link></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.fat}</span></Link></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.carbs}</span></Link></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.protein}</span></Link></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.protein}</span></Link></TableCell>
                                                <TableCell align="right" className="xs-none"><Link to="full-market"><span>{row.protein}</span></Link></TableCell>
                                                <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#"></Link></TableCell>
                                            </TableRowLink>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                            {!isMobile && (
                                <Footer />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} xs={12} className="betSlipGrid xs-none">
                    <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                            <Typography>Bet Slip</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="d-none bet-slip-block" display="inline-block" style={{ height: "100%" }}>
                            <Typography className="bet-slip-content">Click on the odds to add selections to the betslip.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>

    )
}
export default CasinoTabs;

