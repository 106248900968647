// import React from 'react';
import '../assets/css/style.css';
import Header from '../includes/Header';
import BetSlip from '../includes/BetSlip'

import React from 'react';
import PropTypes from 'prop-types';
import {  makeStyles, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody,  AppBar, Tab, Tabs, Box} from '@material-ui/core'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 0,
        marginLeft: 20,
        marginRight: 20,
        height: '100%',
        overflow: 'hidden',
        // width: '75%',
        // maxWidth: "70%",
        backgroundColor: theme.palette.background.paper,
    },
}));

function createData(time,name,home,away) {
    return { time,name,home,away };
}
export default function Result() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const rows = [
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-10-12 11:00','Sydney Sixers SRL T20 v Renegades SRL T20','107/4','10/1'),
    ];
    const yesterday = [
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-08-12 11:00','Sydney Sixers SRL T20 v Melbourne Renegades SRL T20','107/2','105/10'),
        createData('2021-10-12 11:00','Sydney Sixers SRL T20 v Renegades SRL T20','107/4','10/1'),
    ];

    const root = classes.root + " inplay-parent";

    return (
        <>
            <Header />
            <div className={root}>
                <Grid container spacing={1} style={{ backgroundColor: '#F0ECE1' }}>
                    <Grid item lg={9} xs={12} className="mini-games-grid">
                        <AppBar position="static" className="tab">
                            <Tabs value={value} variant="fullWidth" onChange={handleChange}>
                                <Tab label="Today" {...a11yProps(0)} />
                                <Tab label="Yesterday" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} className="result-table">
                            <Box fontWeight="fontWeightBold" fontSize={14} className="result-box">
                                <Typography variant="span">Result</Typography>
                            </Box>
                            <Table className={`result-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Event Date/Time</TableCell>
                                        <TableCell align="left">Event Name</TableCell>
                                        <TableCell align="left">Home</TableCell>
                                        <TableCell align="right">Away</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {rows.map((row) => (
                                        <TableRow key={row.time}>
                                            <TableCell component="th" scope="row">{row.time}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left"><strong>{row.home}</strong></TableCell>
                                            <TableCell align="right"><strong>{row.away}</strong></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel value={value} index={1} className="result-table">
                            <Box fontWeight="fontWeightBold" fontSize={14} className="result-box">
                                <Typography variant="span">Result</Typography>
                            </Box>
                            <Table className={`result-table ${classes.table}`}>
                                <TableHead className="table_head">
                                    <TableRow>
                                        <TableCell>Event Date/Time</TableCell>
                                        <TableCell align="left">Event Name</TableCell>
                                        <TableCell align="left">Home</TableCell>
                                        <TableCell align="right">Away</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table_body">
                                    {yesterday.map((row) => (
                                        <TableRow key={row.time}>
                                            <TableCell component="th" scope="row">{row.time}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left"><strong>{row.home}</strong></TableCell>
                                            <TableCell align="right"><strong>{row.away}</strong></TableCell>
                                        </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    </Grid>

                    <Grid item lg={3}   className="betSlipGrid xs-none">
                        <BetSlip />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
