import React from 'react'
import '../assets/css/style.css'
import '../assets/css/Responsive.css'
import { v4 as uuidv4 } from 'uuid';


import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Slide, InputBase, Dialog, IconButton, Button, BottomNavigation, BottomNavigationAction, Modal, Backdrop, Fade, Grid, Menu, MenuItem, List, ListItem, ListItemText, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import Marquee from "react-fast-marquee";

import SearchIcon from '@material-ui/icons/Search';
import Person from '@material-ui/icons/Person';
import Setting from '@material-ui/icons/Settings';
import Replay from '@material-ui/icons/Replay';
import CheckBox from '@material-ui/icons/CheckBoxOutlineBlank';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Edit from '@material-ui/icons/Edit';
import Clear from '@material-ui/icons/Clear';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';



import { BrowserView, MobileView, isMobile } from "react-device-detect";

// Import Images
// import LOGO from '../assets/images/lce.webp';
import LOGO from '../assets/images/logo.png';

import Login from '../assets/images/download.svg';
import Verify from '../assets/images/verifycode.gr';
import Live from '../assets/images/live.svg';
import Account from '../assets/images/account.svg';
import Multi from '../assets/images/multi.svg';
import HomeIcon from '../assets/images/home.svg';
import Clock from '../assets/images/clock.svg';
import Sport from '../assets/images/sport-trophy.svg';
import LoginBg from '../assets/images/bg-login.jpg';
import Transparent from '../assets/images/transparent.gif';
import Mic from '../assets/images/mic.svg';
import Appconfig from "../config/config";
import axios from "axios";
import { postErrorToslack } from '../config/common';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
        width: "inherit"
    },
    search: {
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '270px',
        },
        backgroundColor: '#ffffff',
        borderRadius: '6px'
    },
    searchIcon: {
        padding: theme.spacing(0, .5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        color: '#000000',
        fontSize: '14px !important'
    },
    inputRoot: {
        color: '#000000',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(.5, .5, .5, 0),
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        fontSize: '14px'
    },
    sectionDesktop: {
        display: 'flex',
    },
    navButton: {
        fontSize: '12px',
        fontWeight: '600',
        padding: '2px 16px',
        textTransform: 'none',
        marginLeft: '8px',
        color: '#ffffff'
    },
    navBtnImg: {
        marginLeft: '6px'
    },
    navInput: {
        border: 'none',
        borderRadius: '4px',
        marginLeft: '6px',
        fontSize: '12px',
        paddingLeft: '8px',
        width: '122px',

    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionSetting = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}
function createData(name1, value, name2, play, link, calories, fat, carbs, protein) {
    return { name1, value, name2, play, link, calories, fat, carbs, protein };
}
const HeaderDesktop = () => {


    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const history = useHistory();
    if (!isLoggedIn) {
        // window.location.href = "/login";

        history.push('/login');
    }


    const [betopen, betsetOpen] = React.useState(false);

    const handleBetClickOpen = () => {
        betsetOpen(true);
    };

    const handleBetClose = () => {
        betsetOpen(false);
    };
    const [settingXSOpen, settingsetOpen] = React.useState(false);

    const handleSettingClickOpen = () => {
        settingsetOpen(true);
    };

    const handleSettingClose = () => {
        settingsetOpen(false);
    };

    const classes = useStyles();
    const lastSegment = window.location.pathname;
    let segValue = 2;
    let menuName = "";
    if (lastSegment === '/cricket' || lastSegment === '/soccer' || lastSegment === '/tennis') {
        segValue = 0;
    } else if (lastSegment === '/in-play') {
        segValue = 1;
        menuName = 'in-play';
    } else if (lastSegment === '/multi-market') {
        segValue = 3;
        menuName = 'multi-market';
    }

    if (lastSegment === '/cricket') {
        menuName = 'cricket';
    } else if (lastSegment === '/soccer') {
        menuName = 'soccer';
    } else if (lastSegment === '/tennis') {
        menuName = 'tennis';
    } else if (lastSegment === '/indian-premier-league') {
        menuName = 'indian-premier-league';
    } else if (lastSegment === '/financial-market') {
        menuName = 'financial-market';
    } else if (lastSegment === '/horse-racing') {
        menuName = 'horse-racing';
    } else if (lastSegment === '/result') {
        menuName = 'result';
    }

    const [value, setValue] = React.useState(segValue);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [login, setLogin] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleOpen = () => {
        return false;
        setOpen(true);
    };

    const handleOpen1 = () => {
        setOpen1(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const menuClose = () => {
        setAnchorEl(null);
    };

    const menuClose2 = () => {
        setAnchorEl2(null);
    };
    const handleInputChange = (event) => {
        let index = event.target.getAttribute('data-id');
        console.clear();
        console.log('index', index)
        // const newChipList = chipList.map((chip, index) => {

        //     if(index == tempIndex)
        //     {
        //         console.log('chipList1',chip);
        //         chipList[index]["chip_value"] =  parseFloat(event.target.value)

        //     }
        // });
        console.log('before', chipList[index])

        chipList[index]["chip_value"] = parseFloat(event.target.value == '' ? 0 : event.target.value);
        console.log('after', chipList[index])


        setchipList([
            ...chipList,
        ]);
        console.log('chipList', chipList);
    }
    const logout = () => {
        sessionStorage.clear();
        sessionStorage.setItem("loggedIn", false);
        history.push("/");
    };

    const menuClose3 = () => {
        setAnchorEl3(null);
    };

    const accountClick = (event) => {
        setAnchorEl2(event.currentTarget);
    }

    const settingOpen = (event) => {
        setAnchorEl3(event.currentTarget);
    }

    const navInput = classes.navInput + " ";
    const searchClass = classes.search + " ";
    const loginBtn = classes.navButton + " red-btn";
    const signupBtn = classes.navButton + " dark-btn";

    const bottomPage = (urlSegment) => {
        history.push(`/${urlSegment}`);
    }
    const openAccount = (URL, search = '') => {
        if (URL === 'current-bets') {
            history.push({
                pathname: '/' + URL,
                search: search,
            });
        } else {
            history.push(`/${URL}`);
        }
    }
    const rows = [
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
        createData('Daring king', '(1) 4 -2 (1)', 'maxime cressy', 'In-Play', 'link', 237, 9.0, 37, 4.3),
    ];
    const displayType = login && isMobile ? 'inline-block' : 'flex';
    const [MainBalance, setMainBalance] = React.useState(0);
    const [exposure, setExposure] = React.useState(0);
    const [chipList, setchipList] = React.useState([]);

    React.useEffect(() => {
        getChips();
        getBalance();
    }, []);
    let pthh = MainBalance - Math.abs(exposure);


    let expocheck = Math.sign(exposure);

    function displayStakeForm() {
        document.getElementById("open-stakeform").style.display = "flex";
        document.getElementById("hide-stakebuttons").style.display = "none";
    }
    function hideStakeForm() {
        document.getElementById("hide-stakebuttons").style.display = "flex";
        document.getElementById("open-stakeform").style.display = "none";
    }


    function updateChips(chipList) {



        let newdata = [];

        for (var i = 0; i < chipList.length; i++) {
            newdata[i] = { "_id": chipList[i]._id, "chip_value": document.getElementById('stakeEdit_8' + chipList[i]._id).value };
        }


        var data = JSON.stringify({
            "data": newdata
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/updateMultipleChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.ok) {
                        hideStakeForm();
                        getChips();
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }


    function getBalance() {
        var data = JSON.stringify({
            user_id: userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result == 0) {
                    } else {
                        console.log(response.data.resultData);
                        setExposure(response.data.resultData.exposure);
                        setMainBalance(response.data.resultData.balance);
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }


    function getChips() {


        var data = JSON.stringify({
            "user_id": userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    setchipList(response.data);
                    let tmpChipList = response.data;
                    const newChipList = tmpChipList.map((chipList, index) => {
                        tmpChipList[index]["id"] = index + 1;
                    });
                    // loader_remove();

                    setchipList(tmpChipList);
                } catch (e) {
                    postErrorToslack(e.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    return (
        <>
            <div className="header">
                {userInfo && userInfo.hasOwnProperty("user_name") ?
                    <meta name="viewport" content="width=1300" /> : null}
                <AppBar position="static">
                    <Toolbar style={{ 'display': 'flex', justifyContent: 'space-between' }}>
                        <div style={{ 'display': 'flex', alignItems: 'center', width: "44%" }}>
                            <Typography className={classes.title} variant="h6">
                                <Link to="/">
                                    <img alt="skyexchange" className="logo" src={LOGO} />
                                </Link>
                            </Typography>

                            <div className={searchClass}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon className="searchInput" />
                                </div>
                                <InputBase
                                    placeholder="Search Events"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                        </div>
                        {login ?
                            <div style={{ 'display': 'flex', alignItems: 'center' }}>
                                <div className="main-balance-btn">
                                    <Button aria-controls="simple-menu" className="expo-btn" aria-haspopup="true" onClick={handleClick}>
                                        Main <span className="">Balance</span> <strong>INR {pthh.toFixed(2)}</strong> <span className="md-none"><br /></span> Exposure
                                        {
                                            expocheck == '-1' ? <strong className="negative-desktop-exposure">({Math.abs(parseFloat(exposure).toFixed(2))})</strong> : <strong className="positive-desktop-exposure">({Math.abs(parseFloat(exposure).toFixed(2))})</strong>
                                        }
                                        {/* <strong>{exposure.toFixed(2)}</strong> */}
                                        <span className="num">+4</span>
                                    </Button>
                                    <Button aria-controls="simple-menu" className="refresh-btn" aria-haspopup="true">
                                        <Replay />
                                    </Button>
                                </div>
                                <div className="account-btn">
                                    <Button aria-controls="account-menu" aria-haspopup="true" onClick={accountClick}>
                                        <Person /> My Account <ArrowDropDown />
                                    </Button>
                                    <Menu
                                        id="account-menu"
                                        anchorEl={anchorEl2}
                                        keepMounted
                                        open={Boolean(anchorEl2)}
                                        onClose={menuClose2}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                        }}
                                    >
                                        <MenuItem className="top-menu-account" onClick={menuClose2}><span>{userInfo && userInfo.hasOwnProperty("user_name") ? userInfo.user_name : ''}</span><span className="b-left">GMT +5:30</span></MenuItem>
                                        <MenuItem onClick={() => openAccount('account')}><Link to="/account">My Profile</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('summary')}><Link to="/summary">Balance Overview</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('account-statement')}><Link to="/account-statement">Account Statement</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('current-bets', 'current')}><Link to="/">My Bets</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('current-bets', 'history')}><Link to="/">Bets History</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('current-bets', 'PandL')}><Link to="/">Profit & Loss</Link></MenuItem>
                                        <MenuItem onClick={() => openAccount('activity-log')}><Link to="/activity-log">Activity Log</Link></MenuItem>

                                        <Button onClick={logout} variant="contained" className="logout-dark-btn">Logout <ExitToApp /></Button>
                                    </Menu>
                                </div>
                            </div>
                            :
                            <div className={classes.sectionDesktop}>
                                <Person className="theme-yellow " />
                                <input type="password" placeholder="Username" className={navInput} />
                                <input type="password" placeholder="Password" className={navInput} />
                                <div className="validation-input">
                                    <input type="text" placeholder="Validation" maxLength="4" className={navInput} />
                                    <img alt="skyexchange" src={Verify} />
                                </div>
                                <Button variant="contained" color="secondary" className={loginBtn}>
                                    Login <img alt="skyexchange" src={Login} className={classes.navBtnImg} />
                                </Button>
                                <Link to="/signup">
                                    <Button variant="contained" className={signupBtn}>
                                        Sign up
                                    </Button>
                                </Link>
                            </div>
                        }
                    </Toolbar>
                </AppBar>
            </div>
            <div className="navbar">
                <AppBar position="static">
                    <Toolbar variant="dense" className="navMenu">
                        <Typography className={menuName === "" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/">
                                Home
                            </Link>
                        </Typography>
                        <Typography className={menuName === "in-play" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/in-play">
                                In-Play
                            </Link>
                        </Typography>
                        <Typography className={menuName === "multi-market" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/in-play">
                                Multi Markets
                            </Link>
                        </Typography>
                        <Typography className={menuName === "cricket" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/in-play">
                                Cricket
                                <span className="live-number">
                                    <strong><img alt="skyexchange" src={Live} /></strong> 0
                                </span>
                            </Link>
                        </Typography>
                        <Typography className={menuName === "soccer" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/in-play">
                                Soccer
                                <span className="live-number">
                                    <strong><img alt="skyexchange" src={Live} /></strong> 3
                                </span>
                            </Link>
                        </Typography>
                        <Typography className={menuName === "tennis" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/in-play">
                                Tennis
                                <span className="live-number">
                                    <strong><img alt="skyexchange" src={Live} /></strong> 12
                                </span>
                            </Link>
                        </Typography>
                        {/* <Typography className={menuName === "indian-premier-league" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/indian-premier-league">
                                IPL Winner
                            </Link>
                        </Typography> */}
                        {/* <Typography className={menuName === "horse-racing" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/horse-racing">
                                Horse Racing
                            </Link>
                        </Typography>
                        <Typography className={menuName === "horse-racing" ? 'active dark-menu' : 'dark-menu'} variant="p" color="inherit">
                            <Link to="/horse-racing">
                                Casino
                            </Link>
                        </Typography> */}
                        {/* <Typography className={menuName === "result" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/result">
                                Result
                            </Link>
                        </Typography> */}
                        {/* <Typography className={menuName === "financial-market" ? 'active' : null} variant="p" color="inherit">
                            <Link to="/financial-market">
                                Binary
                            </Link>
                        </Typography> */}
                        <div className="margin-left-auto nav-right">
                            <Typography variant="p" color="inherit" className="time-zone">
                                Time Zone : <strong>GMT +5:30</strong>
                            </Typography>
                            <Typography variant="p" color="inherit" className="one-click-bet">
                                <Link to="#" onClick={handleOpen}>
                                    <CheckBox /> One Click Bet
                                </Link>
                            </Typography>
                            <Typography variant="p" color="inherit">
                                <Link onClick={settingOpen} to="#">
                                    Setting <Setting />
                                </Link>
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="login-modal">
                        <Grid container>
                            <Grid item xs={6}>
                                <img className="login-modal-img" src={LoginBg} />
                            </Grid>
                            <Grid item xs={6}>
                                <div className="right-login">
                                    <h3>Please login to continue</h3>
                                    <div>
                                        <input placeholder="Username" />
                                    </div>
                                    <div>
                                        <input placeholder="Password" type="password" />
                                    </div>
                                    <div className="validation-input">
                                        <input type="text" placeholder="Validation Code" maxLength="4" className={classes.navInput} />
                                        <img alt="skyexchange" src={Verify} />
                                    </div>
                                    <Button variant="contained" className="w-100 popup-login-btn">Login<img alt="skyexchange" src={Transparent} className={classes.navBtnImg} /></Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open1}
                onClose={handleClose1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open1}>
                    <div className={`news-modal`}>
                        <div className="news-head">
                            <h5>Announcement</h5>
                            <Link onClick={handleClose1} to="#">
                                <Clear />
                            </Link>
                        </div>
                        <div className="news-body">
                            <div className="news-block">
                                <div className="news-date">
                                    <span>10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                            <div className="news-block">
                                <div className="news-date">
                                    <span>10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                            <div className="news-block">
                                <div className="news-date">
                                    <span>10</span>
                                    <span className="inner-date">
                                        <span>Sep</span>
                                        <span>2021</span>
                                    </span>
                                </div>
                                <p>Event :- St Lucia Kings v Jamaica Tallawahs .... Market :- JT 6 Over Runs 75-76 (IST 04:51:37 - 04:51:44) ; JT 10 Over Runs 102-104 (IST 04:51:35 - 04:51:52) ; JT 20 Over Runs 191-193 (IST 04:51:35 - 04:51:38) , 192-194 (IST 04:51:39 - 04:51:46) Bets Voided Because of "Wrong Commentary" .....Sorry for the Inconvenience Caused</p>
                            </div>
                        </div>
                        <div className="news-footer">
                            <ul className="pages">
                                <li id="prev">
                                    <a href="javascript:void(0);" className="ui-link disable">Prev</a>
                                </li>
                                <li id="pageNumber">
                                    <a href="javascript:void(0);" className="ui-link select">1</a>
                                </li>
                                <li id="next">
                                    <a href="javascript:void(0);" className="ui-link disable">Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Fade>
            </Modal>

            <Menu
                id="setting-list"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={menuClose3}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
            >
                <ListItem className="default-setting setting-first-list">
                    <ListItemText primary="Default stake" secondary={
                        <>
                            <input value="950" />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting setting-stack">
                    <ListItemText primary="Default stake" secondary={
                        <>
                            <Grid container spacing={2} id="hide-stakebuttons">
                                <Grid item md={9}>
                                    <Grid container spacing={2} >

                                        {chipList.map((row) => (
                                            <Grid item md={3} xs={3} >
                                                <label>
                                                    {/* <input type="checkbox" /> */}
                                                    <a>{row.chip_value}</a>
                                                </label>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item md={3}>
                                    <a className="edit-settings" onClick={displayStakeForm}>Edit <Edit /></a>
                                </Grid>
                            </Grid>
                            <div className="grid-text-boxes" id="open-stakeform">
                                <dl id="editCustomizeStakeList" className="setting-block stake-custom-edit stake-setting">
                                    <dt>Quick Stakes</dt>

                                    {chipList.map((row, index) => (
                                        <dd ><div className="setting-first-list">
                                            <input id={`stakeEdit_8${row._id}`} type="text" onChange={handleInputChange} pattern="[0-9]*" data-id={index} value={row.chip_value} /></div>
                                        </dd>
                                    ))}
                                    <dd className="col-stake_edit"><Link id="ok" onClick={() => updateChips(chipList)} className="btn-send ui-link" to="#">OK</Link></dd>
                                </dl>
                            </div>
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting odd-list">
                    <ListItemText primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Highlight when odds change"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting odd-list">
                    <ListItemText primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Odds"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting odd-list">
                    <ListItemText primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Odds"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting odd-list">
                    <ListItemText primary="Odds" secondary={
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Accept Any Price"
                            />
                        </>
                    } />
                </ListItem>
                <Divider />
                <ListItem className="default-setting setting-button">
                    <Button className="close-btn" variant="contained" >
                        Cancel
                    </Button>
                    <Button className="close-btn save-btn" variant="contained">
                        Save
                    </Button>
                </ListItem>
                <Divider />
            </Menu>
            <Dialog fullScreen open={betopen} onClose={handleBetClose} TransitionComponent={Transition} className="bet-popup">
                <AppBar className='header'>
                    <Box className="xs-bets-popup" display="flex" justifyContent="space-between">
                        <Box className="header-name">
                            <img src={Transparent} alt="bets" />
                            Open Bets
                        </Box>
                        <Box>
                            <IconButton edge="start" color="inherit" onClick={handleBetClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </AppBar>
                <List className="bet-body-popup">
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody className="tbl_body">
                            {rows.map((row) => (
                                <TableRowLink to={row.link} p={1} className="odd_even_clr" button>
                                    <TableCell className="table_first_row" colSpan="5">
                                        <div className="text_left-in-play">
                                            <FiberManualRecordIcon className="circle-size" />
                                            <Link to='/full-market' style={{ color: '#2789CE' }}>
                                                <Box display="contents">
                                                    <span> {row.name1} </span>
                                                    <span className="in-play"> v </span>
                                                    <span>{row.name2}</span>
                                                </Box>
                                            </Link>
                                        </div>
                                    </TableCell>
                                    <TableCell className="arrow-icon" style={{ width: "30px" }}>
                                        <Link to="#" className="inplay-last-icon"></Link>
                                    </TableCell>
                                </TableRowLink>
                            ))}
                        </TableBody>
                    </Table>
                </List>
            </Dialog>

            <Dialog fullScreen open={settingXSOpen} onClose={handleSettingClose} TransitionComponent={TransitionSetting} className="set-popup">
                <AppBar className='header'>
                    <Box className="xs-setting-popup" display="flex" justifyContent="space-between">
                        <Box className="header-name">
                            <Setting />Setting
                        </Box>
                        <Box>
                            <IconButton edge="start" color="inherit" onClick={handleSettingClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </AppBar>
                <List className="popup-setting-body">
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Stack</Typography>
                    </Box>
                    <ListItem className="default-setting setting-first-list">
                        <ListItemText primary="Default stake" secondary={
                            <>
                                <input type="number" className="userCoin" value="915" />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem className="default-setting setting-stack">
                        <ListItemText primary="Quick stakes" secondary={
                            <>
                                <Grid container spaceing={2}>
                                    <Grid item md={9} xs={12}>
                                        <Grid container spaceing={2}>
                                            <Grid item md={3} xs={3}>
                                                <a>10</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>30</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>185</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>190</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>250</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>300</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a className="select">1350</a>
                                            </Grid>
                                            <Grid item md={3} xs={3}>
                                                <a>1500</a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <Link to="#" className="edit-settings">Edit Stakes<Edit /></Link>
                                    </Grid>
                                </Grid>
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Odds</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedA}
                                            onChange={handleChange}
                                            name="checkedA"
                                            color="primary"
                                        />
                                    }
                                    label="Highlight when odds change"
                                />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">FancyBet</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Accept Any Odds"
                                />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Sportsbook</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Accept Any Odds"
                                />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <Box fontWeight="fontWeightBold" fontSize={14} className="header-set">
                        <Typography variant="span">Binary</Typography>
                    </Box>
                    <ListItem className="default-setting odd-list">
                        <ListItemText primary="" secondary={
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Accept Any Price"
                                />
                            </>
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem className="default-setting setting-button">
                        <Button className="close-btn" variant="contained" >
                            Cancel
                        </Button>
                        <Button className="close-btn save-btn" variant="contained">
                            Save
                        </Button>
                    </ListItem>
                </List>
            </Dialog>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={menuClose}
            >
                <List className={classes.root}>
                    <ListItem className="main-balance balance-list">
                        <ListItemText primary="Main Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">{pthh.toFixed(2)}</span>
                                <Divider />
                                <div className="main-footer">
                                    <span>Exposure</span>
                                    <span> {

                                        expocheck == '-1' ? <strong className="negative-exposure-popup">({Math.abs(parseFloat(exposure).toFixed(2))})</strong> : <strong className="positive-exposure-popup">({Math.abs(parseFloat(exposure).toFixed(2))})</strong>

                                    }</span>
                                </div>
                            </>
                        } />
                        <Divider />
                    </ListItem>
                </List>
                <List className={classes.root}>
                    <ListItem className="balance-list">
                        <ListItemText primary="SABA Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem className="balance-list">
                        <ListItemText primary="Casino Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem className="balance-list">
                        <ListItemText primary="BPoker Balance" secondary={
                            <>
                                <span className="pth">INR</span>
                                <span className="points">0</span>
                            </>
                        } />
                        <Button className="recall-btn">Recall</Button>
                    </ListItem>
                    <ListItem className="balance-list">
                        <ListItemText primary="&nbsp;" secondary={
                            <>
                                <span className="points"></span>
                            </>
                        } />
                        <Button className="recall-btn">Recall All</Button>
                    </ListItem>
                </List>
                <Button variant="contained" onClick={menuClose} className="close-btn">Close</Button>
            </Menu>
        </>
    )
}

export default HeaderDesktop;
