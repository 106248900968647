import axios from "axios";
export async function postErrorToslack(data) {
    let postdata = {
        text: data
    };
    try {
        // axios.post(`https://hooks.slack.com/services/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`, postdata, {
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //     }
        // });
    } catch (e) {
        // console.log("msg sent", e.response ? e.response.body : e);
    }
}