import '../assets/css/style.css';
import React from 'react';
import Header from '../includes/Header';
import LeftSideBar from '../includes/LeftSideBar';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import MicNoneIcon from '@material-ui/icons/MicNone';
import background from '../assets/images/bg-purple.png';
import { Box,Grid,AppBar,Tabs,Tab,Paper,ListItem, Divider, List, makeStyles, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import { Link } from 'react-router-dom';

//IMAGES
import KV1 from '../assets/images/horse.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createData(name,...time) {
    return { name,time };
}
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles((theme) => ({

}));
const HorseRacing = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();
    const rows = [
        createData('Daring king', "12:00" , "20:15","23:15"),
        createData('Jong', "12:00" , "18:30" , "17:45" , "20:15"),
        createData('Roy', "12:00" , "20:15"),
        createData('Daring ', "12:00" , "20:15"),
        createData('King ', "12:00" , "20:15"),
    ];
    return (
        <>
            <Header />
            <Grid container  style={{ backgroundColor: '#F0ECE1' }}>
                <Grid item lg={2} xs={6} >
                    <LeftSideBar />
                </Grid>
                <Grid item lg={7} xs={6} className="horse-section">
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                        <Grid item lg={12} className="cricket-banner">
                            <div className="horse-tbl-top">
                                <div>
                                    <MicNoneIcon/>
                                    <span style={{verticalAlign:'top'}}>News</span>
                                </div>
                            </div>
                            <img alt="horse" src={KV1} />
                        </Grid>
                        <Grid item lg={12}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow className="tbl_head">
                                        <TableCell colSpan="1">
                                            <Link style={{width:"300px",height:"10px"}} className="header-pin">
                                            </Link>
                                            <span style={{fontSize:"12px",padding:'6px'}}>Next Horse Race</span> 
                                            <span className="header-one-link"><Link to="#">18:40 Catterick3rd</Link> 6f Hcap</span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell align="center" className="horse-table-space">
                                        <div className="game-info">
                                            <div className="upcomeHeader">Comming Up</div>
                                        </div>
                                        <div className="header-link">
                                            <Link  to="#">19:05 ChelmsfordCity</Link>
                                            <Link  to="#">19:05 Deauville(FRA)</Link>
                                            <Link  to="#">19:05 ChelmsfordCity</Link>
                                            <Link  to="#">19:05 Deauville(FRA)</Link>
                                        </div>
                                    </TableCell>
                                </TableBody>
                            </Table>
                            <AppBar position="static" className="tab">
                                <Tabs value={value} variant="fullWidth" onChange={handleChange}>
                                    <Tab label="Today" {...a11yProps(0)} />
                                    <Tab label="Tomorrow" {...a11yProps(1)} />
                                    <Tab label="Day After" {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel className="horse-tabs" value={value} index={0}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                        <Typography>ZA</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Paper style={{ width: "100%"}}>
                                            <List component="p" className={classes.listStyle}>
                                                {console.log(rows)}
                                                {rows.map((item) => (
                                                    <div>
                                                        <ListItemLink href={item.link} p={1} >
                                                            <Box display="inline-block">
                                                                <Typography className="horse-link">
                                                                    <span>{item.name}</span>
                                                                </Typography>
                                                                <Box display="flex">
                                                                    {item.time.map((t) => (
                                                                        <Box marginLeft={1} className="horse-link">
                                                                            <Link to="#">{t}</Link>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                ))}
                                            </List>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            </TabPanel>
                            <TabPanel className="horse-tabs" value={value} index={1}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                        <Typography>ZA</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Paper style={{ width: "100%"}}>
                                            <List component="p" className={classes.listStyle}>
                                                {console.log(rows)}
                                                {rows.map((item) => (
                                                    <div>
                                                        <ListItemLink href={item.link} p={1} >
                                                            <Box display="inline-block">
                                                                <Typography className="horse-link">
                                                                    <span>{item.name}</span>
                                                                </Typography>
                                                                <Box display="flex">
                                                                    {item.time.map((t) => (
                                                                        <Box marginLeft={1} className="horse-link">
                                                                            <Link to="#">{t}</Link>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                ))}
                                            </List>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            </TabPanel>
                            <TabPanel className="horse-tabs" value={value} index={2}>
                                <Accordion defaultExpanded={false} className="margin-table-bottom">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                        <Typography>ZA</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Paper style={{ width: "100%"}}>
                                            <List component="p" className={classes.listStyle}>
                                                {console.log(rows)}
                                                {rows.map((item) => (
                                                    <div>
                                                        <ListItemLink href={item.link} p={1} >
                                                            <Box display="inline-block">
                                                                <Typography className="horse-link">
                                                                    <span>{item.name}</span>
                                                                </Typography>
                                                                <Box display="flex">
                                                                    {item.time.map((t) => (
                                                                        <Box marginLeft={1} className="horse-link">
                                                                            <Link to="#">{t}</Link>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                ))}
                                            </List>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                                        <Typography>ZA</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="d-none p-0" display="inline-block" style={{ height: "100%" }}>
                                        <Paper style={{ width: "100%"}}>
                                            <List component="p" className={classes.listStyle}>
                                                {console.log(rows)}
                                                {rows.map((item) => (
                                                    <div>
                                                        <ListItemLink href={item.link} p={1} >
                                                            <Box display="inline-block">
                                                                <Typography className="horse-link">
                                                                    <span>{item.name}</span>
                                                                </Typography>
                                                                <Box display="flex">
                                                                    {item.time.map((t) => (
                                                                        <Box marginLeft={1} className="horse-link">
                                                                            <Link to="#">{t}</Link>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </ListItemLink>
                                                        <Divider />
                                                    </div>
                                                ))}
                                            </List>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} xs={12}  className="betSlipGrid">
                    <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                            <Typography>Bet Slip</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="d-none bet-slip-block" display="inline-block" style={{ height: "100%" }}>
                            <Typography className="bet-slip-content">Click on the odds to add selections to the betslip.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>

    )
}
export default HorseRacing;

