import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Box,Link} from '@material-ui/core';

import betbarterLogo from '../assets/images/betbarter-logo.png';
import BetHiveLogo from '../assets/images/BetHive-logo.png';
import sky247Logo from '../assets/images/sky247-logo-black.png';
import playerFootball from '../assets/images/player-football.webp';
import playerCricket from '../assets/images/player-cricket.webp';



export default function Content() {

  return (
    <>
      {/* <Header/> */}
    <Box className="content">
        <Box className="announce-header">
            <Typography variant="h1">
                General Terms and Conditions-Exchange
            </Typography>
        </Box>
        <Box className="tc-element">
        
        </Box>
    </Box>
    </>
  );
}
