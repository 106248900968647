import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItem, Tabs, Button } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, AppBar, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Appconfig from "../config/config";
import axios from "axios";
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import Header from '../includes/HeaderDesktop';
import AccountSidebar from '../includes/AccountSidebar';
import { postErrorToslack } from '../config/common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 2,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '230px',
        marginLeft: 'auto',
        background: '#ffffff'
    },
    table: {
        minWidth: 650,
    },
}));

function createData(name, value) {
    return { name, value };
}
function activity(time, status, ip, isp, city, type) {
    return { time, status, ip, isp, city, type };
}

const rows = [
    createData('First Name', 'Sumit'),
    createData('Last Name', '--'),
    createData('Birthday', '--'),
    createData('Email', 'test@gmail.com'),
    createData('Password', '***************************'),
];
const address = [
    createData('Address', '--'),
    createData('Town/City', '--'),
    createData('Country', '--'),
    createData('Country/State', '--'),
    createData('Postcode', '--'),
    createData('Timezone', 'IST'),
];
const activity_log = [
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
    activity('2021-08-11 15:57:28', 'Login Success', '157.38.232.170', 'nfocomm Limited', 'erabad, Telangan', 'Browser (mobile)'),
];

export default function VerticalTabs() {
    const lastSegment = window.location.pathname;
    let segment = 0
    if (lastSegment === '/account-statement') {
        segment = 2;
    }
    const classes = useStyles();
    const [value, setValue] = React.useState(segment);
    const [valuex, setxValue] = React.useState(0);
    const [valuen, setnValue] = React.useState(0);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [accountList, SetAccountList] = React.useState([]);
    const handlexChange = (event, newValue) => {
        setxValue(newValue);
    };
    const handlenChange = (event, newValue) => {
        setnValue(newValue);
    };

    const gridType = isMobile ? 10 : 8;
    // const [formData, setFormData] = React.useState([]);

    function getAccount(type) {
        var data = JSON.stringify({

            user_id: userInfo && userInfo.hasOwnProperty("_id") ? userInfo._id : '',
            game_type: "All",
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/accountStatement`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    SetAccountList(response.data.resultData);
                    let tmpAccountList = response.data.resultData;
                    const newChipList = tmpAccountList.map((accountList, index) => {
                        tmpAccountList[index]["id"] = index + 1;
                    });
                    SetAccountList(tmpAccountList);
                    console.log(JSON.stringify(response.data));
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    React.useEffect(() => {
        getAccount();
    }, []);

    return (
        <>
            {/* <meta name="viewport" content="width=1300" /> */}
            <Header />
            <Grid className="account-stat" container style={{ backgroundColor: '#F0ECE1' }}>
                <AccountSidebar />
                <Grid item lg={gridType} xs={12} spacing={2}>

                    {/* Account Statement */}
                    <TabPanel value={value} index={2}>
                        <Box fontWeight="fontWeightBold" fontSize={14}>
                            <Typography variant="span">Account Statement</Typography>
                        </Box>
                        <Table className={`statement-table ${classes.table}`}>
                            <TableHead className="table_head">
                                <TableRow>
                                    <TableCell>Date/Time</TableCell>
                                    <TableCell align="right">Deposite</TableCell>
                                    <TableCell align="right">Withdraw</TableCell>
                                    <TableCell align="right">Balance</TableCell>
                                    <TableCell align="right">Remarks</TableCell>
                                    <TableCell align="right">From/To</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table_body">
                                {accountList.map((datas) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {moment(datas.createdAt).format('DD-MM-YYYY  hh:mm ')}
                                        </TableCell>
                                        <TableCell align="right" data-value={datas.transaction_type == "Credit" || datas.transaction_type != "Debit" && datas.amount > 0
                                            ? datas.amount : 0}>{datas.transaction_type == "Credit" || datas.transaction_type != "Debit" && datas.amount > 0
                                                ? parseFloat(datas.amount).toFixed(2) : "-"}</TableCell>
                                        <TableCell align="right" data-value={datas.transaction_type == "Debit" || datas.transaction_type != "Credit" && datas.amount < 0 ?
                                            datas.amount : 0}>{datas.transaction_type == "Debit" || datas.transaction_type != "Credit" && datas.amount < 0 ?
                                                parseFloat(datas.amount).toFixed(2) : "-"}</TableCell>
                                        <TableCell align="right">{datas.balance.toFixed(2)}</TableCell>
                                        <TableCell align="right">

                                            {datas.type == "Settlement" ? datas.type + " / " : ""}

                                            {datas.betting_type ? <Link to={`/bethistory/${datas._id.x}/${datas.betting_type == 'Match' ? 'Match' : datas.selection_id}/0`}>{`${datas.remarks}` + (datas.betting_type ? `(${datas.betting_type} / ${datas.place_name})` : "")}</Link> : datas.remarks}
                                        </TableCell>
                                        <TableCell align="right">
                                            {moment(datas.createdAt).format('DD-MM-YYYY  hh:mm ')}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                </Grid>
            </Grid>
        </>
    );
}
