import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';


const useStyles = (theme) => ({
  root: {
  },
  margin: {
  },
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="close-saga" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: '#FFB80C',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);


export default function Casino(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isOpen);
  const [balance, setBalance] = useState(80);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} className="saga-modal" aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" className="casino-title" onClose={handleClose}>
          <Typography variant="span" className="awcTitle">SABA Sports Book</Typography>
        </DialogTitle>
        <DialogContent className="range-show" dividers>
          <div className="left-saga">
            Main Balance
            <p>0.00</p>
          </div>
          <div className="right-saga">
            Casino Balance
            <p>0.00</p>
          </div>
          <div className="balance-show">
            <input disabled value="0" />
          </div>
        </DialogContent>
        <DialogContent className="range-parent" dividers>
          <div className="ranger-section">
            <div className="ranger-bar">
              <div className="start">0</div>
              <div className="range">
                <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={balance} />
                <div className={classes.margin} />
              </div>
              <div className="max">Max</div>
            </div>
          </div>
        </DialogContent>
        <div className="saga-login-msg">
          <p>Please Login</p>
        </div>
        <DialogActions className="saga-footer">
          <Button onClick={handleClose} className="cancel" variant="contained" >
            Cancel
          </Button>
          <Button variant="contained" disabled>
            Enter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
