import React from 'react';
import PropTypes from 'prop-types';
import Header from '../includes/Header';
import LeftSideBar from '../includes/LeftSideBar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { isMobile } from 'react-device-detect';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import Footer from '../includes/Footer';
import Appconfig from "../config/config";
import clsx from 'clsx';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Table, TableRow, TableCell, TableHead, TableBody, Box } from '@material-ui/core';
import { makeStyles, AppBar, Tabs, Tab, Typography, Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Search from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Close from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router';
import casinoimg from '../assets/images/casino.gif'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import background from '../assets/images/bg-purple.png';
import Transparent from '../assets/images/transparent.gif'
import Live from '../assets/images/live.svg';
import InPlayImg from '../assets/images/play.svg'
import cricketE from '../assets/images/cricket-e.png';
import soccerE from '../assets/images/soccer-e.png';
import tennisE from '../assets/images/tennis-e.png';
import PinOff from '../assets/images/pin-off.svg';



import Slide1 from "../assets/images/1_banner.jpg";
import Slide2 from "../assets/images/2_banner.jpg";
import Slide3 from "../assets/images/slider1.jpg";
import Slide4 from "../assets/images/slider2.jpg";
import Slide5 from "../assets/images/slider3.jpg";

import { useQuery, useQueryClient } from 'react-query';
import Carousel from "react-material-ui-carousel";
import axios from 'axios';
import moment from 'moment';
import BookmakerImg from '../assets/images/bookmaker.svg'
import FancyImg from '../assets/images/fancy.svg'
import PremiumImg from '../assets/images/premium.svg';
import { postErrorToslack } from '../config/common';
function Item(props) {
    return <img alt="skyexchange" fetchpriority="low" src={props.item.image} />;
}
function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}
var items = [
    // { image: Slide3 },
    {
        image: Slide1,
    }
];


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}
function loader_default() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "grid";
    }
}
function loader_remove() {
    if (document.getElementById("poker_loading")) {
        document.getElementById("poker_loading").style.display = "none";
    }
}
function loginAlert() {
    alert("Please Login");
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    list: {
        padding: "16px 10px"
    },
    fullList: {
        width: 'auto',
    },
    input: {
        flex: 1,
        width: '70%'
    },
    iconButton: {
        padding: 10,
        width: '10%'
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));
const SportTab = (props) => {
    const queryClient = useQueryClient();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const stotedList = JSON.parse(window.localStorage.getItem("eventListByUser"));
    const [eventListData, setEventListData] = React.useState(stotedList ? stotedList : []);
    const [eventCount, setEventCount] = React.useState({
        "cricket": 0,
        "tennis": 0,
        "soccer": 0,
    });
    const classes = useStyles();
    const rows = [
    ];

    const getDashboardDataNew = () => {
        // alert("hello 1");
        var data = JSON.stringify({
            user_id: userInfo ? userInfo._id : "",
            event_type_id: 0,
        });
        let url = "get-dashboard-data-with-user-id";
        if (!userInfo) {
            url = "get-dashboard-data-without-user-id";
        }
        return axios.post(`${Appconfig.apiUrl}eventsDashboard/${url}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
    const onSuccess = (data) => {
        try {
            if (data) {
                renderEventData(data.data.resultData);
                loader_remove();
            }
        } catch (e) {
            loader_remove();
            postErrorToslack(e.message);
        }
    }

    const diamondCasinoLogin = (gameCode = null) => {
        let data = JSON.stringify({
            'username': userInfo._id,
            'sitename': 'https://skyexchangeart.in/',
            'game': gameCode,
        })
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}diamond-casino/get-casino-link`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.casino_link) {
                        // history.push('/casino-market', { casino_path:response.data.casino_link });
                        window.location.href = response.data.casino_link
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            }).catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }

    const onCache = (data) => {
        try {
            if (data) {
                renderEventData(data);
                loader_remove();
            }
        } catch (e) {
            loader_remove();
        }
    }
    const { isLoading, isError, error, data, refetch } = useQuery('eventLists', getDashboardDataNew, {
        onSuccess
    })
    React.useEffect(() => {
        loader_remove();
        if (queryClient.getQueryData(`eventLists`)) {
            onCache(queryClient.getQueryData(`eventLists`).data.resultData);
        }
    }, [])
    const history = useHistory();
    const current = new Date();
    const today = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    const tomorrow = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate() + 1}`;
    const renderEventData = (eventData) => {
        let tempData = [];
        let inplayArr = [];
        let outPlayArr = [];
        let cricketcount = 0;
        let soccercount = 0;
        let tenniscount = 0;

        eventData.map((Crick, index) => {
            Crick.competitions.map((competition, index) => {
                competition.events.map((event, index) => {
                    if (event.event_type == 4 && event.is_inplay == "True") {
                        cricketcount += 1;
                    }
                    // if (event.marketTypes.length) {
                    let sport_type = '';
                    if (event.event_type == 4) {
                        sport_type = 'Cricket';
                    }
                    else if (event.event_type == 2) {
                        sport_type = 'Tennis';
                    }
                    else if (event.event_type == 1) {
                        sport_type = 'Soccer';
                    }
                    let eventDetail = {
                        sport: sport_type,
                        event_id: event.event_id,
                        name: event.event_name,
                        // time: moment(event.open_date).format("hh:mm"),
                        time: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("HH:mm"),
                        date: moment(new Date(event.open_date), "MM/DD/YYYY hh:mm:ss A").format("YYYY-MM-DD"),
                        // date: moment(event.open_date).format("YYYY-MM-DD"),
                        is_inplay: event.is_inplay == 'True' ? "Inplay" : "Going Inplay",
                        backFirst: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].back_1_price : 0,
                        layFirst: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].lay_1_price : 0,
                        backSecond: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].back_1_price : 0,
                        laySecond: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].lay_1_price : 0,
                        selection1Id: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketTypes[0].marketType[0].marketRunners && event.marketTypes[0].marketType[0].marketRunners.length && event.marketTypes[0].marketType[0].marketRunners[0]) ? event.marketTypes[0].marketRunners[0].selection_id : 0,
                        selection2Id: (event.marketTypes && event.marketTypes.length && event.marketTypes[0] && event.marketTypes[0].marketType && event.marketTypes[0].marketType.length && event.marketType[0].marketType[0].marketRunners && event.marketType[0].marketType[0].marketRunners.length && event.marketType[0].marketType[0].marketRunners[0]) ? event.marketType[0].marketRunners[0].selection_id : 0,
                        is_fancy: event.is_fancy,
                        is_bookmaker: event.is_bookmaker,
                        is_virtual: event.is_virtual == "Yes" ? true : false,
                        tv_link: event.is_inplay == 'True' ? event.tv_link : ""
                    }
                    if (event.is_inplay == 'True') {
                        inplayArr.push(eventDetail)
                    } else {
                        outPlayArr.push(eventDetail)
                    }
                    // }
                    if (event.event_type == 1 && event.is_inplay == "True") { soccercount += 1; }
                    if (event.event_type == 2 && event.is_inplay == "True") { tenniscount += 1; }
                });
            });
        });
        setEventCount({
            "cricket": cricketcount,
            "tennis": tenniscount,
            "soccer": soccercount,
        });
        tempData = inplayArr.concat(outPlayArr);
        setEventListData(tempData);
        window.localStorage.setItem("eventListByUser", JSON.stringify(tempData));
    };
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    const lastSegment = window.location.pathname;
    let segValue = 1
    if (lastSegment === '/soccer') {
        segValue = 1;
    } else if (lastSegment === '/tennis') {
        segValue = 2;
    }
    const [value, setValue] = React.useState(segValue);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const openSport = (urlValue) => {
        history.push(`/${urlValue}`);
    }
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const awcsport = (placeFormName, gameCode = null) => {
        let data = JSON.stringify({
            'platform': placeFormName,
            'user_name': userInfo.user_name,
            'externalURL': "https://skyexchangeart.in/",
            "gameCode": gameCode
        })
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}awc/awc-login`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                try {
                    if (response.data.result) {
                        window.location.href = response.data.resultData.url
                    }
                } catch (e) {
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                postErrorToslack(error.message);
                console.log(error);
            });
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            {['Inbox'].map((text, index) => (
                <Paper component="form" className={classes.root}>
                    <IconButton type="button" onClick={toggleDrawer(anchor, false)} className={classes.iconButton} aria-label="search">
                        <ArrowBackIos />
                    </IconButton>
                    <InputBase
                        className={classes.input}
                        placeholder="Search Events"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                    <IconButton type="button" className={classes.iconButton} aria-label="search">
                        <Close />
                    </IconButton>
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            ))}
        </div>
    );
    return (
        <>
            <Header eventList={eventListData} />
            <Grid container style={{ backgroundColor: '#F0ECE1' }}>
                {isLoggedIn ? null :
                    <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
                }
                <Grid item lg={2} xs={6} className="xs-none">
                    <LeftSideBar />
                </Grid>
                <Grid item lg={7} xs={12} className="cricket-section">
                    <div className="sportsSlider" style={{ marginTop: userInfo ? 13 : 64 }}>
                        <Carousel animation={"slide"} indicatorIconButtonProps={{ style: { display: "none", }, }}>
                            {items.map((item, i) => (
                                <Item item={item} />
                            ))}
                        </Carousel>
                    </div>
                    <Grid container spacing={2} style={{ padding: '10px' }} className="xs-padding">
                        {/* <Grid item lg={12} className="cricket-banner sportsSlider">
                        {/* <img alt="cricket" fetchpriority="low"  src={KV1} /> 
                    </Grid> */}
                        <Grid item lg={12} className="pt-0">
                            <div className="xs-sport-tab tab-sportsname">
                                <div className="sport-xs-tab">
                                    <AppBar position="static" color="default">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs example"
                                        >
                                            <Tab label={
                                                <>
                                                    <div className="aaa">Casino</div>
                                                    <span className="live-number">
                                                        <strong><img alt="skyexchange" fetchpriority="low" src={Live} /></strong> {8}
                                                    </span>
                                                </>
                                            } className="casinoTabbtn" key="casinoTab" icon={<img className='icon-casino' fetchpriority="low" src={casinoimg} />}  {...a11yProps(0)} />
                                            <Tab label={
                                                <>
                                                    <div className="aaa">Cricket</div>
                                                    <span className="live-number">
                                                        <strong><img alt="skyexchange" fetchpriority="low" src={Live} /></strong>{eventCount.cricket}
                                                    </span>
                                                </>
                                            } className="cricket-img" key="cricketTab" icon={<img fetchpriority="low" src={Transparent} />}  {...a11yProps(1)} ></Tab>
                                            {/* <Tab label="IPL Winner" className="cricket-img" icon={<img fetchpriority="low"  src={Transparent} />} onClick={() => openSport('indian-premier-league')} {...a11yProps(1)} /> */}
                                            <Tab label={
                                                <>
                                                    <div className="aaa">Soccer</div>
                                                    <span className="live-number">
                                                        <strong><img alt="skyexchange" fetchpriority="low" src={Live} /></strong> {eventCount.soccer}
                                                    </span>
                                                </>
                                            } className="soccer-img" key="soccerTab" icon={<img fetchpriority="low" src={Transparent} />}  {...a11yProps(2)} />
                                            <Tab label={
                                                <>
                                                    <div className="aaa">Tennis</div>
                                                    <span className="live-number">
                                                        <strong><img alt="skyexchange" fetchpriority="low" src={Live} /></strong> {eventCount.tennis}
                                                    </span>
                                                </>
                                            } className="tennis-img" key="tennisTab" icon={<img fetchpriority="low" src={Transparent} />}  {...a11yProps(3)} />
                                        </Tabs>
                                    </AppBar>
                                    {['top'].map((anchor) => (
                                        <React.Fragment >
                                            <Button className="search-xs" onClick={toggleDrawer('top', true)}><Search /></Button>
                                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                                {list(anchor)}
                                            </Drawer>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="clear-fix"></div>
                            </div>
                            <div className="tbl-top-head">Highlights</div>
                            <TabPanel className="inplay-tabs" key="casino" value={value} index={0}>
                                {isMobile ?
                                    <>
                                        <div className="sport-blocks" style={{ marginTop: 4, overflow: "hidden" }}>
                                            <Grid container spacing={2}>
                                                <Grid className="prx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('teenpatti/t20') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="/images/teen20.jpg" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Teenpatti T20</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('dt20') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/dt20.jpg" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">1 Day Dragon Tiger</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="prx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('lucky7') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="/images/lucky7.jpg" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Lucky 7A</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('teenpatti/oneday') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/teen.jpg" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Teenpatti One Day</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="prx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('ab20') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="/images/ab20.webp" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Andarbar</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('poker/6player') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/poker6.webp" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Poker 6 Player</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="prx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('poker/t20') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="/images/poker20.webp" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Poker T20</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('ddb') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/bollywood.webp" style={{ height: 115, width: 200 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Lucky 7B</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('card32a') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/card32.jpg" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Card 32A</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                                <Grid className="plx-4" item md={3} xs={6}>
                                                    <div className="sport-block" onClick={() => userInfo ? diamondCasinoLogin('aaa') : loginAlert()}>
                                                        <Link to="#">
                                                            <img alt="skyexchange" fetchpriority="low" src="images/aaa.webp" style={{ height: 115 }} />
                                                            <div className="bottom-title">
                                                                <div className="title">Amar Akbar Anthony</div>
                                                                <div className="play-now">Play Now</div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Grid>

                                                {/*               
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-029') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={bannerevo} />
                    <div className="bottom-title">
                      <div className="title">Bia Buu</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

                                                {/* <Grid item md={6} xs={12}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER') : loginAlert()} >
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={kingmakercasino} />
                    <div className="bottom-title">
                      <div className="title">Kingmaker games</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}


                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('VENUS') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={venus} />
                    <div className="bottom-title">
                      <div className="title">Venus</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

                                                {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-048') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={banner_horsebook} />
                    <div className="bottom-title">
                      <div className="title">HORSEBOOK</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}










                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/t20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={teenpattihalf} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}


                                                {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-020') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={baicao} />
                    <div className="bottom-title">
                      <div className="title">Bai Cao</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-035') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={bolagolek} />
                    <div className="bottom-title">
                      <div className="title">Bola Golek</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
               */}

                                                {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-043') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={dice} />
                    <div className="bottom-title">
                      <div className="title">Dice</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-037') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={hl} />
                    <div className="bottom-title">
                      <div className="title">HL</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-030') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={munda} />
                    <div className="bottom-title">
                      <div className="title">Munda</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-017') : loginAlert()} >
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={taixiu} />
                    <div className="bottom-title">
                      <div className="title">Taixiu</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block" onClick={() => userInfo ? awcsport('KINGMAKER', 'KM-TABLE-009') : loginAlert()}>
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={thai} />
                    <div className="bottom-title">
                      <div className="title">Thai</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="prx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={mojo7} />
                    <div className="bottom-title">
                      <div className="title">7Mojo</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid item md={6} xs={12}>
                <div className="sport-block">
                  <Link to="#">
                    <img alt="skyexchange" fetchpriority="low" src={slotgames} />
                    <div className="bottom-title">
                      <div className="title">Slot Games</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/t20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti T20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/oneday') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti Oneday</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('teenpatti/test') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_teenpati_t20} />
                    <div className="bottom-title">
                      <div className="title">Teen Patti Test</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('lucky7') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_lucky7a} />
                    <div className="bottom-title">
                      <div className="title">Lucky 7 A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('lucky7eu') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_lucky7b} />
                    <div className="bottom-title">
                      <div className="title">Lucky 7 B</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt6} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger 2020</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt202') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt202} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger 2020 2</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dt6') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dt6} />
                    <div className="bottom-title">
                      <div className="title">OneDay Dragon Tiger</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}
                                                {/* <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('dtl20') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_dtl20} />
                    <div className="bottom-title">
                      <div className="title">Dragon Tiger Lion 20-20</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('card32a') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_card32a} />
                    <div className="bottom-title">
                      <div className="title">32 Cards A</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('card32b') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_card32b} />
                    <div className="bottom-title">
                      <div className="title">32 Cards B</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('aaa') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_aaa} />
                    <div className="bottom-title">
                      <div className="title">Amar Akbar Anthony</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('ddb') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_Bollywood_casino} />
                    <div className="bottom-title">
                      <div className="title">Bollywood Casino</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid>
              <Grid className="plx-4" item md={3} xs={6}>
                <div className="sport-block">
                  <Link onClick={() => userInfo ? CreateJwtAndLaunchCasino('war') : loginAlert()} to="#">
                    <img alt="skyexchange" fetchpriority="low" src={api_war} />
                    <div className="bottom-title">
                      <div className="title">Casino War</div>
                      <div className="play-now">Play Now</div>
                    </div>
                  </Link>
                </div>
              </Grid> */}

                                            </Grid>
                                        </div>
                                    </>
                                    :
                                    rows.map((row) => (
                                        <TableRow to={row.link} p={1} className="odd_even_clr" button>
                                            <TableCell className="table_first_row" colSpan="5">
                                                <div className="md-none tags">
                                                    <img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" />
                                                    <img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" />
                                                    <img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" />
                                                    <span> {row.play} </span>
                                                </div>
                                                <Link to="#">
                                                    <div className="text_left-in-play">
                                                        {row.play === 'In play' ?
                                                            <FiberManualRecordIcon className="circle-size" /> :
                                                            <FiberManualRecordIcon className="circle-size" style={{ color: '#ced5da' }} />
                                                        }
                                                        <span> {row.event_id} </span>
                                                        {row.play === 'In play' ?
                                                            <span className="in-play"> {row.value} </span> :
                                                            <span className="in-play" style={{ color: "#ced5da", fontWeight: 'none' }}> {row.value} </span>
                                                        }
                                                        <span>{row.name2}</span>
                                                        {row.play === 'In play' ?
                                                            <span className="in-play xs-none"> {row.play} </span> :
                                                            <span className="in-play xs-none" style={{ color: '#ced5da' }}> {row.play} </span>
                                                        }
                                                    </div>
                                                </Link>
                                            </TableCell>
                                            <TableCell align="" className="xs-none"><img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" /></TableCell>
                                            <TableCell align="" className="xs-none"><img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" /></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.calories}</span></Link></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.fat}</span></Link></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.carbs}</span></Link></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                            <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                            <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#"></Link></TableCell>
                                        </TableRow>
                                    ))
                                }
                                {/* {!isMobile && ( */}
                                <Footer />
                                {/* )} */}
                            </TabPanel>
                            <TabPanel className="inplay-tabs" key="cricket" value={value} index={1}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="tbl_head xs-none">
                                            <TableCell colSpan="5"></TableCell>
                                            <TableCell align="right" colSpan="2"></TableCell>
                                            <TableCell align="right" colSpan="2">1</TableCell>
                                            <TableCell align="right" colSpan="2">X</TableCell>
                                            <TableCell align="right" colSpan="2">2</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="cricket_tbl_body">
                                        {isMobile ?
                                            eventListData.map((row) => (
                                                row.sport == "Cricket" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} to="#" p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none circle-colorr" />
                                                                {row.is_virtual && <SportsEsportsIcon className="vrIcon" />}
                                                                {/* <img fetchpriority="low"  src={InPlayImg} alt="skyexchange" /> */}
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img fetchpriority="low" src={InPlayImg} alt="skyexchange" />
                                                                        : null
                                                                }
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                        : null
                                                                }

                                                                {
                                                                    row.is_fancy == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src="/images/clock-green.png"
                                                                                height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" />
                                                                        </> : null
                                                                }
                                                                {
                                                                    row.is_bookmaker == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src="/images/clock-green.png"
                                                                                height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                        </> : null
                                                                }
                                                                {
                                                                    <span className="time">{row.is_inplay == 'Inplay' ? <><span className="time green">In-Play </span></> : row.date == today ? row.time : row.date == tomorrow ? `Tomorrow ${row.time}` : `${row.date} ${row.time}`}</span>
                                                                }
                                                                <span className="">{row.play}</span>
                                                                <span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={cricketE} alt="cricket" /></span>
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        :
                                                                        <FiberManualRecordIcon className="circle-size" />
                                                                }
                                                                <Link to="#" style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        <span>
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                        </span>
                                                                        {/* <span className="in-play"> v </span> */}
                                                                        {/* <span>{row.name2}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.play} <img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" />
                                                                </span>
                                                                <span className="game-name xs-none"><img fetchpriority="low" src={cricketE} alt="cricket" /></span>
                                                                <span className="in-play xs-none icon-on-right"><img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" /> </span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.calories}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.fat}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img fetchpriority="low" src={PinOff} /></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                            :
                                            eventListData.map((row) => (
                                                row.sport == "Cricket" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row">
                                                            <div className="md-none tags">
                                                                {row.name}
                                                            </div>
                                                            <Link to="#">
                                                                <div className="text_left-in-play">
                                                                    {row.play === 'In play' ?
                                                                        <FiberManualRecordIcon className="circle-size" /> :
                                                                        <FiberManualRecordIcon className="circle-size" style={{ color: '#ced5da' }} />
                                                                    }
                                                                    <span> {row.event_id} </span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play"> {row.value} </span> :
                                                                        <span className="in-play" style={{ color: "#ced5da", fontWeight: 'none' }}> {row.value} </span>
                                                                    }
                                                                    <span>{row.name2}</span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play xs-none"> {row.play} </span> :
                                                                        <span className="in-play xs-none" style={{ color: '#ced5da' }}> {row.play} </span>
                                                                    }
                                                                </div>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell colspan="5" align="left" className="name-event-cric">{row.name}{row.is_inplay != "True" ? <><span className="time green">In-Play </span></> : null}</TableCell>
                                                        <TableCell align="left" className="desktop-inplay-xs-title">
                                                            {
                                                                row.is_fancy == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" />
                                                                    </> : null
                                                            }
                                                            {
                                                                row.is_bookmaker == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                    </> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.calories}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.fat}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.carbs}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#"></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                {/* {!isMobile && ( */}
                                <Footer />
                                {/* )} */}
                            </TabPanel>
                            <TabPanel className="inplay-tabs" key="soccer" value={value} index={2}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="tbl_head xs-none">
                                            <TableCell colSpan="5"></TableCell>
                                            <TableCell align="right" colSpan="2"></TableCell>
                                            <TableCell align="right" colSpan="2">1</TableCell>
                                            <TableCell align="right" colSpan="2">X</TableCell>
                                            <TableCell align="right" colSpan="2">2</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="cricket_tbl_body">
                                        {isMobile ?
                                            eventListData.map((row) => (
                                                row.sport == "Soccer" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} to="#" p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none circle-colorr" />
                                                                {/* <img fetchpriority="low"  src={InPlayImg} alt="skyexchange" /> */}
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img fetchpriority="low" src={InPlayImg} alt="skyexchange" />
                                                                        : null
                                                                }
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                        : null
                                                                }
                                                                {/* {
                                                                    row.is_fancy == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src="/images/clock-green.png" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" />
                                                                        </> : null
                                                                }
                                                                {
                                                                    row.is_bookmaker == "True" ?
                                                                        <>
                                                                            <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                            <img src="/images/clock-green.png" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                        </> : null
                                                                } */}
                                                                {
                                                                    <span className="time">{row.is_inplay == 'Inplay' ? <><span className="time green">In-Play </span></> : row.date == today ? row.time : row.date == tomorrow ? `Tomorrow ${row.time}` : `${row.date} ${row.time}`}</span>
                                                                }
                                                                <span className="">{row.play}</span>
                                                                {/* <span className="game-name xs-game-name"><img fetchpriority="low"  src={soccerE} alt="cricket" /></span> */}
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        :
                                                                        <FiberManualRecordIcon className="circle-size" />
                                                                }
                                                                <Link to="#" style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        <span>
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                        </span>
                                                                        {/* <span className="in-play"> v </span> */}
                                                                        {/* <span>{row.name2}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                {/* <span className="in-play xs-none"> {row.play} <img alt="bookMark" fetchpriority="low" src={bookmarkwithtime} /> </span> */}
                                                                <span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={soccerE} alt="cricket" /></span>
                                                                {/* <span className="game-name xs-none"><img fetchpriority="low" src={cricketE} alt="cricket" /></span> */}
                                                                {/* <span className="in-play xs-none icon-on-right"><img alt="bookMark" fetchpriority="low" src={bookmarkwithtime} /> </span> */}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.calories}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.fat}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img fetchpriority="low" src={PinOff} /></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                            :
                                            eventListData.map((row) => (
                                                row.sport == "Soccer" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row">
                                                            <div className="md-none tags">
                                                                {row.name}
                                                            </div>
                                                            <Link to="#">
                                                                <div className="text_left-in-play">
                                                                    {row.play === 'In play' ?
                                                                        <FiberManualRecordIcon className="circle-size" /> :
                                                                        <FiberManualRecordIcon className="circle-size" style={{ color: '#ced5da' }} />
                                                                    }
                                                                    <span> {row.event_id} </span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play"> {row.value} </span> :
                                                                        <span className="in-play" style={{ color: "#ced5da", fontWeight: 'none' }}> {row.value} </span>
                                                                    }
                                                                    <span>{row.name2}</span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play xs-none"> {row.play} </span> :
                                                                        <span className="in-play xs-none" style={{ color: '#ced5da' }}> {row.play} </span>
                                                                    }
                                                                </div>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell colspan="5" align="left" className="name-event-cric">{row.name}{row.is_inplay != "True" ? <><span className="time green">In-Play </span></> : null}</TableCell>
                                                        <TableCell align="left" className="desktop-inplay-xs-title">
                                                            {
                                                                row.is_fancy == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" />
                                                                    </> : null
                                                            }
                                                            {
                                                                row.is_bookmaker == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                    </> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.calories}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.fat}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.carbs}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#"></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                {/* {!isMobile && ( */}
                                <Footer />
                                {/* )} */}
                            </TabPanel>
                            <TabPanel className="inplay-tabs" key="tennis" value={value} index={3}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="tbl_head xs-none">
                                            <TableCell colSpan="5"></TableCell>
                                            <TableCell align="right" colSpan="2"></TableCell>
                                            <TableCell align="right" colSpan="2">1</TableCell>
                                            <TableCell align="right" colSpan="2">X</TableCell>
                                            <TableCell align="right" colSpan="2">2</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="cricket_tbl_body">
                                        {isMobile ?
                                            eventListData.map((row) => (
                                                row.sport == "Tennis" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} to="#" p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row" colSpan="5">
                                                            <div className="md-none inplay-xs-title">
                                                                <FiberManualRecordIcon className="circle-size v-none circle-colorr" />
                                                                {/* <img fetchpriority="low"  src={InPlayImg} alt="skyexchange" /> */}
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img fetchpriority="low" src={InPlayImg} alt="skyexchange" />
                                                                        : null
                                                                }
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <img src={PremiumImg} alt="skyexchange" className="premiumicon" />
                                                                        : null
                                                                }
                                                                {
                                                                    row.is_fancy == "True" ?
                                                                        <>
                                                                            {/* <FiberManualRecordIcon className="circle-size v-none game-fancy" /> */}
                                                                            {/* <img src="/images/clock-green.png" className="clockImg" alt="skyexchange" /><img src="/images/clock-green.png" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" /> */}
                                                                        </> : null
                                                                }
                                                                {
                                                                    row.is_bookmaker == "True" ?
                                                                        <>
                                                                            {/* <FiberManualRecordIcon className="circle-size v-none game-fancy" /> */}
                                                                            {/* <img src="/images/clock-green.png" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" /> */}
                                                                        </> : null
                                                                }
                                                                {
                                                                    <span className="time">{row.is_inplay == 'Inplay' ? <><span className="time green">In-Play </span></> : row.date == today ? row.time : row.date == tomorrow ? `Tomorrow ${row.time}` : `${row.date} ${row.time}`}</span>
                                                                }
                                                                <span className="">{row.play}</span>
                                                                <span className={`game-name ${row.name.includes('T10') ? '' : 'xs-none'}`}><img src={tennisE} alt="cricket" /></span>
                                                                {/* <span className="game-name xs-game-name"><img fetchpriority="low"  src={cricketE} alt="cricket" /></span> */}
                                                            </div>
                                                            <div className="text_left-in-play">
                                                                {
                                                                    row.is_inplay == "Inplay" ?
                                                                        <FiberManualRecordIcon className="circle-size circle-inplay" />
                                                                        :
                                                                        <FiberManualRecordIcon className="circle-size" />
                                                                }
                                                                <Link to="#" style={{ color: '#2789CE' }}>
                                                                    <Box display="contents">
                                                                        <span>
                                                                            {
                                                                                row.name.length > 36 ?
                                                                                    `${row.name.substring(0, 36)}...` :
                                                                                    row.name
                                                                            }
                                                                        </span>
                                                                        {/* <span className="in-play"> v </span> */}
                                                                        {/* <span>{row.name2}</span> */}
                                                                    </Box>
                                                                </Link>
                                                                <span className="in-play xs-none"> {row.play} <img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" /> </span>
                                                                <span className="in-play xs-none icon-on-right"><img alt="bookMark" fetchpriority="low" src="/images/bookmarkwithtime.png" /> </span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.calories}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.fat}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.carbs}</span></TableCell>
                                                        <TableCell className="xs-none" align="right"><span>{row.protein}</span></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#" className="inplay-last-icon"><img fetchpriority="low" src={PinOff} /></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                            :
                                            eventListData.map((row) => (
                                                row.sport == "Tennis" ?
                                                    <TableRow onClick={() => history.push(`/full-market/${row.event_id}/${row.is_inplay}`, { tv_link: row.tv_link })} p={1} className="odd_even_clr" button>
                                                        <TableCell className="table_first_row">
                                                            <div className="md-none tags">
                                                                {row.name}
                                                            </div>
                                                            <Link to="#">
                                                                <div className="text_left-in-play">
                                                                    {row.play === 'In play' ?
                                                                        <FiberManualRecordIcon className="circle-size" /> :
                                                                        <FiberManualRecordIcon className="circle-size" style={{ color: '#ced5da' }} />
                                                                    }
                                                                    <span> {row.event_id} </span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play"> {row.value} </span> :
                                                                        <span className="in-play" style={{ color: "#ced5da", fontWeight: 'none' }}> {row.value} </span>
                                                                    }
                                                                    <span>{row.name2}</span>
                                                                    {row.play === 'In play' ?
                                                                        <span className="in-play xs-none"> {row.play} </span> :
                                                                        <span className="in-play xs-none" style={{ color: '#ced5da' }}> {row.play} </span>
                                                                    }
                                                                </div>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell colspan="5" align="left" className="name-event-cric">{row.name}{row.is_inplay != "True" ? <><span className="time green">In-Play </span></> : null}</TableCell>
                                                        <TableCell align="left" className="desktop-inplay-xs-title">
                                                            {
                                                                row.is_fancy == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={FancyImg} alt="skyexchange" className="fancy-f-icon" />
                                                                    </> : null
                                                            }
                                                            {
                                                                row.is_bookmaker == "True" ?
                                                                    <>
                                                                        <FiberManualRecordIcon className="circle-size v-none game-fancy" />
                                                                        <img src="/images/clock-green.png" height="16" className="clockImg" alt="skyexchange" /><img fetchpriority="low" src={BookmakerImg} alt="skyexchange" className="bookmarker-f-icon" />
                                                                    </> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.calories}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.fat}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.carbs}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell align="right" className="xs-none"><Link to="#"><span>{row.protein}</span></Link></TableCell>
                                                        <TableCell className="pin-icon" style={{ width: "30px" }}><Link to="#"></Link></TableCell>
                                                    </TableRow> : ""
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                {/* {!isMobile && ( */}
                                <Footer />
                                {/* )} */}
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} xs={12} className="betSlipGrid xs-none">
                    <Accordion defaultExpanded={true} style={{ marginTop: 10 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className="bet-slip-bar" style={{ backgroundImage: `url(${background})` }}>
                            <Typography>Bet Slip</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="d-none bet-slip-block" display="inline-block" style={{ height: "100%" }}>
                            <Typography className="bet-slip-content">Click on the odds to add selections to the betslip.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    )
}
export default React.memo(SportTab);