import React, { useState } from 'react'
import Header from '../includes/Header'
import BetSlip from '../includes/BetSlip';
import LeftSideBar from '../includes/LeftSideBar';
import MultimarketComponent from './MultimarketComponent';
import { Grid, Table, TableRow, TableCell, TableHead, TableBody, Link, makeStyles } from '@material-ui/core'
import { ArrowForwardIos, MoreVert } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import GreenPin from '../assets/images/green-pin-off.svg';
import MinMax from '../assets/images/min-max.svg';
import Depth from '../assets/images/depth.svg';
import BackballDisabled from '../assets/images/bg-backall-disabled.png'
import transparent from '../assets/images/transparent.gif';
import pin from '../assets/images/pin.png';
import darkPin from '../assets/images/dark-pin-off.svg';
import Time from '../assets/images/time.png';

import ReplayIcon from '@material-ui/icons/Replay';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Loader from '../assets/images/loading40.gif';
import axios from 'axios';
import Appconfig from "../config/config";
import { postErrorToslack } from '../config/common';
const mainMenu = () => {
    return (
        <div>
            <MoreVert /> <ArrowForwardIos />
        </div>
    )
}

function TableRowLink(props) {
    return <TableRow button component="Link" {...props} />;
}

const useStyles = makeStyles((theme) => ({

    tableHeight: {
        height: 'calc(100vh - 120px)',
    },
    root: {
        minWidth: 275,
        height: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));
function loader_default() {
    document.getElementById("poker_loading").style.display = "grid";
}
function loader_remove() {
    document.getElementById("poker_loading").style.display = "none";
}
const MultiMarket = () => {
    const history = useHistory();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [list, setList] = React.useState([]);
    const classes = useStyles();
    const [match, setMatch] = useState(true)
    const isLoggedIn = window.sessionStorage.getItem("loggedIn") && window.sessionStorage.getItem("loggedIn") != "false" ? true : false;
    React.useEffect(() => {
        if (!userInfo) {
            history.push("/login")
        }
        else {
            getMypins();
        }

    }, []);
    function getMypins() {
        loader_default();
        var data = JSON.stringify({
            user_id: userInfo._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getFullMarket`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                try {
                    loader_remove();
                    setList(response.data.resultData);
                } catch (e) {
                    loader_remove();
                    postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                postErrorToslack(error.message);
            });
    }
    return (
        <>
            <Header />
            {console.log(list)}
            {isLoggedIn ? null :
                <div id="poker_loading" className="loading-wrap" style={{ display: "flex" }}><div class="loading"><div></div><div></div></div><p>Loading...</p></div>
            }
            <Grid container>
                <Grid item lg={2} xs={12} className="xs-none">
                    <LeftSideBar />
                </Grid>
                <Grid lg={7} xs={12} style={{ marginBottom: 48 }}>
                    {list.length > 0 && list.map((val, index) => {
                        return <MultimarketComponent event_id={val.event_id} match_type={val.type == "Fancy" ? "Fancy" : val.market_id.includes("_BM") ? "Bookmaker" : "MatchOdds"} />
                    })}
                    {list.length <= 0 && <>
                        <Card className="mulmarket-nodata">
                            <CardContent>

                                <Typography className="mulmarketp1" variant="h5" component="h2">
                                    There are currently no followed multi markets.
                                </Typography>
                                <Typography className="mulmarketp2" color="textSecondary" gutterBottom>
                                    Please add some markets from events.
                                </Typography>

                            </CardContent>

                        </Card>
                    </>}
                </Grid>
            </Grid>
        </>
    )
}

export default MultiMarket